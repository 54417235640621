import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { createUser, signupUser } from "../../services/userService";
import { axiosErrorToString } from "../../services/restful/utils";
import Logo from "../../components/Logo";
import { AUTH_TOKEN, COPY_SLOGAN, UserStatus } from "../../const";
import { AuthContext } from "../../contexts/AuthContext";
import { login } from "../../services/authService";
import { PATHS } from "../../router";

function SignupPage() {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [idHash, setIdHash] = useState("");
  const [passHash, setPassHash] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [status] = useState(UserStatus.ACTIVE);
  const [formError, setFormError] = useState(null);
  const auth = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const location = useLocation();
  const from = location.state?.from?.pathname || PATHS.CLIENTS;

  const navigate = useNavigate();

  useEffect(() => {
    setEmail(searchParams.get("e"));
    setFullName(searchParams.get("n"));
    setPassHash(searchParams.get("pwd"));
    setIdHash(searchParams.get("id"));
  }, [searchParams]);

  const strongRegex =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!strongRegex.test(password)) {
      setFormError(true);
    } else if (password !== confirmPassword) {
      setFormError("Passwords must match");
    } else {
      try {
        const response = searchParams.get("id")
          ? await signupUser({
              id_hash: idHash,
              password_hash: passHash,
              password,
              full_name: fullName,
              status,
            })
          : await createUser({
              email,
              password,
              full_name: fullName,
              status,
              is_superuser: false,
            });
        if (response.status >= 200 && response.status <= 299) {
          const credential = { email, password };
          try {
            const loginResponse = await login(credential);
            const { token } = loginResponse.data;
            localStorage.setItem(AUTH_TOKEN, token);
            auth.signin(token);
            navigate(from, { replace: true });
          } catch (err) {
            switch (err.name) {
              case "AxiosError":
                setFormError(err.response?.data?.Message || "Unexpected Error");
                break;
              case "TokenExpiredError":
                setFormError("Token Expired");
                break;
              default:
                throw err;
            }
          }
        }
      } catch (err) {
        const message = axiosErrorToString(err);
        setFormError(message || "Unexpected Error");
      }
    }
  };

  const { user } = useContext(AuthContext);
  if (user) {
    return <Navigate to={PATHS.CLIENTS} replace />;
  }

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <form onSubmit={handleSignup}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Logo sx={{ marginBottom: "2rem" }} />
          <Typography align="left" variant="p">
            {COPY_SLOGAN}
          </Typography>
          <Typography align="left" variant="h1">
            Sign Up
          </Typography>
          <TextField
            variant="outlined"
            sx={{ marginBottom: "1rem" }}
            type="text"
            placeholder="Full Name"
            label="Full Name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          <TextField
            variant="outlined"
            sx={{ marginBottom: "1rem" }}
            type="text"
            placeholder="Email/User ID"
            label="Email/User ID"
            value={email}
            disabled={!!searchParams.get("e")}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormControl sx={{ marginBottom: "1rem" }} variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formError !== null && (
              <FormHelperText error>{formError}</FormHelperText>
            )}
          </FormControl>
          <Typography variant="body3" color={formError ? "error" : "InfoText"}>
            Password must include:
          </Typography>
          <Typography variant="body3" color={formError ? "error" : "InfoText"}>
            - At least 8 characters
          </Typography>
          <Typography variant="body3" color={formError ? "error" : "InfoText"}>
            - At least 1 lowercase AND 1 uppercase letter
          </Typography>
          <Typography variant="body3" color={formError ? "error" : "InfoText"}>
            - At least 1 special character
          </Typography>
          <Typography
            variant="body3"
            color={formError ? "error" : "InfoText"}
            sx={{ marginBottom: "1rem" }}
          >
            - At least 1 number
          </Typography>
          <FormControl sx={{ marginBottom: "1rem" }} variant="outlined">
            <InputLabel htmlFor="password">Re-enter Password</InputLabel>
            <OutlinedInput
              id="confirmPassword"
              type={showPassword ? "text" : "password"}
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              label="Password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formError !== null && (
              <FormHelperText error>{formError}</FormHelperText>
            )}
          </FormControl>
          <Button
            type="submit"
            variant="contained"
            size="large"
            sx={{ marginBottom: "1rem" }}
            disabled={
              fullName === "" ||
              email === "" ||
              password === "" ||
              confirmPassword === ""
            }
          >
            Sign Up
          </Button>
          <Typography>
            Already have an account?
            <Button
              onClick={() => navigate(PATHS.LOGIN)}
              sx={{ paddingLeft: "12px" }}
            >
              Log in
            </Button>
          </Typography>
        </Box>
      </form>
    </Container>
  );
}

export default SignupPage;
