import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Client } from "src/interfaces/IClient";
import { updateClient } from "../../services/clientService";
import { Country } from "../../const";

type Props = {
  isEditing: boolean;
  onClose: () => void;
  client: Client;
  handleClientUpdated: (client: Client) => void;
};

function EditClientForm({
  isEditing,
  onClose,
  client,
  handleClientUpdated,
}: Props) {
  const [inputCountry, setInputCountry] = useState("");
  const [inputClientName, setinputClientName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const clientId = client.client_id;
      const updatedClient = {
        client_name: inputClientName,
        country: inputCountry,
      };
      const response = await updateClient({ clientId, ...updatedClient });
      handleClientUpdated(response.data);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setInputCountry(client.country);
    setinputClientName(client.client_name);
  }, [client]);
  return (
    <form onSubmit={handleFormSubmit}>
      <Box sx={{ display: "flex" }}>
        <Box
          sx={{
            width: "100%",
            maxWidth: "15rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "0 1rem 1rem 0",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", marginBottom: ".5rem" }}
          >
            Country
          </Typography>
          {isEditing ? (
            <FormControl fullWidth required variant="outlined">
              <Select
                size="small"
                value={inputCountry}
                onChange={(e) => setInputCountry(e.target.value)}
                sx={{ textAlign: "left" }}
              >
                {Object.values(Country).sort().map(countryName => (
                  <MenuItem value={countryName}>{countryName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          ) : (
            <Typography variant="body2">{client.country}</Typography>
          )}
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "15rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "0 1rem 1rem 0",
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontWeight: "bold", marginBottom: ".5rem" }}
          >
            Organization Name
          </Typography>
          {isEditing ? (
            <TextField
              fullWidth
              value={inputClientName}
              onChange={(e) => setinputClientName(e.target.value)}
              size="small"
              placeholder="Organization Name"
            />
          ) : (
            <Typography variant="body2">{client.client_name}</Typography>
          )}
        </Box>
      </Box>
      {isEditing && isLoading && <CircularProgress />}
      {isEditing && !isLoading && (
        <Box>
          <Button
            type="submit"
            variant="contained"
            sx={{ marginRight: "1rem" }}
          >
            Save
          </Button>
          <Button onClick={() => onClose()} variant="outlined">
            Cancel
          </Button>
        </Box>
      )}
    </form>
  );
}

export default EditClientForm;
