import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { ApolloClient, useApolloClient } from "@apollo/client";
import {
  mutateRemoveDevice,
  editDeviceCache,
} from "src/services/graphql/Device";
import { ClientDBName } from "src/interfaces/IClient";
import { Device } from "src/interfaces/IDevice";
import * as Nullable from "src/helper/nullable";

async function remove(
  gqlClient: ApolloClient<Object>,
  clientDBName: ClientDBName,
  devie: Device
) {
  return gqlClient.mutate({
    mutation: mutateRemoveDevice(clientDBName),
    variables: {
      device_id: devie.device_id,
    },
    update: editDeviceCache(clientDBName, "update_"),
  });
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  device: Nullable.T<Device>;
  clientDBName: ClientDBName;
  onRemove: () => Promise<void>;
};

export default function RemoveDeviceModal({
  isOpen,
  onClose,
  device,
  clientDBName,
  onRemove,
}: Props) {
  const gqlClient = useApolloClient();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const onRemoveDevice = () => {
    if (device) {
      setIsLoading(true);
      remove(gqlClient, clientDBName, device)
        .then((result) => {
          if (result) {
            onRemove().then(() => onClose());
          } else {
            console.error("no return result, something went wrong");
          }
        })
        .catch(console.error)
        .then(() => setIsLoading(false));
    }
  };

  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogTitle>
        <Typography variant="h7">Remove Device?</Typography>
      </DialogTitle>
      <DialogContent>
        Are you sure you want to remove this device?
      </DialogContent>
      <DialogActions>
        <Button color="info" variant="outlined" onClick={() => onClose()}>
          Cancel
        </Button>
        <Button
          color="danger"
          variant="contained"
          onClick={() => onRemoveDevice()}
          disabled={isLoading}
        >
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}
