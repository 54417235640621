import { useApolloClient, useQuery } from "@apollo/client";
import { ArrowBack, FileUpload } from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PropTypes } from "prop-types";
import PageContainer from "../../components/Layout/PageContainer";
import { ClientContext } from "../../contexts/GqlContext";
import { propertyQuery, zoneQuery } from "../../services/graphqlService";
import { PATHS, resolvePath } from "../../router";
import { mutateAddZones, addZoneCache } from "../../services/graphql/Zone";

export default function NewZonePage() {
  const { clientId } = useParams();
  const navigate = useNavigate();
  const [newZones, setNewZones] = useState([{}]);
  const {
    client: { client_dbname: clientDBName },
  } = useContext(ClientContext);

  const properties = useQuery(propertyQuery(clientDBName));
  const zones = useQuery(zoneQuery(clientDBName));

  const gqlClient = useApolloClient();

  const updateNewZones = (zoneNum, field, value) => {
    const tempNewZones = [...newZones];
    tempNewZones[zoneNum][field] = value;
    setNewZones(tempNewZones);
  };

  const handleZoneAdd = () => {
    const tempNewZones = [...newZones];
    tempNewZones.push({});
    setNewZones(tempNewZones);
  };

  const saveChanges = () => {
    const mutation = mutateAddZones(clientDBName);
    gqlClient.mutate({
      mutation,
      variables: {
        objects: newZones.map((zone) => ({
          zone_name: zone.zone_name,
          property_id: zone.property_id,
          floor: zone.floor,
        })),
      },
      update: addZoneCache(clientDBName),
    });
    navigate(resolvePath(PATHS.ZONES, { ":clientId": clientId }));
  };

  return (
    <PageContainer isLoading={properties.loading || zones.loading}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton
          onClick={() =>
            navigate(resolvePath(PATHS.ZONES, { ":clientId": clientId }))
          }
        >
          <ArrowBack sx={{ width: "32px", height: "32px" }} />
        </IconButton>
        <Typography variant="h3" sx={{ margin: "0 0 0 1rem" }}>
          Add New Zone
        </Typography>
      </Box>
      {newZones.map((element, index) => (
        <>
          <ZoneSection
            zoneNum={index}
            updateNewZones={updateNewZones}
            zones={zones.data[`${clientDBName}_zone`]}
            properties={properties.data[`${clientDBName}_property`]}
          />
          <Divider />
        </>
      ))}
      <Divider />
      <Box
        sx={{
          display: "flex",
          margin: "2rem 0",
        }}
      >
        <Button variant="contained" onClick={handleZoneAdd}>
          + Add Zone
        </Button>
      </Box>
      <Divider />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          margin: "3rem",
        }}
      >
        <Button variant="contained" onClick={saveChanges}>
          Save Change(s)
        </Button>
      </Box>
    </PageContainer>
  );
}

function ZoneSection({ zoneNum, updateNewZones, zones, properties }) {
  const [addedFloors, setAddedFloors] = useState([]);
  const [removeButtons, setRemoveButtons] = useState([]);
  const [curProperty, setCurProperty] = useState(0);
  const [inputFloorVal, setInputFloorVal] = useState("");

  // creates Set of JSON strings to remove duplicates, then parses unique list to JSON objects
  const floors = [
    ...new Set(
      zones.map(
        (zoneItem) =>
          `{"floor":"${zoneItem.floor}","property_id":${zoneItem.property_id}}`
      )
    ),
  ].map((jsonStr) => JSON.parse(jsonStr));

  const handlePropertySelect = (event) => {
    updateNewZones(zoneNum, "property_id", event.target.value.property_id);
    setCurProperty(event.target.value.property_id);
  };

  const handleFloorSelect = (event) => {
    updateNewZones(zoneNum, "floor", event.target.value);
    const curIndex = addedFloors.indexOf(event.target.value);
    const tempRemoveButtons = new Array(removeButtons.length).fill("x");
    if (curIndex !== -1) {
      tempRemoveButtons[curIndex] = "";
    }
    setRemoveButtons(tempRemoveButtons);
  };

  const handleFloorAdd = () => {
    setAddedFloors([...addedFloors, inputFloorVal]);
    setRemoveButtons([...removeButtons, "x"]);
  };

  const handleFloorRemove = (event) => {
    const indexToRemove = addedFloors.indexOf(event.target.value);
    setAddedFloors(addedFloors.filter((val, index) => index !== indexToRemove));
    setRemoveButtons(
      removeButtons.filter((val, index) => index !== indexToRemove)
    );
  };

  return (
    <Box
      sx={{
        padding: "1rem 2rem",
      }}
    >
      <Box
        sx={{
          display: "flex",
          margin: "2rem 0",
        }}
      >
        <Typography
          align="left"
          variant="body1"
          sx={{ fontWeight: 900, width: "10rem" }}
          fontSize="24px"
        >
          Zone {zoneNum + 1}
        </Typography>
        <Grid sx={{ flex: "1", marginLeft: "1rem" }} container spacing={2}>
          <Grid item sm={12}>
            <Typography align="left" sx={{ fontWeight: 700 }} variant="body2">
              Zone Info
            </Typography>
          </Grid>
          <Grid item sm={12} md={4}>
            <FormControl fullWidth required variant="outlined">
              <InputLabel>Select Property</InputLabel>
              <Select onChange={handlePropertySelect}>
                {properties.map((property) => (
                  <MenuItem value={property}>{property.property_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={4}>
            <FormControl fullWidth required variant="outlined">
              <InputLabel id="select-floor">Select Floor</InputLabel>
              <Select onChange={handleFloorSelect}>
                {floors
                  .filter((floor) => floor.property_id === curProperty)
                  .map((floor) => (
                    <MenuItem value={floor.floor}>{floor.floor}</MenuItem>
                  ))}
                {addedFloors.map((floor) => (
                  <MenuItem
                    value={floor}
                    sx={{ justifyContent: "space-between" }}
                  >
                    {floor}
                    <Button onClick={handleFloorRemove} value={floor}>
                      {removeButtons[addedFloors.indexOf(floor)]}
                    </Button>
                  </MenuItem>
                ))}
                <Box>
                  <Grid
                    container
                    spacing={1}
                    justifyContent="flex-end"
                    alignItems="center"
                  >
                    <MenuItem
                      item
                      sm={8}
                      md={8}
                      // this prevents the textbox from being recognized as a selectable option
                      onClickCapture={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      <TextField
                        required
                        fullWidth
                        onChange={(e) => setInputFloorVal(e.target.value)}
                        // this deactivates the typing search feature
                        onKeyDown={(e) => e.stopPropagation()}
                      />
                    </MenuItem>
                    <MenuItem item sm={4} md={4}>
                      <Button
                        size="large"
                        variant="contained"
                        onClick={handleFloorAdd}
                      >
                        Add
                      </Button>
                    </MenuItem>
                  </Grid>
                </Box>
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={12} md={4}>
            <TextField
              required
              fullWidth
              placeholder="Zone"
              label="Zone"
              onChange={(e) =>
                updateNewZones(zoneNum, "zone_name", e.target.value)
              }
            />
          </Grid>
          <Grid container item sm={12} justifyContent="flex-start">
            <Button variant="text" startIcon={<FileUpload />}>
              Upload Floor Plan
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ZoneSection.propTypes = {
  zoneNum: PropTypes.number.isRequired,
  updateNewZones: PropTypes.func.isRequired,
  zones: PropTypes.arrayOf(PropTypes.shape()),
  properties: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

ZoneSection.defaultProps = {
  zones: [],
};
