import { httpAuthorizedRequest } from "./httpService";

const createUser = (user) => httpAuthorizedRequest.post("/v1/users", user);

const getUsers = () => httpAuthorizedRequest.get("/v1/users");

const inviteUser = (user) =>
  httpAuthorizedRequest.post("/v1/users/invite", user);

const signupUser = (user) =>
  fetch(`${process.env.REACT_APP_API_DOMAIN}/v1/users/signup`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(user),
  });

function randomString() {
  const array = new Uint8Array(4); // produces string of length 2^4 = 16
  (window.crypto || window.msCrypto).getRandomValues(array);
  return Array.from(array)
    .map((randomCode) => btoa(randomCode.toString()))
    .join("");
}

// eslint-disable-next-line import/prefer-default-export
export { createUser, getUsers, inviteUser, signupUser, randomString };
