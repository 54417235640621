import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { Typography, Button, Stack, CircularProgress } from "@mui/material";
import { Client } from "src/interfaces/IClient";
import { getClients } from "src/services/restful/clients";
import { axiosErrorToString } from "src/services/restful/utils";
import { useNavigate, useParams } from "react-router-dom";
import PageContainer from "src/components/Layout/PageContainer";
import GqlProvider from "src/contexts/GqlContext";
import SelectClient from "./SelectClient";
import ClientFleetManagement from "../../ClientFleetManagement";
import * as PageController from "../../PageController";
import { makeViewProgramPage } from "../ViewProgram";
import * as SelectProperty from "./SelectProperty";
import { emitFailed } from "../../ErrorToast";

export const pageName = PageController.defaultPageName;

const useClients = () => {
  const [clients, setClients] = useState<Array<Client>>([]);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setIsLoading(true);
    getClients()
      .then((res) => {
        setClients(
          res.data.sort((c1, c2) =>
            c1.client_name.localeCompare(c2.client_name)
          )
        );
      })
      .catch((err) => {
        let errMsg = axiosErrorToString(err);
        errMsg = errMsg || "Unexpected error";
        emitFailed("Fetch clients data failed", errMsg);
      })
      .then(() => setIsLoading(false));
  }, []);

  return { clients, isLoading };
};

type SelectClientAndPropertyProps = {
  clients: Client[];
  onChangeClientId: (id: number) => void;
  onChangePropertyId: (id: undefined | number) => void;
  clientId: undefined | number;
  propertyId: undefined | number;
};

function SelectClientAndProperty({
  clients,
  onChangeClientId,
  onChangePropertyId,
  clientId,
  propertyId,
}: SelectClientAndPropertyProps) {
  const client = useMemo(
    () => clients.find((c) => c.client_id === clientId),
    [clients, clientId]
  );

  const [hidden, setHidden] = useState<"pending" | boolean>("pending");

  useEffect(() => {
    if (clients.length === 1) {
      const id = clients[0].client_id;
      onChangeClientId(id);
      setHidden(true);
    } else if (clients.length > 1) {
      setHidden(false);
    }
  }, [clients, onChangeClientId]);

  return hidden === "pending" ? (
    <CircularProgress size={14} />
  ) : (
    <Stack direction="row" gap={3} sx={{ marginBottom: 7 }}>
      {hidden ? null : (
        <SelectClient
          sx={{ flex: "0 1 50%" }}
          clients={clients}
          clientId={clientId}
          onChange={(id) => {
            onChangeClientId(id);
            onChangePropertyId(undefined);
          }}
        />
      )}
      {client ? (
        <GqlProvider noLoadingAnimation>
          <SelectProperty.Component
            sx={{ flex: "0 1 50%" }}
            client={client}
            onChange={(id: number) => onChangePropertyId(id)}
            propertyId={propertyId}
          />
        </GqlProvider>
      ) : (
        <SelectProperty.Fake sx={{ flex: "0 1 50%" }} />
      )}
    </Stack>
  );
}

function parseOptionalInt(s: undefined | number | string) {
  if (s === undefined) return undefined;
  if (typeof s === "number") return s;
  const int = parseInt(s, 10);
  if (Number.isNaN(int)) {
    return undefined;
  }
  return int;
}

export function Component() {
  const { clients, isLoading } = useClients();
  const { clientId: clientIdFromParams } = useParams();
  const clientId = parseOptionalInt(clientIdFromParams);

  const navigate = useNavigate();
  const { pushToPage } = useContext(PageController.Context);
  const selectClientId = useCallback(
    (cid: number) => {
      if (cid !== clientId) navigate(`${cid}`, { replace: true });
    },
    [clientId, navigate]
  );

  const [propertyId, setPropertyId] = useState<undefined | number>(undefined);

  const onViewSchedules = () => {
    if (propertyId) {
      pushToPage(
        makeViewProgramPage({
          propertyId,
        })
      );
    }
  };

  return (
    <PageContainer isLoading={isLoading}>
      <Stack direction="column">
        <SelectClientAndProperty
          clients={clients}
          onChangeClientId={selectClientId}
          onChangePropertyId={setPropertyId}
          clientId={clientId}
          propertyId={propertyId}
        />

        <Stack direction="row" sx={{ marginBottom: 6 }}>
          <Typography variant="h3">Manage Fleets</Typography>
          {clientId ? (
            <Button
              variant="outlined"
              color="info"
              sx={{ marginLeft: 3 }}
              size="large"
              onClick={onViewSchedules}
              // disabled={propertyId === undefined}
            >
              View Schedule
            </Button>
          ) : null}
        </Stack>
        {propertyId ? (
          <GqlProvider isBatchMode>
            {/* Use key to force reload the Outlet component */}
            <ClientFleetManagement key={clientId} propertyId={propertyId} />
          </GqlProvider>
        ) : (
          <Typography>Please select a client to manage fleets.</Typography>
        )}
      </Stack>
    </PageContainer>
  );
}
