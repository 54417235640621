import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import Paper, { PaperProps } from "@mui/material/Paper";
import React, { useContext, useState } from "react";
import { useApolloClient } from "@apollo/client";
import { ClientContext } from "../../contexts/GqlContext";
import {
  mutateAddProperty,
  addPropertyCache,
} from "../../services/graphql/Property";

const FormPaperComponent = (props: PaperProps) =>
  React.createElement(Paper, { ...props, component: "form" } as PaperProps);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onPropertyAdded: () => void;
};

export default function NewPropertyModal({
  isOpen,
  onClose,
  onPropertyAdded,
}: Props) {
  const [isAddingProperty, setIsAddingProperty] = useState(false);
  const [propertyName, setPropertyName] = useState("");
  const [address, setAddress] = useState("");
  const {
    client: { client_dbname: clientDBName },
  } = useContext(ClientContext);
  const gqlClient = useApolloClient();
  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsAddingProperty(true);
    const MUTATE_ADD_PROPERTY = mutateAddProperty(clientDBName);

    await gqlClient.mutate({
      mutation: MUTATE_ADD_PROPERTY,
      variables: {
        propertyName,
        address,
      },
      update: addPropertyCache(clientDBName),
    });
    setIsAddingProperty(false);
    onPropertyAdded();
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      PaperComponent={FormPaperComponent}
      PaperProps={{ onSubmit: handleFormSubmit }}
    >
      {isAddingProperty && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
          }}
        />
      )}
      <DialogTitle>New Property</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={propertyName}
          onChange={(e) => setPropertyName(e.target.value)}
          label="Property Name"
          placeholder="Property Name"
          sx={{ margin: ".5rem 0" }}
        />
        <TextField
          fullWidth
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          label="Address"
          placeholder="Address"
          sx={{ margin: ".5rem 0" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isAddingProperty}
          onClick={onClose}
          color="info"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button disabled={isAddingProperty} type="submit" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

NewPropertyModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onPropertyAdded: PropTypes.func.isRequired,
};
