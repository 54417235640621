import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useContext, useState, useEffect } from "react";
import { useApolloClient } from "@apollo/client";
import type { Property } from "src/interfaces/IProperty";
import { deleteClientProperty } from "src/services/restful/clients";
import { queryGetDevices } from "src/services/graphql/Device";
import { Device } from "src/interfaces/IDevice";
import { queryGetZones } from "src/services/graphql/Zone";
import { axiosErrorToString } from "src/services/restful/utils";
import { ClientContext } from "../../contexts/GqlContext";
import { Nullable } from "../../helper";

namespace DeletionGuard {
  const Pending = Symbol("Pending");
  const Passed = Symbol("Passed");
  export type Result = typeof Pending | typeof Passed | string;

  export const isPending = (r: Result) => r === Pending;
  export const isNotPassed = (r: Result) => r !== Pending && r !== Passed;

  export const toErrorString = (r: Result) => {
    if (typeof r === "string") {
      return r;
    }
    return "";
  };

  export const useGuard = (property: undefined | Property): Result => {
    const [guardResult, setGuardResult] = useState<Result>(Pending);
    const gqlClient = useApolloClient();
    const { client } = useContext(ClientContext);
    const propertyId = property && property.property_id;
    useEffect(() => {
      setGuardResult(Pending);
      if (!propertyId) {
        return;
      }
      gqlClient
        .query<{ [key: string]: Array<Device> }>({
          query: queryGetDevices(client.client_dbname),
          variables: {
            property_id: {
              _eq: propertyId,
            },
          },
        })
        .then((res) => {
          if (res.errors) {
            setGuardResult(res.errors.map((e) => e.message).join(","));
            return;
          }
          if (!res.data) {
            // this line is reached only if you open "partial data" in Apollo
            setGuardResult(
              "Property cannot be deleted because of unknown reason."
            );
            return;
          }

          const devices = res.data[`${client.client_dbname}_device`];
          if (devices.length > 0) {
            setGuardResult(
              "Property cannot be deleted because there's still a device. Please relocate this device first."
            );
          } else {
            setGuardResult(Passed);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    }, [propertyId, gqlClient, client.client_dbname]);

    return guardResult;
  };
}

function Content({
  guardResult,
  property,
}: {
  guardResult: DeletionGuard.Result;
  property: undefined | Property;
}) {
  const theme = useTheme();

  return DeletionGuard.isNotPassed(guardResult) ? (
    <Typography
      variant="body1"
      component="div"
      sx={{ color: theme.palette.error.main }}
    >
      {DeletionGuard.toErrorString(guardResult)}
    </Typography>
  ) : (
    <Typography variant="body1" component="div">
      Are you sure you want to remove {property?.property_name}
    </Typography>
  );
}

function Error({ error }: { error: Nullable.T<string> }) {
  const theme = useTheme();
  return error ? (
    <Typography
      variant="body2"
      component="div"
      sx={{
        color: theme.palette.error.main,
        marginTop: 2,
      }}
    >
      {error}
    </Typography>
  ) : null;
}

function Warning({ property }: { property: undefined | Property }) {
  const gqlClient = useApolloClient();
  const { client } = useContext(ClientContext);
  const [warning, setWarning] = useState<Nullable.T<string>>(null);

  const propertyId = property && property.property_id;
  const theme = useTheme();

  useEffect(() => {
    setWarning(null);

    gqlClient
      .query({
        query: queryGetZones(client.client_dbname),
      })
      .then((res) => {
        if (res.errors) {
          return setWarning(res.errors.map((e) => e.message).join(","));
        }
        if (res.data) {
          const zones = res.data[`${client.client_dbname}_zone`];
          if (zones.length > 0) {
            setWarning(
              `You are about to delete this property with ${zones.length} zones.`
            );
          }
        }
        return undefined;
      });
  }, [gqlClient, client.client_dbname, propertyId]);

  return warning ? (
    <Typography
      variant="body2"
      component="span"
      sx={{ color: theme.palette.danger.main }}
    >
      {warning}
    </Typography>
  ) : null;
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  property?: Property;
  onPropertyRemoved: () => void;
};

function ConfirmDeletePropertyModal({
  property,
  onPropertyRemoved,
  isOpen,
  onClose,
}: Props) {
  const [isDeleting, setIsDeleting] = useState(false);
  const [error, setError] = useState<Nullable.T<string>>(null);
  const {
    client: { client_dbname: clientDBName, client_id: clientId },
  } = useContext(ClientContext);
  const gqlClient = useApolloClient();

  const guardResult = DeletionGuard.useGuard(property);

  const removeProperty = async () => {
    if (property) {
      setIsDeleting(true);
      try {
        await deleteClientProperty(clientId, property.property_id);
        // Currently, since property doesn't have id for cache
        // Only whole client's property cache field can be evicted
        // Please beware of any other property-linked cache record if exists
        gqlClient.cache.evict({
          id: "ROOT_QUERY",
          fieldName: `${clientDBName}_property`,
        });
        gqlClient.cache.gc();
        onPropertyRemoved();
      } catch (err) {
        const message = axiosErrorToString(err as Error);
        setError(message || "Unexpected error");
      } finally {
        setIsDeleting(false);
      }
    }
  };
  const isPending = isDeleting || DeletionGuard.isPending(guardResult);
  const hasError = error !== null || DeletionGuard.isNotPassed(guardResult);

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      {isPending && (
        <LinearProgress
          color="danger"
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
          }}
        />
      )}
      <DialogContent sx={{ display: "flex", flexDirection: "column" }}>
        <Content guardResult={guardResult} property={property} />
        {error && <Warning property={property} />}
        <Error error={error} />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isDeleting}
          onClick={onClose}
          color="info"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          onClick={removeProperty}
          color="danger"
          variant="contained"
          disabled={isPending || hasError}
          type="submit"
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConfirmDeletePropertyModal;
