import { createContext } from "react";
import { User } from "../../interfaces/IUser";
import * as Nullable from "../../helper/nullable";

const never = () => {
  throw new Error("Context is not initiated!");
};

export type Context = {
  user: Nullable.T<User>;
  signin: (token: string) => void;
  signout: () => void;
  isAuthLoading: boolean;
  shouldSelectClient: boolean;
};

export const context = createContext<Context>({
  user: null,
  signin: never,
  signout: never,
  isAuthLoading: false,
  shouldSelectClient: false,
});
