import MuiCard from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import React from "react";

import { CircularProgress, styled, useTheme } from "@mui/material";
import WifiSlash from "src/assets/icons/WifiSlash";
import { BORDER_COLOR_CARD } from "src/styles";

const Img = styled("img")({
  height: "100%",
});

type DisconnectedBadgeProps = {
  hideBadge: boolean;
  iconSrc: string;
};

const DisconnectedBadge = ({ hideBadge, iconSrc }: DisconnectedBadgeProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        position: "relative",
        flex: 1,
        textAlign: "right",
      }}
    >
      <Img src={iconSrc} alt="device-icon" />
      {hideBadge ? null : (
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            right: 0,
            backgroundColor: theme.palette.error.main,
            borderRadius: "100%",
            width: "24px",
            height: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <WifiSlash sx={{ width: "14px", height: "14px" }} />
        </Box>
      )}
    </Box>
  );
};

const Card = styled(MuiCard)({
  boxShadow: "none",
  border: `2px solid ${BORDER_COLOR_CARD}`,
  borderRadius: "8px",
});

type Props = {
  title: string;
  isLoading: boolean;
  number: number;
  disconnectedClients?: number;
  iconSrc: string;
};
export default function StatisticsCard({
  title,
  number,
  isLoading,
  disconnectedClients,
  iconSrc,
}: Props) {
  const isDisconnectedCard = disconnectedClients !== undefined;
  const theme = useTheme();
  return (
    <Card
      sx={{
        display: "flex",
        flex: "0 0 264px",
        justifyContent: "space-between",
        padding: 4.5,
        overflow: "unset",
      }}
    >
      <Stack direction="column">
        <Typography variant="subtitle4">{title}</Typography>
        <Stack direction="row" sx={{ alignItems: "flex-end" }}>
          <Typography
            variant="subtitle1"
            sx={{
              marginTop: "12px",
              color: isDisconnectedCard ? theme.palette.error.main : "initial",
            }}
          >
            {isLoading ? (
              <CircularProgress
                size={24}
                color={isDisconnectedCard ? "error" : "inherit"}
              />
            ) : (
              number
            )}
          </Typography>
          {isDisconnectedCard && (
            <Typography
              variant="body3"
              sx={{ marginLeft: 0.5, marginBottom: "2px" }}
            >
              {isLoading ? null : `from ${disconnectedClients} clients`}
            </Typography>
          )}
        </Stack>
      </Stack>
      <DisconnectedBadge hideBadge={!isDisconnectedCard} iconSrc={iconSrc} />
    </Card>
  );
}
