import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React from "react";
import {
  Device,
  DeviceType,
  deviceTypeToPluralName,
} from "src/interfaces/IDevice";
import * as IProgram from "src/interfaces/IProgram";
import DeviceCount from "../DeviceCount";
import RowBox from "../RowBox";
import { CompactDeviceList as DeviceList } from "../SimpleDeviceList";
import Display from "../ScheduleAndModes/Display";
import {
  getSchedulesOfProgramDisplay,
  toScheduleAndModesValue,
} from "../../utils";

type Program = IProgram.Program<IProgram.ProgramType.REGULAR>;

const toValues = (program: Program) =>
  program.program_displays.map((display) =>
    toScheduleAndModesValue(
      display,
      getSchedulesOfProgramDisplay(display, program.schedules)
    )
  );

type Props = {
  program: Program;
  devices: Array<Device>;
  deviceType: DeviceType;
};

export default function DisplayProgram({
  program,
  devices,
  deviceType,
}: Props) {
  const values = toValues(program);
  return (
    <>
      <RowBox
        label={
          <Stack direction="row" alignItems="flex-end">
            {`${deviceTypeToPluralName(deviceType)}`}
            <DeviceCount count={devices.length} />
          </Stack>
        }
      >
        <DeviceList devices={devices} />
      </RowBox>
      <Divider />
      <RowBox label="Settings">
        <Stack direction="column" gap={6}>
          {values.map((value) => (
            <Display key={value.name} value={value} />
          ))}
        </Stack>
      </RowBox>
    </>
  );
}
