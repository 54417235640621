import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { User } from "src/interfaces/IUser";
import { AUTH_TOKEN, HASURA_ADMIN_ROLE } from "../../const";
import {
  decodeAndCheckJWTValidity,
  isClientSpecific,
} from "../../services/authService";
import TokenExpiredModal from "./TokenExpiredModal";
import { context as AuthContext } from "./context";

import * as Nullable from "../../helper/nullable";

type Props = {
  children: React.ReactNode;
};

function AuthProvider({ children }: Props) {
  const [user, setUser] = useState<Nullable.T<User>>(null);
  const [isAuthLoading, setIsAuthLoading] = useState(true);
  const [shouldSelectClient, setShouldSelectClient] = useState(false);

  const signin = useCallback((token: string) => {
    const tokenData = decodeAndCheckJWTValidity(token);
    setUser({
      userId: tokenData.user_id,
      fullName: tokenData.full_name,
      email: tokenData.email,
      isSuperuser: tokenData.is_superuser,
      status: tokenData.status,
      hasuraClaims: {
        allowedRoles:
          tokenData["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"],
        defaultRole:
          tokenData["https://hasura.io/jwt/claims"]["x-hasura-default-role"],
      },
      isClientSpecific: isClientSpecific(tokenData),
      token,
    });
  }, []);

  const signout = useCallback(() => {
    setUser(null);
    localStorage.removeItem(AUTH_TOKEN);
  }, []);

  useEffect(() => {
    const token = localStorage.getItem(AUTH_TOKEN);
    if (token && user == null) {
      try {
        signin(token);
      } catch (e) {
        signout(); // eslint-disable-line no-use-before-define
      } finally {
        setIsAuthLoading(false);
      }
    } else {
      setIsAuthLoading(false); // no token & auth credential 
    }
  }, [signin, signout, user]);

  useEffect(() => {
    if (
      user &&
      (user.hasuraClaims.defaultRole === HASURA_ADMIN_ROLE ||
        user.hasuraClaims.allowedRoles.length > 1)
    ) {
      setShouldSelectClient(true);
    } else {
      setShouldSelectClient(false);
    }
  }, [user]);

  const value = useMemo(
    () => ({
      user,
      signin,
      signout,
      isAuthLoading,
      shouldSelectClient,
    }),
    [user, signin, signout, isAuthLoading, shouldSelectClient]
  );
  
  return (
    <AuthContext.Provider value={value}>
      {children}
      <TokenExpiredModal />
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
export { AuthContext };
