import { ApolloCache, DocumentNode, gql } from "@apollo/client";
import { Modifiers } from "@apollo/client/cache/core/types/common";
import { curry } from "lodash";
import type { Property } from "../../interfaces/IProperty";
import type { ClientDBName } from "../../interfaces/IClient";

type Returning<V> = {
  data: {
    [key: string]: {
      returning: V;
    };
  };
};

type UpdateProperty = (
  property: Property,
  properties: Property[]
) => Property[];
type UpdateProperties = (
  property: Property[],
  properties: Property[]
) => Property[];

const addProperty: UpdateProperty = (newProperty, properties) => [
  newProperty,
  ...properties,
];

const addProperties: UpdateProperties = (newProperties, properties) =>
  newProperties.reduce((acc, value) => addProperty(value, acc), properties);

const editProperty: UpdateProperty = (property, properties) =>
  properties.map((p) =>
    p.property_id === property.property_id ? property : p
  );

const editProperties: UpdateProperties = (newProperties, properties) =>
  newProperties.reduce((acc, value) => editProperty(value, acc), properties);

const removeProperty: UpdateProperty = (property, properties) =>
  properties.filter((p) => p.property_id !== property.property_id);

const removeProperties: UpdateProperties = (newProperties, properties) =>
  newProperties.reduce((acc, value) => removeProperty(value, acc), properties);

const updatePropertyCache = curry(
  (
    method: UpdateProperties,
    clientDBName: ClientDBName,
    action: "insert" | "update" | "delete",
    cache: ApolloCache<any>,
    { data }: Returning<Property[]>
  ) => {
    if (clientDBName) {
      const newProperties =
        data[`${action}_${clientDBName}_property`].returning;

      const fields: Modifiers = {
        [`${clientDBName}_property`]: (properties: Property[]) =>
          method(newProperties, properties),
      };

      cache.modify({
        fields,
      });
    }
  }
);

const queryGetProperties = (clientDBName: ClientDBName): DocumentNode => gql`
        query GetProperties {
          ${clientDBName}_property {
            property_id
            property_name
            property_address
          }
        }
      `;

const mutateAddProperty = (clientDBName: ClientDBName): DocumentNode => gql`
        mutation AddProperty($propertyName: String, $address: String) {
          insert_${clientDBName}_property(objects:[{
            property_name: $propertyName,
            property_address: $address,
          }]){
            returning {
              property_id,
              property_name,
              property_address
            }
          }
        }
      `;

const mutateUpdateProperty = (clientDBName: ClientDBName): DocumentNode => gql`
        mutation UpdateProperty($propertyId: Int, $propertyName: String, $address: String) {
          update_${clientDBName}_property(
            where: {property_id: {_eq: $propertyId}},
            _set: {property_name: $propertyName, property_address: $address}
          ){
            returning {
              property_id,
              property_name,
              property_address
            }
          }
        }
        `;

const mutateRemoveProperty = (clientDBName: ClientDBName): DocumentNode => gql`
        mutation RemoveProperty($propertyId: Int) {
          delete_${clientDBName}_property(where: {property_id: {_eq: $propertyId}}) {
            returning {
              property_id
            }
          }
        }
      `;

const addPropertyCache = (clientDBName: ClientDBName) =>
  updatePropertyCache(addProperties, clientDBName, "insert");
const editPropertyCache = (clientDBName: ClientDBName) =>
  updatePropertyCache(editProperties, clientDBName, "update");
const removePropertyCache = (clientDBName: ClientDBName) =>
  updatePropertyCache(removeProperties, clientDBName, "delete");

export {
  queryGetProperties,
  mutateAddProperty,
  mutateUpdateProperty,
  mutateRemoveProperty,
  addPropertyCache,
  editPropertyCache,
  removePropertyCache,
};
