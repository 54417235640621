import { ClientHash } from "src/interfaces/IClient";
import { Schedule } from "src/interfaces/ISchedule";
import { DesiredShadow } from "src/interfaces/IShadow";
import { httpAuthorizedRequest } from "../httpService";

export interface ClientScheduleBase {
  starting_at: string;
  recurring?: string;
  timezone: string;
  desired_shadow?: DesiredShadow;
}
export interface CreateClientSchedule extends ClientScheduleBase {
  desired_shadow: DesiredShadow;
  program_id: number;
}
export interface CreateClientSchedules {
  client_schedules: CreateClientSchedule[];
}
export interface UpdateClientSchedule extends ClientScheduleBase {
  desired_shadow: DesiredShadow;
  program_id: number;
  schedule_id: number;
}
export interface UpdateClientSchedules {
  client_schedules: UpdateClientSchedule[];
}
export interface DeleteClientSchedule {
  schedule_id: number;
}
export interface DeleteClientSchedules {
  schedule_ids: number[];
}
// TODO: Remove hash api calling and use client.hash directly
const deleteSchedule = async (
  clientHash: ClientHash,
  scheduleId: DeleteClientSchedule["schedule_id"]
) =>
  httpAuthorizedRequest.delete(
    `/v1/cre/clients/${clientHash}/fleet/schedule/${scheduleId}`
  );

const deleteSchedules = async (
  clientHash: ClientHash,
  schedule_ids: DeleteClientSchedules["schedule_ids"]
) =>
  httpAuthorizedRequest.delete(
    `/v1/cre/clients/${clientHash}/fleet/schedules`,
    { data: JSON.stringify({ schedule_ids }) }
  );

// TODO: Remove hash api calling and use client.hash directly
const createSchedule = async <ScheduleType extends Schedule = Schedule>(
  clientHash: ClientHash,
  body: CreateClientSchedule
) =>
  httpAuthorizedRequest.post<ScheduleType>(
    `/v1/cre/clients/${clientHash}/fleet/schedule`,
    JSON.stringify(body)
  );

const createSchedules = async <ScheduleType extends Schedule = Schedule>(
  clientHash: ClientHash,
  schedules: CreateClientSchedules["client_schedules"]
) =>
  httpAuthorizedRequest.post<{
    failed_list: any[];
    schedules: ScheduleType[];
  }>(
    `/v1/cre/clients/${clientHash}/fleet/schedules`,
    JSON.stringify({ client_schedules: schedules })
  );

const updateSchedules = async <ScheduleType extends Schedule = Schedule>(
  clientHash: ClientHash,
  schedules: UpdateClientSchedules["client_schedules"]
) =>
  httpAuthorizedRequest.put<{
    failed_list: any[];
    schedules: ScheduleType[];
  }>(
    `/v1/cre/clients/${clientHash}/fleet/schedules`,
    JSON.stringify({ client_schedules: schedules })
  );

export {
  deleteSchedule,
  deleteSchedules,
  createSchedule,
  createSchedules,
  updateSchedules,
};
