import React, { useState, useEffect } from "react";
import { Nullable, Result } from "src/helper";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
  Button,
  Stack,
  LinearProgress,
  Fade,
  Box,
} from "@mui/material";
import Input from "./Input";

enum State {
  Content,
  ConfirmDelete,
}
type EditModalProps<V> = {
  // validation happens when user type input and input is not "" or same with props.value
  validateInput: (v: V) => Result.T<void, string /* error string */>;
  onClose: () => void;
  value: Nullable.T<V>;
  // the promise error should contain message
  onSubmit: Nullable.T<(newValue: V) => Promise<void>>;
  // the promise error should contain message
  onDelete: Nullable.T<(v: V) => Promise<void>>;
  editTypeName: string;
  confirmDeleteMsg: string;
};
export default function EditModal<V>({
  validateInput,
  onClose,
  value: extValue,
  onSubmit,
  onDelete,
  editTypeName,
  confirmDeleteMsg,
}: EditModalProps<V>) {
  const [state, setState] = useState<State>(State.Content);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Nullable.T<string>>(null);
  const [value, setValue] = useState<V | "">(extValue || "");

  const handleClose = () => {
    onClose();
    setIsLoading(false);
    setError(null);
    // just for making transition smooth
    setTimeout(() => setState(State.Content), 1000);
  };

  useEffect(() => {
    setValue(extValue || "");
  }, [extValue]);

  const handleSubmit = async () => {
    if (value === "" || extValue === null || onSubmit === null) {
      return undefined;
    }

    setIsLoading(true);
    try {
      await onSubmit(value);
      setIsLoading(false);
      handleClose();
    } catch (err) {
      console.error(err);
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("unknown error");
      }
      setState(State.Content);
    } finally {
      setIsLoading(false);
    }

    return undefined;
  };

  const handleDelete = async () => {
    if (extValue === null || onDelete === null) {
      return undefined;
    }
    setIsLoading(true);
    await onDelete(extValue);
    handleClose();
    setIsLoading(false);
    return undefined;
  };

  const handleChange = (v: "" | V) => {
    setValue(v);
    if (v !== "" && v !== extValue) {
      const validateResult = validateInput(v);
      if (validateResult.type === "Error") {
        setError(validateResult.value);
      } else {
        setError(null);
      }
    }
  };

  return (
    <Dialog open={extValue !== null} onClose={handleClose} fullWidth>
      {isLoading ? <LinearProgress /> : null}
      <Fade
        in={state === State.ConfirmDelete}
        unmountOnExit
        mountOnEnter
        exit={false}
      >
        <Box sx={{ gap: "24px", display: "flex", flexDirection: "column" }}>
          <DialogTitle>
            <Typography variant="h7">
              {`Do you want to delete the ${editTypeName}?`}
            </Typography>
          </DialogTitle>
          <DialogContent>{confirmDeleteMsg}</DialogContent>
          <DialogActions>
            <Button variant="outlined" color="info" onClick={handleClose}>
              No
            </Button>
            <Button
              disabled={isLoading}
              variant="contained"
              color="primary"
              onClick={handleDelete}
            >
              Yes
            </Button>
          </DialogActions>
        </Box>
      </Fade>
      <Fade
        in={state === State.Content}
        unmountOnExit
        mountOnEnter
        enter={false}
        exit={false}
      >
        <Box sx={{ gap: "24px", display: "flex", flexDirection: "column" }}>
          <DialogTitle>
            <Typography variant="h7">{`Edit ${editTypeName}`}</Typography>
          </DialogTitle>
          <DialogContent sx={{ display: "flex" }}>
            <Input
              fullWidth
              onChange={handleChange}
              value={value}
              label={`${editTypeName} Name`}
              error={error !== null}
              helperText={error || ""}
              placeholder="Enter the name"
            />
          </DialogContent>
          <DialogActions
            sx={{ display: "flex", justifyContent: "space-between" }}
          >
            <Button
              disabled={isLoading}
              color="danger"
              startIcon={
                <img
                  alt="Trash"
                  src={`${process.env.PUBLIC_URL}/icons/Trash.svg`}
                />
              }
              onClick={() => setState(State.ConfirmDelete)}
            >
              {`Delete ${editTypeName}`}
            </Button>
            <Stack direction="row" sx={{ columnGap: 3 }}>
              <Button color="info" variant="outlined" onClick={handleClose}>
                Cancel
              </Button>
              <Button
                disabled={isLoading || error !== null}
                color="primary"
                variant="contained"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </Fade>
    </Dialog>
  );
}
