import { Nullable } from "src/helper";
import { DesiredShadow } from "./IShadow";

interface ScheduleBasic {
  schedule_id: number;
  schedule_name: string;
  starting_at: string /* datetime string */;
  recurring: Nullable.T<string> /* cron string */;
  schedule_arn: string;
  desired_shadow: Nullable.T<DesiredShadow>;
}

export interface RecurringSchedule extends ScheduleBasic {
  recurring: string /* cron string */;
}

export interface OneTimeSchedule extends ScheduleBasic {
  recurring: null;
}

export type Schedule = OneTimeSchedule | RecurringSchedule;

export const isOneTimeSchedule = (s: Schedule) => s.recurring === null;

export const isRecurringSchedule = (s: Schedule) => s.recurring !== null;

export const filterRecurringSchedules = (
  schedules: Array<Schedule>
): Array<RecurringSchedule> =>
  schedules.reduce((acc: Array<RecurringSchedule>, s) => {
    if (s.recurring !== null) return acc.concat([s]);
    return acc;
  }, []);

export const filterOneTimeSchedules = (
  schedules: Array<Schedule>
): Array<OneTimeSchedule> =>
  schedules.reduce((acc: Array<OneTimeSchedule>, s) => {
    if (s.recurring === null) return acc.concat([s]);
    return acc;
  }, []);
