import { ApolloClient, useApolloClient } from "@apollo/client";
import React, { useContext } from "react";
import { Nullable, Result } from "src/helper";
import { Client } from "src/interfaces/IClient";
import { editZoneCache, mutateUpdateZone } from "src/services/graphql/Zone";
import * as GraphQLUtils from "src/services/graphql/utils";
import EditModal from "src/components/UI/EditModal";
import { ClientContext } from "src/contexts/GqlContext";
import { Zone } from "src/interfaces/IZone";
import { deleteClientZone as deleteZone } from "src/services/restful/clients";

const updateZone = async (
  gqlClient: ApolloClient<object>,
  client: Client,
  zone: Zone,
  newZoneName: string
) => {
  const result = await gqlClient.mutate({
    mutation: mutateUpdateZone(client.client_dbname),
    variables: {
      where: {
        zone_id: { _eq: zone.zone_id },
      },
      _set: {
        zone_name: newZoneName,
      },
    },
    update: editZoneCache(client.client_dbname),
  });

  const fetchResult = GraphQLUtils.parseFetchResult(result);
  if (fetchResult.type === "Error") {
    return Promise.reject(
      new Error(GraphQLUtils.errorMessages(fetchResult.value))
    );
  }

  return undefined;
};

export default function EditZoneModal({
  zones,
  zoneToEdit,
  onClose,
  onEditSuccessCallback,
  onDeleteSuccessCallback,
  onDeleteFailedCallback,
}: {
  zones: Array<Zone>;
  zoneToEdit: Nullable.T<Zone>;
  onClose: () => void;
  onEditSuccessCallback?: () => void;
  onDeleteSuccessCallback?: () => void;
  onDeleteFailedCallback?: () => void;
}) {
  const handleInputValidate = (newZoneName: string) => {
    if (zones.find((z) => z.zone_name === newZoneName)) {
      return Result.error("The name of the zone is taken.");
    }
    return Result.ok(undefined);
  };

  const gqlClient = useApolloClient();
  const { client } = useContext(ClientContext);

  const handleSubmit = async (zone: Zone, newZoneName: string) => {
    await updateZone(gqlClient, client, zone, newZoneName);
    if (onEditSuccessCallback) await onEditSuccessCallback();
    return undefined;
  };

  const handleDelete = async (zone: Zone) => {
    try {
      await deleteZone(client.client_id, zone.zone_id);
      if (onDeleteSuccessCallback) onDeleteSuccessCallback();
    } catch (err) {
      console.error(err);
      if (onDeleteFailedCallback) onDeleteFailedCallback();
    }
  };

  return (
    <EditModal
      editTypeName="Zone"
      value={Nullable.map((z: Zone) => z.zone_name)(zoneToEdit)}
      onClose={onClose}
      validateInput={handleInputValidate}
      onSubmit={
        zoneToEdit === null
          ? null
          : (newZoneName) => handleSubmit(zoneToEdit, newZoneName)
      }
      onDelete={zoneToEdit === null ? null : () => handleDelete(zoneToEdit)}
      confirmDeleteMsg="Deleting the zone will make all the existing devices in the default. Do you still want to delete the zone?"
    />
  );
}
