import React from "react";
import { Card, CircularProgress, Box, Typography } from "@mui/material";
import {
  DM_DARK_GREY,
  MESSAGING_ERROR,
  MESSAGING_SUCCESS,
  MESSAGING_WARNING,
  WYND_WHITE,
} from "src/styles";
import { WyndScoreRatingThreshold } from "src/const";

type CleanestZoneWidgetProps = {
  zone: String;
  property: String;
  floor: String;
  value: number;
  sx?: object;
};

const CleanestZoneWidget = ({
  zone,
  property,
  floor,
  value,
  sx,
}: CleanestZoneWidgetProps) => {
  const levelColor =
    value > WyndScoreRatingThreshold.FANTASTIC
      ? MESSAGING_SUCCESS
      : value > WyndScoreRatingThreshold.FAIR
      ? MESSAGING_WARNING
      : MESSAGING_ERROR;
  return (
    <Card sx={{ padding: 2, height: "100%", ...sx }}>
      <Typography variant="subtitle4" color={DM_DARK_GREY}>
        Cleanest Zone
      </Typography>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Box display="flex" flexDirection="column" justifyContent="initial">
          <Typography variant="subtitle3" color={DM_DARK_GREY}>
            {zone}
          </Typography>
          <Typography variant="body4" color={DM_DARK_GREY}>
            {property}, {floor}
          </Typography>
        </Box>
        <Box position="relative">
          <CircularProgress
            variant="determinate"
            value={100}
            size={70}
            thickness={5}
            sx={{ color: WYND_WHITE }}
          />
          <CircularProgress
            variant="determinate"
            value={value}
            size={70}
            thickness={5}
            sx={{ color: levelColor, position: "absolute", left: 0 }}
          />
          <Box
            top={0}
            left={0}
            bottom={0}
            right={0}
            position="absolute"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <Typography variant="body1">{value}</Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
};

export default CleanestZoneWidget;
