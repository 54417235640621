// This is a utility type for representing values with two possibilities.
// You can read this to get basic concept
// https://antman-does-software.com/stop-catching-errors-in-typescript-use-the-either-type-to-make-your-code-predictable

interface Ok<Value> {
  type: "Ok";
  value: Value;
}

interface Error<Value> {
  type: "Error";
  value: Value;
}

type T<O, E> = Ok<O> | Error<E>;

function error<E>(e: E): T<never, E> {
  return {
    type: "Error",
    value: e,
  };
}

function ok<O>(o: O): T<O, never> {
  return {
    type: "Ok",
    value: o,
  };
}

function isError<O, E>(result: T<O, E>) {
  return result.type === "Error";
}

function isOk<O, E>(result: T<O, E>) {
  return result.type === "Ok";
}

function mapOk<A, B, E>(f: (a: A) => B, result: T<A, E>): T<B, E> {
  if (result.type === "Error")
    return {
      type: "Error",
      value: result.value,
    };

  return {
    type: "Ok",
    value: f(result.value),
  };
}

function mapError<O, E1, E2>(f: (a: E1) => E2, result: T<O, E1>): T<O, E2> {
  if (result.type === "Ok")
    return {
      type: "Ok",
      value: result.value,
    };

  return {
    type: "Error",
    value: f(result.value),
  };
}

function flatMapOk<A, B, E>(f: (a: A) => T<B, E>, result: T<A, E>): T<B, E> {
  if (result.type === "Error")
    return {
      type: "Error",
      value: result.value,
    };

  return f(result.value);
}

function flatMapError<O, E1, E2>(
  f: (a: E1) => T<O, E2>,
  result: T<O, E1>
): T<O, E2> {
  if (result.type === "Ok")
    return {
      type: "Ok",
      value: result.value,
    };

  return f(result.value);
}

export type { T };
export { error, ok, isError, isOk, mapError, mapOk, flatMapOk, flatMapError };
