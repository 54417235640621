import { Shadow } from "./IShadow";

export enum DeviceType {
  HALO = "HALO",
  MAX = "MAX",
  ESSENTIAL = "ESSENTIAL",
  PLUS = "PLUS",
  TRACKER = "TRACKER",
}

export const deviceTypeToName = (dt: DeviceType): string => {
  switch (dt) {
    case DeviceType.HALO:
      return "Halo";
      break;
    case DeviceType.MAX:
      return "Max";
      break;
    case DeviceType.ESSENTIAL:
      return "Essential";
      break;
    case DeviceType.PLUS:
      return "Plus";
      break;
    case DeviceType.TRACKER:
      return "Tracker";
      break;
    default:
      throw TypeError(`Unknown device type: ${dt}`);
      break;
  }
};

export const deviceTypeToPluralName = (dt: DeviceType): string => {
  switch (dt) {
    case DeviceType.HALO:
      return "Halos";
      break;
    case DeviceType.MAX:
      return "Maxes";
      break;
    case DeviceType.ESSENTIAL:
      return "Essentials";
      break;
    case DeviceType.PLUS:
      return "Plus";
      break;
    case DeviceType.TRACKER:
      return "Trackers";
      break;
    default:
      throw TypeError(`Unknown device type: ${dt}`);
      break;
  }
};

export interface Device {
  device_id: string; // serial
  property_id: number;
  property: {
    name: string;
  };
  zone_id: number;
  zone: {
    name: string;
    floor: string;
  };
  extra_data?: JSON;
  device_type: DeviceType;
}

export interface DeviceShadow {
  device_id: string; // serial
  property_id: number;
  property_name: string;
  zone_id: number;
  zone_name: string;
  device_type: DeviceType;
  shadow?: Shadow;
}
