const CHALICE_URL = `${process.env.REACT_APP_CHALICE_URL}`;
const HASURA_URL = `${process.env.REACT_APP_HASURA_URL}/v1/graphql`;
const AUTH_TOKEN = "token";
const GRAPHQL_TOKEN = "graphql_token";

const HASURA_ADMIN_ROLE = "admin";

// use for graphql request header
const HASURA_ROLE_HEADER_KEY = "x-hasura-role";

const UserStatus = {
  ACTIVE: "ACTIVE",
  REQUEST_PASSWORD_CHANGE: "REQUEST_PASSWORD_CHANGE",
  INACTIVE: "INACTIVE",
  INVITED: "INVITED",
};

const MembershipRole = {
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
};

const Country = {
  CAN: "Canada",
  USA: "United States of America",
};

const WyndScoreRatingThreshold = {
  FANTASTIC: 85,
  FAIR: 60,
};

const InfectionRiskRating = {
  HIGH: "HIGH",
  MEDIUM: "MEDIUM",
  LOW: "LOW",
};

const COPY_SLOGAN = "Enabling Healthy and Sustainable spaces through optimized air quality";


export {
  AUTH_TOKEN,
  CHALICE_URL,
  COPY_SLOGAN,
  GRAPHQL_TOKEN,
  HASURA_ADMIN_ROLE,
  HASURA_ROLE_HEADER_KEY,
  HASURA_URL,
  Country,
  MembershipRole,
  UserStatus,
  WyndScoreRatingThreshold,
  InfectionRiskRating,
};
