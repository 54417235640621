import React, { useState } from "react";
import Box from "@mui/material/Box";
import * as InfoIcon from "src/assets/icons/Info";
import { useTheme } from "@mui/material/styles";
import { Fade, Paper, Typography } from "@mui/material";

type InfoTooltipProps = {
  title: string;
  content?: string;
};

export default function InfoTooltip({ title, content }: InfoTooltipProps) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const openDescription = Boolean(anchorEl);

  const theme = useTheme();

  return (
    <Box
      component="span"
      sx={{
        position: "relative",
        "*": {
          verticalAlign: "text-bottom",
        },
      }}
      onMouseOver={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <InfoIcon.Outlined color="primary" fontSize="small" />
      <Box sx={{ position: "absolute", top: "0", left: "100%" }}>
        <Fade in={openDescription} unmountOnExit>
          <Paper
            sx={{
              shadow: theme.shadows[1],
              padding: 3,
              maxWidth: "256px",
              width: "100vw",
              marginLeft: "6px",
            }}
          >
            <Typography
              variant="subtitle2"
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "6px",
                marginBottom: "14px",
                "& svg": {
                  color: "#259CFF",
                },
              }}
            >
              <InfoIcon.Contained color="inherit" fontSize="medium" />
              {title}
            </Typography>
            <Typography>{content}</Typography>
          </Paper>
        </Fade>
      </Box>
    </Box>
  );
}
