import React from "react";
import { Card, Grid, Typography, Divider, Box } from "@mui/material";
import { DM_DARK_GREY, MESSAGING_SUCCESS } from "src/styles";
import { ArrowDownwardRounded } from "@mui/icons-material";

type EnergySavingsWidgetProps = {
  energy_savings: number;
  carbon_emissions: number;
  sx?: object;
};

const EnergySavingsWidget = ({
  energy_savings,
  carbon_emissions,
  sx,
}: EnergySavingsWidgetProps) => (
  <Card sx={{ padding: 2, height: "100%", ...sx }}>
    <Grid container direction="row">
      <Grid
        item
        xs={5}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Typography variant="subtitle4" color={DM_DARK_GREY}>
          Equivalent Energy Saving (last 30 days)
        </Typography>
        <Typography variant="subtitle2">{energy_savings}kWh</Typography>
      </Grid>
      <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid
        item
        xs={5}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Box display="flex" alignItems="center">
          <ArrowDownwardRounded sx={{ color: MESSAGING_SUCCESS }} />
          <Typography variant="h7" color={MESSAGING_SUCCESS}>
            {carbon_emissions}k kWh
          </Typography>
        </Box>
        <Typography variant="body3" color={DM_DARK_GREY}>
          equivalent energy saved by using HEPA filter to augment HVAC operation
        </Typography>
      </Grid>
    </Grid>
  </Card>
);

export default EnergySavingsWidget;
