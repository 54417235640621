// TypedSet is a speciallized version of TypedMap where the value is always null
import { Record } from "immutable";
import { TypedMap, makeTypedMap } from "../map/TypedMap";

export interface TypedSet<
  KeyType extends MaskedKeyType,
  MaskedKeyType extends {}
> {
  /**
   * Get the value of the given type
   * @param {KeyType} type
   */
  toArray(): KeyType[];
  /**
   * Set the value of the given type
   * @param {KeyType} type
   * @param {Value} value
   */
  add(type: KeyType): TypedSet<KeyType, MaskedKeyType>;
  /**
   * Check if the map has the given type
   * @param {KeyType} type
   * @returns {boolean}
   */
  has(type: KeyType): boolean;
  /**
   * Delete the given type from the set
   * @param {KeyType} type
   * @returns {TypedSet<KeyType, MaskedKeyType>}
   */
  delete(type: KeyType): TypedSet<KeyType, MaskedKeyType>;
  /**
   * Find the first type that matches the predicate
   * @param {(value: null, key: KeyType) => boolean} predicate
   * @returns {KeyType | undefined}
   */
  find(predicate: (key: KeyType) => boolean): KeyType | undefined;
  /**
   * Get the first value in the set
   * @returns {KeyType | undefined}
   */
  first(): KeyType | undefined;
  /**
   * Get the size of the set
   * @returns {number}
   */
  size: number;
}

class TypedSetBase<KeyType extends MaskedKeyType, MaskedKeyType extends {}>
  implements TypedSet<KeyType, MaskedKeyType>
{
  protected typedMap: TypedMap<KeyType, MaskedKeyType, null>;

  protected keyFactory: Record.Factory<MaskedKeyType>;

  constructor(
    keyFactory: Record.Factory<MaskedKeyType>,
    typedMap: TypedMap<KeyType, MaskedKeyType, null>
  ) {
    this.keyFactory = keyFactory;
    this.typedMap = typedMap;
  }

  toArray(): KeyType[] {
    return this.typedMap.toArray().map(([type]) => type);
  }

  add(key: KeyType): TypedSet<KeyType, MaskedKeyType> {
    return new TypedSetBase(this.keyFactory, this.typedMap.set(key, null));
  }

  has(key: KeyType): boolean {
    return this.typedMap.has(key);
  }

  delete(key: KeyType): TypedSet<KeyType, MaskedKeyType> {
    return new TypedSetBase(this.keyFactory, this.typedMap.delete(key));
  }

  find(predicate: (key: KeyType) => boolean): KeyType | undefined {
    return this.typedMap.findKey((key) => predicate(key));
  }

  first(): KeyType | undefined {
    return this.typedMap.first()?.[0];
  }

  get size(): number {
    return this.typedMap.size;
  }
}

export const makeTypedSet =
  <KeyType extends MaskedKeyType, MaskedKeyType extends {}>(
    keyFactory: Record.Factory<MaskedKeyType>
  ) =>
  (init?: KeyType[]): TypedSet<KeyType, MaskedKeyType> => {
    let initValue: undefined | [KeyType, null][];
    if (init && Array.isArray(init)) {
      initValue = init.map((key) => [key, null]);
    } else {
      initValue = init;
    }
    const map = makeTypedMap<KeyType, MaskedKeyType>(keyFactory)(initValue);

    return new TypedSetBase<KeyType, MaskedKeyType>(keyFactory, map);
  };
