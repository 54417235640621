import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  LinearProgress,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { axiosErrorToString } from "src/services/restful/utils";
import { unassignUserFromClient } from "../../services/clientService";
import * as Nullable from "../../helper/nullable";

type Props = {
  isOpen: boolean;
  clientId: number;
  userId?: number;
  onDeleted: (userId: number) => void;
  onClose: () => void;
};

function DeleteConfirmation({
  clientId,
  userId,
  onDeleted,
  onClose,
  isOpen,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Nullable.T<string>>(null);
  const deleteMembership = async () => {
    if (userId) {
      setIsLoading(true);
      try {
        await unassignUserFromClient({ clientId, userId });
        onDeleted(userId);
        onClose();
      } catch (e) {
        const message = axiosErrorToString(e as Error);
        setError(message || "Unexpected error");
      } finally {
        setIsLoading(false);
      }
    }
  };
  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      {isLoading && (
        <LinearProgress
          color="danger"
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
          }}
        />
      )}
      <DialogContent>
        <Typography>
          Are you sure you want to unassign this point of contact?
        </Typography>
        {error !== null && <Alert severity="error">{error}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={onClose}
          color="info"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button
          disabled={isLoading}
          onClick={deleteMembership}
          variant="contained"
          color="danger"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default DeleteConfirmation;
