import * as Result from "../result";

namespace PromiseHelpers {
  const mapArraySeriesPrivate = async <A, B>(
    accum: Array<B>,
    values: Array<A>,
    mapper: (v: A, i: number) => Promise<B>
  ): Promise<Array<B>> => {
    if (values.length === 0) {
      return Promise.resolve(accum);
    }

    const [head, tail] = [values[0], values.slice(1)];
    const index = accum.length;
    const resultOfHead = await mapper(head, index);

    return mapArraySeriesPrivate(accum.concat([resultOfHead]), tail, mapper);
  };

  export function mapArraySeries<V, K>(
    vs: Array<V>,
    f: ((v: V, index: number) => Promise<K>) | ((v: V) => Promise<K>)
  ): Promise<Array<K>> {
    return mapArraySeriesPrivate([], vs, (v, i) => f(v, i));
  }
}

class PrivateError<V> extends Error {
  value: V;

  constructor(v: V) {
    super();
    this.value = v;
  }
}

class PromiseResult<Ok, Err> extends Promise<Result.T<Ok, Err>> {
  constructor(
    executor: (
      resolve: (t: Result.T<Ok, Err>) => void,
      reject: (any: any) => void
    ) => void
  ) {
    super((resolve, reject) => executor(resolve, reject));
  }

  then<OkResult, ErrResult, TResult1 = Result.T<OkResult, ErrResult>>(
    onFulfilled: (value: Result.T<Ok, Err>) => TResult1 | PromiseLike<TResult1>
  ) {
    return super.then(onFulfilled);
  }

  static toPromise<Ok, Err>(p: PromiseResult<Ok, Err>): Promise<Ok> {
    return p.then((pr) => {
      // toPromise because I want to use Promise.all racing features
      // This may be changed in the future if we can directly implement this feature here
      if (pr.type === "Error") {
        return Promise.reject(new PrivateError(pr.value));
      }
      return Promise.resolve(pr.value);
    });
  }

  static fromPromise<Ok, Err>(
    p: Promise<Result.T<Ok, Err>>
  ): PromiseResult<Ok, Err> {
    return new PromiseResult((resolve, reject) => {
      p.then(resolve).catch(reject);
    });
  }

  mapOk<OkResult>(
    onFulfilled: (ok: Ok) => OkResult
  ): PromiseResult<OkResult, Err> {
    return new PromiseResult((resolve) => {
      this.then((r: Result.T<Ok, Err>) => Result.mapOk(onFulfilled, r)).then(
        resolve
      );
    });
  }

  flatMapOk<OkResult>(
    onFulfilled: (ok: Ok) => PromiseResult<OkResult, Err>
  ): PromiseResult<OkResult, Err> {
    return new PromiseResult((resolve) =>
      this.then((r) => {
        if (r.type === "Ok") {
          return onFulfilled(r.value);
        }
        return PromiseResult.resolve(r);
      }).then(resolve)
    );
  }

  mapError<ErrResult>(
    onRejected: (err: Err) => ErrResult
  ): PromiseResult<Ok, ErrResult> {
    return new PromiseResult((resolve) => {
      this.then((r: Result.T<Ok, Err>) => Result.mapError(onRejected, r)).then(
        resolve
      );
    });
  }

  flatMapError<RE>(
    onRejected: (err: Err) => PromiseResult<Ok, RE>
  ): PromiseResult<Ok, RE> {
    return new PromiseResult((resolve) =>
      this.then((r) => {
        if (r.type === "Error") {
          return onRejected(r.value);
        }
        return PromiseResult.resolve(r);
      }).then(resolve)
    );
  }

  static pair<OkResult1, OkResult2, Err>([p1, p2]: [
    PromiseResult<OkResult1, Err>,
    PromiseResult<OkResult2, Err>
  ]): PromiseResult<[OkResult1, OkResult2], Err> {
    return new PromiseResult((resolve, reject) => {
      Promise.all([PromiseResult.toPromise(p1), PromiseResult.toPromise(p2)])
        .then(([r1, r2]) => {
          resolve(Result.ok([r1, r2]));
        })
        .catch((e) => {
          if (e instanceof PrivateError) {
            resolve(Result.error(e.value));
          } else {
            reject(e);
          }
        });
    });
  }

  /**
   * @deprecated Don't use all in PromiseResult, use `array`, `pair` instead
   */

  static all<Ok, Err>(
    ps: Array<Promise<Result.T<Ok, Err>>>
  ): Promise<Array<Result.T<Ok, Err>>> {
    return super.all(ps);
  }

  static array<Ok, Err>(
    ps: Array<PromiseResult<Ok, Err>>
  ): PromiseResult<Array<Ok>, Err> {
    return new PromiseResult((resolve, reject) => {
      Promise.all(
        // toPromise because I want to use Promise.all racing features
        // This may be changed in the future if we can directly implement this feature here
        ps.map(PromiseResult.toPromise)
      )
        .then((os) => {
          resolve(Result.ok(os));
        })
        .catch((e) => {
          if (e instanceof PrivateError) {
            resolve(Result.error(e.value));
          } else {
            reject(e);
          }
        });
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export { PromiseResult, PromiseHelpers };
