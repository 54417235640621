import { MoreHoriz } from "@mui/icons-material";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
  Menu,
  MenuItem,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getUsers } from "../../services/userService";
import PageContainer from "../../components/Layout/PageContainer";
import { PATHS } from "../../router";
import SearchBar from "../../components/UI/SearchBar";

const IS_ACTION_BUTTON_VISIBLE = false;

const useUsers = (setError) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUserClients = async () => {
    try {
      const response = await getUsers();
      setUsers(JSON.parse(response.data.users));
    } catch (e) {
      setError("Unable to retreive client data");
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUserClients();
  }, []);

  return [users, isLoading];
};

export default function UsersPage() {
  const [error, setError] = useState(null);
  const [filterInput, setFilterInput] = useState("");
  const [users, isLoading] = useUsers(setError);

  const visibleUsers = users.filter((user) =>
    user.full_name.includes(filterInput)
  );

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ marginRight: "1rem" }}>
            Users
          </Typography>
          <Link to={PATHS.USERS_NEW} style={{ textDecoration: "none" }}>
            <Button variant="contained">Add New User</Button>
          </Link>
        </Box>
        <SearchBar
          id="combo-box-demo"
          options={users.map((user) => user.full_name)}
          onChange={(newValue) => {
            if (newValue === null) {
              setFilterInput("");
            } else {
              setFilterInput(newValue);
            }
          }}
        />
      </Box>
      <Box sx={{ display: "flex", marginBottom: "1rem" }} />
      <Box>
        <TableContainer>
          <Table size="medium">
            <TableHead>
              <TableRow>
                <TableCell>Full Name</TableCell>
                <TableCell>Email</TableCell>
                <TableCell>Super User?</TableCell>
                {IS_ACTION_BUTTON_VISIBLE && <TableCell>Action</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {!!error && (
                <TableRow>
                  <TableCell colSpan={4}>Can not load client</TableCell>
                </TableRow>
              )}

              {!!isLoading && (
                <TableRow>
                  <TableCell colSpan={4}>
                    <LinearProgress />
                  </TableCell>
                </TableRow>
              )}

              {!isLoading &&
                visibleUsers.map((user) => (
                  <TableRow hover sx={{ cursor: "pointer" }} key={user.user_id}>
                    <TableCell>{user.full_name}</TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>{user.is_superuser ? "yes" : "no"}</TableCell>
                    {IS_ACTION_BUTTON_VISIBLE && (
                      <TableCell>
                        <IconButton>
                          <MoreHoriz />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Menu // open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}
        >
          <MenuItem>Account Info</MenuItem>
          <MenuItem>Manage Devices</MenuItem>
        </Menu>
      </Box>
    </PageContainer>
  );
}
