import {
  Container,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
} from "@mui/material";
import React, { useContext } from "react";
import { Link, Location, useLocation } from "react-router-dom";
import { PATHS } from "src/router";
import LogoutIcon from "src/assets/icons/Signin";
import * as DashboardIcon from "../../assets/icons/Gauge";
import * as ClientIcon from "../../assets/icons/IdentificationCard";
import Sliders from "../../assets/icons/Sliders";
import Logo from "../Logo";

import { WYND_WHITE } from "../../styles";
import { AuthContext } from "../../contexts/AuthContext";

const pathIsClient = (loc: Location) =>
  [
    PATHS.CLIENTS,
    PATHS.CLIENTS_NEW,
    PATHS.CLIENT,
    PATHS.ZONES,
    PATHS.ZONES_NEW,
    PATHS.AUTH_CLIENTS,
  ].includes(loc.pathname);
const pathIsDashboard = (loc: Location) =>
  [PATHS.DASHBOARD].includes(loc.pathname);
const pathIsFleetManage = (loc: Location) =>
  loc.pathname.startsWith(PATHS.FLEET_MANAGEMENT);
const pathIsUsers = (loc: Location) =>
  [PATHS.USERS, PATHS.USERS_NEW].includes(loc.pathname);
const pathIsIAQ = (loc: Location) =>
  [PATHS.IAQ_CONTROL_CENTER].includes(loc.pathname);

const WyndLink = styled(Link)({
  flex: 1,
  textDecoration: "none",
  color: "inherit",
});

function WyndSideMenuFake() {
  return (
    <Container
      disableGutters
      sx={{
        display: "flex",
        minHeight: "100vh",
        flexDirection: "column",
        width: "15rem",
        visibility: "hidden",
        flexShrink: 0,
      }}
    >
      1
    </Container>
  );
}

function WyndSideMenuReal() {
  const { signout, user } = useContext(AuthContext);
  const location = useLocation();

  return (
    <Container
      disableGutters
      sx={{
        position: "fixed",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
        width: "15rem",
        backgroundColor: WYND_WHITE,
      }}
    >
      <Logo size="sm" sx={{ margin: "1rem" }} />
      <Divider />
      <nav
        aria-label="main side-nav"
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
        }}
      >
        <List>
          {user && user.isSuperuser && (
            <>
              <ListItem>
                <WyndLink to={PATHS.DASHBOARD}>
                  <ListItemButton selected={pathIsDashboard(location)}>
                    <ListItemIcon>
                      {pathIsDashboard(location) ? (
                        <DashboardIcon.Filled />
                      ) : (
                        <DashboardIcon.Outlined color="disabled" />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Dashboard" />
                  </ListItemButton>
                </WyndLink>
              </ListItem>
              <ListItem>
                <WyndLink to={PATHS.USERS}>
                  <ListItemButton selected={pathIsUsers(location)}>
                    <ListItemIcon>
                      {pathIsUsers(location) ? (
                        <ClientIcon.Filled />
                      ) : (
                        <ClientIcon.Outlined color="disabled" />
                      )}
                    </ListItemIcon>
                    <ListItemText primary="Users" />
                  </ListItemButton>
                </WyndLink>
              </ListItem>
            </>
          )}
          <ListItem>
            <WyndLink to={PATHS.IAQ_CONTROL_CENTER}>
              <ListItemButton selected={pathIsIAQ(location)}>
                <ListItemIcon>
                  {pathIsIAQ(location) ? (
                    <DashboardIcon.Filled />
                  ) : (
                    <DashboardIcon.Outlined color="disabled" />
                  )}
                </ListItemIcon>
                <ListItemText primary="IAQ Control Center" />
              </ListItemButton>
            </WyndLink>
          </ListItem>
          {user && !user.isClientSpecific && (
            <ListItem>
              <WyndLink to={PATHS.CLIENTS}>
                <ListItemButton selected={pathIsClient(location)}>
                  <ListItemIcon>
                    {pathIsClient(location) ? (
                      <ClientIcon.Filled />
                    ) : (
                      <ClientIcon.Outlined color="disabled" />
                    )}
                  </ListItemIcon>
                  <ListItemText primary="Client" />
                </ListItemButton>
              </WyndLink>
            </ListItem>
          )}
          {process.env.REACT_APP_ENV === "prod" ? null : (
            <ListItem>
              <WyndLink to={PATHS.FLEET_MANAGEMENT}>
                <ListItemButton selected={pathIsFleetManage(location)}>
                  <ListItemIcon>
                    <Sliders
                      color={
                        pathIsFleetManage(location) ? "primary" : "disabled"
                      }
                    />
                  </ListItemIcon>
                  <ListItemText primary="Fleet Manage" />
                </ListItemButton>
              </WyndLink>
            </ListItem>
          )}
        </List>
        <List>
          <ListItem onClick={signout}>
            <ListItemButton>
              <ListItemIcon>
                <LogoutIcon color="disabled" />
              </ListItemIcon>
              <ListItemText primary="Log Out" />
            </ListItemButton>
          </ListItem>
        </List>
      </nav>
    </Container>
  );
}

export default function WyndSideMenu() {
  return (
    <>
      <WyndSideMenuFake />
      <WyndSideMenuReal />
    </>
  );
}
