import React from "react";
import { Box, Grid, MenuItem } from "@mui/material";
import { Zone } from "src/interfaces/IZone";
import { Property } from "src/interfaces/IProperty";
import curry from "lodash/curry";
import Select, { Item } from "../../UI/Select";
import { Nullable } from "../../../helper";

/**
 * Using pending to indicate that the data is not yet loaded.
 * The returned value of `to[field]Options` will be [] and the returned `getSelected[field]` will be null
 */
const pending = Symbol("pending");
type Pending<V> = typeof pending | V;

export type FormType = {
  properties: Pending<Property[]>; // all properties of the client
  zones: Pending<Zone[]>; // all zones of the client
  propertyId: Nullable.T<number>; // property_id
  zoneIdsOfFloors: Array<number>; // Array<zone_id>
  zoneId: Nullable.T<number>; // null | zone_id
};

export type SelectedValue = {
  propertyId?: Nullable.T<number>;
  zoneIdsOfFloors?: Array<number>;
  zoneId?: Nullable.T<number>;
};

export const make = (value?: SelectedValue): FormType => ({
  properties: pending,
  zones: pending,
  propertyId: value?.propertyId || null,
  zoneIdsOfFloors: value?.zoneIdsOfFloors || [],
  zoneId: value?.zoneId || null,
});

export const isPending = (form: FormType): boolean =>
  form.properties === pending || form.zones === pending;

export const withModifiedProperties = (
  properties: Array<Property>,
  form: FormType
): FormType => {
  const property = properties.find((p) => p.property_id === form.propertyId);
  return {
    ...form,
    properties,
    propertyId: property === undefined ? null : property.property_id,
  };
};

export const withModifiedZones = (
  zones: Array<Zone>,
  form: FormType
): FormType => {
  const currentFloors = form.zoneIdsOfFloors.reduce(
    (acc: Set<string>, zoneId) => {
      // check the zoneId exists in the zones
      const zone = zones.find((z) => z.zone_id === zoneId);
      if (zone !== undefined) {
        acc.add(zone.floor);
      }
      return acc;
    },
    new Set<string>()
  );

  const zoneIdsOfFloors = zones
    .filter((z) => currentFloors.has(z.floor))
    .map((z) => z.zone_id);

  return {
    ...form,
    zones,
    zoneIdsOfFloors,
    zoneId: Nullable.flatMap(
      (zid: number) => (zoneIdsOfFloors.includes(zid) ? zid : null),
      form.zoneId
    ),
  };
};

// setter
export const withModifiedPropertyId = curry<number, FormType, FormType>(
  (propertyId: number, form: FormType): FormType => ({
    ...form,
    propertyId,
    zoneIdsOfFloors: [],
    zoneId: null,
  })
);
export const withModifiedFloors = curry<Array<string>, FormType, FormType>(
  (floors: Array<string>, form: FormType): FormType => {
    const newZoneIdsFromSelectedFloor = (
      form.zones !== pending ? form.zones : []
    )
      .filter((zone) => floors.includes(zone.floor))
      .map((z) => z.zone_id);

    const newSelectedZoneId =
      form.zoneId !== null && newZoneIdsFromSelectedFloor.includes(form.zoneId)
        ? form.zoneId
        : null;

    return {
      ...form,
      zoneIdsOfFloors: newZoneIdsFromSelectedFloor,
      zoneId: newSelectedZoneId,
    };
  }
);
export const withModifiedZoneIdOfFloors = curry<number[], FormType, FormType>(
  (zoneIdsOfFloors: number[], form: FormType): FormType => ({
    ...form,
    zoneIdsOfFloors,
  })
);
export const withModifiedZoneId = curry<Nullable.T<number>, FormType, FormType>(
  (zoneId: Nullable.T<number>, form: FormType): FormType => ({
    ...form,
    zoneId,
  })
);
export const toPropertyOptions = (form: FormType): Array<Property> => {
  if (form.zones === pending || form.properties === pending) {
    return [];
  }
  return [...form.properties];
};
export const toFloorOptions = (form: FormType): Array<string> => {
  if (form.zones === pending || form.properties === pending) {
    return [];
  }
  const s = form.zones.reduce((acc, zone) => {
    if (zone.property_id === form.propertyId) {
      acc.add(zone.floor);
    }
    return acc;
  }, new Set<string>([]));

  return Array.from(s.values());
};

export const toZoneOptions = (form: FormType): Array<Zone> => {
  if (form.zones === pending || form.properties === pending) {
    return [];
  }
  const zoneIsIncluded = (zone: Zone) =>
    zone.property_id === form.propertyId &&
    form.zoneIdsOfFloors.includes(zone.zone_id);

  return form.zones.reduce((acc: Array<Zone>, zone: Zone) => {
    if (zoneIsIncluded(zone)) {
      acc.push(zone);
    }
    return acc;
  }, []);
};

export const getSelectedPropertyId = (form: FormType): Nullable.T<number> =>
  form.propertyId;

export const getSelectedProperty = (form: FormType): Nullable.T<Property> => {
  if (form.zones === pending || form.properties === pending) {
    return null;
  }
  return (
    (form.properties as Property[]).find(
      (p) => p.property_id === form.propertyId
    ) || null
  );
};

export const getSelectedFloors = (form: FormType): Array<string> => {
  if (form.zones === pending || form.properties === pending) {
    return [];
  }
  const floors = form.zoneIdsOfFloors.reduce((acc, zoneId) => {
    const zone = (form.zones as Zone[]).find((z: Zone) => z.zone_id === zoneId);
    if (zone !== undefined) {
      acc.add(zone.floor);
    }
    return acc;
  }, new Set<string>([]));

  return Array.from(floors.values());
};

/**
 * Get zone that is selected by the form.
 */
export const getSelectedZone = (form: FormType): Nullable.T<Zone> => {
  if (form.zones === pending || form.properties === pending) {
    return null;
  }
  return (form.zones as Zone[]).find((z) => z.zone_id === form.zoneId) || null;
};

export const getSelectedZoneId = (form: FormType): Nullable.T<number> => {
  if (form.zones === pending || form.properties === pending) {
    return null;
  }
  return form.zoneId;
};

/**
 * Get zones that are not filtered out by the form.
 */
export const getIncludedZones = (form: FormType): Array<Zone> => {
  if (form.zones === pending || form.properties === pending) {
    return [];
  }

  const zoneIsIncluded = (zone: Zone) =>
    zone.property_id === form.propertyId &&
    form.zoneIdsOfFloors.includes(zone.zone_id) &&
    (form.zoneId === null || zone.zone_id === form.zoneId);

  return form.zones.reduce((acc: Array<Zone>, zone: Zone) => {
    if (zoneIsIncluded(zone)) {
      acc.push(zone);
    }
    return acc;
  }, []);
};

export const isEmpty = (form: FormType) =>
  form.propertyId === null || form.zoneIdsOfFloors.length === 0;

export interface ComponentOptions {
  hideProperty?: boolean;
}

namespace Sorter {
  export const property = (p1: Property, p2: Property) =>
    p1.property_name.localeCompare(p2.property_name);
  export const floor = (f1: string, f2: string) => f1.localeCompare(f2);
  export const zone = (z1: Zone, z2: Zone) =>
    z1.zone_name.localeCompare(z2.zone_name);
}

type Props = {
  form: FormType;
  setForm: React.Dispatch<React.SetStateAction<FormType>>;
  options?: ComponentOptions;
};
export function Component({ form, setForm, options }: Props) {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          margin: "2rem 0",
        }}
      >
        <Grid
          sx={{ flex: "1", marginLeft: "1rem" }}
          container
          columnSpacing={[0, 3]}
        >
          {!options?.hideProperty && (
            <Grid container item sm={12} md={3}>
              <Select
                label="Property"
                sx={{ flex: "1 1 100%" }}
                value={Nullable.unwrapOr("", getSelectedPropertyId(form))}
                onChange={(id) => setForm(withModifiedPropertyId(id as number))}
              >
                {toPropertyOptions(form)
                  .sort(Sorter.property)
                  .map((property: Property) => (
                    <MenuItem
                      value={property.property_id}
                      key={property.property_id}
                    >
                      {property.property_name}
                    </MenuItem>
                  ))}
              </Select>
            </Grid>
          )}
          <Grid container item sm={12} md={3}>
            <Select
              label="Floor"
              sx={{ flex: "1 1 100%" }}
              multiple
              value={getSelectedFloors(form)}
              onChange={(floors) => setForm(withModifiedFloors(floors))}
            >
              {toFloorOptions(form)
                .sort(Sorter.floor)
                .map((floor: string) => (
                  <Item key={floor} value={floor} showCheckbox>
                    {floor}
                  </Item>
                ))}
            </Select>
          </Grid>
          <Grid container item sm={12} md={6}>
            <Select
              label="Zone"
              sx={{ flex: "1 1 100%" }}
              value={Nullable.unwrapOr("", getSelectedZoneId(form))}
              onChange={(zoneId) =>
                setForm(
                  withModifiedZoneId(zoneId === "" ? null : (zoneId as number))
                )
              }
            >
              <MenuItem value="">--</MenuItem>
              {toZoneOptions(form)
                .sort(Sorter.zone)
                .map((zone: Zone) => (
                  <MenuItem key={zone.zone_id} value={zone.zone_id}>
                    {zone.zone_name}
                  </MenuItem>
                ))}
            </Select>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
