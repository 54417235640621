type Enumerate<
  N extends number,
  Acc extends number[] = []
> = Acc["length"] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc["length"]]>;

/**
 * Generate a range of numbers
 * @param F - From (inclusive)
 * @param T - To (exclusive)
 */
export type Range<F extends number, T extends number> = Exclude<
  Enumerate<T>,
  Enumerate<F>
>;

export const generateIntegers = (min: number, max: number): Array<number> => {
  const result = [];
  for (let i = min; i <= max; i += 1) {
    result.push(i);
  }
  return result;
};
