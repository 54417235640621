import React from "react";
import { Client } from "src/interfaces/IClient";
import Select, { Item } from "src/components/UI/Select";
import { SxProps, Theme } from "@mui/material/styles";

type ClientId = number;

type Props = {
  clients: Array<Client>;
  clientId: undefined | ClientId;
  onChange: (clientId: ClientId) => void;
  sx?: SxProps<Theme>;
};

export default function SelectClient({
  clients,
  clientId,
  onChange,
  sx,
}: Props) {
  const handleChange = (value: "" | number) => {
    if (value !== "") {
      onChange(value);
    }
  };

  return (
    <Select
      sx={sx}
      label="Client"
      value={clientId || ""}
      onChange={handleChange}
    >
      <Item sx={{ display: "none" }} value="" />
      {clients.map(({ client_id, client_name }) => (
        <Item key={client_id} value={client_id}>
          {client_name}
        </Item>
      ))}
    </Select>
  );
}
