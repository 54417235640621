import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
} from "@mui/material";
import moment from "moment";
import React from "react";
import UIInput from "src/components/UI/Input";
import UISelect, { Item } from "src/components/UI/Select";
import { generateIntegers } from "src/helper/number";

type Props = {
  isOpen: boolean;
  onPause: (date: Date) => Promise<void>;
  onClose: () => void;
};

export default function PauseProgramModal({ isOpen, onPause, onClose }: Props) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [date, setDate] = React.useState(new Date());
  const minDate = moment().format("YYYY-MM-DD");
  const dateValue = moment(date).format("YYYY-MM-DD");
  const minHour = dateValue === minDate ? new Date().getHours() + 1 : 0;

  const onChangeDate = (dateStr: string) => {
    const hour = date.getHours();
    const newDate = new Date(dateStr);
    newDate.setHours(hour);
    setDate(newDate);
  };

  const onChangeHour = (h: number) => {
    const newDate = new Date(date);
    newDate.setHours(h);
    setDate(newDate);
  };

  const onSave = () => {
    setIsLoading(true);
    const d = new Date(date);
    d.setMilliseconds(0);
    d.setSeconds(0);
    d.setMinutes(0);
    onPause(d).then(() => setIsLoading(false));
  };

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>Pause Schedule Until</DialogTitle>
      <DialogContent>
        <Stack
          direction="row"
          flexWrap="wrap"
          gap={3}
          sx={{
            gridTemplateColumns: "1fr 1fr",
            marginTop: 1,
            marginBottom: 2,
          }}
        >
          <UIInput
            type="date"
            label="Date"
            inputProps={{ min: minDate }}
            value={dateValue}
            onChange={onChangeDate}
            sx={{ flex: 1 }}
          />
          <UISelect
            label="Time"
            value={date.getHours()}
            onChange={onChangeHour}
            sx={{ flex: 1 }}
          >
            {generateIntegers(minHour, 23).map((h) => (
              <Item key={h} value={h}>
                {h > 9 ? `${h}:00` : `0${h}:00`}
              </Item>
            ))}
          </UISelect>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={onClose}>
          Cancel
        </Button>
        <Button
          onClick={onSave}
          variant="contained"
          disabled={isLoading}
          endIcon={
            isLoading ? <CircularProgress color="inherit" size={14} /> : null
          }
        >
          Save Change
        </Button>
      </DialogActions>
    </Dialog>
  );
}
