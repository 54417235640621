import React, { useContext, useEffect } from "react";
import { ArrowBack } from "@mui/icons-material";
import PageContainer from "src/components/Layout/PageContainer";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import useDeviceSelector from "src/components/Widget/DeviceSelector";
import { ClientContext } from "src/contexts/GqlContext";
import { getProgramsByDevices } from "src/services/graphql/Program";
import { useQuery } from "@apollo/client";
import {
  RegularProgram as Program,
  ProgramType,
} from "src/interfaces/IProgram";
import _get from "lodash/get";
import { useTheme } from "@mui/material";
import { Page, Context as PageContext } from "../../PageController";
import ProgramCard from "./ProgramCard";
import { makeEditProgramPage } from "../EditProgramPage";

type Props = { propertyId: number };

export type ViewProgramPage = Page<Props>;

export const pageName = "view-schedules";

export const makeViewProgramPage = (props: Props): ViewProgramPage => ({
  name: pageName,
  props,
});

export function Component({ propertyId }: Props) {
  const { client } = useContext(ClientContext);
  const { devices, formElement } = useDeviceSelector(client, {
    controlledValue: { propertyId },
    hideProperty: true,
  });
  const { backToLastPage, pushToPage, page } = useContext(PageContext);

  const routeToProgram = (program: Program) =>
    pushToPage(makeEditProgramPage({ program }));

  const { data, refetch } = useQuery(
    getProgramsByDevices(client.client_dbname),
    {
      variables: {
        device_ids: devices.map((d) => d.device_id),
        program_type: ProgramType.REGULAR,
      },
    }
  );

  useEffect(() => {
    // Workaround to refetch when page is re-entered. So Delete, Edit program will be rendered correctly.
    if (page.name === pageName) {
      refetch();
    }
  }, [page.name, refetch]);

  const programs: Program[] = _get(data, `${client.client_dbname}_program`, []);

  const theme = useTheme();

  return (
    <PageContainer>
      <Stack direction="row" sx={{ marginBottom: 5 }}>
        <IconButton onClick={() => backToLastPage()}>
          <ArrowBack sx={{ width: "32px", height: "32px" }} />
        </IconButton>
        <Typography variant="h3" sx={{ margin: "0 1rem" }}>
          View Schedule
        </Typography>
      </Stack>
      <Stack>{formElement}</Stack>
      <Stack
        sx={{
          display: "grid",
          gap: 3,
          [theme.breakpoints.down("sm")]: {
            gridTemplateColumns: "1fr",
          },
          [theme.breakpoints.up("md")]: {
            gridTemplateColumns: "1fr 1fr",
          },
        }}
      >
        {programs.map((program) => (
          <ProgramCard
            key={program.program_id}
            program={program}
            routeToProgram={routeToProgram}
          />
        ))}
      </Stack>
    </PageContainer>
  );
}
