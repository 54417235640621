import { styled } from "@mui/material";
import React from "react";
import Clock from "src/assets/icons/Clock";
import { MODE, modeToName } from "src/interfaces/IShadow";
import { ACTIVE_GREEN, INACTIVE_RED, WYND_WHITE } from "src/styles";

const Container = styled("span")({
  position: "relative",
  height: "18px",
});

const Body = styled("span")({
  position: "absolute",
  padding: "6px",
  flex: 0,
  borderRadius: "16px",
  alignSelf: "baseline",
  transform: "scale(0.67)",
  transformOrigin: "top left",
  height: "27px",
  display: "flex",
});

const TextContainer = styled("span")({
  fontWeight: 900,
  fontSize: "12px",
  gap: "6px",
  whiteSpace: "nowrap",
});

interface WyndWhiteBadge {
  <Props extends { timeLeft?: string }>(
    toLabel: (props: Props) => string
  ): React.FC<Props>;
}
// Use transform and absolute to workaround with browser's font rendering
const WyndWhiteBadge: WyndWhiteBadge = (toLabel) => (props) => {
  const { timeLeft } = props;
  const label = toLabel(props);
  return (
    <Container sx={{ width: `${label.length * 6}px` }}>
      <Body sx={{ backgroundColor: WYND_WHITE }}>
        <TextContainer>
          {label}
          {timeLeft && (
            <>
              <Clock />
              {timeLeft}
            </>
          )}
        </TextContainer>
      </Body>
    </Container>
  );
};

type LightProps = {
  on: boolean;
  timeLeft?: string;
};
export const Light = WyndWhiteBadge<LightProps>(({ on }) =>
  on ? "LIGHT ON" : "LIGHT OFF"
);

type LockedProps = {
  locked: boolean;
  timeLeft?: string;
};
export const Locked = WyndWhiteBadge<LockedProps>(({ locked }) =>
  locked ? "LOCKED" : "UNLOCKED"
);

type ModeProps = {
  mode: MODE;
  timeLeft?: string;
};
export const Mode = WyndWhiteBadge<ModeProps>(({ mode }) =>
  modeToName(mode).toUpperCase()
);

export function Online() {
  return (
    <Container sx={{ width: "41px" }}>
      <Body sx={{ backgroundColor: ACTIVE_GREEN, color: "white" }}>
        <TextContainer>ONLINE</TextContainer>
      </Body>
    </Container>
  );
}

export function Offline() {
  return (
    <Container sx={{ width: "44px" }}>
      <Body sx={{ backgroundColor: INACTIVE_RED, color: "white" }}>
        <TextContainer>OFFLINE</TextContainer>
      </Body>
    </Container>
  );
}
