import { gql, DocumentNode } from "@apollo/client";
import { ClientDBName } from "src/interfaces/IClient";

const SCHEDULE_BODY = `
  program_id
  recurring
  schedule_arn
  schedule_id
  schedule_name
  starting_at
  desired_shadow
`;

const queryTurboModeSchedule = (
  clientDBName: ClientDBName
): DocumentNode => gql`
    query GetTurboModeSchedules($program_type: program_type!) {
        ${clientDBName}_schedule(where: {
          recurring: {_is_null: true},
          program: {
            program_type: {_eq: $program_type},
          }
        }) {
          ${SCHEDULE_BODY}
      }
    }
  `;

const queryGetSchedules = (clientDBName: ClientDBName): DocumentNode => gql`
    query GetSchedules(
      $deviceIds: [String!] = [],
      $recurring: String_comparison_exp = {},
      $program_type: program_type) {
        ${clientDBName}_schedule(where: {
          recurring: $recurring,
          program: {
            program_type: {_eq: $program_type},
            devices: {device_id: {_in: $deviceIds}}}
        }) {
          ${SCHEDULE_BODY}
      }
    }
  `;

export { queryGetSchedules, queryTurboModeSchedule };
