import React, { useState, useContext, useEffect } from "react";
import { Stack, Typography, useTheme } from "@mui/material";
import { Map } from "immutable";
import useDeviceSelector from "src/components/Widget/DeviceSelector";
import { ClientContext } from "src/contexts/GqlContext";
import { Device } from "src/interfaces/IDevice";
import isEqual from "lodash/isEqual";
import { useIsEqual } from "src/helper/hooks";
import { getClientFleet } from "src/services/restful/device";
import { Client } from "src/interfaces/IClient";
import { axiosErrorToString } from "src/services/restful/utils";
import type { DeviceShadowMap } from "./DeviceList";
import DeviceList from "./DeviceList";
import SelectionContext from "./DeviceSelection";
import TurboMode from "./TurboMode";

const compareDevices = (ds1: Array<Device>, ds2: Array<Device>) => {
  const sorter = (d1: Device, d2: Device) =>
    d1.device_id.localeCompare(d2.device_id);

  return isEqual([...ds1].sort(sorter), [...ds2].sort(sorter));
};

const useDeviceShadowMap = (client: Client, devices: Array<Device>) => {
  const devicesState = useIsEqual(compareDevices, devices);

  const [map, setMap] = useState<DeviceShadowMap>(Map());
  const [error, setError] = useState("");
  const theme = useTheme();

  useEffect(() => {
    getClientFleet({
      clientHash: client.hash,
      deviceIds: devicesState.map((d) => d.device_id),
    })
      .then((res) => {
        const initMap: DeviceShadowMap = Map();
        setMap(
          res.data.reduce(
            (acc: DeviceShadowMap, { device_id, shadow }) =>
              shadow ? acc.set(device_id, shadow) : acc,
            initMap
          )
        );
      })
      .catch((err: Error) => {
        const errString = axiosErrorToString(err);
        setError(errString || "Unexpected error");
      });
  }, [client.hash, devicesState]);

  const errorElement = error ? (
    <Typography sx={{ color: theme.palette.error.main }}>
      Get device shadow failed: {error}
    </Typography>
  ) : null;

  return { deviceShadowMap: map, errorElement };
};

type Props = {
  propertyId: number;
};

export default function ClientFleetManagement({ propertyId }: Props) {
  const { client } = useContext(ClientContext);

  const {
    property,
    zones,
    floors,
    devices,
    formElement,
    refetchDevice,
    refetchZone,
  } = useDeviceSelector(client, {
    controlledValue: { propertyId },
    // Property should be selected by top level component
    hideProperty: true,
  });

  const { deviceShadowMap, errorElement } = useDeviceShadowMap(client, devices);

  return (
    <Stack direction="column" spacing={6}>
      <TurboMode property={property} />
      <Stack direction="column">
        {formElement}
        {errorElement}
        <SelectionContext propertyId={property?.property_id}>
          <DeviceList
            devices={devices}
            deviceShadowMap={deviceShadowMap}
            zones={zones}
            floors={floors}
            onRemoveDevice={() => refetchDevice().then()}
            onRelocateDevice={() => refetchDevice().then()}
            onZoneChange={() => refetchZone().then()}
          />
        </SelectionContext>
      </Stack>
    </Stack>
  );
}
