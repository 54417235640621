import React, { useEffect, useState } from "react";
import EventEmitter from "eventemitter3";
import Alert, { AlertColor } from "@mui/material/Alert";

import {
  Box,
  Slide,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import { Nullable, useAtomicVar, useIsMounted } from "src/helper";

type NotiType = "Failed" | "Success";
const notiToAlertType = (type: NotiType): AlertColor =>
  type === "Success" ? "success" : type === "Failed" ? "error" : "info";

export type NotiEvent = {
  delay: number | "persist"; // Milliseconds before toast auto close. Will be persist if the prop is "persist"
  type: NotiType;
  title: string;
  content?: string;
};

type Snackbar = {
  serial: number;
  isOpen: boolean;
  type: NotiType;
  autoHideDuration: Nullable.T<number>;
  notiContentProps: NotiContentProps;
};

const makeKeyFromState = ({ notiContentProps: n }: Snackbar) =>
  `${n.title} ${n.content}`;

export interface Intf {
  eventEmitter: EventEmitter;
  emit: (event: NotiEvent) => boolean;
  listen: (f: (e: NotiEvent) => void) => EventEmitter;
  unlisten: (f: (e: NotiEvent) => void) => EventEmitter;
  Component: (props: NotiComponentProps) => null | JSX.Element;
  clearAll: () => void;
}

type NotiContentProps = {
  title: string;
  content?: string;
};

type NotiComponentProps = {
  sx?: SxProps<Theme>;
};

const NotiContent = ({ title, content }: NotiContentProps) => (
  <Box>
    <Typography variant="subtitle3" component="h6" sx={{ marginBottom: 0.5 }}>
      {title}
    </Typography>
    {!content ? null : (
      <Typography sx={{ transform: "scale(0.83)" }} variant="body4">
        {content}
      </Typography>
    )}
  </Box>
);

const closeSnackbar =
  (serial: number, acc: Snackbar[] = []) =>
  (snackbars: Snackbar[]): Snackbar[] => {
    if (snackbars.length === 0) return acc;

    const hd = snackbars[0];
    const tl = snackbars.slice(1);
    if (hd.serial === serial) {
      return acc.concat([{ ...hd, isOpen: false }]).concat(tl);
    }
    return closeSnackbar(serial, acc.concat([hd]))(tl);
  };

const removeSnackbar =
  (serial: number) =>
  (snackbars: Snackbar[]): Snackbar[] =>
    snackbars.filter((thisState) => thisState.serial !== serial);

const pushSnackbar =
  (snackbar: Snackbar) =>
  (snackbars: Snackbar[]): Snackbar[] =>
    snackbars.concat([snackbar]);

export default function NotificationToast(): Intf {
  const EVENT_OPEN = "open";
  const EVENT_CLEAR = "clear";
  const eventEmitter = new EventEmitter();
  const emit = (openEvent: NotiEvent) =>
    eventEmitter.emit(EVENT_OPEN, openEvent);
  const listen = (handler: (e: NotiEvent) => void) =>
    eventEmitter.addListener(EVENT_OPEN, handler);
  const unlisten = (handler: (e: NotiEvent) => void) =>
    eventEmitter.removeListener(EVENT_OPEN, handler);
  const clearAll = () => eventEmitter.emit(EVENT_CLEAR);

  const Component = ({ sx = {} }: NotiComponentProps) => {
    // Use this to prevent state being updated after unmount
    const isMounted = useIsMounted();

    const [snackbars, setSnackbars] = useState<Array<Snackbar>>([]);
    const applySerial = useAtomicVar<number>(0);

    const unmountSnackbar = (serial: number) =>
      setSnackbars(removeSnackbar(serial));

    const theme = useTheme();

    useEffect(() => {
      const handler = () => {
        setSnackbars([]);
      };

      eventEmitter.addListener(EVENT_CLEAR, handler);
      return () => {
        eventEmitter.removeListener(EVENT_CLEAR, handler);
      };
    }, []);

    useEffect(() => {
      const handler = (event: NotiEvent) => {
        applySerial((serial: number) => {
          const newState: Snackbar = {
            serial,
            isOpen: true,
            type: event.type,
            autoHideDuration: event.delay === "persist" ? null : event.delay,
            notiContentProps: {
              title: event.title,
              content: event.content,
            },
          };
          if (typeof event.delay === "number") {
            setTimeout(() => {
              if (isMounted()) {
                setSnackbars(closeSnackbar(serial));
              }
            }, event.delay);
          }
          setSnackbars(pushSnackbar(newState));

          return serial + 1;
        });
      };

      listen(handler);
      return () => {
        unlisten(handler);
      };
    }, [applySerial, isMounted]);

    return snackbars.length === 0 ? null : (
      <Stack
        sx={{
          position: "fixed",
          top: "48px",
          right: "32px",
          zIndex: theme.zIndex.snackbar,
          ...sx,
        }}
        gap={2}
      >
        {snackbars.map((snackbar) => (
          <Slide
            key={snackbar.serial}
            in={snackbar.isOpen}
            onExited={() => unmountSnackbar(snackbar.serial)}
            direction="left"
          >
            <Alert
              sx={{ maxWidth: "400px", minHeight: "80px" }}
              key={makeKeyFromState(snackbar)}
              onClose={() => setSnackbars(closeSnackbar(snackbar.serial))}
              variant="standard"
              color={notiToAlertType(snackbar.type)} // for color
              severity={notiToAlertType(snackbar.type)} // for icon
            >
              {React.createElement(NotiContent, snackbar.notiContentProps)}
            </Alert>
          </Slide>
        ))}
      </Stack>
    );
  };

  return {
    eventEmitter,
    emit,
    listen,
    unlisten,
    Component,
    clearAll,
  };
}
