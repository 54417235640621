import { gql, useApolloClient } from "@apollo/client";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  TextField,
} from "@mui/material";
import Paper, { PaperProps } from "@mui/material/Paper";
import React, { useContext, useEffect, useState } from "react";
import { Property } from "src/interfaces/IProperty";
import {
  mutateUpdateProperty,
  editPropertyCache,
} from "../../services/graphql/Property";
import { ClientContext } from "../../contexts/GqlContext";

const FormPaperComponent = (props: PaperProps) =>
  React.createElement(Paper, { ...props, component: "form" } as PaperProps);

type Props = {
  property?: Property;
  onPropertyEdited: () => void;
  isOpen: boolean;
  onClose: () => void;
};

export default function EditPropertyModal({
  property,
  onPropertyEdited,
  isOpen,
  onClose,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [propertyName, setPropertyName] = useState<string>("");
  const [address, setAddress] = useState<string>("");
  const {
    client: { client_dbname: clientDBName },
  } = useContext(ClientContext);
  const gqlClient = useApolloClient();

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsEditing(true);
    const mutation = mutateUpdateProperty(clientDBName);
    await gqlClient.mutate({
      mutation: gql`
        ${mutation}
      `,
      variables: {
        // eslint-disable-next-line react/destructuring-assignment
        propertyId: property?.property_id,
        propertyName,
        address,
      },
      update: editPropertyCache(clientDBName),
    });
    setIsEditing(false);
    onPropertyEdited();
  };
  useEffect(() => {
    if (property) {
      setAddress(property.property_address);
      setPropertyName(property.property_name);
    }
  }, [property]);
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      PaperComponent={FormPaperComponent}
      PaperProps={{ onSubmit: handleFormSubmit }}
    >
      {isEditing && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
          }}
        />
      )}
      <DialogTitle>Edit Property</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={propertyName}
          onChange={(e) => setPropertyName(e.target.value)}
          label="Property Name"
          placeholder="Property Name"
          sx={{ margin: ".5rem 0" }}
        />
        <TextField
          fullWidth
          value={address}
          onChange={(e) => setAddress(e.target.value)}
          label="Address"
          placeholder="Address"
          sx={{ margin: ".5rem 0" }}
        />
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isEditing}
          onClick={onClose}
          color="info"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button disabled={isEditing} type="submit" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}
