import React, { useState, useContext, useEffect } from "react";
import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { context } from "./context";
import { isTokenExpired } from "../../services/authService";

export default function TokenExpiredModal() {
  const ctx = useContext(context);
  const token = ctx?.user?.token;
  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    if (token) {
      const isRunExpired = isTokenExpired(token);
      const iid = setInterval(() => {
        if (isRunExpired()) {
          setIsOpen(true);
        }
      }, 1000);

      return () => clearInterval(iid);
    }
    return undefined;
  }, [token]);

  const onConfirm = () => {
    ctx?.signout();
    setIsOpen(false);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen}>
      <DialogContent>
        This session is expired. Please login again.
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onConfirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
}
