import {
  ArrowBack,
  MoreHoriz,
  Sort,
  SortByAlpha,
  ContentCopy,
} from "@mui/icons-material";
import {
  Box,
  IconButton,
  Typography,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  LinearProgress,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PageContainer from "../../components/Layout/PageContainer";
import { AUTH_TOKEN } from "../../const";

function PastCodesPage() {
  const navigate = useNavigate();
  const [codesList, setCodesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [sortBy, setSortBy] = useState("");

  async function queryPastCodes() {
    const token = localStorage.getItem(AUTH_TOKEN);
    const requestURL = `${process.env.REACT_APP_API_DOMAIN}/v1/auth/codes`;
    const response = await fetch(requestURL, {
      headers: { Authorization: token },
    });
    return response.json();
  }

  useEffect(() => {
    queryPastCodes()
      .then((value) => setCodesList(value))
      .catch((err) => {
        setError(`Unable to retreive past codes: ${err}`);
      });
    setIsLoading(false);
  }, []);

  function sortFunction(a, b) {
    if (sortBy === "installer") {
      return `${a.firstName} ${a.lastName}`.localeCompare(
        `${b.firstName} ${b.lastName}`
      );
    }
    if (sortBy === "client") {
      return a.client.localeCompare(b.client);
    }
    return 0;
  }

  if (isLoading) {
    return (
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell colSpan={4}>
                <LinearProgress />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  if (error) {
    return (
      <TableContainer>
        <Table size="small">
          <TableBody>
            <TableRow>
              <TableCell colSpan={4}>Can not load past codes</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack sx={{ width: "32px", height: "32px" }} />
          </IconButton>
          <Typography variant="h3" sx={{ marginRight: "1rem" }}>
            Past Codes
          </Typography>
        </Box>
      </Box>
      <Box>
        <TableContainer>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>
                  Installer Name
                  <IconButton onClick={() => setSortBy("installer")}>
                    <Sort />
                  </IconButton>
                </TableCell>
                <TableCell>
                  Client Name
                  <IconButton onClick={() => setSortBy("client")}>
                    <SortByAlpha />
                  </IconButton>
                </TableCell>
                <TableCell>Installer Code</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {codesList.sort(sortFunction).map((entry) => (
                <TableRow hover sx={{ cursor: "pointer" }} key={entry.code}>
                  <TableCell>{`${entry.firstName} ${entry.lastName}`}</TableCell>
                  <TableCell>{entry.client}</TableCell>
                  <TableCell>
                    {entry.code}
                    <IconButton
                      onClick={() => navigator.clipboard.writeText(entry.code)}
                    >
                      <ContentCopy />
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    <IconButton>
                      <MoreHoriz />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </PageContainer>
  );
}

export default PastCodesPage;
