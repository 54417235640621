import axios from "axios";
import { CHALICE_URL, AUTH_TOKEN } from "../const";

const REQUEST_TIMEOUT = 60 * 1000;  // in milliseconds

const httpPublicRequest = axios.create({
  baseURL: CHALICE_URL,
  timeout: REQUEST_TIMEOUT,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});

const httpAuthorizedRequest = axios.create({
  baseURL: CHALICE_URL,
  timeout: REQUEST_TIMEOUT,
  headers: { "Content-Type": "application/json", Accept: "application/json" },
});
httpAuthorizedRequest.interceptors.request.use((config) => {
  const cfx = config;
  const authToken = localStorage.getItem(AUTH_TOKEN);
  if (authToken) {
    cfx.headers.Authorization = authToken;
  }
  return cfx;
});

export { httpPublicRequest, httpAuthorizedRequest };
