import { gql } from "@apollo/client";
import { ClientDBName } from "src/interfaces/IClient";

const queryAirQuality1minByDevice = (clientDBName: ClientDBName) => gql`
  query GetAirQuality1minByDevice($device_id: String!) {
    ${clientDBName}_air_quality_1min(limit: 1, order_by: {sensor_dt: desc}, where: {device_id: {_eq: $device_id}}) {
      id
      device_id
      sensor_dt
      db
      eco2
      lux
      pm1
      pm2_5
      pm10
      pm4
      rh
      temp
      voc
      wynd_score
    }
  }
  `;

const queryAirQuality1minByDevices = (clientDBName: ClientDBName) => gql`
  query GetAirQuality1minByDevices($device_ids: [String!]) {
    ${clientDBName}_air_quality_1min(where: {device_id: {_in: $device_ids}}) {
      id
      device_id
      sensor_dt
      db
      eco2
      lux
      pm1
      pm2_5
      pm10
      pm4
      rh
      temp
      voc
      wynd_score
    }
  }
  `;

// eslint-disable-next-line import/prefer-default-export
export { queryAirQuality1minByDevice, queryAirQuality1minByDevices };
