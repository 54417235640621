import { Program, ProgramType } from "src/interfaces/IProgram";
import { Device } from "src/interfaces/IDevice";
import { ClientHash } from "src/interfaces/IClient";
import { ClientScheduleBase, DeleteClientSchedule } from "./schedule";
import { httpAuthorizedRequest } from "../httpService";

export interface ClientProgramBase {
  program_name: string;
  program_type: ProgramType;
}
export interface ClientProgramDisplayBase {
  display_name: string;
  notes: string;
  days_of_week: number;
}
export interface CreateClientScheduleInProgram extends ClientScheduleBase {}
export interface CreateClientProgramDisplayInProgram
  extends ClientProgramDisplayBase {}
export interface CreateClientProgramDisplay extends ClientProgramDisplayBase {
  program_id?: number;
}

export interface DeleteClientProgram {
  program_id: number;
}
export interface DeleteClientProgramDisplay {
  program_display_id?: number;
}

export interface UpdateClientScheduleInProgram extends ClientScheduleBase {
  schedule_id: number;
}
export interface UpdateClientProgramDisplayInProgram
  extends ClientProgramDisplayBase {
  program_display_id: number;
}
export interface UpdateClientProgramDisplay {
  display_name: string;
  notes: string;
  days_of_week: number;
  program_display_id?: number;
  program_id?: number;
}
export interface CreateClientProgram extends ClientProgramBase {
  schedules?: CreateClientScheduleInProgram[];
  program_displays?: CreateClientProgramDisplayInProgram[];
  devices?: Device["device_id"][];
}
export interface UpdateClientProgram {
  program_name: string;
  program_type: ProgramType;
  create_schedules?: CreateClientScheduleInProgram[];
  update_schedules?: UpdateClientScheduleInProgram[];
  delete_schedules?: DeleteClientSchedule[];
  create_program_displays?: CreateClientProgramDisplayInProgram[];
  update_program_displays?: UpdateClientProgramDisplayInProgram[];
  delete_program_displays?: DeleteClientProgramDisplay[];
  add_device_ids?: Device["device_id"][];
  delete_device_ids?: Device["device_id"][];
}

export interface PauseClientProgram {
  // Time in %Y-%m-%dT%H:%M:%S format
  pause_until: string;
  timezone: string;
}

export const updateProgram = async <PT extends ProgramType = ProgramType>(
  clientHash: ClientHash,
  programId: number,
  requestBody: UpdateClientProgram
) =>
  httpAuthorizedRequest.put<Program<PT>>(
    `/v1/cre/clients/${clientHash}/fleet/program/${programId}`,
    JSON.stringify(requestBody)
  );

export const createProgram = async <PT extends ProgramType = ProgramType>(
  clientHash: ClientHash,
  requestBody: CreateClientProgram
) =>
  httpAuthorizedRequest.post<Program<PT>>(
    `/v1/cre/clients/${clientHash}/fleet/program`,
    JSON.stringify(requestBody)
  );

export const pauseProgram = async (
  clientHash: ClientHash,
  programId: Program<ProgramType.REGULAR>["program_id"],
  requestBody: PauseClientProgram
) =>
  httpAuthorizedRequest.post<Program<ProgramType.REGULAR>>(
    `/v1/cre/clients/${clientHash}/fleet/program/${programId}/pause`,
    JSON.stringify(requestBody)
  );

export const resumeProgram = async (
  clientHash: ClientHash,
  programId: Program<ProgramType.REGULAR>["program_id"]
) =>
  httpAuthorizedRequest.post<Program<ProgramType.REGULAR>>(
    `/v1/cre/clients/${clientHash}/fleet/program/${programId}/resume`,
    ""
  );

export const deleteProgram = async (
  clientHash: ClientHash,
  programId: Program["program_id"]
) =>
  httpAuthorizedRequest.delete(
    `/v1/cre/clients/${clientHash}/fleet/program/${programId}`
  );
