import { AxiosError } from "axios";
import { Nullable } from "src/helper";

const axiosErrorToString = (err: Error): Nullable.T<string> => {
  if (err instanceof AxiosError<{ Message: string; Code: string }>) {
    const message =
      (err.response && err.response.data.Message) ||
      err.message ||
      "Request failed";
    const code = (err.response && err.response.data.Code) || err.code;
    return `[${code}] ${message}`;
  }
  return null;
};

// eslint-disable-next-line import/prefer-default-export
export { axiosErrorToString };
