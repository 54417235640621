import React, { useEffect, useState } from "react";
import { Set as DeviceSet } from "src/interfaces/set/device";
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormGroup,
  Typography,
  Checkbox,
  Stack,
  DialogActions,
} from "@mui/material";
import { Device } from "src/interfaces/IDevice";
import { EventEmitter } from "eventemitter3";

export type ContentProps = {
  devices: Device[];
  label: string;
  onCancel: () => void;
  onNext: (schedules: Device[]) => void;
};

const Content = ({ label, devices, onCancel, onNext }: ContentProps) => {
  const [selected, setSelected] = useState<DeviceSet>(DeviceSet(devices));

  useEffect(() => {
    setSelected(DeviceSet(devices));
  }, [devices]);

  const handleCheck =
    (device: Device) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        setSelected((set: DeviceSet) => set.add(device));
      } else {
        setSelected((set: DeviceSet) => set.delete(device));
      }
    };
  const handleSelectAll = () => setSelected(DeviceSet(devices));

  return (
    <>
      <DialogTitle>
        <Typography variant="h7" sx={{ marginBottom: 1 }}>
          Following devices are included in other schedules
        </Typography>
        <Typography variant="body1">
          Please select the devices you want to update the schedule.
        </Typography>
      </DialogTitle>
      <DialogContent>
        <FormControl sx={{ margin: 0 }} variant="standard">
          <Stack
            direction="row"
            sx={{ alignItems: "center", marginTop: 1, marginBottom: 3 }}
            gap={1}
          >
            <Typography variant="subtitle2" component="span">
              {label}
            </Typography>
            <Button onClick={handleSelectAll}>Select All</Button>
          </Stack>
          {devices
            .sort((d1, d2) => d1.device_id.localeCompare(d2.device_id))
            .map((device) => (
              <FormControlLabel
                key={device.device_id}
                label={
                  <Typography variant="subtitle2" component="span">
                    {`${device.zone.name}`}
                    <Typography component="span" sx={{ marginLeft: 1 }}>
                      {device.device_id}
                    </Typography>
                  </Typography>
                }
                control={
                  <Checkbox
                    checked={selected.has(device)}
                    onChange={handleCheck(device)}
                  />
                }
              />
            ))}
          <FormGroup>
            <FormControl />
          </FormGroup>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="info" onClick={onCancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => onNext(selected.toArray())}
        >
          Next
        </Button>
      </DialogActions>
    </>
  );
};

namespace Event {
  const ee = new EventEmitter();
  export type Value = {
    isOpen: boolean;
    devices: Array<Device>;
    callback: (devices: Array<Device>) => void;
  };
  export const defaultValue = {
    isOpen: false,
    devices: [],
    callback: () => {},
  };
  export const listenOpenEvent = (handler: (v: Value) => void) =>
    ee.on("open", handler);
  export const unlistenOpenEvent = (handler: (v: Value) => void) =>
    ee.off("open", handler);
  export const listenCloseEvent = (handler: () => void) =>
    ee.on("close", handler);
  export const unlistenCloseEvent = (handler: () => void) =>
    ee.off("close", handler);
  export const open = (
    devices: Array<Device>,
    callback: (devices: Array<Device>) => void
  ) => ee.emit("open", { isOpen: true, devices, callback });
  export const close = () => ee.emit("close");
}

// This modal assume all devices are in the same floor and property
const labelFromDevices = (devices: Device[]) => {
  if (devices.length === 0) return "";
  return `${devices[0].property.name}, ${devices[0].zone.floor}`;
};

function DeviceSelectModal() {
  const [{ isOpen, devices, callback }, setValue] = useState<Event.Value>(
    Event.defaultValue
  );

  const onClose = () => {
    setValue(Event.defaultValue);
  };

  const onSubmit = (thisDevices: Device[]) => {
    callback(thisDevices);
    onClose();
  };

  useEffect(() => {
    const handler = setValue;
    Event.listenOpenEvent(handler);
    return () => {
      Event.unlistenOpenEvent(handler);
    };
  }, []);

  useEffect(() => {
    const handler = () => {
      setValue(Event.defaultValue);
    };
    Event.listenCloseEvent(handler);
    return () => {
      Event.unlistenOpenEvent(handler);
    };
  }, []);

  return (
    <Dialog
      keepMounted={false}
      TransitionProps={{
        unmountOnExit: true,
      }}
      open={isOpen}
      onClose={onClose}
    >
      <Content
        label={labelFromDevices(devices)}
        devices={devices}
        onCancel={onClose}
        onNext={onSubmit}
      />
    </Dialog>
  );
}

DeviceSelectModal.open = Event.open;

export default DeviceSelectModal;
