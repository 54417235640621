import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import logo from "../assets/images/logo.png";
import { lightTheme } from "../styles";

const theme = lightTheme;

function Logo({ sx, size }) {
  const getSizes = () => {
    switch (size) {
      case "sm":
        return { logoHeight: 48, titleSize: "1.5rem", subtitleSize: "0.6rem" };
      case "md":
        return { logoHeight: 64, titleSize: "2rem", subtitleSize: "1rem" };
      default:
        return { logoHeight: 64, titleSize: "2rem", subtitleSize: "1rem" };
    }
  };
  const sizes = getSizes();
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        ...sx,
      }}
    >
      <img src={logo} alt="Wynd logo" height={sizes.logoHeight} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          marginLeft: "1rem",
        }}
      >
        <Typography
          sx={{
            lineHeight: sizes.titleSize,
            fontSize: sizes.titleSize,
            fontWeight: "200",
            color: theme.palette.primary.main,
          }}
        >
          WYND
        </Typography>
        <Typography
          sx={{
            color: theme.palette.primary.main,
            fontStyle: "italic",
            fontSize: sizes.subtitleSize,
            whiteSpace: "nowrap",
          }}
        >
          IAQ Control Center
        </Typography>
      </Box>
    </Box>
  );
}

Logo.propTypes = {
  sx: PropTypes.objectOf(PropTypes.shape()),
  size: PropTypes.string,
};

Logo.defaultProps = {
  sx: {},
  size: "md",
};

export default Logo;
