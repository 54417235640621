import React from "react";
import { Card, Box, Typography } from "@mui/material";
import { DM_DARK_GREY } from "src/styles";
import { InfectionRiskRating } from "src/const";
import Low from "./Low";
import Medium from "./Medium";
import High from "./High";

type InfectionRiskWidgetProps = {
  risk: String;
  sx?: object;
};

const InfectionRiskWidget = ({ risk, sx }: InfectionRiskWidgetProps) => {
  const icon =
    risk === InfectionRiskRating.HIGH ? (
      <High />
    ) : risk === InfectionRiskRating.MEDIUM ? (
      <Medium />
    ) : (
      <Low />
    );
  return (
    <Card sx={{ padding: 2, height: "100%", ...sx }}>
      <Typography variant="subtitle4" color={DM_DARK_GREY}>
        Infection Risk
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle1">{risk}</Typography>
        {icon}
      </Box>
    </Card>
  );
};

export default InfectionRiskWidget;
