import { generateIntegers } from "src/helper/number";
import type { ModeCycle, StartHour, EndHour } from "./types";

const startHours = generateIntegers(0, 23) as StartHour[];
const endHours = generateIntegers(1, 24) as EndHour[];

const getNearestHour =
  <H extends number>(greaterThan: boolean) =>
  (hours: H[], n: H): undefined | H => {
    const hs = hours
      .map((v) => v - n)
      .filter((v) => (greaterThan ? v >= 0 : v <= 0))
      .sort();
    if (greaterThan) {
      return (hs[hs.length - 1] && n + hs[hs.length - 1]) as undefined | H;
    }
    return (hs[0] && n + hs[0]) as undefined | H;
  };
const getNearestSmallerHour = getNearestHour(false);
const getNearestLargerHour = getNearestHour(true);

const spareHours = <H extends number>(
  hours: H[],
  modeCycles: ModeCycle[]
): H[] => {
  const occupied = new Set<number>();
  modeCycles.forEach(({ startTime, endTime }) => {
    if (startTime !== null && endTime !== null) {
      for (let i = startTime; i < endTime; i += 1) {
        occupied.add(i);
      }
    }
  });
  return hours.filter((v) => !occupied.has(v));
};

export const generateNthStartTimeOptions = (
  modeCycles: ModeCycle[],
  index: number
): StartHour[] => {
  const thisEndTime = modeCycles[index]?.endTime;
  const otherModeCycles = modeCycles
    .slice(0, index)
    .concat(modeCycles.slice(index + 1));

  if (thisEndTime === null) {
    return spareHours(startHours, otherModeCycles);
  }
  const endHoursExisted = otherModeCycles.reduce(
    (acc: EndHour[], v) => (v.endTime === null ? acc : [...acc, v.endTime]),
    []
  );
  const nearestEndHour = getNearestSmallerHour(endHoursExisted, thisEndTime);

  return generateIntegers(nearestEndHour || 0, thisEndTime - 1) as StartHour[];
};

export const generateNthEndTimeOptions = (
  modeCycles: ModeCycle[],
  index: number
): EndHour[] => {
  const thisStartTime = modeCycles[index]?.startTime;
  const otherModeCycles = modeCycles
    .slice(0, index)
    .concat(modeCycles.slice(index + 1));

  if (thisStartTime === null) {
    return spareHours(endHours, otherModeCycles);
  }
  const startHoursExisted = otherModeCycles.reduce(
    (acc: StartHour[], v) =>
      v.startTime === null ? acc : [...acc, v.startTime],
    []
  );
  const nearestStartHour = getNearestLargerHour(
    startHoursExisted,
    thisStartTime
  );

  return generateIntegers(
    thisStartTime + 1,
    nearestStartHour || 24
  ) as EndHour[];
};
