import React from "react";
import { Box } from "@mui/material";
import { Outlet } from "react-router-dom";
import WyndSideMenu from "./WyndSideMenu";

function WyndSideMenuLayout() {
  return (
    <Box
      sx={{
        minHeight: "100vh",
        width: "100%",
        display: "flex",
      }}
    >
      <WyndSideMenu />
      <Box sx={{ flex: "1" }}>
        <Outlet />
      </Box>
    </Box>
  );
}

export default WyndSideMenuLayout;
