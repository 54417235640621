import { Record } from "immutable";

import * as IDevice from "../IDevice";
import { TypedSet, makeTypedSet } from "./TypedSet";

export type Set = TypedSet<IDevice.Device, { device_id: string }>;

const keyFactory = Record({ device_id: "" });

export const Set = makeTypedSet<IDevice.Device, { device_id: string }>(
  keyFactory
);
