import {
  CircularProgress,
  IconButton,
  styled,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import Stack from "@mui/material/Stack";
import React from "react";
import Arrow from "@mui/icons-material/ArrowForward";
import { Link } from "react-router-dom";
import { PATHS, format } from "src/router";
import { Client } from "src/interfaces/IClient";
import SortAtoZ from "src/assets/icons/SortAtoZ";
import { DeviceCount } from "./Model";

// const rowToKey = (row: Row) =>
//   `${row.client.client_id}-${row.property.property_id}`;

type CountUIKey = keyof DeviceCount.Count;

function Count({
  count,
  showOffline,
  numerator,
  denominator = "total",
}: {
  count: DeviceCount.Count;
  showOffline: boolean;
  numerator: CountUIKey;
  denominator?: CountUIKey;
}) {
  return showOffline ? (
    <span>
      <Typography
        variant="body1"
        color={count.disconnected > 0 ? "error" : "inherit"}
        component="span"
      >
        {count[numerator]}
      </Typography>
      <Typography variant="body1" component="span">
        / {count[denominator]}
      </Typography>
    </span>
  ) : (
    <Typography variant="body1">{count.total}</Typography>
  );
}

const StyledLink = styled(Link)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

type ClientTableRowProps = {
  client: Client;
  propertyId: number;
  propertyName: string;
  countIsLoading: boolean;
  counts: undefined | DeviceCount.Counts;
};
function ClientTableRow({
  client,
  propertyId,
  propertyName,
  countIsLoading,
  counts,
}: ClientTableRowProps) {
  const showOffline =
    counts && (counts.halo.disconnected > 0 || counts.max.disconnected > 0);

  return (
    <TableRow>
      <TableCell>
        <StyledLink
          target="_blank"
          to={format(PATHS.CLIENT, { clientId: client.client_id.toString() })}
        >
          {client.client_name}
        </StyledLink>
      </TableCell>
      <TableCell>{propertyName}</TableCell>
      <TableCell>{client.country}</TableCell>
      <TableCell>
        {countIsLoading && <CircularProgress size={14} />}
        {counts ? (
          <Count
            count={counts.max}
            numerator="disconnected"
            showOffline={!!showOffline}
          />
        ) : countIsLoading ? null : (
          0
        )}
      </TableCell>
      <TableCell>
        {countIsLoading && <CircularProgress size={14} />}
        {counts ? (
          <Count
            count={counts.halo}
            numerator="disconnected"
            showOffline={!!showOffline}
          />
        ) : countIsLoading ? null : (
          0
        )}
      </TableCell>
      <TableCell>
        {countIsLoading && <CircularProgress size={14} />}
        {counts ? (
          <Count
            count={DeviceCount.add(counts.halo, counts.max)}
            numerator="withError"
            showOffline={!!showOffline}
          />
        ) : countIsLoading ? null : (
          0
        )}
      </TableCell>
      <TableCell>
        <Link
          target="_blank"
          to={`${format(PATHS.ZONES, {
            clientId: client.client_id.toString(),
          })}?property_id=${propertyId}`}
        >
          <IconButton>
            <Arrow />
          </IconButton>
        </Link>
      </TableCell>
    </TableRow>
  );
}

type Props = {
  title: string;
  children?: React.ReactNode;
  onSort: () => void;
};

const ClientTable = React.forwardRef(
  (
    { title, children, onSort }: Props,
    ref: React.Ref<HTMLTableSectionElement>
  ) => (
    <Stack direction="column">
      <Typography variant="h7">{title}</Typography>
      <Table sx={{ marginTop: 4, marginBottom: 7 }}>
        <TableHead>
          <TableRow>
            <TableCell>
              Client Name{" "}
              <IconButton size="small" onClick={onSort}>
                <SortAtoZ />
              </IconButton>{" "}
            </TableCell>
            <TableCell>Property Name</TableCell>
            <TableCell>Country</TableCell>
            <TableCell>Offline Halos</TableCell>
            <TableCell>Offline Maxes</TableCell>
            <TableCell>Device Errors</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody ref={ref}>{children}</TableBody>
      </Table>
    </Stack>
  )
);

export { ClientTableRow, ClientTable };
