import { Box, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { makeBatchGqlClient } from "src/contexts/GqlContext";
import { Client } from "src/interfaces/IClient";
import { queryGetProperties } from "src/services/graphql/Property";
import { Nullable, PromiseResult } from "src/helper";
import { Property } from "src/interfaces/IProperty";
import { Zone } from "src/interfaces/IZone";
import { queryGetZones } from "src/services/graphql/Zone";
import UIClientCard from "./UIClientCard";
import { errorMessages, parseFetchResult } from "../../services/graphql/utils";
import UIMasonry from "./UIMasonry";

type ClientCardProps = {
  client: Client;
  onClickedAccountInfo: () => void;
  onClickedManageDevices: () => void;
};

function useInvariant<V>(init: () => V) {
  const [v] = useState(() => init());
  return v;
}

function ClientCard({
  client,
  onClickedAccountInfo,
  onClickedManageDevices,
}: ClientCardProps) {
  const gqlClient = useInvariant(() =>
    makeBatchGqlClient(client.client_dbname)
  );

  const [error, setError] = useState<Nullable.T<string>>(null);
  const [zones, setZones] = useState<Array<Zone>>([]);
  const [properties, setProperties] = useState<Array<Property>>([]);

  useEffect(() => {
    PromiseResult.pair([
      PromiseResult.fromPromise(
        gqlClient
          .query<{ [endpoint: string]: Array<Property> }>({
            query: queryGetProperties(client.client_dbname),
          })
          .then(parseFetchResult)
      ).mapOk((data) => data[`${client.client_dbname}_property`]),

      PromiseResult.fromPromise(
        gqlClient
          .query<{ [endpoint: string]: Array<Zone> }>({
            query: queryGetZones(client.client_dbname),
            variables: {
              property_id: {},
            },
          })
          .then(parseFetchResult)
      ).mapOk((data) => data[`${client.client_dbname}_zone`]),
    ])
      .mapOk(([ps, zs]) => {
        setZones(zs);
        setProperties(ps);
      })
      .mapError((e) => {
        console.error(e);
        setError(errorMessages(e));
      })
      .catch((e) => {
        console.error(e);
        if (e instanceof Error) {
          setError(e.message);
        } else {
          setError("Unknown error");
        }
      });
  }, [client.client_dbname, gqlClient]);

  return (
    <UIClientCard
      client={client}
      properties={properties}
      zones={zones}
      error={error}
      onClickedAccountInfo={onClickedAccountInfo}
      onClickedManageDevices={onClickedManageDevices}
    />
  );
}

type Props = {
  clients: Array<Client>;
  handleAccountInfoClicked: (clientId: number) => void;
  handleManageDevicesClicked: (clientId: number) => void;
};

// to be discussed if we should try to implement masonry layout like in design or not
export default function ClientCardView({
  clients,
  handleAccountInfoClicked,
  handleManageDevicesClicked,
}: Props) {
  return (
    <Stack direction="column" spacing={2}>
      <UIMasonry colNum={2}>
        {clients.map((client) => (
          <Box key={client.client_id}>
            <ClientCard
              client={client}
              onClickedAccountInfo={() =>
                handleAccountInfoClicked(client.client_id)
              }
              onClickedManageDevices={() =>
                handleManageDevicesClicked(client.client_id)
              }
            />
          </Box>
        ))}
      </UIMasonry>
    </Stack>
  );
}
