import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React, { useState } from "react";
import { useIsMounted } from "src/helper";

/**
 * inverted: if true, the buttons will be inverted and use No for the onConfirmed action
 */
type ConfirmModalProps = {
  inverted?: boolean;
  title: string;
  content: string;
  isOpen: boolean;
  onClose: () => void;
  onConfirmed: () => Promise<void>;
};
export default function ConfirmModal({
  inverted = false,
  title,
  content,
  isOpen,
  onClose,
  onConfirmed,
}: ConfirmModalProps) {
  const [isLoading, setIsLoading] = useState(false);

  const getIsMounted = useIsMounted();

  const onYes = async () => {
    setIsLoading(true);
    onConfirmed().then(() => getIsMounted() && setIsLoading(false));
  };
  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm">
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button
          onClick={inverted ? onYes : onClose}
          color="info"
          variant="outlined"
        >
          No
        </Button>
        <Button
          disabled={isLoading}
          startIcon={
            isLoading ? <CircularProgress size={12} color="inherit" /> : null
          }
          onClick={inverted ? onClose : onYes}
          color="primary"
          variant="contained"
        >
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
}
