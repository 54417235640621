import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import React, { useContext, useEffect, useRef, useState } from "react";
import UIInput from "src/components/UI/Input";
import {
  Device,
  DeviceType,
  deviceTypeToPluralName,
} from "src/interfaces/IDevice";
import _differenceWith from "lodash/differenceWith";
import _isEqual from "lodash/isEqual";
import { RegularProgram as Program } from "src/interfaces/IProgram";
import AddIcon from "@mui/icons-material/Add";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { ClientContext } from "src/contexts/GqlContext";
import CollapsibleBox from "../CollapsibleBox";
import DeviceCount from "../DeviceCount";
import RowBox from "../RowBox";
import DeviceList from "../SimpleDeviceList";
import { Value, ErrorObject as ItemError, validate } from "../ScheduleAndModes";
import EditScheduleAndModes from "../ScheduleAndModes/Edit";
import {
  programToItems,
  makeProgramUpdateRequest,
  Item as ItemType,
  makeProgramCreateRequest,
  CreatedItem,
  makeDefaultItem,
  validateItems,
} from "./utils";
import { createProgram, updateProgram } from "../../api";
import ConfirmModal from "../ConfirmModal";

function Item({
  index,
  value,
  error,
  showError,
  onChange,
  onDelete,
}: {
  index: number;
  value: Value;
  error: ItemError;
  showError: boolean;
  onChange: (v: Value) => void;
  onDelete: null | (() => void);
}) {
  return (
    <RowBox
      label={
        <Stack direction="column" gap={4} alignItems="flex-start">
          {`Schedule Setting ${index}`}
          {onDelete && (
            <Button variant="outlined" color="danger" onClick={onDelete}>
              Delete
            </Button>
          )}
          {showError && !error.noError() ? (
            <Typography color="error" variant="caption">
              Please fill in this required field
            </Typography>
          ) : null}
        </Stack>
      }
    >
      <Stack sx={{ flex: 1 }}>
        <EditScheduleAndModes
          value={value}
          onChange={onChange}
          error={error}
          showError={showError}
        />
      </Stack>
    </RowBox>
  );
}

/**
 * @param program
 * @param states are some information that can be generated from program
 * @returns false if the states input are different from the states generated from program
 */
const statesAreFromProgram = (
  program: Program,
  states: {
    programName: string;
    devices: Device[];
    items: ItemType[];
  }
) => {
  const deviceIdsFromProgram = program.devices.map((d) => d.device_id);
  const deviceIdSetFromProgram = new Set(deviceIdsFromProgram);

  if (states.programName !== program.program_name) {
    return false;
  }

  if (states.devices.some((d) => !deviceIdSetFromProgram.has(d.device_id))) {
    return false;
  }

  const itemsFromProgram = programToItems(program);
  const itemComparator = (item1: ItemType, item2: ItemType) => {
    const typeIsSame = () => item1.type === item2.type;
    const valueIsSame = () => _isEqual(item1.value, item2.value);
    const idIsSame = () => {
      if (item1.type === "update" && item2.type === "update") {
        return item1.id === item2.id;
      }
      return true;
    };

    return typeIsSame() && idIsSame() && valueIsSame();
  };

  return (
    _differenceWith(states.items, itemsFromProgram, itemComparator).length === 0
  );
};

/**
 * This hook run a function periodically to check if the user has made some changes to the form
 * Using periodical runner improve performance because we don't need to run the function on every render
 */
const useChangeDetector = (
  program: undefined | Program,
  states: {
    programName: string;
    devices: Device[];
    items: ItemType[];
  }
) => {
  const [statesAreChanged, setStateAreChanged] = useState(!program);
  const statesRef = useRef(states);
  statesRef.current = states;

  useEffect(() => {
    if (!program) {
      setStateAreChanged(true);
      return undefined;
    }
    const iid = setInterval(() => {
      const notChange = statesAreFromProgram(program, statesRef.current);
      setStateAreChanged(!notChange);
    }, 1000);
    return () => clearInterval(iid);
  }, [program]);

  return statesAreChanged;
};

type Props = {
  deviceType: DeviceType;
  devicesOfProgram: Device[];
  program?: Program;
  runBeforeSave?: () => Promise<void>;
  onCancel: () => void;
  onDone: (program: Program) => void;
};
export default function EditProgram({
  program,
  deviceType,
  devicesOfProgram,
  runBeforeSave,
  onCancel,
  onDone,
}: Props) {
  const [name, setName] = useState(program?.program_name || "");
  const [devices, setDevices] = useState<Device[]>(devicesOfProgram);
  const [generalError, setGeneralError] = useState("");

  const [items, setItems] = useState<ItemType[]>(() =>
    program ? programToItems(program) : [makeDefaultItem()]
  );
  useEffect(() => {
    if (program) {
      setName(program.program_name);
      setItems(programToItems(program));
    }
  }, [program]);

  const onAddItem = () => setItems((is) => [...is, makeDefaultItem()]);

  const { client } = useContext(ClientContext);

  const [isLoading, setIsLoading] = useState(false);
  const [showError, setShowError] = useState(false);
  const onSave = () => {
    setGeneralError("");

    const validationError = validateItems(items);
    if (validationError !== "valid") {
      setGeneralError(validationError);
      setShowError(true);
      return undefined;
    }

    setIsLoading(true);
    let p: () => Promise<Program>;
    if (program) {
      p = () =>
        updateProgram(
          client,
          program.program_id,
          makeProgramUpdateRequest(program, name, items, devices)
        );
    } else {
      p = () =>
        createProgram(
          client,
          makeProgramCreateRequest(
            name,
            // This should be fine since if there is no program, the item will al be CreatedItem
            items.filter((i) => i.type === "create") as CreatedItem[],
            devices
          )
        );
    }

    return (runBeforeSave ? runBeforeSave().then(() => p()) : p())
      .then((returnedProgram) => {
        onDone(returnedProgram);
      })
      .catch((error: any) => {
        if (error instanceof Error) {
          setGeneralError(error.message);
        } else {
          setGeneralError("Unexpected error occurred. Please try again later.");
        }
      })
      .then(() => setIsLoading(false));
  };

  const isChanged = useChangeDetector(program, {
    programName: name,
    devices,
    items,
  });

  const [cancelConfirmModalIsOpen, setCancelConfirmModalIsOpen] =
    useState(false);
  const onCancelClicked = () => {
    if (isChanged) {
      setCancelConfirmModalIsOpen(true);
    } else {
      onCancel();
    }
  };

  return (
    <>
      <CollapsibleBox
        label={
          <>
            {`${deviceTypeToPluralName(deviceType)} Selected`}
            <DeviceCount count={devices.length} />
          </>
        }
      >
        <DeviceList
          devices={devices}
          onRemoveDevice={(d: Device) =>
            setDevices((ds) =>
              ds.filter(({ device_id }) => device_id !== d.device_id)
            )
          }
        />
      </CollapsibleBox>
      <Divider />
      <CollapsibleBox label="Schedule">
        <Stack direction="column" gap={7} marginTop={3}>
          <RowBox label="Schedule Info">
            <Grid container sx={{ width: "unset", flex: 1 }}>
              <Grid item xs={12} md={4}>
                <UIInput
                  fullWidth
                  required
                  label="Schedule Name"
                  value={name}
                  onChange={(v) => setName(v)}
                />
              </Grid>
            </Grid>
          </RowBox>
          <Divider />
          {items.map((item, i) => (
            <Item
              key={item.type === "create" ? `create-${i}` : item.id}
              index={i + 1}
              value={item.value}
              error={item.error}
              showError={showError}
              onDelete={
                items.length <= 1
                  ? null
                  : () => setItems((arr) => arr.filter((_, j) => i !== j))
              }
              onChange={(v) => {
                setItems((st) =>
                  st.map((stItem, j) =>
                    i === j ? { ...item, value: v, error: validate(v) } : stItem
                  )
                );
              }}
            />
          ))}
          <Divider />
          <Stack alignItems="flex-start">
            <Button
              variant="contained"
              color="info"
              startIcon={<AddIcon />}
              sx={{ flex: 0 }}
              onClick={onAddItem}
            >
              Add Schedule Setting
            </Button>
          </Stack>
          <Divider />
          <Stack direction="row" justifyContent="center" gap={3}>
            <Button variant="outlined" color="info" onClick={onCancelClicked}>
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={onSave}
              startIcon={
                isLoading ? (
                  <CircularProgress size={16} color="inherit" />
                ) : undefined
              }
              disabled={isLoading || !isChanged}
            >
              Save Schedule
            </Button>
            {generalError && (
              <Typography variant="body2" color="error">
                {generalError}
              </Typography>
            )}
          </Stack>
        </Stack>
      </CollapsibleBox>
      <ConfirmModal
        inverted
        title="Do you want to continue to edit?"
        content="You have unsaved changes. Do you want to continue to edit?"
        isOpen={cancelConfirmModalIsOpen}
        onClose={() => setCancelConfirmModalIsOpen(false)}
        onConfirmed={() => {
          setCancelConfirmModalIsOpen(false);
          onCancel();
          return Promise.resolve();
        }}
      />
    </>
  );
}
