import { useQuery } from "@apollo/client";
import {
  styled,
  Typography,
  Box,
  Icon,
  Stack,
  FormControlLabel,
  Switch,
  CircularProgress,
  useTheme,
  IconButton,
  MenuItem,
  Menu,
  Checkbox as MuiCheckbox,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { queryAirQuality1minByDevice } from "src/services/graphql/AirQuality1min";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import _get from "lodash/get";
import isFiniteNumber from "lodash/isFinite";
import { AirQuality1min } from "src/interfaces/IAirQuality1min";
import moment from "moment";
import { BORDER_COLOR_CARD, INACTIVE_RED } from "src/styles";
import { toggleNightMode } from "src/services/restful/device";
import { ClientContext } from "src/contexts/GqlContext";
import {
  Device,
  DeviceType,
  DeviceType as DeviceTypeEnum,
} from "src/interfaces/IDevice";
import * as Shadow from "src/interfaces/IShadow";
import { Shadow as ShadowType } from "src/interfaces/IShadow";
import type { Zone } from "src/interfaces/IZone";
import { Nullable } from "src/helper";
import { HaloBadges, MaxBadges } from "./Badges";
import { Online as OnlineBadge, Offline as OfflineBadge } from "../DeviceBadge";

namespace Connection {
  interface Connected {
    type: "Connected";
    ledColor: Nullable.T<string>;
  }

  interface Disconnected {
    type: "Disconnected";
    lastConnectedTime: Date;
  }

  interface ConnectionError {
    type: "ConnectionError";
    reason: string;
  }
  export function connectionError(reason: string) {
    return {
      type: "ConnectionError",
      reason,
    };
  }

  export type T = Connected | ConnectionError | Disconnected;

  export function fromShadow(shadow?: ShadowType): T {
    if (!shadow)
      return {
        type: "ConnectionError",
        reason: "no shadow",
      };

    const shadowErrorMessage = Shadow.error(shadow);
    if (shadowErrorMessage)
      return {
        type: "ConnectionError",
        reason: shadowErrorMessage,
      };

    try {
      if (Shadow.connected(shadow))
        return {
          type: "Connected",
          ledColor: Shadow.ledColor(shadow),
        };

      return {
        type: "Disconnected",
        lastConnectedTime: Shadow.lastConnectedTime(shadow),
      };
    } catch (err) {
      return {
        type: "ConnectionError",
        reason: err instanceof Error ? err.message : "unknown",
      };
    }
  }
}

type DeviceIconProps = { deviceType: DeviceType };

const Img = styled("img")({
  height: "100%",
});

function DeviceIcon({ deviceType }: DeviceIconProps) {
  let src: string;
  switch (deviceType) {
    case DeviceTypeEnum.HALO:
      src = `${process.env.PUBLIC_URL}/icons/Halo.svg`;
      break;
    case DeviceTypeEnum.MAX:
      src = `${process.env.PUBLIC_URL}/icons/Max.svg`;
      break;
    default:
      src = `${process.env.PUBLIC_URL}/icons/Max.svg`;
      break;
  }

  return (
    <Icon sx={{ marginRight: 1, display: "flex", alignItems: "center" }}>
      <Img src={src} alt="device-icon" width="24px" height="24px" />
    </Icon>
  );
}

type DeviceStatusProps = {
  connection: Connection.T;
  deviceId: string;
};

function DeviceStatus({ connection, deviceId }: DeviceStatusProps) {
  if (connection.type === "Connected") {
    return <OnlineBadge />;
  }

  if (connection.type === "ConnectionError") {
    console.warn(`Device ${deviceId}: ${connection.reason}`);
    return <OfflineBadge />;
  }

  return <OfflineBadge />;
}

type DeviceLastConnectedTimeProps = {
  connection: Connection.T;
};
function DeviceLastConnectedTime({ connection }: DeviceLastConnectedTimeProps) {
  if (connection.type === "Disconnected") {
    const lastConnectedTime = moment(connection.lastConnectedTime).format(
      "DD/MM/YYYY | hh:mm:ss A"
    );
    return (
      <Typography
        color="error"
        sx={{
          color: INACTIVE_RED,
          transform: "scale(0.83)", // 10/12
          transformOrigin: "left",
        }}
        variant="body3"
      >
        {`Last connect at ${lastConnectedTime}`}
      </Typography>
    );
  }
  return null;
}

type ScoreProps = { score: Nullable.T<number> /* 0 - 100 */ };

function Score({ score }: ScoreProps) {
  const theme = useTheme();
  return score === null ? null : (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress
        thickness={4}
        value={score}
        variant="determinate"
        size="70px"
        color="inherit"
        sx={{
          color: theme.palette.primary.light,
        }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          position: "absolute",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          sx={{
            color: theme.palette.primary.light,
          }}
          variant="subtitle2"
        >
          {score}
        </Typography>
        <Typography
          sx={{
            transform: "scale(0.66)",
          }}
          variant="body3"
        >
          {score > 0 ? "FANTASTIC" : "--"}
        </Typography>
      </Box>
    </Box>
  );
}

type ActionMenuProps = {
  onRemove: () => void;
  onRelocate: () => void;
};

function ActionMenu({ onRemove, onRelocate }: ActionMenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleClose = () => setAnchorEl(null);
  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
  };
  const handleClickItemRemove = () => {
    onRemove();
    setAnchorEl(null);
  };

  const handleClickItemRelocate = () => {
    onRelocate();
    setAnchorEl(null);
  };

  const theme = useTheme();

  return (
    <Box sx={{ position: "absolute", top: 0, right: theme.spacing(-2) }}>
      <IconButton
        color="primary"
        onClick={handleClick}
        sx={{ borderRadius: 0 }}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <MenuItem onClick={handleClickItemRelocate}>Relocate device</MenuItem>
        <MenuItem onClick={handleClickItemRemove}>Remove device</MenuItem>
      </Menu>
    </Box>
  );
}

type CheckboxProps = {
  onCheck: (checked: boolean) => void;
  checked: boolean;
  disabled: boolean;
};
function Checkbox({ onCheck, checked, disabled }: CheckboxProps) {
  return (
    <MuiCheckbox
      color="primary"
      disableRipple
      checked={checked}
      disabled={disabled}
      onChange={(e) => {
        const c = e.target.checked;
        onCheck(c);
      }}
      size="small"
      sx={{
        left: "-8px",
        paddingLeft: "3px",
      }}
    />
  );
}

type FindDeviceProps = {
  deviceId: string;
};

function FindDevice({ deviceId }: FindDeviceProps) {
  const [isFindingDevice, setIsFindingDevice] = useState(false);
  const [inNightMode, setInNightMode] = useState(false);
  const {
    client: { hash },
  } = useContext(ClientContext);

  const INTERVAL = 2000;

  useEffect(() => {
    const interval = setInterval(() => {
      if (isFindingDevice) {
        toggleNightMode(hash, deviceId, !inNightMode);
        setInNightMode(!inNightMode);
      }
    }, INTERVAL);
    return () => clearInterval(interval);
  }, [isFindingDevice, inNightMode, deviceId, hash]);

  return (
    <FormControlLabel
      componentsProps={{
        typography: {
          variant: "body3",
        },
      }}
      sx={{
        marginLeft: 0,
      }}
      label="Find Device"
      labelPlacement="start"
      control={<Switch onChange={() => setIsFindingDevice(!isFindingDevice)} />}
    />
  );
}

type CardProps = {
  deviceId: string;
  shadow?: ShadowType;
  deviceType: DeviceType;
  serial: string;
  zoneName: string;
  checkbox: React.ReactNode;
  actionMenu: React.ReactNode;
  score: Nullable.T<number>;
  checked: boolean;
};

function Card({
  deviceId,
  shadow,
  deviceType,
  serial,
  zoneName,
  checkbox,
  actionMenu,
  score,
  checked,
}: CardProps) {
  const connection = Connection.fromShadow(shadow);

  return (
    <Stack
      direction="row"
      sx={[
        {
          borderRadius: "4px",
          width: "408px",
          padding: 3,
          paddingBottom: 2,
          paddingTop: 2,
        },
        checked
          ? {
              border: 2,
              margin: "0px",
              borderColor: "#2DCCD3",
              backgroundColor: "#F5FDFD",
            }
          : {
              border: 1,
              // use margin to prevent box moved when checked/unchecked
              margin: "1px",
              borderColor: BORDER_COLOR_CARD,
              backgroundColor: "white",
            },
      ]}
    >
      {checkbox}
      <Stack direction="column" sx={{ flex: 1 }}>
        <Stack
          direction="row"
          sx={{
            position: "relative",
            display: "flex",
            alignItems: "center",
            marginBottom: 1,
          }}
        >
          <DeviceIcon deviceType={deviceType} />
          <Stack
            direction="row"
            sx={{
              flexGrow: 1,
              justifyContent: "flex-start",
              alignItems: "center",
              gap: 1,
            }}
          >
            <DeviceStatus deviceId={deviceId} connection={connection} />
          </Stack>
          {actionMenu}
        </Stack>
        <Stack direction="row" sx={{ justifyContent: "space-between" }}>
          <Stack direction="column" sx={{ alignItems: "flex-start" }} gap={1}>
            <Typography variant="subtitle2">{zoneName}</Typography>
            <Typography variant="subtitle4">{serial}</Typography>
            {connection.type === "Connected" &&
              (deviceType === DeviceTypeEnum.HALO ? (
                <HaloBadges shadow={shadow} />
              ) : deviceType === DeviceTypeEnum.MAX ? (
                <MaxBadges shadow={shadow} />
              ) : null)}
            {connection.type === "Connected" && (
              <FindDevice deviceId={deviceId} />
            )}
            <DeviceLastConnectedTime connection={connection} />
          </Stack>
          <Stack direction="column" sx={{ justifyContent: "center" }}>
            {connection.type === "Connected" ? (
              <Score score={score} />
            ) : (
              <div />
            )}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}

function parseWyndScore(airQuality1min: AirQuality1min) {
  const v = parseFloat(airQuality1min.wynd_score);
  if (isFiniteNumber(v)) {
    // airQuality1min's measurement is a summation of 4 observations
    return v / 4;
  }
  return null;
}

function useScore(deviceId: string): [Nullable.T<number>, boolean] {
  const {
    client: { client_dbname: clientDBName },
  } = useContext(ClientContext);

  const { data, loading } = useQuery(
    queryAirQuality1minByDevice(clientDBName),
    {
      variables: {
        device_id: deviceId,
      },
    }
  );

  const airQuality1min: Nullable.T<AirQuality1min> = _get(
    data,
    `${clientDBName}_air_quality_1min.0`,
    null
  );
  const score = Nullable.flatMap(parseWyndScore, airQuality1min);

  return [score, loading];
}

type Editable = {
  onRelocate: () => void;
  onRemove: () => void;
};

type Checkable = {
  checked: boolean;
  onCheck: (checked: boolean) => void;
  disabled?: boolean;
};

type DeviceProps = {
  device: Device;
  shadow?: ShadowType;
  zone: Zone;
  editable?: Editable;
  /**
   * If provided, show top-right action menu which can invoke onRelocate and onRemove
   */
  checkable?: Checkable;
  /**
   * If provided, show left checkbox which can invoke onCheck
   */
};

export default function DeviceComponent({
  device,
  shadow,
  zone,
  editable,
  checkable,
}: DeviceProps) {
  const [score] = useScore(device.device_id);
  return (
    <Card
      deviceId={device.device_id}
      shadow={shadow}
      deviceType={device.device_type}
      serial={device.device_id}
      zoneName={zone.zone_name}
      actionMenu={
        editable && (
          <ActionMenu
            onRemove={editable.onRemove}
            onRelocate={editable.onRelocate}
          />
        )
      }
      checkbox={
        checkable && (
          <Checkbox
            disabled={!!checkable.disabled}
            checked={checkable.checked}
            onCheck={checkable.onCheck}
          />
        )
      }
      checked={!!(checkable && checkable.checked)}
      score={score}
    />
  );
}

export { Connection };
