import React from "react";
import { Stack, Typography } from "@mui/material";
import { modeToName } from "src/interfaces/IShadow";
import DayOfWeekPicker from "src/components/UI/DayOfWeekPicker";
import { WYND_WHITE } from "src/styles";
import type { ModeCycle, Value } from "./types";

export function Content({ modeCycle }: { modeCycle: ModeCycle }) {
  const { mode, startTime, endTime } = modeCycle;
  return (
    <Stack direction="row" sx={{ alignItems: "center" }}>
      <Typography
        sx={{
          backgroundColor: WYND_WHITE,
          borderRadius: "16px",
          padding: 0.5,
          marginRight: 1,
        }}
        variant="subtitle4"
      >
        {modeToName(mode).toUpperCase()}
      </Typography>
      {startTime ? `${startTime}:00` : ""} {" - "}
      {endTime ? `${endTime}:00` : ""}
    </Stack>
  );
}

export default function Display({ value }: { value: Value }) {
  return (
    <Stack direction="column" gap={3}>
      <Typography variant="subtitle2">{value.name}</Typography>
      {value.notes ? (
        <Typography variant="body2">{value.notes}</Typography>
      ) : null}
      <DayOfWeekPicker isEditing={false} selected={value.daysOfWeek} />
      <Stack direction="row" gap={6} flexWrap="wrap">
        {value.modeCycles.map((modeCycle, i) => {
          const { mode, startTime, endTime } = modeCycle;
          return (
            <Stack
              key={`${mode}${startTime}${endTime}`}
              direction="column"
              gap={1}
            >
              <Typography variant="subtitle2">{`Mode ${i + 1}`}</Typography>
              <Content modeCycle={modeCycle} />
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
