import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Nullable } from "src/helper";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";

type Props<V> = {
  id?: string;
  options: Array<V>;
  onChange?: (v: Nullable.T<V>) => void;
  onTextChange?: (text: string) => void;
};

export default function SearchBar<V>({
  id,
  options,
  onTextChange,
  onChange,
}: Props<V>) {
  return (
    <Autocomplete
      disablePortal
      fullWidth
      id={id}
      options={options}
      sx={{ maxWidth: "396px" }}
      renderInput={(params) =>
        React.createElement(TextField, {
          ...params,
          placeholder: "Type in your search",
          onChange: (event) => {
            const text = event.target && event.target.value;
            if (onTextChange) onTextChange(text);
          },
          InputProps: {
            ...(params.InputProps || {}),
            startAdornment: (
              <InputAdornment position="start" sx={{ marginRight: 0 }}>
                <SearchIcon fontSize="small" color="info" />
              </InputAdornment>
            ),
          },
        })
      }
      onChange={(event, newValue: Nullable.T<V>) => {
        if (onChange) {
          onChange(newValue);
        }
      }}
    />
  );
}
