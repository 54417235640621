import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

export default function WifiSlash(props: SvgIconProps) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" {...props}>
      <path
        d="M5.80615 10.3813C6.39619 9.81517 7.18222 9.49908 7.9999 9.49908C8.81758 9.49908 9.60361 9.81517 10.1937 10.3813"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 3.5L11 6.5"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 6.5L11 3.5"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M1.56885 6.13747C3.27913 4.44193 5.5918 3.49349 8.0001 3.49997C8.33423 3.49984 8.66809 3.51862 9.0001 3.55622"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.6875 8.25626C4.83698 7.1248 6.38709 6.49352 8 6.50001C8.33505 6.49835 8.66962 6.52553 9 6.58126"
        fill="white"
      />
      <path
        d="M3.6875 8.25626C4.83698 7.1248 6.38709 6.49352 8 6.50001C8.33505 6.49835 8.66962 6.52553 9 6.58126"
        stroke="white"
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.6875 12.5C8.6875 12.8797 8.3797 13.1875 8 13.1875C7.6203 13.1875 7.3125 12.8797 7.3125 12.5C7.3125 12.1203 7.6203 11.8125 8 11.8125C8.3797 11.8125 8.6875 12.1203 8.6875 12.5Z"
        fill="white"
        stroke="white"
        strokeWidth="0.125"
      />
    </SvgIcon>
  );
}
