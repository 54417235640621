import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  MenuItem,
  Button,
  FormHelperText,
  Stack,
  CircularProgress,
} from "@mui/material";
import _get from "lodash/get";
import Select from "src/components/UI/Select";
import { getClients } from "src/services/clientService";
import { queryGetProperties } from "src/services/graphql/Property";
import { queryGetZones } from "src/services/graphql/Zone";
import {
  mutateRelocateDevice,
  editDeviceCache,
  mutateRemoveDevice,
  mutateUpsertDevice,
  mutateUndoRemoveDevice,
} from "src/services/graphql/Device";
import type { Property } from "src/interfaces/IProperty";
import type { Zone } from "src/interfaces/IZone";
import type { Device, DeviceType } from "src/interfaces/IDevice";
import type {
  Client,
  ClientDBName,
  ClientDetails,
} from "src/interfaces/IClient";
import * as Nullable from "src/helper/nullable";
import { makeGqlClient } from "src/contexts/GqlContext";
import type { GqlClient } from "src/contexts/GqlContext";

type Clients = Array<Client>;
type Properties = Array<Property>;
type Zones = Array<Zone>;
type Floors = Array<string>;

type Id = "" | number;

namespace Form {
  type Form = {
    // data reference inserted when form is initialized
    readonly device_type: DeviceType;
    readonly device_id: string;
    readonly gqlClient: GqlClient;
    readonly current_client_dbname: ClientDBName;
    client: {
      id: Id;
      dbname: ClientDBName; // client_name is need for gql querying
    };
    property_id: Id;
    floor: string;
    zone_id: Id;
  };

  export type T = Nullable.T<Form>;

  export const init = (
    device: null | Device,
    clientId: Id,
    clientDBName: ClientDBName,
    floor: string
  ): T => {
    if (device && clientId !== "" && floor !== "") {
      return {
        gqlClient: makeGqlClient(clientDBName),
        device_id: device.device_id,
        current_client_dbname: clientDBName,
        device_type: device.device_type,
        client: {
          id: clientId,
          dbname: clientDBName,
        },
        property_id: device.property_id,
        floor,
        zone_id: device.zone_id,
      };
    }
    return null;
  };
  // getter
  export const client = (t: T): Nullable.T<{ id: Id; dbname: string }> =>
    t ? t.client : null;
  export const clientId = (t: T): Nullable.T<Id> => (t ? t.client.id : null);
  export const propertyId = (t: T): Id => (t ? t.property_id : "");
  export const floor = (t: T): string => (t ? t.floor : "");
  export const zoneId = (t: T): Id => (t ? t.zone_id : "");

  // select options generator
  export const useClientOptions = (): [Clients, boolean] => {
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
      setIsLoading(true);
      getClients()
        .then((res) => setData(res.data))
        .catch(console.error)
        .then(() => setIsLoading(false));
    }, []);

    return [data, isLoading];
  };

  export const usePropertyOptions = (t: T): [Properties, boolean] => {
    const [data, setData] = useState([]);
    const clientName = t?.client.dbname;
    const [isLoading, setIsLoading] = useState(false);
    const gqlClient = t?.gqlClient;
    useEffect(() => {
      setData([]);
      if (clientName && gqlClient) {
        setIsLoading(true);
        gqlClient
          .query({
            query: queryGetProperties(clientName),
          })
          .then((res) => setData(_get(res, `data.${clientName}_property`, [])))
          .catch(console.error)
          .then(() => setIsLoading(false));
      } else {
        // this prevent mui select out of range warning when modal is just opened
        setIsLoading(true);
      }
    }, [clientName, gqlClient]);

    return [data, isLoading];
  };

  export const useZoneFloorOptions = (t: T): [[Zones, Floors], boolean] => {
    const [zones, setZones] = useState<Zones>([]);
    const [floors, setFloors] = useState<Floors>([]);
    const [isLoading, setIsLoading] = useState(false);
    const clientName = t?.client.dbname;
    const pid = t?.property_id;
    const gqlClient = t?.gqlClient;

    useEffect(() => {
      setZones([]);
      setFloors([]);
      if (gqlClient && clientName && pid) {
        setIsLoading(true);
        gqlClient
          .query({
            query: queryGetZones(clientName),
            variables: {
              property_id: { _eq: pid },
            },
          })
          .then((res) => {
            const zonesData = _get(res, `data.${clientName}_zone`, []);
            const floorsData = Array.from(
              new Set(zonesData.map((z: Zone) => z.floor)).values()
            ) as Array<string>;
            setZones(zonesData);
            setFloors(floorsData);
          })
          .catch(console.error)
          .then(() => setIsLoading(false));
      } else {
        setIsLoading(true);
      }
    }, [clientName, pid, gqlClient]);

    const zoneOptions = useMemo(() => {
      if (t?.floor) {
        return zones.filter((zone) => zone.floor === t?.floor);
      }
      return [];
    }, [t?.floor, zones]);

    return [[zoneOptions, floors], isLoading];
  };

  // setter
  export const setClient = (c: {
    id: Id;
    dbname: ClientDBName;
  }): ((t: T) => T) =>
    Nullable.map((t: Form) => ({
      ...t,
      client: c,
      property_id: "",
      floor: "",
      zone_id: "",
      gqlClient: makeGqlClient(c.dbname),
    }));

  export const setProperty = (id: number): ((t: T) => T) =>
    Nullable.map((t: Form) => ({
      ...t,
      property_id: id,
      floor: "",
      zone_id: "",
    }));
  export const setFloor = (f: string): ((t: T) => T) =>
    Nullable.map((t: Form) => ({
      ...t,
      floor: f,
      zone_id: "",
    }));
  export const setZone = (id: number): ((t: T) => T) =>
    Nullable.map((t: Form) => ({
      ...t,
      zone_id: id,
    }));

  // validator
  export function isComplete(t: T): boolean {
    const failed =
      t === null ||
      t.client.id === "" ||
      t.floor === "" ||
      t.property_id === "" ||
      t.zone_id === "";

    return !failed;
  }

  // submit

  export async function relocateToDifferentClient(
    t: T
  ): Promise<undefined | string> {
    if (t && t.zone_id !== "" && t.property_id !== "") {
      // remove device from this client
      const { data: removeResult } = await t.gqlClient.mutate({
        mutation: mutateRemoveDevice(t.current_client_dbname),
        variables: {
          device_id: t.device_id,
        },
        update: editDeviceCache(t.current_client_dbname, "update_"),
      });

      // upsert device to the new client
      let upsertResult;
      try {
        const { data } = await t.gqlClient.mutate({
          mutation: mutateUpsertDevice(t.client.dbname),
          variables: {
            device_id: t.device_id,
            device_type: t.device_type,
            zone_id: t.zone_id,
            property_id: t.property_id,
          },
        });
        upsertResult = data;
      } catch (err) {
        // undo the remove action
        await t.gqlClient.mutate({
          mutation: mutateUndoRemoveDevice(t.current_client_dbname),
          variables: {
            device_id: t.device_id,
          },
          update: editDeviceCache(t.current_client_dbname, "update_"),
        });
        throw err;
      }

      if (upsertResult && removeResult) {
        return t.device_id;
      }
    }

    return undefined;
  }

  export function relocateToSameClient(
    t: T
  ): Promise<undefined | Array<Device>> {
    if (t && t.zone_id !== "" && t.property_id !== "") {
      return t.gqlClient
        .mutate({
          mutation: mutateRelocateDevice(t.client.dbname),
          variables: {
            device_id: t.device_id,
            zone_id: t.zone_id,
            property_id: t.property_id,
          },
          update: editDeviceCache(t.client.dbname, "update_"),
        })
        .then(({ data }) => {
          const returning = _get(
            data,
            `update_${t.client.dbname}_device`,
            undefined
          );
          return returning ? returning.returning : undefined;
        });
    }
    return Promise.resolve(undefined);
  }
}

type Props = {
  isOpen: boolean;
  onClose: () => void;
  device: null | Device;
  client: ClientDetails;
  floor: string;
  onDeviceChange: () => Promise<void>;
};

export default function RelocateDeviceModal({
  isOpen,
  onClose,
  device,
  client,
  floor,
  onDeviceChange,
}: Props) {
  const [form, setForm] = useState<Form.T>(null);

  const [clients, clientsIsLoading] = Form.useClientOptions();
  const [properties, propertiesIsLoading] = Form.usePropertyOptions(form);
  const [[zones, floors], zoneFloorIsLoading] = Form.useZoneFloorOptions(form);
  const [isRelocating, setIsRelocating] = useState(false);

  const [submitError, setSubmitError] = useState<string>("");
  useEffect(() => {
    if (!isOpen) {
      setSubmitError("");
    }
  }, [isOpen]);

  const onRelocateToSameClient = async () => {
    setIsRelocating(true);
    setSubmitError("");
    try {
      const deviceReturned = await Form.relocateToSameClient(form);
      if (deviceReturned) {
        onDeviceChange().then(onClose);
      }
    } catch (err) {
      console.error(err);
      if (err instanceof Error) setSubmitError(err.toString());
    } finally {
      setIsRelocating(false);
    }
  };

  const onRelocateToDifferentClient = async () => {
    setSubmitError("");
    setIsRelocating(true);
    try {
      const deviceId = await Form.relocateToDifferentClient(form);

      if (deviceId) {
        onDeviceChange().then(onClose);
      }
    } catch (err) {
      console.error(err);
      if (err instanceof Error) setSubmitError(err.toString());
    } finally {
      setIsRelocating(false);
    }
  };

  useEffect(() => {
    if (isOpen) {
      setForm(Form.init(device, client.client_id, client.client_dbname, floor));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Typography variant="h7">Relocate Device</Typography>
      </DialogTitle>
      <DialogContent
        sx={{ display: "flex", flexWrap: "wrap", rowGap: 4, columnGap: 3 }}
      >
        <Select
          label="Client"
          sx={{ flex: "1 1 100%" }}
          value={
            clientsIsLoading
              ? ""
              : Nullable.flatMap((c: { id: Id }) => c.id, Form.client(form)) ||
                ""
          }
          onChange={(id) => {
            const thisClient = clients.find((c) => c.client_id === id);
            if (thisClient) {
              setForm(
                Form.setClient({
                  id: thisClient.client_id,
                  dbname: thisClient.client_dbname,
                })
              );
            }
          }}
        >
          {clients.map((thisClient) => (
            <MenuItem value={thisClient.client_id} key={thisClient.client_id}>
              {thisClient.client_name}
            </MenuItem>
          ))}
        </Select>
        <Select
          label="Property"
          sx={{ flex: "1 1 auto" }}
          value={propertiesIsLoading ? "" : Form.propertyId(form) || ""}
          onChange={(v) => {
            setForm(Form.setProperty(v as number));
          }}
        >
          {properties.map((property) => (
            <MenuItem value={property.property_id} key={property.property_id}>
              {property.property_name}
            </MenuItem>
          ))}
        </Select>
        <Select
          label="Floor"
          sx={{ flex: "1 1 auto" }}
          value={zoneFloorIsLoading ? "" : Form.floor(form) || ""}
          onChange={(v) => {
            setForm(Form.setFloor(v as string));
          }}
        >
          {floors.map((f) => (
            <MenuItem value={f} key={f}>
              {f}
            </MenuItem>
          ))}
        </Select>
        <Select
          label="Zone"
          sx={{ flex: "1 1 100%" }}
          value={zoneFloorIsLoading ? "" : Form.zoneId(form) || ""}
          onChange={(v) => {
            setForm(Form.setZone(v as number));
          }}
        >
          {zones.map((zone) => (
            <MenuItem value={zone.zone_id} key={zone.zone_id}>
              {zone.zone_name}
            </MenuItem>
          ))}
        </Select>
      </DialogContent>
      <DialogActions>
        <Stack>
          <Stack direction="row" sx={{ gap: 3, justifyContent: "flex-end" }}>
            <Button color="info" variant="outlined" onClick={onClose}>
              Cancel
            </Button>
            <Button
              color="primary"
              variant="contained"
              disabled={!Form.isComplete(form)}
              onClick={
                Form.clientId(form) === client.client_id
                  ? onRelocateToSameClient
                  : onRelocateToDifferentClient
              }
              endIcon={
                isRelocating ? (
                  <CircularProgress color="inherit" size="1rem" />
                ) : null
              }
            >
              Relocate Device
            </Button>
          </Stack>
          <FormHelperText error>{submitError}</FormHelperText>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
