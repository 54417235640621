import { Client, ClientDetails } from "src/interfaces/IClient";
import { httpAuthorizedRequest } from "../httpService";

const deleteClientZone = (clientId: number, zoneId: number) =>
  httpAuthorizedRequest.delete<{ affected_devices: Array<string> }>(
    `/clients/${clientId}/zones/${zoneId}`
  );

const deleteClientProperty = (clientId: number, propertyId: number) =>
  httpAuthorizedRequest.delete<{}>(
    `/clients/${clientId}/properties/${propertyId}`
  );

const getClients = () => httpAuthorizedRequest.get<Array<Client>>("/clients");

const getClientDetails = (clientId: number) =>
  httpAuthorizedRequest.get<ClientDetails>(`/clients/${clientId}`);

const deleteClient = (clientId: number) =>
  httpAuthorizedRequest.delete<{}>(`/clients/${clientId}`);

// eslint-disable-next-line import/prefer-default-export
export {
  deleteClientProperty,
  deleteClientZone,
  getClients,
  getClientDetails,
  deleteClient,
};
