import React from "react";
import { Card, Divider, Stack, Typography, Box, Button } from "@mui/material";
import Calendar from "src/assets/icons/Calendar";
import * as IProgram from "src/interfaces/IProgram";
import { ProgramDisplay } from "src/interfaces/IProgramDisplay";
import { RecurringSchedule as Schedule } from "src/interfaces/ISchedule";
import { packSchedulesToModeCycles } from "../../ui/FormEdit/modeCycle";
import { Content as ModeCycleDisplay } from "../../ui/ScheduleAndModes/Display";
import {
  getSchedulesOfProgramDisplay,
  getProgramStartingAt,
} from "../../utils";
import PauseCountDown from "../../ui/PauseCountDown";

type Program = IProgram.Program<IProgram.ProgramType.REGULAR>;

type DisplayProps = {
  display: ProgramDisplay;
  schedules: Schedule[];
};
function Display({ display, schedules }: DisplayProps) {
  const modeCycles = packSchedulesToModeCycles(schedules);
  return (
    <Stack direction="column" gap={1.5}>
      <Typography variant="subtitle3">{display.display_name}</Typography>
      {modeCycles.map((modeCycle) => (
        <ModeCycleDisplay
          key={`${modeCycle.mode}${modeCycle.startTime}${modeCycle.endTime}`}
          modeCycle={modeCycle}
        />
      ))}
    </Stack>
  );
}

type Props = {
  program: Program;
  routeToProgram: (program: Program) => void;
};
export default function ProgramCard({ program, routeToProgram }: Props) {
  const startingAt = getProgramStartingAt(program);

  return (
    <Card
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: 4.5,
        borderRadius: "4px",
      }}
    >
      <Stack
        direction="row"
        sx={{ marginBottom: 2 }}
        gap={1}
        alignItems="center"
      >
        <Calendar htmlColor="#879497" />
        <Stack direction="column" gap={0.5}>
          <Typography variant="subtitle2">{program.program_name}</Typography>
          <Typography variant="body3">{`${program.devices.length} Devices`}</Typography>
        </Stack>
      </Stack>
      <Divider />
      <Box
        sx={{
          marginTop: 3,
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          columnGap: 3,
        }}
        flexWrap="wrap"
      >
        {program.program_displays.map((display) => (
          <Display
            key={display.program_display_id}
            display={display}
            schedules={getSchedulesOfProgramDisplay(display, program.schedules)}
          />
        ))}
      </Box>
      <Divider sx={{ marginTop: 6.5, marginBottom: 3 }} />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="body3">
          {startingAt && <PauseCountDown startingAt={startingAt} />}
        </Typography>
        <Button
          variant="outlined"
          color="info"
          onClick={() => routeToProgram(program)}
        >
          Schedule Details
        </Button>
      </Stack>
    </Card>
  );
}
