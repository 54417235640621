import React from "react";
import * as IShadow from "src/interfaces/IShadow";
import { Shadow as ShadowType } from "src/interfaces/IShadow";
import { Stack } from "@mui/material";
import * as DeviceBadge from "../DeviceBadge";

export function HaloBadges({
  // deviceId,
  shadow,
}: {
  // deviceId: Device["device_id"];
  shadow: undefined | ShadowType;
}) {
  if (!shadow) return null;

  const lightIsOn = IShadow.nightMode(shadow);
  const locked = IShadow.lockMode(shadow);
  return (
    <Stack direction="row" gap={0.5}>
      <DeviceBadge.Light on={lightIsOn} />
      <DeviceBadge.Locked locked={!!locked} />
    </Stack>
  );
}

export function MaxBadges({
  // deviceId,
  shadow,
}: {
  // deviceId: Device["device_id"];
  shadow: undefined | ShadowType;
}) {
  if (!shadow) return null;

  const mode = IShadow.deviceMode(shadow);
  return (
    <Stack direction="row">
      <DeviceBadge.Mode mode={mode} />
    </Stack>
  );
}
