/* eslint-disable react/destructuring-assignment */
import React, { useEffect, useRef, useState } from "react";
import { Nullable, Parser } from "src/helper";
import { MODE, Shadow } from "src/interfaces/IShadow";
import * as ShadowParser from "src/interfaces/IShadow";
import { ShadowBody } from "src/services/restful/device";
import { Map } from "immutable";
import { Stack } from "@mui/material";
import { Device } from "../../types";
import TabSelectComponent from "../../ui/TabSelect";
import DurationSelector from "../../ui/DurationSelect";
import { ChangeModeParams } from "../../api";
import RowBox from "../../ui/RowBox";

type Hours = number;
type SetChangeModeParams = React.Dispatch<
  React.SetStateAction<Array<ChangeModeParams>>
>;
type ShadowKey = keyof ShadowBody & string;
type ChangeModeParamsMap = Map<ShadowKey, ChangeModeParams>;
type Props = {
  devices: Array<Device>;
  setChangeModeParams: SetChangeModeParams;
};

// According to the design:
//    "If all the selected devices are in the same mode, display the mode they are in. Otherwise use null"
// Return the field value if all devices have same value
// Return null otherwise
function getDevicesField<V>(parser: Parser.Parser<V>) {
  return (devices: Array<Device>) => {
    if (devices.length === 0) {
      return null;
    }

    const runParser = (shadow?: Shadow) => {
      if (!shadow) return null;
      try {
        return parser(shadow);
      } catch (err) {
        console.warn(err);
        return null;
      }
    };

    const hd = devices[0];
    const hdValue = runParser(hd.shadow);

    if (devices.some((d) => runParser(d.shadow) !== hdValue)) {
      return null;
    }

    return hdValue;
  };
}
const getDevicesMode = getDevicesField(ShadowParser.deviceMode);
const getDeviceNightMode = getDevicesField(ShadowParser.nightMode);
const getDeviceLocked = getDevicesField(ShadowParser.lockMode);

function Container({ children }: { children: React.ReactNode }) {
  return (
    <RowBox label="Edit Modes">
      <Stack direction="column" sx={{ gap: 6, flexGrow: 1 }}>
        {children}
      </Stack>
    </RowBox>
  );
}

type SelectProps<V> = {
  shadowKey: ShadowKey;
  initValue: Nullable.T<V>;
  defaultValue: V;
  label?: string;
  description?: string;
  options: Array<{
    name: string;
    value: V;
  }>;
  updateParams: React.Dispatch<React.SetStateAction<ChangeModeParamsMap>>;
  // handleUpdate: (value: V, hours?: number) => void;
  // handleCancel: () => void;
};
function Select<V>({
  shadowKey,
  initValue,
  defaultValue,
  label,
  description,
  options,
  updateParams,
}: SelectProps<V>) {
  const updateParamsRef = useRef(updateParams);
  updateParamsRef.current = updateParams;

  const [value, setValue] = useState<Nullable.T<V>>(initValue);

  const [hours, setHours] = useState<Nullable.T<Hours>>(null);
  const hideHourSelect = value === defaultValue;
  useEffect(() => {
    if (hideHourSelect) {
      setHours(null);
    } else {
      setHours(2);
    }
  }, [hideHourSelect]);

  useEffect(() => {
    if (value !== null) {
      const thisParams: ChangeModeParams = {
        shadow: { [shadowKey]: value },
      };
      if (hours) {
        thisParams.duration = {
          hours,
          shadow: { [shadowKey]: defaultValue },
        };
      }

      updateParamsRef.current((params) => params.set(shadowKey, thisParams));
    }
  }, [value, hours, shadowKey, defaultValue]);

  return (
    <>
      <TabSelectComponent
        label={label}
        description={description}
        value={value}
        options={options}
        onSelect={setValue}
      />
      {hours && (
        <DurationSelector
          hours={hours}
          onSelect={setHours}
          description="All devices will reset to auto mode after the selected duration."
        />
      )}
    </>
  );
}

const nightModeOptions = [
  { name: "Light Off", value: true },
  { name: "Light On", value: false },
];
const lockOptions = [
  { name: "Locked", value: true },
  { name: "Unlocked", value: false },
];
function Halo({ devices, setChangeModeParams }: Props) {
  const [paramsMap, setParamsMap] = useState<ChangeModeParamsMap>(Map());
  useEffect(() => {
    setChangeModeParams(Array.from(paramsMap.values()));
  }, [paramsMap, setChangeModeParams]);

  return (
    <Container>
      <Select
        label="Lock"
        description="Halos won’t be physically interactive in the locked state."
        shadowKey="lock_mode"
        // eslint-disable-next-line react/jsx-boolean-value
        defaultValue={true}
        initValue={getDeviceLocked(devices)}
        options={lockOptions}
        updateParams={setParamsMap}
      />
      <Select
        label="Light" // Beware this select is actually 'nightMode'
        description="The outer light ring of Halo device."
        shadowKey="night_mode"
        // eslint-disable-next-line react/jsx-boolean-value
        defaultValue={true}
        initValue={getDeviceNightMode(devices)}
        options={nightModeOptions}
        updateParams={setParamsMap}
      />
    </Container>
  );
}

const maxModeOptions = [
  { name: "Auto", value: MODE.AUTO },
  { name: "Low", value: MODE.LOW },
  { name: "Medium", value: MODE.MEDIUM },
  { name: "High", value: MODE.HIGH },
];
function Max({ devices, setChangeModeParams }: Props) {
  const [paramsMap, setParamsMap] = useState<ChangeModeParamsMap>(Map());
  useEffect(() => {
    setChangeModeParams(Array.from(paramsMap.values()));
  }, [paramsMap, setChangeModeParams]);

  return (
    <Container>
      <Select
        shadowKey="device_mode"
        initValue={getDevicesMode(devices)}
        defaultValue={MODE.AUTO}
        options={maxModeOptions}
        updateParams={setParamsMap}
      />
    </Container>
  );
}

export { Halo, Max };
