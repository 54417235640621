import PropTypes from "prop-types";
import { MoreHoriz, SortByAlpha } from "@mui/icons-material";
import {
  Box,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  TableBody,
  LinearProgress,
  Menu,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import { deleteClient } from "../../services/restful/clients";

// components
import SuperUserOnly from "../../components/Auth/SuperUserOnly";

export default function ClientListView({
  clients,
  isLoading,
  error,
  handleAccountInfoClicked,
  handleManageDevicesClicked,
}) {
  const [sortBy, setSortBy] = useState("a_z");
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuClient, setMenuClient] = useState(null);

  const handleOpen = (event, client) => {
    setAnchorEl(event.currentTarget);
    setMenuClient(client);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setMenuClient(null);
  };

  const [isDeleteClientModalOpen, setIsDeleteClientModalOpen] = useState(false);
  const [isDeleteClientLoading, setIsDeleteClientLoading] = useState(false);

  function sortFunction(a, b) {
    if (sortBy === "a_z") {
      return a.client_name.localeCompare(b.client_name);
    }
    if (sortBy === "z_a") {
      return b.client_name.localeCompare(a.client_name);
    }
    return 0;
  }

  const handleDeleteClient = async (clientId) => {
    setIsDeleteClientLoading(true);
    try {
      await deleteClient(clientId);
    } catch (e) {
      console.log(e);
    } finally {
      // refresh to requery and update frontend
      window.location.reload(false);
    }
  };

  return (
    <Box>
      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Client ID</TableCell>
              <TableCell>
                Client Name
                <IconButton
                  onClick={() =>
                    sortBy === "a_z" ? setSortBy("z_a") : setSortBy("a_z")
                  }
                >
                  <SortByAlpha />
                </IconButton>
              </TableCell>
              <SuperUserOnly>
                <TableCell>DB Name</TableCell>
                <TableCell>Hash</TableCell>
              </SuperUserOnly>
              <TableCell>Country</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!!error && (
              <TableRow>
                <TableCell colSpan={4}>Can not load client</TableCell>
              </TableRow>
            )}

            {!!isLoading && (
              <TableRow>
                <TableCell colSpan={4}>
                  <LinearProgress />
                </TableCell>
              </TableRow>
            )}
            {!isLoading &&
              clients.sort(sortFunction).map((client) => (
                <TableRow
                  hover
                  sx={{ cursor: "pointer" }}
                  key={client.client_id}
                  onClick={(e) =>
                    // Check click target is row and not "Action" button (which contains SVG elem).
                    !(
                      e.target instanceof SVGPathElement ||
                      e.target instanceof SVGSVGElement
                    ) && handleManageDevicesClicked(client.client_id)
                  }
                >
                  <TableCell>{client.client_id}</TableCell>
                  <TableCell>{client.client_name}</TableCell>
                  <SuperUserOnly>
                    <TableCell>{client.client_dbname}</TableCell>
                    <TableCell>{client.hash}</TableCell>
                  </SuperUserOnly>
                  <TableCell>{client.country}</TableCell>
                  <TableCell>
                    <IconButton onClick={(e) => handleOpen(e, client)}>
                      <MoreHoriz />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
        <MenuItem
          onClick={() => handleAccountInfoClicked(menuClient.client_id)}
        >
          Account Info
        </MenuItem>
        <MenuItem
          onClick={() => handleManageDevicesClicked(menuClient.client_id)}
        >
          Manage Devices
        </MenuItem>
        <MenuItem
          target="_blank"
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_API_DOMAIN}/v1/grafana?hash=${menuClient.hash}&dbname=${menuClient.client_dbname}&client=${menuClient.client_name})`,
              "_blank"
            );
          }}
        >
          Generate Grafana
        </MenuItem>
        <MenuItem onClick={() => setIsDeleteClientModalOpen(true)}>
          Delete Client
        </MenuItem>
      </Menu>
      {isDeleteClientModalOpen && (
        <Dialog fullWidth maxWidth="sm" open={isDeleteClientModalOpen}>
          {isDeleteClientLoading && <LinearProgress />}
          <DialogTitle>Delete {menuClient.client_name}?</DialogTitle>
          <DialogContent>
            Are you sure you want to delete this client? This cannot be undone.
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setIsDeleteClientModalOpen(false)}
              color="primary"
              variant="contained"
              disabled={isDeleteClientLoading}
            >
              Cancel
            </Button>
            <Button
              onClick={() => handleDeleteClient(menuClient.client_id)}
              color="error"
              variant="contained"
              disabled={isDeleteClientLoading}
            >
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
}

ClientListView.propTypes = {
  clients: PropTypes.arrayOf(
    PropTypes.shape({
      client_id: PropTypes.number,
      client_name: PropTypes.string,
      client_dbname: PropTypes.string,
      hash: PropTypes.string,
      country: PropTypes.string,
    })
  ).isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.string,
  handleAccountInfoClicked: PropTypes.func.isRequired,
  handleManageDevicesClicked: PropTypes.func.isRequired,
};

ClientListView.defaultProps = {
  error: null,
};
