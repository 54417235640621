import React, { useState } from "react";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Nullable } from "src/helper";
import { Shadow } from "src/interfaces/IShadow";
import EditFloorModal from "src/components/UI/EditFloorModal";
import ToastMaker from "src/components/UI/NotificationToast";
import { Zones } from "./Zone";
import { Zone as ZoneType } from "../../interfaces/IZone";
import type { Device } from "../../interfaces/IDevice";
import * as Icons from "./Icons";

const Toast = ToastMaker();

type FloorProps = {
  floor: string;
  zones: Array<ZoneType>;
  devices: Array<{ device: Device; shadow: undefined | Shadow }>;
  onEdit: (floor: string) => void;
  onRelocate: (device: Device) => void;
  onRemove: (device: Device) => void;
  onZoneChange: () => Promise<void>;
};

export function Floor({
  floor,
  zones,
  devices,
  onEdit,
  onRelocate,
  onRemove,
  onZoneChange,
}: FloorProps) {
  return (
    <Box sx={{ display: "flex", rowGap: 3 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          flexShrink: 0,
          width: "280px",
        }}
      >
        <Typography variant="h7" sx={{ marginBottom: 3 }}>
          {floor}
        </Typography>
        <Button
          sx={{ marginBottom: 2 }}
          startIcon={<Icons.FloorPlan />}
          variant="outlined"
          color="info"
        >
          Floor Plan
        </Button>
        <Button
          sx={{ marginBottom: 2 }}
          startIcon={<Icons.Edit />}
          variant="outlined"
          color="info"
          onClick={() => onEdit(floor)}
        >
          Edit Floor
        </Button>
      </Box>
      <Zones
        zones={zones}
        devices={devices}
        onRelocate={onRelocate}
        onRemove={onRemove}
        onZoneChange={onZoneChange}
      />
    </Box>
  );
}

const notiEmitSuccess = (floor: string) =>
  Toast.emit({
    delay: 8000,
    type: "Success",
    title: "Floor Deleted",
    content: `"${floor}" has been successfully deleted. All the existing devices on this floor would be moved to unassigned.`,
  });

const notiEmitFailed = (floor: string) =>
  Toast.emit({
    delay: "persist",
    type: "Failed",
    title: "Failed to Delete the Floor",
    content: `We are unable to delete "${floor}". Please try again.`,
  });

type FloorsProps = {
  devices: Array<{ device: Device; shadow: undefined | Shadow }>;
  zones: Array<ZoneType>;
  floors: Array<string>;
  onRelocate: (device: Device, floor: string) => void;
  onRemove: (device: Device) => void;
  onZoneChange: () => Promise<void>;
};
export function Floors({
  devices,
  zones,
  floors,
  onRelocate,
  onRemove,
  onZoneChange,
}: FloorsProps) {
  const [editFloor, setEditFloor] = useState<Nullable.T<string>>(null);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        margin: "2rem 0",
      }}
    >
      {floors.map((floor) => (
        <Floor
          key={floor}
          floor={floor}
          zones={zones.filter((zone) => zone.floor === floor)}
          onEdit={setEditFloor}
          onRelocate={(d) => onRelocate(d, floor)}
          onZoneChange={onZoneChange}
          onRemove={onRemove}
          devices={devices.filter((device) => {
            const zone = zones.find((z) => z.zone_id === device.device.zone_id);
            if (!zone) {
              console.warn(
                `cannot find zone of device: ${device.device.device_id} '${floor}' in zones`
              );
              return false;
            }
            return zone.floor === floor;
          })}
        />
      ))}
      <Toast.Component />
      <EditFloorModal
        floors={floors}
        floorToEdit={editFloor}
        onClose={() => setEditFloor(null)}
        onEditSuccessCallback={onZoneChange}
        onDeleteSuccessCallback={() => {
          onZoneChange();
          if (editFloor) notiEmitSuccess(editFloor);
        }}
        onDeleteFailedCallback={() => {
          if (editFloor) notiEmitFailed(editFloor);
        }}
      />
    </Box>
  );
}
