import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

export default function Sliders(props: SvgIconProps) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M10.5625 5.10938V5.15341L10.604 5.16823C10.9559 5.29402 11.2604 5.52554 11.4757 5.83108C11.6909 6.13662 11.8065 6.50124 11.8065 6.875C11.8065 7.24876 11.6909 7.61338 11.4757 7.91892C11.2604 8.22446 10.9559 8.45598 10.604 8.58177L10.5625 8.59659V8.64062V16.875C10.5625 17.0242 10.5032 17.1673 10.3977 17.2727C10.2923 17.3782 10.1492 17.4375 10 17.4375C9.85082 17.4375 9.70774 17.3782 9.60225 17.2727C9.49677 17.1673 9.4375 17.0242 9.4375 16.875V8.64062V8.59659L9.39604 8.58177C9.04408 8.45598 8.73961 8.22446 8.52435 7.91892C8.30908 7.61338 8.19353 7.24876 8.19353 6.875C8.19353 6.50124 8.30908 6.13662 8.52435 5.83108C8.73961 5.52554 9.04408 5.29402 9.39604 5.16823L9.4375 5.15341V5.10938V3.125C9.4375 2.97582 9.49677 2.83274 9.60225 2.72725C9.70774 2.62176 9.85082 2.5625 10 2.5625C10.1492 2.5625 10.2923 2.62176 10.3977 2.72725C10.5032 2.83274 10.5625 2.97582 10.5625 3.125V5.10938Z"
        fill="currentColor"
        strokeWidth="0.125"
      />
      <path
        d="M16.1875 11.3594V11.4036L16.2292 11.4183C16.5822 11.5431 16.8879 11.7742 17.1043 12.0798C17.3207 12.3855 17.4371 12.7506 17.4375 13.125C17.4371 13.4994 17.3207 13.8645 17.1043 14.1702C16.8879 14.4758 16.5822 14.7069 16.2292 14.8317L16.1875 14.8464V14.8906V16.875C16.1875 17.0242 16.1282 17.1673 16.0227 17.2727C15.9173 17.3782 15.7742 17.4375 15.625 17.4375C15.4758 17.4375 15.3327 17.3782 15.2273 17.2727C15.1218 17.1673 15.0625 17.0242 15.0625 16.875V14.8906V14.8466L15.021 14.8318C14.6691 14.706 14.3646 14.4745 14.1493 14.1689C13.9341 13.8634 13.8185 13.4988 13.8185 13.125C13.8185 12.7512 13.9341 12.3866 14.1493 12.0811C14.3646 11.7755 14.6691 11.544 15.021 11.4182L15.0625 11.4034V11.3594V3.125C15.0625 2.97582 15.1218 2.83274 15.2273 2.72725C15.3327 2.62176 15.4758 2.5625 15.625 2.5625C15.7742 2.5625 15.9173 2.62176 16.0227 2.72725C16.1282 2.83274 16.1875 2.97582 16.1875 3.125V11.3594Z"
        fill="currentColor"
        strokeWidth="0.125"
      />
      <path
        d="M5 8.85938V3.125C5 2.95924 4.93415 2.80027 4.81694 2.68306C4.69973 2.56585 4.54076 2.5 4.375 2.5C4.20924 2.5 4.05027 2.56585 3.93306 2.68306C3.81585 2.80027 3.75 2.95924 3.75 3.125V8.85938C3.38591 8.9895 3.07094 9.229 2.84825 9.54508C2.62556 9.86116 2.50603 10.2384 2.50603 10.625C2.50603 11.0116 2.62556 11.3888 2.84825 11.7049C3.07094 12.021 3.38591 12.2605 3.75 12.3906V16.875C3.75 17.0408 3.81585 17.1997 3.93306 17.3169C4.05027 17.4342 4.20924 17.5 4.375 17.5C4.54076 17.5 4.69973 17.4342 4.81694 17.3169C4.93415 17.1997 5 17.0408 5 16.875V12.3906C5.3641 12.2605 5.67906 12.021 5.90175 11.7049C6.12444 11.3888 6.24398 11.0116 6.24398 10.625C6.24398 10.2384 6.12444 9.86116 5.90175 9.54508C5.67906 9.229 5.3641 8.9895 5 8.85938Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}
