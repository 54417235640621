// This should be further restricted as Json type

import type { CurriedFunction1 } from "lodash";
import curry from "lodash/curry";
import * as Nullable from "../nullable";

// But currently Decode is not limited to Api response body, so leave it.
type Value = any;
type Parser<V> = (value: Value) => V;

class ParseError extends Error {
  constructor(message: string) {
    super(message);
    this.name = "DecodeError";
  }
}

// parser
interface ObjectField {
  <V>(f: string, parser: Parser<V>, object: { [key: string]: Value }): V;
  <V>(f: string, parser: Parser<V>): CurriedFunction1<{}, V>;
}
const objectField: ObjectField = curry(
  <V>(
    fieldName: string,
    parser: Parser<V>,
    object: { [key: string]: Value }
  ): V => {
    const value = object && object[fieldName];

    if (value === undefined)
      throw new ParseError(`field '${fieldName}' is missing`);

    try {
      return parser(value);
    } catch (err) {
      if (err instanceof ParseError) {
        err.message = `Field '${fieldName}': ${err.message}`;
        throw err;
      } else {
        throw err;
      }
    }
  }
);

interface ObjectOptionalField {
  <V>(
    f: string,
    parser: Parser<V>,
    object: { [key: string]: Value }
  ): Nullable.T<V>;
  <V>(f: string, parser: Parser<V>): CurriedFunction1<{}, Nullable.T<V>>;
}
const objectOptionalField: ObjectOptionalField = curry(
  <V>(
    fieldName: string,
    parser: Parser<V>,
    object: { [key: string]: Value }
  ): Nullable.T<V> => {
    try {
      if (!object) {
        throw new ParseError(`cannot parse field of this value`);
      }

      const value = object[fieldName];
      if (!value) {
        return null;
      }

      return parser(value);
    } catch (err) {
      if (err instanceof ParseError) {
        err.message = `Field '${fieldName}': ${err.message}`;
        throw err;
      } else {
        throw err;
      }
    }
  }
);

const compose =
  <M, N>(parserM: Parser<M>, parserN: (m: M) => N): Parser<N> =>
  (v: Value) =>
    parserN(parserM(v));

const composeMany =
  <V>(parser: Parser<any>, ...parsers: Parser<any>[]): Parser<V> =>
  (value: Value) => {
    if (parsers.length === 0) {
      return parser(value);
    }

    return composeMany(compose(parser, parsers[0]), ...parsers.slice(1))(value);
  };

const oneOf =
  <V>(parsers: Array<Parser<V>>) =>
  (v: Value): V => {
    if (parsers.length > 0) {
      const hdParser = parsers[0];
      try {
        return hdParser(v);
      } catch (error) {
        return oneOf(parsers.slice(1))(v);
      }
    }

    throw new ParseError(
      "Parse string failed. None of the provided parsers worked"
    );
  };

const string = (v: Value) => {
  if (typeof v === "string") {
    return v;
  }
  throw new ParseError("Parse string failed");
};

const number = (v: Value) => {
  if (typeof v === "number") {
    return v;
  }
  throw new ParseError("Parse number failed");
};

const stringNumber = (v: Value) => {
  const s = string(v);
  const n = Number(s);
  if (s === "" || Number.isNaN(n))
    throw new ParseError("Parse number from string failed");
  return n;
};

const boolean = (v: Value) => {
  if (typeof v === "boolean") {
    return v;
  }
  throw new ParseError("Parse boolean failed");
};

const date = (v: Value) => {
  if (v instanceof Date) {
    return v;
  }
  throw new ParseError("Parse Date failed");
};

// { [fieldName]: 12345 as timestamp }
const dateFromNumber = (v: Value) => {
  let d;
  try {
    d = number(v);
  } catch (err) {
    throw new ParseError("Parse date from number failed");
  }
  return new Date(d);
};

export type { Parser, Value };
export {
  ParseError,
  objectField,
  objectOptionalField,
  string,
  number,
  stringNumber,
  boolean,
  date,
  dateFromNumber,
  compose,
  composeMany,
  oneOf,
};
