import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSearchParams } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import Container from "@mui/material/Container";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import NewReleasesRoundedIcon from "@mui/icons-material/NewReleasesRounded";
import { useAsync, IfPending, IfFulfilled } from "react-async";
import Select from "@mui/material/Select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

import { lightTheme } from "../styles";

const theme = lightTheme;

const SELECT_ALL = "select-all";
const CLEAR = "clear";

const COPY = {
  SELECT_ZONES: "Please select Zone(s)",
};

const ORIGIN_ALLOWLIST = ["wynd.grafana.net"];

const LED_COLOR = {
  BLUE: "blue",
  GREEN: "green",
  RED: "red",
  TEAL: "teal",
  WHITE: "white",
};

const MODE = {
  AUTO: "auto",
  LOW: "soft",
  MEDIUM: "medium",
  HIGH: "max",
};

const DEVICE_MODE_ENUM = {
  [MODE.OFF]: "1",
  [MODE.AUTO]: "2",
  [MODE.LOW]: "3",
  [MODE.MEDIUM]: "4",
  [MODE.HIGH]: "5",
};

const modeToMotorSpeed = {
  [MODE.OFF]: "0",
  [MODE.AUTO]: "10",
  [MODE.LOW]: "10",
  [MODE.MEDIUM]: "30",
  [MODE.HIGH]: "100",
};

function getModeFromMotorSpeed(speed) {
  if (speed < 20) {
    return MODE.LOW;
  }
  if (speed < 50) {
    return MODE.MEDIUM;
  }
  return MODE.HIGH;
}

function getFleetEndpoint({ clientHash, queryParams }) {
  let urlSearchParams = "";
  if (queryParams) {
    urlSearchParams = new URLSearchParams(queryParams).toString();
  }
  return `${process.env.REACT_APP_API_DOMAIN}/v1/cre/clients/${clientHash}/fleet?${urlSearchParams}`;
}

const loadFleet = async ({ clientHash, callback }, { signal }) => {
  /*
   * Output: { property => { zone => [ device1, device2... ] } }
   * */

  if (clientHash === null || clientHash === "" || clientHash === undefined) {
    return null;
  }

  const res = await fetch(getFleetEndpoint({ clientHash }), { signal });
  if (!res.ok) throw new Error(res.statusText);
  const devices = await res.json();
  const fleet = {};
  devices
    .filter((device) => device.device_id.search("PUR") >= 0)
    .forEach((device) => {
      const propertyObj = fleet[device.property_name] || {};
      const zones = propertyObj[device.zone_name] || [];
      const deviceObj = {
        deviceId: device.device_id,
        motorSpeed: device.shadow.motor_speed,
        ledColor: device.shadow.led_color,
      };
      zones.push(deviceObj);
      propertyObj[device.zone_name] = zones;
      fleet[device.property_name] = propertyObj;
    });

  // Callback used to store `fleet` in state of component
  //   since this function lives outside of the component's scope.
  callback(fleet);
  return fleet;
};

const updateDevices = async ({ clientHash, devices, mode, color }) => {
  const desiredShadow = {};
  const motorSpeed = modeToMotorSpeed[mode];
  const deviceMode = DEVICE_MODE_ENUM[mode];
  if (mode) {
    desiredShadow.motor_speed = motorSpeed;
    desiredShadow.device_mode = deviceMode;
  }
  if (color) {
    desiredShadow.led_color = color;
  }
  const newShadow = {
    desired_shadow: desiredShadow,
  };
  const queryParams = { devices: devices.map((x) => x.deviceId).join(",") };
  const endpoint = getFleetEndpoint({ clientHash, queryParams });
  const res = await fetch(endpoint, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(newShadow),
  });
  if (!res.ok) throw new Error(res.statusText);
};

function TabPanel(props) {
  const { children, value, index } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
    >
      {value === index && (
        <Box
          sx={{
            paddingTop: 3,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function ZoneDropdown({
  labelText,
  items,
  selected,
  handleSelect,
  zoneWarning,
}) {
  const totalDeviceCount = items
    .map((x) => x.deviceCount)
    .reduce((p, a) => p + a, 0);

  return (
    <Box
      sx={{
        justifyContent: "flex-start",
        minWidth: 80,
        marginRight: theme.spacing(2),
        marginBottom: theme.spacing(4),
      }}
    >
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">{labelText}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={selected}
          label={labelText}
          renderValue={() => {
            if (selected.length === items.length) {
              return <Typography align="left">All</Typography>;
            }
            return <Typography align="left">{selected.join(",")}</Typography>;
          }}
          onChange={handleSelect}
          sx={{
            maxWidth: "400px",
            marginRight: 2,
            height: "56px",
          }}
          multiple
        >
          <MenuItem value={CLEAR} disabled={selected.length === 0}>
            <Button>Clear all</Button>
          </MenuItem>
          <MenuItem
            value={SELECT_ALL}
            disabled={selected.length === items.length}
          >
            <Checkbox checked={selected.length === items.length} />
            <ListItemText
              primary={`Select all (${totalDeviceCount} ${totalDeviceCount === 1 ? "Device" : "Devices"
                })`}
            />
          </MenuItem>
          {items.map((item) => (
            <MenuItem key={item.value} value={item.value}>
              <Checkbox checked={selected.includes(item.value)} />
              <ListItemText primary={item.label} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {zoneWarning && (
        <Box sx={{ display: "flex" }}>
          <NewReleasesRoundedIcon
            color="warning"
            sx={{ fontSize: 10, marginRight: 0.5 }}
          />
          <Typography variant="caption" component="div">
            {zoneWarning}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

function Toggle({ values, value, handleChange, disabled }) {
  return (
    <Box
      sx={{
        display: "flex",
        marginBottom: 3,
        justifyContent: "flex-start",
      }}
    >
      <ToggleButtonGroup
        value={value}
        exclusive
        onChange={handleChange}
        disabled={disabled}
      >
        {values.map((mode) => (
          <ToggleButton key={mode} value={mode}>
            {mode}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
    </Box>
  );
}

function FleetPage() {
  const [canSave, setCanSave] = useState(false);
  const [currentColor, setCurrentColor] = useState(null);
  const [currentMode, setCurrentMode] = useState(null);
  const [currentTabIndex, setCurrentTabIndex] = useState(0);
  const [fleetData, setFleetData] = useState(null);
  const [isSaveSuccessful, setIsSaveSuccessful] = useState(null);
  const [searchParams] = useSearchParams();
  const [selectedZones, setSelectedZones] = useState([]);
  const [zoneWarning, setZoneWarning] = useState(COPY.SELECT_ZONES);

  const clientHash = searchParams.get("c");
  const state = useAsync({
    promiseFn: loadFleet,
    clientHash,
    callback: (x) => setFleetData(x),
  });

  useEffect(() => {
    // Save confirmation/error message disappears after 3 seconds.
    if (isSaveSuccessful !== null) {
      setTimeout(() => setIsSaveSuccessful(null), 3000);
    }
  }, [isSaveSuccessful]);

  useEffect(() => {
    // Show state of selected devices.
    if (fleetData === null || selectedZones.length === 0) {
      return;
    }

    const property = Object.keys(fleetData)[currentTabIndex];
    const devices = selectedZones
      .map((zone) => fleetData[property][zone])
      .flat();
    const meanMotorSpeed =
      devices.map((x) => x.motorSpeed).reduce((a, b) => a + b) / devices.length;
    const mode = getModeFromMotorSpeed(meanMotorSpeed);
    const color = devices[0].ledColor;
    const colorCounter = devices
      .map((d) => d.ledColor)
      .reduce((acc, curr) => {
        if (acc[curr]) {
          acc[curr] += 1;
        } else {
          acc[curr] = 1;
        }
        return acc;
      }, {});
    if (Object.keys(colorCounter).length === 1) {
      setCurrentMode(mode);
      setCurrentColor(color);
      setZoneWarning("");
    } else if (Object.keys(colorCounter).length > 1) {
      setCurrentMode(null);
      setCurrentColor(null);
      setZoneWarning("Your selected purifiers have different modes");
    } else {
      throw new Error("No devices found.");
    }
  }, [currentTabIndex, fleetData, selectedZones]);

  const origin = searchParams.get("origin");
  if (!ORIGIN_ALLOWLIST.includes(origin)) {
    return <h1>Page Not Found</h1>;
  }

  const isColorChangeEnabled = searchParams.get("change_color") === "true";

  const resetUserInputs = () => {
    setSelectedZones([]);
    setCurrentMode(null);
    setCurrentColor(null);
    setZoneWarning(COPY.SELECT_ZONES);
  };

  const handleChangeTab = (event, newValue) => {
    setCurrentTabIndex(newValue);
    resetUserInputs();
  };

  const handleSelectZones = (event, zoneItems) => {
    if (event.target.value.includes(CLEAR) || event.target.value.length === 0) {
      resetUserInputs();
      return;
    }

    if (event.target.value.includes(SELECT_ALL)) {
      setSelectedZones(zoneItems.map((item) => item.value));
      setZoneWarning("");
    } else {
      setSelectedZones([...event.target.value]);
    }
  };

  const handleChangeMode = (event, newValue) => {
    const mode = newValue.toLowerCase();
    setCurrentMode(mode);
    setCanSave(true);
  };

  const handleChangeColor = (event, color) => {
    setCurrentColor(color);
    setCanSave(true);
  };

  const handleSave = async () => {
    const property = Object.keys(fleetData)[currentTabIndex];
    const devices = selectedZones
      .map((zone) => fleetData[property][zone])
      .flat();
    try {
      await updateDevices({
        clientHash,
        devices,
        mode: currentMode,
        color: currentColor,
      });
      setIsSaveSuccessful(true);
      setZoneWarning("");
      setCanSave(false);
    } catch (err) {
      setIsSaveSuccessful(false);
    }
    selectedZones.forEach((zone) => {
      const zoneDevices = fleetData[property][zone];
      fleetData[property][zone] = zoneDevices.map((device) => ({
        ...device,
        ledColor: currentColor,
        motorSpeed: modeToMotorSpeed[currentMode],
      }));
    });
    setFleetData(fleetData);
    //    await loadFleet({clientHash, callback: x => setFleetData(x)})
  };

  return (
    <Container maxWidth="fullWidth">
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          marginX: 4,
        }}
      >
        <Typography align="left" variant="h1">
          Fleet Management
        </Typography>

        <IfPending state={state}>
          <Typography align="left" variant="h2">
            Loading..
          </Typography>
        </IfPending>

        <IfFulfilled state={state}>
          {(data) => (
            <Box>
              <Tabs value={currentTabIndex} onChange={handleChangeTab}>
                {Object.keys(data).map((propertyName) => (
                  <Tab key={propertyName} label={propertyName} />
                ))}
              </Tabs>
              {Object.keys(data).map((propertyName, index) => {
                const zoneItems = Object.keys(data[propertyName]).map(
                  (zoneName) => {
                    const devices = data[propertyName][zoneName];
                    return {
                      label: `${zoneName} (${devices.length} ${devices.length === 1 ? "device" : "devices"
                        })`,
                      value: zoneName,
                      deviceCount: devices.length,
                    };
                  }
                );
                // Take only zone that has device
                const filteredZoneItems = zoneItems.filter(
                  (z) => z.deviceCount > 0
                );
                const modes = Object.values(MODE);
                return (
                  <TabPanel
                    key={propertyName}
                    value={currentTabIndex}
                    index={index}
                  >
                    <ZoneDropdown
                      labelText="Zone(s)"
                      items={filteredZoneItems}
                      selected={selectedZones}
                      handleSelect={(event) =>
                        handleSelectZones(event, filteredZoneItems)
                      }
                      zoneWarning={zoneWarning}
                    />
                    <Typography variant="h2" align="left">
                      Change purifier(s) fan modes
                    </Typography>
                    <Toggle
                      disabled={selectedZones.length === 0}
                      values={modes}
                      value={currentMode}
                      handleChange={handleChangeMode}
                    />
                    {isColorChangeEnabled && (
                      <Toggle
                        disabled={selectedZones.length === 0}
                        values={Object.values(LED_COLOR)}
                        value={currentColor}
                        handleChange={handleChangeColor}
                      />
                    )}
                    <Box sx={{ display: "flex", marginTop: theme.spacing(4) }}>
                      <Button
                        disabled={
                          selectedZones.length === 0 || canSave === false
                        }
                        variant="contained"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                      {isSaveSuccessful && (
                        <>
                          <CheckCircleRoundedIcon
                            color="success"
                            sx={{
                              fontSize: 10,
                              marginRight: 0.5,
                              marginLeft: 1,
                            }}
                          />
                          <Typography variant="caption" component="span">
                            Saved!
                          </Typography>
                        </>
                      )}
                      {isSaveSuccessful != null && !isSaveSuccessful && (
                        <>
                          <CheckCircleRoundedIcon
                            color="error"
                            sx={{
                              fontSize: 10,
                              marginRight: 0.5,
                              marginLeft: 1,
                            }}
                          />
                          <Typography variant="caption" component="span">
                            Oh no! Something went wrong - please try that again.
                          </Typography>
                        </>
                      )}
                    </Box>
                  </TabPanel>
                );
              })}
            </Box>
          )}
        </IfFulfilled>
      </Box>
    </Container>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node.isRequired,
  value: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
};

ZoneDropdown.propTypes = {
  labelText: PropTypes.string.isRequired,
  items: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape())).isRequired,
  selected: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape())),
  handleSelect: PropTypes.func.isRequired,
  zoneWarning: PropTypes.string.isRequired,
};

ZoneDropdown.defaultProps = {
  selected: [],
};

Toggle.propTypes = {
  values: PropTypes.arrayOf(PropTypes.string).isRequired,
  value: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default FleetPage;
