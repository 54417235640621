import moment, { Moment } from "moment";
import { Nullable } from "src/helper";
import { getDaysOfWeekFromCronString } from "src/helper/cron";
import { DayOfWeek, decodeDaysOfWeek } from "src/helper/datetime";
import { ProgramDisplay } from "src/interfaces/IProgramDisplay";
import { Program, ProgramType } from "src/interfaces/IProgram";
import {
  Schedule,
  RecurringSchedule,
  isRecurringSchedule,
} from "src/interfaces/ISchedule";
import { Value } from "src/pages/FleetManagement/ui/ScheduleAndModes";
import { packSchedulesToModeCycles } from "./ui/FormEdit/modeCycle";

export const isRecurringMatchDaysOfWeek = (
  recurring: RecurringSchedule["recurring"],
  daysOfWeek: Array<DayOfWeek>
) =>
  getDaysOfWeekFromCronString(recurring).every((dayOfWeek) =>
    daysOfWeek.includes(dayOfWeek)
  );

export const getSchedulesOfProgramDisplay = (
  programDisplay: ProgramDisplay,
  schedules: Schedule[]
): RecurringSchedule[] =>
  (schedules.filter(isRecurringSchedule) as RecurringSchedule[]).filter(
    (schedule) =>
      isRecurringMatchDaysOfWeek(
        schedule.recurring,
        decodeDaysOfWeek(programDisplay.days_of_week)
      )
  );

export const toScheduleAndModesValue = (
  programDisplay: ProgramDisplay,
  schedules: RecurringSchedule[]
): Value => ({
  name: programDisplay.display_name,
  notes: programDisplay.notes || "",
  daysOfWeek: decodeDaysOfWeek(programDisplay.days_of_week),
  modeCycles: packSchedulesToModeCycles(schedules),
});

/**
 * @returns The time when the will program starts
 * @returns null if the program has no schedule or is already started
 */
export const getProgramStartingAt = (
  program: Program<ProgramType.REGULAR>
): Nullable.T<Moment> => {
  const { schedules } = program;
  if (schedules.length === 0) {
    return null;
  }

  // Assume all schedule in the paused regular program has the same start time
  const startTime = moment.utc(schedules[0].starting_at);
  const now = moment.utc();

  if (startTime.isAfter(now)) return startTime;

  return null;
};
