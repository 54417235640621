import React, { useContext, useEffect, useState } from "react";
import PageContainer from "src/components/Layout/PageContainer";
import { DeviceType, deviceTypeToPluralName } from "src/interfaces/IDevice";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import {
  Button,
  CircularProgress,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { ClientContext } from "src/contexts/GqlContext";
import { useApolloClient } from "@apollo/client";
import { Page, Context as PageContext } from "../../PageController";
import { ChangeModeParams, changeMode } from "../../api";
import DeviceList from "../../ui/SimpleDeviceList";
import * as ModeSelection from "./ModeSelection";
import { Device } from "../../types";
import CollapsibleBox from "../../ui/CollapsibleBox";
import DeviceCount from "../../ui/DeviceCount";
import { emitFailed } from "../../ErrorToast";

type Props = {
  devices: Array<Device>;
  deviceType: DeviceType;
};

export type ChangeModePage = Page<Props>;

export const pageName = "change-mode";

export const makeChangeModePage = (props: Props): ChangeModePage => ({
  name: pageName,
  props,
});

export function Component({ devices: devicesExt, deviceType }: Props) {
  const { client } = useContext(ClientContext);
  const { backToLastPage } = useContext(PageContext);

  const [devices, setDevices] = useState(devicesExt);
  const disabled = devices.length === 0;

  useEffect(() => {
    setDevices(devicesExt);
  }, [devicesExt]);

  const handleCancel = () => backToLastPage();

  const [isLoading, setIsLoading] = useState(false);
  const [changeModeParams, setChangeModeParams] = useState<
    Array<ChangeModeParams>
  >([]);

  const gqlClient = useApolloClient();

  const handleSave = () => {
    setIsLoading(true);

    Promise.all(
      changeModeParams.map((params) =>
        changeMode(client, gqlClient, devices, params)
      )
    )
      .then(backToLastPage)
      .catch((err) => {
        setIsLoading(false);
        console.error(err);
        emitFailed("Failed to change mode", err?.message || "Unknown error");
      });
  };

  return (
    <PageContainer>
      <Stack direction="column" gap={7}>
        <Stack direction="row">
          <IconButton onClick={backToLastPage} edge="start">
            <ArrowBack sx={{ width: "32px", height: "32px" }} />
          </IconButton>
          <Typography variant="h3">Change Mode</Typography>
        </Stack>
        <CollapsibleBox
          label={
            <>
              {`${deviceTypeToPluralName(deviceType)} Selected`}
              <DeviceCount count={devices.length} />
            </>
          }
        >
          <DeviceList
            devices={devices}
            onRemoveDevice={(d: Device) =>
              setDevices((ds) =>
                ds.filter(({ device_id }) => device_id !== d.device_id)
              )
            }
          />
        </CollapsibleBox>

        <Divider />
        <Stack direction="column" gap={8}>
          <Typography variant="h6">Change Mode</Typography>
          {deviceType === DeviceType.HALO ? (
            <ModeSelection.Halo
              devices={devices}
              setChangeModeParams={setChangeModeParams}
            />
          ) : deviceType === DeviceType.MAX ? (
            <ModeSelection.Max
              devices={devices}
              setChangeModeParams={setChangeModeParams}
            />
          ) : null}
        </Stack>
        <Divider />
        <Stack direction="row" gap={3} sx={{ justifyContent: "center" }}>
          <Button
            variant="outlined"
            color="info"
            disabled={isLoading}
            onClick={handleCancel}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={disabled || isLoading}
            onClick={handleSave}
            startIcon={
              isLoading ? (
                <CircularProgress color="inherit" size="14px" />
              ) : undefined
            }
          >
            Save Change
          </Button>
        </Stack>
      </Stack>
    </PageContainer>
  );
}
