import { Stack, Typography } from "@mui/material";

import React, { useState } from "react";
import PageContainer from "src/components/Layout/PageContainer";
import { Client } from "src/interfaces/IClient";
import SearchBar from "src/components/UI/SearchBar";
import { Nullable } from "src/helper";
import uniq from "lodash/uniq";
import * as Model from "./Model";
import { DeviceCount } from "./Model";
import { ClientTable, ClientTableRow } from "./ClientTable";
import StatisticsCard from "./StatisticCard";
import Toast from "./ErrorToast";

type TableRowItem = {
  client: Client;
  property_id: number;
  property_name: string;
  counts: DeviceCount.Counts;
};

export type Sorter = (v: TableRowItem[]) => TableRowItem[];

namespace Sorter {
  export const identity: Sorter = (v) => v;
  type Opt = { inverse: boolean };
  const tableRowItem =
    (opt: Opt): Sorter =>
    (items) => {
      const byString = (s1: string, s2: string) =>
        opt.inverse ? s2.localeCompare(s1) : s1.localeCompare(s2);
      return [...items].sort((item1, item2) => {
        const r = byString(item1.client.client_name, item2.client.client_name);
        if (r !== 0) return r;
        return byString(item1.property_name, item2.property_name);
      });
    };

  export const aToZ = tableRowItem({
    inverse: false,
  });
  export const zToA = tableRowItem({
    inverse: true,
  });
}

namespace TableRowItem {
  export const fromDict = (dict: DeviceCount.Dict): TableRowItem[] => {
    const tableRowItems: TableRowItem[] = [];
    dict.forEach(({ client, propertyDict }) =>
      propertyDict.forEach(({ property_name, counts }, property_id) =>
        tableRowItems.push({
          client,
          property_id,
          property_name,
          counts,
        })
      )
    );

    return tableRowItems;
  };
}

type ActionNeededTableProps = {
  tableRowItems: TableRowItem[];
  setSorter: React.Dispatch<React.SetStateAction<Sorter>>;
};
function ActionNeededTable({
  tableRowItems,
  setSorter,
}: ActionNeededTableProps) {
  return (
    <ClientTable
      title="Action Needed"
      onSort={() =>
        setSorter((s: Sorter) =>
          s === Sorter.aToZ ? Sorter.zToA : Sorter.aToZ
        )
      }
    >
      {tableRowItems.reduce((acc: Array<React.ReactElement>, item) => {
        acc.push(
          <ClientTableRow
            key={`${item.client.client_id}-${item.property_id}`}
            client={item.client}
            propertyId={item.property_id}
            propertyName={item.property_name}
            countIsLoading={false}
            counts={item.counts}
          />
        );
        return acc;
      }, [])}
    </ClientTable>
  );
}

export default function Dashboard() {
  const clients = Model.useClients();

  const deviceCount = Model.DeviceCount.useDict(clients.data);
  const sum = DeviceCount.countByDeviceType(deviceCount.data);

  const [sorter, setSorter] = useState<Sorter>(() => Sorter.identity);
  const [filterInput, setFilterInput] = useState<Nullable.T<string>>(null);
  const tableRowItems = filterInput
    ? sorter(TableRowItem.fromDict(deviceCount.data)).filter(({ client }) =>
        client.client_name.includes(filterInput)
      )
    : sorter(TableRowItem.fromDict(deviceCount.data));

  return (
    <PageContainer isLoading={clients.isLoading}>
      <Toast.Component />
      <Stack
        direction="row"
        sx={{
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 6,
        }}
      >
        <Typography variant="h3">Dashboard</Typography>
        <SearchBar
          options={uniq(tableRowItems.map(({ client }) => client.client_name))}
          onChange={setFilterInput}
        />
      </Stack>
      <Stack
        direction="row"
        sx={{
          gap: 3,
          marginBottom: 7,
          flexWrap: "wrap",
        }}
      >
        <StatisticsCard
          isLoading={deviceCount.isLoading}
          title="Halos Deployed"
          number={sum.numOfHalo}
          iconSrc={`${process.env.PUBLIC_URL}/icons/Halo.svg`}
        />
        <StatisticsCard
          isLoading={deviceCount.isLoading}
          title="Halos Offline"
          number={sum.numOfDisconnectedHalo}
          disconnectedClients={sum.numOfClientWithDisconnectedHalo}
          iconSrc={`${process.env.PUBLIC_URL}/icons/Halo.svg`}
        />
        <StatisticsCard
          isLoading={deviceCount.isLoading}
          title="Maxes Deployed"
          number={sum.numOfMax}
          iconSrc={`${process.env.PUBLIC_URL}/icons/Max.svg`}
        />
        <StatisticsCard
          isLoading={deviceCount.isLoading}
          title="Maxes Offline"
          number={sum.numOfDisconnectedMax}
          disconnectedClients={sum.numOfClientWithDisconnectedMax}
          iconSrc={`${process.env.PUBLIC_URL}/icons/Max.svg`}
        />
      </Stack>
      <ActionNeededTable tableRowItems={tableRowItems} setSorter={setSorter} />
      <ClientTable
        title="Other Clients"
        onSort={() =>
          setSorter((s: Sorter) =>
            s === Sorter.aToZ ? Sorter.zToA : Sorter.aToZ
          )
        }
      >
        {tableRowItems.map(({ client, property_id, property_name }) => (
          <ClientTableRow
            key={`${client.client_id}-${property_id}`}
            client={client}
            propertyId={property_id}
            propertyName={property_name}
            countIsLoading={deviceCount.isLoading}
            counts={undefined}
          />
        ))}
      </ClientTable>
    </PageContainer>
  );
}
