import React from "react";
import PageContainer from "src/components/Layout/PageContainer";
import { Page } from "../../PageController";

export type UpdateFirmwarePage = Page<{}>;

export const pageName = "update-firmware";

export const makeUpdateFirmwarePage = (): UpdateFirmwarePage => ({
  name: pageName,
  props: {},
});

export function Component() {
  return <PageContainer>change mode</PageContainer>;
}
