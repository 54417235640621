import React from "react";
import { Property } from "src/interfaces/IProperty";
import Select, { Item } from "src/components/UI/Select";
import { makeUseQuery } from "src/services/graphql/utils";
import { queryGetProperties } from "src/services/graphql/Property";
import { Client } from "src/interfaces/IClient";
import { SxProps, Theme } from "@mui/material/styles";

type PropertyId = Property["property_id"];

const usePropertyQuery = makeUseQuery(
  queryGetProperties,
  (dbname) => `${dbname}_property`
);

type Props = {
  client: Client;
  propertyId?: PropertyId;
  onChange: (pid: PropertyId) => void;
  sx?: SxProps<Theme>;
};
export function Component({ client, propertyId, onChange, sx }: Props) {
  const { data } = usePropertyQuery<Property[]>(client.client_dbname, {
    variables: {},
  });

  const handleChange = (value: "" | number) => {
    if (value !== "") {
      onChange(value);
    }
  };

  return (
    <Select
      label="Property"
      value={propertyId || ""}
      onChange={handleChange}
      sx={sx}
    >
      {(data || []).map(({ property_id, property_name }) => (
        <Item key={property_id} value={property_id}>
          {property_name}
        </Item>
      ))}
    </Select>
  );
}

export function Fake({ sx }: { sx?: SxProps<Theme> }) {
  return (
    <Select label="Property" value="" onChange={() => {}} sx={sx}>
      <Item sx={{ display: "none" }} value="" />
    </Select>
  );
}
