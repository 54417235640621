import {
  Add,
  DeleteOutline,
  EditOutlined,
  EmailOutlined,
  LocationOnOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import { ClientDetails as Client } from "src/interfaces/IClient";
import { Member } from "src/interfaces/IMember";
import { Property } from "src/interfaces/IProperty";
import { inviteUser } from "src/services/userService";
import { UserStatus } from "src/const";
import ConfirmDeletePOCModal from "./ConfirmDeletePOCModal";
import CreateUserModal from "./CreateUserModal";
import * as Nullable from "../../helper/nullable";

type Props = {
  client: Client;
  isEditing: boolean;
  handleUserCreated: (member: Member) => void;
  handleUserUnassigned: (userId: number) => void;
  properties: Property[];
};

function PointOfContact({
  client,
  isEditing,
  handleUserCreated,
  handleUserUnassigned,
  properties,
}: Props) {
  const [isCreatePOCModalOpened, setIsCreatePOCModalOpened] = useState(false);
  const [focusedMember, setFocusedMember] = useState<Nullable.T<Member>>(null);
  const [invitedIds, setInvitedIds] = useState(new Set<Number>());

  const isConfirmDeleteOpened = focusedMember !== null;

  const handleDeleteClicked = (member: Member) => {
    setFocusedMember(member);
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        margin: "2rem 0",
      }}
    >
      <Typography
        align="left"
        variant="h5"
        sx={{ fontWeight: "bold", marginBottom: "2rem" }}
      >
        Point of Contacts
      </Typography>
      <Box sx={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
        {client.members.map((member) => (
          <Paper
            key={member.user_id}
            variant="outlined"
            sx={{
              width: "100%",
              maxWidth: "15rem",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              padding: "1rem",
              margin: "0 1rem 1rem 0",
              position: "relative",
            }}
          >
            {isEditing && (
              <Box
                sx={{
                  position: "absolute",
                  top: ".5rem",
                  right: ".5rem",
                }}
              >
                <IconButton
                  edge="start"
                  size="small"
                  color="primary"
                  disabled
                  onClick={() => console.log("not implemented")}
                >
                  <EditOutlined />
                </IconButton>
                <IconButton
                  edge="start"
                  size="small"
                  color="danger"
                  onClick={() => handleDeleteClicked(member)}
                >
                  <DeleteOutline />
                </IconButton>
              </Box>
            )}
            <Typography
              variant="body2"
              sx={{ fontWeight: "bold", marginBottom: ".5rem" }}
            >
              {member.full_name}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <LocationOnOutlined
                sx={{
                  width: "1rem",
                  height: "1rem",
                  marginRight: ".25rem",
                }}
              />
              <Typography variant="body2">
                {member?.extra_data?.properties
                  ? properties
                      .filter((property: Property) =>
                        // member.extra_data.properties expected to be small, so set not neccesary
                        member.extra_data.properties.includes(
                          property.property_id
                        )
                      )
                      .map((property: Property) => property.property_name)
                      .join(", ")
                  : "not specified"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <EmailOutlined
                sx={{
                  width: "1rem",
                  height: "1rem",
                  marginRight: ".25rem",
                }}
              />
              <Typography variant="body2">
                {member.email || "Not specified"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <PhoneOutlined
                sx={{
                  width: "1rem",
                  height: "1rem",
                  marginRight: ".25rem",
                }}
              />
              <Typography variant="body2">
                {member.phone_number || "Not specified"}
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              {member.status === UserStatus.INVITED &&
              !invitedIds.has(member.user_id) ? (
                <Button
                  onClick={() => {
                    setInvitedIds(new Set(invitedIds.add(member.user_id)));
                    inviteUser(member);
                  }}
                >
                  Send Invite
                </Button>
              ) : (
                <Button disabled>Invite Sent</Button>
              )}
            </Box>
          </Paper>
        ))}
      </Box>
      {isEditing && (
        <Button
          variant="text"
          startIcon={<Add />}
          onClick={() => {
            setIsCreatePOCModalOpened(true);
          }}
        >
          Create New Point of Contacts
        </Button>
      )}
      {isCreatePOCModalOpened && (
        <CreateUserModal
          client={client}
          onClose={() => {
            setIsCreatePOCModalOpened(false);
          }}
          isOpen={isCreatePOCModalOpened}
          onUserCreated={handleUserCreated}
          properties={properties}
        />
      )}
      <ConfirmDeletePOCModal
        clientId={client.client_id}
        userId={focusedMember?.user_id}
        isOpen={isConfirmDeleteOpened}
        onClose={() => {
          setFocusedMember(null);
        }}
        onDeleted={handleUserUnassigned}
      />
    </Box>
  );
}

export default PointOfContact;
