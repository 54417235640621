import { ApolloClient } from "@apollo/client";
import { useContext, useEffect } from "react";
import { ClientContext } from "src/contexts/GqlContext";
import {
  queryGetSchedules,
  queryTurboModeSchedule,
} from "src/services/graphql/Schedule";
import _get from "lodash/get";
import { OneTimeSchedule, Schedule } from "src/interfaces/ISchedule";
import { Client, ClientDBName } from "src/interfaces/IClient";
import { Device, DeviceType } from "src/interfaces/IDevice";
import { updateDevice } from "src/services/restful/device";
import {
  MODE,
  modeToMotorSpeed,
  DesiredShadow,
  makeDesiredModeShadow,
} from "src/interfaces/IShadow";
import { deleteSchedule, deleteSchedules } from "src/services/restful/schedule";
import {
  UpdateClientProgram,
  CreateClientProgram,
  updateProgram as updateProgramApi,
  createProgram as createProgramApi,
  deleteProgram as deleteProgramApi,
  pauseProgram as pauseProgramApi,
  resumeProgram as resumeProgramApi,
} from "src/services/restful/program";
import moment from "moment";
import { mutateRemoveProgramDevice } from "src/services/graphql/Program";
import {
  makeUseQuery,
  handleErrors as handleGqlErrors,
} from "src/services/graphql/utils";
import { ProgramType, Program } from "src/interfaces/IProgram";
import { Nullable } from "src/helper";
import { axiosErrorToString } from "src/services/restful/utils";
import { Property } from "src/interfaces/IProperty";
import { queryGetDevices } from "src/services/graphql/Device";
import ErrorToast from "./ErrorToast";

import {
  TURBO_MODE_PROGRAM_NAME,
  MODE_CHANGE_PROGRAM_NAME,
  DATE_TIME_FORMAT,
} from "./const";
import { getUserTimezoneName } from "./ui/FormEdit/utils";

const useTurboModeScheduleQuery = makeUseQuery(
  queryTurboModeSchedule,
  (dbname) => `${dbname}_schedule`
);
const useTurboModeSchedule = (property: Nullable.T<Property>) => {
  const { client } = useContext(ClientContext);
  const { data, isLoading, error, refetch } = useTurboModeScheduleQuery<
    OneTimeSchedule[]
  >(client.client_dbname, {
    skip: !property,
    variables: {
      program_type: ProgramType.TURBO_MODE,
    },
  });

  useEffect(() => {
    if (error) {
      ErrorToast.emit({
        delay: "persist",
        type: "Failed",
        title: "Failed to fetch turbo mode schedule",
        content: error.message || "Unknown error",
      });
      console.error(error);
    }
  }, [error]);

  return {
    schedule: data ? data[0] : undefined,
    refetch,
    isLoading,
    hasError: !!error,
  };
};

const getDevices = (
  gqlClient: ApolloClient<object>,
  clientDBName: ClientDBName,
  property: Property
): Promise<Device[]> =>
  gqlClient
    .query({
      fetchPolicy: "network-only",
      query: queryGetDevices(clientDBName),
      variables: {
        property_id: { _eq: property.property_id },
      },
    })
    .then(handleGqlErrors)
    .then((data) => _get(data, `${clientDBName}_device`, []));

const filterPurifiers = (devices: Array<Device>) =>
  devices.filter((device) => device.device_type === DeviceType.MAX);

const stopTurboMode = (
  client: Client,
  devices: Array<Device>,
  scheduleId: number
): Promise<unknown> =>
  Promise.all([
    updateDevice(
      client.hash,
      filterPurifiers(devices).map((d) => d.device_id),
      makeDesiredModeShadow(MODE.AUTO)
    ).catch((e) => axiosErrorToString(e) || "Unknown error"),
    deleteSchedule(client.hash, scheduleId),
  ]);

const removeProgramDevice = (
  gqlClient: ApolloClient<object>,
  clientDBName: ClientDBName,
  relationship: [Program["program_id"], Device["device_id"]][]
): Promise<(undefined | { device_id: string; program_id: number })[]> =>
  Promise.all(
    relationship.map(([programId, deviceId]) =>
      gqlClient
        .mutate({
          mutation: mutateRemoveProgramDevice(clientDBName),
          variables: {
            program_id: programId,
            device_id: deviceId,
          },
        })
        .then(handleGqlErrors)
        .then((data) =>
          _get(data, `delete_${clientDBName}_program_device`, undefined)
        )
    )
  );

const createProgram = async <PT extends ProgramType>(
  client: Client,
  requestBody: CreateClientProgram
): Promise<Program<PT>> => {
  try {
    const { data: program } = await createProgramApi<ProgramType>(
      client.hash,
      requestBody
    );

    return program;
  } catch (err) {
    const message = axiosErrorToString(err as Error) || "Unknown error";
    throw new Error(message);
  }
};

const updateProgram = async <PT extends ProgramType>(
  client: Client,
  programId: number,
  requestBody: UpdateClientProgram
): Promise<Program<PT>> => {
  try {
    const { data: program } = await updateProgramApi<ProgramType>(
      client.hash,
      programId,
      requestBody
    );

    return program;
  } catch (err) {
    const message = axiosErrorToString(err as Error) || "Unknown error";
    throw new Error(message);
  }
};

const pauseProgram = async (
  client: Client,
  programId: number,
  date: Date
): Promise<Program<ProgramType.REGULAR>> => {
  try {
    const { data: program } = await pauseProgramApi(client.hash, programId, {
      pause_until: moment(date).format(DATE_TIME_FORMAT),
      timezone: getUserTimezoneName(),
    });

    return program;
  } catch (err) {
    const message = axiosErrorToString(err as Error) || "Unknown error";
    throw new Error(message);
  }
};

const resumeProgram = async (
  client: Client,
  programId: number
): Promise<Program<ProgramType.REGULAR>> => {
  try {
    const { data: program } = await resumeProgramApi(client.hash, programId);

    return program;
  } catch (err) {
    const message = axiosErrorToString(err as Error) || "Unknown error";
    throw new Error(message);
  }
};

const deleteProgram = async (
  client: Client,
  programId: number
): Promise<void> => {
  try {
    await deleteProgramApi(client.hash, programId);
  } catch (err) {
    const message = axiosErrorToString(err as Error) || "Unknown error";
    throw new Error(message);
  }
};

const startTurboMode = (
  gqlClient: ApolloClient<object>,
  client: Client,
  property: Property
): Promise<unknown> =>
  getDevices(gqlClient, client.client_dbname, property).then((devices) =>
    Promise.all([
      // Update device mode to high
      updateDevice(
        client.hash,
        filterPurifiers(devices).map((d) => d.device_id),
        makeDesiredModeShadow(MODE.HIGH)
      ).catch((e) => axiosErrorToString(e) || "Unknown error"),
      // Create program and schedule
      createProgram<ProgramType.TURBO_MODE>(client, {
        program_name: TURBO_MODE_PROGRAM_NAME(property.property_id),
        program_type: ProgramType.TURBO_MODE,
        schedules: [
          {
            starting_at: moment()
              .add(moment.duration(15, "minutes"))
              .format(DATE_TIME_FORMAT),
            desired_shadow: makeDesiredModeShadow(MODE.AUTO),
            timezone: getUserTimezoneName(),
          },
        ],
        devices: devices.map((d) => d.device_id),
      }),
    ])
  );

type ChangeModeParamsDuration = {
  hours: number;
  shadow: DesiredShadow;
};
type ChangeModeParams = {
  shadow: DesiredShadow;
  duration?: ChangeModeParamsDuration;
};

const desiredShadowHasSameKeys = (s1: DesiredShadow, s2: DesiredShadow) => {
  const keys1 = Object.keys(s1).sort();
  const keys2 = Object.keys(s2).sort();
  if (keys1.length !== keys2.length) return false;

  for (let i = 0; i < keys1.length; i += 1) {
    if (keys1[i] !== keys2[i]) {
      return false;
    }
  }

  return true;
};

/**
 * Remove schedules of the devices with same shadow key
 */
const removeSameKeySchedules = async (
  client: Client,
  gqlClient: ApolloClient<object>,
  devices: Array<Device>,
  desiredShadow: DesiredShadow
): Promise<unknown> => {
  const clientQuery = queryGetSchedules(client.client_dbname);
  // Get devices' schedules. These schedule should be one-time schedule
  const { data } = await gqlClient.query({
    fetchPolicy: "network-only",
    query: clientQuery,
    variables: {
      program_type: ProgramType.MODE_CHANGE,
      deviceIds: devices.map((d) => d.device_id),
      recurring: { _is_null: true }, // only one-time schedule
    },
  });

  const schedules: Array<Schedule> =
    (data && data[`${client.client_dbname}_schedule`]) || [];

  const schedulesToRemove = schedules.filter(
    (schedule) =>
      schedule.desired_shadow &&
      desiredShadowHasSameKeys(schedule.desired_shadow, desiredShadow)
  );

  return schedulesToRemove.length > 0
    ? deleteSchedules(
        client.hash,
        schedulesToRemove.map((s) => s.schedule_id)
      )
    : Promise.resolve();
};

const sanitizeShadow = (shadow: DesiredShadow): DesiredShadow => {
  const sanitized: DesiredShadow = { ...shadow };
  if (shadow.device_mode !== undefined) {
    return Object.assign(sanitized, makeDesiredModeShadow(shadow.device_mode));
  }
  return sanitized;
};

const changeMode = (
  client: Client,
  gqlClient: ApolloClient<object>,
  devices: Array<Device>,
  { shadow, duration }: ChangeModeParams
) => {
  const sanitizedShadow = sanitizeShadow(shadow);
  const remove = removeSameKeySchedules(
    client,
    gqlClient,
    devices,
    sanitizedShadow
  );
  const update = updateDevice(
    client.hash,
    devices.map((d) => d.device_id),
    sanitizedShadow
  );

  const promises = [remove, update];

  if (duration) {
    promises.push(
      createProgram<ProgramType.MODE_CHANGE>(client, {
        program_name: MODE_CHANGE_PROGRAM_NAME,
        program_type: ProgramType.MODE_CHANGE,
        schedules: [
          {
            starting_at: moment()
              .add(moment.duration(duration.hours, "hours"))
              .format(DATE_TIME_FORMAT),
            desired_shadow: sanitizedShadow,
            timezone: getUserTimezoneName(),
          },
        ],
      })
    );
  }

  return Promise.all(promises);
};

export type { ChangeModeParams, ChangeModeParamsDuration };

export {
  useTurboModeSchedule,
  stopTurboMode,
  startTurboMode,
  changeMode,
  updateProgram,
  createProgram,
  deleteProgram,
  pauseProgram,
  resumeProgram,
  removeProgramDevice,
};
