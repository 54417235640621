/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { Typography } from "@mui/material";
import WyndSideMenuLayout from "./components/Layout/WyndSideMenuLayout";
import {
  ClientDetailsPage,
  ClientsPage,
  FleetPage,
  LoginPage,
  NewClientPage,
  SelectClientPage,
  NewZonePage,
  ZonePage,
  GenerateCodesPage,
  PastCodesPage,
  Dashboard,
  FleetManagement,
  UsersPage,
  NewUserPage,
  SignupPage,
  IAQControlCenter,
} from "./pages";
import { AuthContext } from "./contexts/AuthContext";
import { PATHS } from "./router";
import GqlProvider from "./contexts/GqlContext";

function WyndRouter() {
  // eslint-disable-next-line no-unused-vars
  return (
    <Routes>
      <Route path={PATHS.ROOT} element={<FleetPage />} />
      <Route path={PATHS.LOGIN} element={<LoginPage />} />
      <Route path={PATHS.AUTH_CLIENTS} element={<SelectClientPage />} />
      <Route path={PATHS.SIGNUP} element={<SignupPage />} />
      <Route
        path={PATHS.CODE}
        element={
          <AuthHandler isAdminOnly>
            <GenerateCodesPage />
          </AuthHandler>
        }
      />
      <Route
        path={PATHS.CODES}
        element={
          <AuthHandler isAdminOnly>
            <PastCodesPage />
          </AuthHandler>
        }
      />
      <Route element={<WyndSideMenuLayout />}>
        <Route
          path={PATHS.CLIENTS}
          element={
            <AuthHandler isAdminOnly>
              <GqlProvider>
                <ClientsPage />
              </GqlProvider>
            </AuthHandler>
          }
        />
        <Route
          path={PATHS.CLIENTS_NEW}
          element={
            <AuthHandler isAdminOnly>
              <GqlProvider>
                <NewClientPage />
              </GqlProvider>
            </AuthHandler>
          }
        />

        <Route
          path={PATHS.CLIENT}
          element={
            <AuthHandler isAdminOnly>
              <GqlProvider>
                <ClientDetailsPage />
              </GqlProvider>
            </AuthHandler>
          }
        />
        <Route
          path={PATHS.ZONES_NEW}
          element={
            <AuthHandler isAdminOnly>
              <GqlProvider>
                <NewZonePage />
              </GqlProvider>
            </AuthHandler>
          }
        />
        <Route
          path={PATHS.ZONES}
          element={
            // use Batch mode for graphql query in this page
            // For all devices' 1min data queries can be emit at the same time
            <AuthHandler isAdminOnly>
              <GqlProvider isBatchMode>
                <ZonePage />
              </GqlProvider>
            </AuthHandler>
          }
        />
        <Route
          path={PATHS.FLEET_MANAGEMENT}
          element={
            <AuthHandler>
              <FleetManagement />
            </AuthHandler>
          }
        >
          <Route index element={null} />
          <Route path=":clientId" element={null} />
          <Route path=":clientId/*" element={null} />
        </Route>
        <Route
          path={PATHS.DASHBOARD}
          element={
            <AuthHandler isSuperuserOnly>
              <GqlProvider isBatchMode>
                <Dashboard />
              </GqlProvider>
            </AuthHandler>
          }
        />
        <Route
          path={PATHS.IAQ_CONTROL_CENTER}
          element={
            <AuthHandler>
              <IAQControlCenter />
            </AuthHandler>
          }
        />
        <Route
          path={PATHS.USERS}
          element={
            <AuthHandler isSuperuserOnly>
              <GqlProvider isBatchMode>
                <UsersPage />
              </GqlProvider>
            </AuthHandler>
          }
        />
        <Route
          path={PATHS.USERS_NEW}
          element={
            <AuthHandler isSuperuserOnly>
              <GqlProvider isBatchMode>
                <NewUserPage />
              </GqlProvider>
            </AuthHandler>
          }
        />
      </Route>
    </Routes>
  );
}

type AuthHandlerProps = {
  children: React.ReactElement;
  isSuperuserOnly?: boolean;
  isAdminOnly?: boolean;
};

function AuthHandler({
  children,
  isSuperuserOnly,
  isAdminOnly,
}: AuthHandlerProps) {
  const { user, isAuthLoading } = useContext(AuthContext);
  const location = useLocation();
  if (isAuthLoading) {
    return <Typography>Please wait . . .</Typography>;
  }
  if (!user) {
    return <Navigate to={PATHS.LOGIN} state={{ from: location }} replace />;
  }
  if (isSuperuserOnly && !user.isSuperuser) {
    return <Typography>This page is only for superuser</Typography>;
  }
  if (isAdminOnly && user.isClientSpecific) {
    return <Typography>This page is only for admin</Typography>;
  }
  return children;
}
AuthHandler.propTypes = {
  children: PropTypes.node.isRequired,
};

export default WyndRouter;
