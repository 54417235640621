// Check on this page for how to handle apollo error
// https://www.apollographql.com/docs/react/data/error-handling/
import { useCallback, useEffect, useState } from "react";
import { FetchResult, useApolloClient } from "@apollo/client";
import { DocumentNode, GraphQLError } from "graphql";
import { ClientDBName } from "src/interfaces/IClient";
import _isEqual from "lodash/isEqual";
import { Result, useIsEqual } from "../../helper";

// Unless "partial data" is enabled (which you should not use this function),
//  the response from server only contains either "data" or "errors" field.
// So this function turn them into our own Result type
// eslint-disable-next-line import/prefer-default-export
function parseFetchResult<TData>(
  result: FetchResult<TData>
): Result.T<TData, ReadonlyArray<GraphQLError>> {
  if (result.errors) {
    return Result.error(result.errors);
  }
  if (result.data) {
    return Result.ok(result.data);
  }

  return Result.error([]);
}

function errorMessages(errors: ReadonlyArray<GraphQLError>): string {
  return errors.map((e: GraphQLError) => e.message).join(" ");
}

function handleErrors<TData>(result: FetchResult<TData>): TData {
  const parsedResult = parseFetchResult(result);
  if (parsedResult.type === "Error") {
    throw new Error(errorMessages(parsedResult.value));
  }
  return parsedResult.value;
}

interface UseQuery {
  /**
   * @param clientDbName The client database name
   * @param opts The options
   * @param opts.variables
   *    If variables is undefined/null: no query will be made
   *    The variables to pass to the query. If variables are the changed, the query will be refetched
   *    The comparison is done using lodash's isEqual
   * @param opts.skip Whether to skip the query. Note that refetch isn't affected by this
   * @returns The query result
   */
  <DataT>(
    clientDbName: ClientDBName,
    opts: {
      variables?: Record<string, any>;
      skip?: boolean;
    }
  ): {
    data: undefined | DataT;
    error: undefined | Error;
    isLoading: boolean;
    /** Function to refetch the query
     * @param isCancel You can use this checker to cancel the refetch
     */
    refetch: (isCancel?: () => boolean) => Promise<void>;
  };
}

/**
 * @param query The query maker from src/services/graphql/*.ts
 * @param returningKey The key of the data returned from the query. Take a look at hasura console to find out
 * @returns The useQuery hook
 */
const makeUseQuery =
  (
    query: (dbname: ClientDBName) => DocumentNode,
    returningKey: (dbname: ClientDBName) => string
  ): UseQuery =>
  <DataT>(
    clientDbName: ClientDBName,
    opts: {
      variables?: Record<string, any>;
      skip?: boolean;
    }
  ) => {
    const [data, setData] = useState<undefined | DataT>(undefined);
    const gqlClient = useApolloClient();

    const [error, setError] = useState<undefined | Error>(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const deepComparedVariables = useIsEqual(_isEqual, opts.variables);

    const fetch = useCallback(
      async (isCancel: () => boolean, refetch: boolean = false) => {
        try {
          setIsLoading(true);
          setError(undefined);
          const res = await gqlClient.query<Record<string, DataT>>({
            query: query(clientDbName),
            variables: deepComparedVariables,
            fetchPolicy: refetch ? "network-only" : "cache-first",
          });

          if (isCancel()) {
            return;
          }
          if (res.errors) {
            throw new Error(errorMessages(res.errors));
          }
          const resultData: undefined | DataT =
            res.data && res.data[returningKey(clientDbName)];

          setData(resultData);
        } catch (e) {
          setData(undefined);
          if (e instanceof Error) {
            setError(e);
          } else {
            console.error("Unexpected exception: ", e);
            setError(new Error("Unexpected exception"));
          }
        } finally {
          setIsLoading(false);
        }
      },
      [gqlClient, clientDbName, deepComparedVariables]
    );

    useEffect(() => {
      if (!opts.skip) {
        const ref = { isCancel: false };
        fetch(() => ref.isCancel);
        return () => {
          ref.isCancel = true;
        };
      }
      return () => {};
    }, [fetch, opts.skip]);

    const refetch = useCallback(
      (ref?: () => boolean) => fetch(ref || (() => false), true),
      [fetch]
    );

    return {
      data,
      refetch,
      isLoading,
      error,
    };
  };

export { makeUseQuery, parseFetchResult, errorMessages, handleErrors };
