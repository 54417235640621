import { Record } from "immutable";

import * as IDevice from "../IDevice";
import { TypedMap, makeTypedMap } from "./TypedMap";

export type Map<Value> = TypedMap<IDevice.Device, { device_id: string }, Value>;

const keyFactory = Record({ device_id: "" });

export const Map = makeTypedMap<IDevice.Device, { device_id: string }>(
  keyFactory
);
