import React, { useState } from "react";
import { Stack, Typography, Collapse, IconButton } from "@mui/material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import ExpandLess from "@mui/icons-material/ExpandLess";
import { BORDER_COLOR_BUTTON } from "src/styles";

type Props = {
  hideButton?: boolean;
  children: React.ReactNode;
  label: React.ReactNode;
};

export default function CollapsibleBox({
  hideButton = false,
  label,
  children,
}: Props) {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Stack direction="column" gap={4}>
      <Stack direction="row" sx={{ alignItems: "flex-end" }} gap={1}>
        <Typography
          variant="h6"
          sx={{ display: "flex", alignItems: "flex-end" }}
        >
          {label}
        </Typography>
        {hideButton ? null : (
          <IconButton
            color="inherit"
            onClick={() => setCollapsed((v) => !v)}
            sx={{
              border: "1px solid",
              borderColor: BORDER_COLOR_BUTTON,
              padding: "0px",
              borderRadius: 1,
            }}
          >
            {collapsed ? (
              <ExpandMore sx={{ fontSize: "30px" }} />
            ) : (
              <ExpandLess sx={{ fontSize: "30px" }} />
            )}
          </IconButton>
        )}
      </Stack>
      <Collapse in={!collapsed}>{children}</Collapse>
    </Stack>
  );
}
