import { Grid, Button } from "@mui/material";

import React from "react";
import { MODE, modeToName, modes } from "src/interfaces/IShadow";
import Trash from "src/assets/icons/Trash";

import Select, { Item as SelectItem } from "src/components/UI/Select";

import type { ModeCycle, ModeCycleError, StartHour, EndHour } from "./types";

type ModeCyclesProps = {
  error: ModeCycleError | undefined;
  modeCycle: ModeCycle;
  onDelete: () => void;
  onChange: (modeCycle: ModeCycle) => void;
  startTimeOptions: StartHour[];
  endTimeOptions: EndHour[];
  hideDeleteButton: boolean;
};

export default function ModeCycleComponent({
  error,
  modeCycle,
  onDelete,
  onChange,
  startTimeOptions,
  endTimeOptions,
  hideDeleteButton,
}: ModeCyclesProps) {
  return (
    <Grid container direction="row" alignItems="flex-end" gap={3}>
      <Grid item xs={12} md={4}>
        <Select
          label="Mode"
          required
          fullWidth
          value={modeCycle.mode}
          onChange={(mode: MODE) => {
            onChange({ ...modeCycle, mode });
          }}
        >
          {modes.map((mode) => (
            <SelectItem key={mode} value={mode}>
              {modeToName(mode)}
            </SelectItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md>
        <Select
          label="Start Time"
          error={error?.startTime}
          required
          fullWidth
          value={modeCycle.startTime || ""}
          onChange={(startTime: StartHour | "") => {
            if (startTime !== "") {
              onChange({ ...modeCycle, startTime });
            }
          }}
        >
          {startTimeOptions.map((hour) => (
            <SelectItem key={hour} value={hour}>{`${hour}:00`}</SelectItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md>
        <Select
          label="End Time"
          error={error?.endTime}
          required
          fullWidth
          value={modeCycle.endTime || ""}
          onChange={(endTime: EndHour | "") => {
            if (endTime !== "") {
              onChange({ ...modeCycle, endTime });
            }
          }}
        >
          {endTimeOptions.map((hour) => (
            <SelectItem key={hour} value={hour}>{`${hour}:00`}</SelectItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={12} md={2}>
        {hideDeleteButton ? null : (
          <Button color="danger" onClick={onDelete} startIcon={<Trash />}>
            Delete
          </Button>
        )}
      </Grid>
    </Grid>
  );
}
