import { ProgramDisplay } from "./IProgramDisplay";
import { Schedule, OneTimeSchedule, RecurringSchedule } from "./ISchedule";

export enum ProgramType {
  TURBO_MODE = "TURBO_MODE",
  MODE_CHANGE = "MODE_CHANGE",
  REGULAR = "REGULAR",
}

export interface Program<ProgramTypeValue extends ProgramType = ProgramType> {
  program_id: number;
  program_name: string;
  program_type: ProgramType;
  schedules: Array<
    ProgramTypeValue extends ProgramType.TURBO_MODE | ProgramType.MODE_CHANGE
      ? OneTimeSchedule
      : ProgramTypeValue extends ProgramType.REGULAR
      ? RecurringSchedule
      : Schedule
  >;
  program_displays: Array<ProgramDisplay>;
  devices: Array<{
    device_id: string;
  }>;
}

export type RegularProgram = Program<ProgramType.REGULAR>;
