import { DeviceShadow } from "src/interfaces/IDevice";
import { ClientHash } from "src/interfaces/IClient";
import { DesiredShadow } from "src/interfaces/IShadow";
import { httpAuthorizedRequest } from "../httpService";

export const getClientFleet = async ({
  clientHash,
  deviceIds,
}: {
  clientHash: ClientHash;
  deviceIds?: Array<string>;
}) => {
  const params: { devices?: string } = {};
  if (deviceIds && Array.isArray(deviceIds)) {
    params.devices = deviceIds.join(",");
  }

  return httpAuthorizedRequest.get<Array<DeviceShadow>>(
    `/v1/cre/clients/${clientHash}/fleet`,
    {
      params,
    }
  );
};

export type ShadowBody = DesiredShadow;

export const updateDevice = async (
  clientHash: ClientHash,
  deviceIds: Array<string>,
  shadowBody: ShadowBody
) =>
  httpAuthorizedRequest.put(
    `/v1/cre/clients/${clientHash}/fleet?devices=${deviceIds.join(",")}`,
    JSON.stringify({
      desired_shadow: shadowBody,
    })
  );

export const toggleNightMode = async (
  hash: string,
  deviceId: string,
  desired_night_mode: boolean
) => {
  const fleetEndpoint = `${process.env.REACT_APP_API_DOMAIN}/v1/cre/clients/${hash}/fleet?devices=${deviceId}`;
  return httpAuthorizedRequest.put(fleetEndpoint, {
    desired_shadow: { night_mode: desired_night_mode },
  });
};
