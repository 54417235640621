import { Member } from "./IMember";

const clientDBNameSymbol = Symbol("symbol for client_dbname type");
export type ClientDBName = string & { _opaque: typeof clientDBNameSymbol };
// ClientDBName is a sub-type of string, but string is not a sub-type of ClientDBName
// ((v: ClientDBName) => v)("value") // <-- this will failed
// ((v: string) => v)("value" as ClientDBName) // <-- this will pass:

const clientHashNameSymbol = Symbol("symbol for Client.hash type");
export type ClientHash = string & { _opaque: typeof clientHashNameSymbol };

export interface Client {
  client_dbname: ClientDBName;
  client_id: number;
  client_name: string;
  country: string;
  hash: ClientHash;
}

export interface ClientDetails {
  client_dbname: ClientDBName;
  client_id: number;
  client_name: string;
  country: string;
  hash: ClientHash;
  members: Array<Member>;
}
