import { gql } from "@apollo/client";

const propertyQuery = (clientDBName) => gql`
  query {
    ${clientDBName}_property {
      property_name
      property_id
    }
  }
  `;

const zoneQuery = (clientDBName) => gql`
  query {
    ${clientDBName}_zone {
      zone_id
      zone_name
      property_id
      floor
    }
  }
  `;

// eslint-disable-next-line import/prefer-default-export
export { propertyQuery, zoneQuery };
