import * as IProgram from "src/interfaces/IProgram";
import { ProgramType } from "src/interfaces/IProgram";
import { ProgramDisplay } from "src/interfaces/IProgramDisplay";
import moment from "moment";
import flatten from "lodash/flatten";
import { Device } from "src/interfaces/IDevice";
import { encodeDaysOfWeek } from "src/helper/datetime";
import pullAt from "lodash/pullAt";
import isEqual from "lodash/isEqual";
import {
  CreateClientProgram,
  CreateClientProgramDisplayInProgram,
  CreateClientScheduleInProgram,
  DeleteClientProgramDisplay,
  UpdateClientProgram,
  UpdateClientProgramDisplayInProgram,
  UpdateClientScheduleInProgram,
} from "src/services/restful/program";
import { DeleteClientSchedule } from "src/services/restful/schedule";
import { MODE } from "src/interfaces/IShadow";
import { Value, ErrorObject as ItemError, validate } from "../ScheduleAndModes";

import {
  getSchedulesOfProgramDisplay,
  toScheduleAndModesValue,
} from "../../utils";
import { unpackModeCycles } from "./modeCycle";
import { DATE_TIME_FORMAT } from "../../const";

type Program = IProgram.Program<IProgram.ProgramType.REGULAR>;

type CreatedItem = {
  type: "create";
  error: ItemError;
  value: Value;
};
type UpdatedItem = {
  type: "update";
  id: ProgramDisplay["program_display_id"];
  error: ItemError;
  value: Value;
  readonly previousValue: Value;
};

type Item = CreatedItem | UpdatedItem;

const makeDefaultItem = (): CreatedItem => {
  const value: Value = {
    name: "",
    notes: "",
    modeCycles: [
      {
        mode: MODE.AUTO,
        startTime: null,
        endTime: null,
      },
    ],
    daysOfWeek: [],
  };
  return { type: "create", value, error: validate(value) };
};

const validateItems = (items: Item[]): string | "valid" => {
  if (items.some((item) => !item.error.noError())) {
    return "";
  }
  const noRepeatedDaysOfWeek = () => {
    const encodedDaysOfWeek = items.map((item) =>
      encodeDaysOfWeek(item.value.daysOfWeek)
    );

    const set = new Set(encodedDaysOfWeek);

    if (set.size !== encodedDaysOfWeek.length) {
      return "No settings with same days of week are allowed. Please remove the repeated settings and try again.";
    }

    return null;
  };
  return noRepeatedDaysOfWeek() || "valid";
};

const itemNeedToBeUpdated = (item: Item): boolean => {
  if (item.type === "create") {
    return true;
  }
  return (
    item.value.name !== item.previousValue.name ||
    item.value.notes !== item.previousValue.notes ||
    encodeDaysOfWeek(item.value.daysOfWeek) !==
      encodeDaysOfWeek(item.previousValue.daysOfWeek) ||
    item.value.modeCycles.length !== item.previousValue.modeCycles.length // <- may need to delete this program_display
  );
};

const programToItems = (program: Program): Item[] =>
  program.program_displays.map((display) => {
    const schedules = getSchedulesOfProgramDisplay(display, program.schedules);
    const value = toScheduleAndModesValue(display, schedules);
    return {
      type: "update",
      previousValue: value,
      id: display.program_display_id,
      schedules,
      value,
      error: validate(value),
    };
  });

type ProgramDisplayRequestBodies = {
  create: CreateClientProgramDisplayInProgram[];
  update: UpdateClientProgramDisplayInProgram[];
  delete: DeleteClientProgramDisplay[];
};
const itemsToProgramDisplayRequestBodies = (
  items: Item[]
): ProgramDisplayRequestBodies =>
  items.reduce(
    (acc: ProgramDisplayRequestBodies, item) => {
      if (item.type === "create") {
        if (item.value.modeCycles.length === 0)
          // This should not happen under current UI flow, but just in case.
          // It is a new item, but it has no mode cycle, so we don't need to create a program display.
          return acc;
        return {
          ...acc,
          create: [
            ...acc.create,
            {
              display_name: item.value.name,
              notes: item.value.notes || "",
              days_of_week: encodeDaysOfWeek(item.value.daysOfWeek),
            },
          ],
        };
      }
      if (item.type === "update" && itemNeedToBeUpdated(item)) {
        if (item.value.modeCycles.length === 0) {
          // The item has no mode cycle, so we need to remove this program display
          return {
            ...acc,
            delete: [...acc.delete, { program_display_id: item.id }],
          };
        }
        return {
          ...acc,
          update: [
            ...acc.update,
            {
              program_display_id: item.id,
              display_name: item.value.name,
              notes: item.value.notes || "",
              days_of_week: encodeDaysOfWeek(item.value.daysOfWeek),
            },
          ],
        };
      }
      return acc;
    },
    {
      create: [],
      update: [],
      delete: [],
    }
  );

export const getUserTimezoneName = () =>
  Intl.DateTimeFormat().resolvedOptions().timeZone;

type ScheduleRequestBodies = {
  creates: CreateClientScheduleInProgram[];
  updates: UpdateClientScheduleInProgram[];
  deletes: DeleteClientSchedule[];
};
/**
 * This function is used to convert the items to schedule requests.
 * It reuse existed schedule if possible and create new schedule if needed.
 * This way, we can avoid creating too many schedules and also more efficient.
 */
const itemsToScheduleRequests = (
  program: Program,
  items: Item[]
): ScheduleRequestBodies => {
  const recurringAndShadows = flatten(
    items.map((item) =>
      unpackModeCycles(item.value.daysOfWeek, item.value.modeCycles)
    )
  );

  let schedules = [...program.schedules];

  for (let index = 0; index < recurringAndShadows.length; index += 1) {
    // Try to find a schedule that has same recurring and desired_shadow.
    // If found, don't need to create/update a schedule for this recurringAndShadow.
    const { recurring, desired_shadow: shadow } = recurringAndShadows[index];
    const scheduleIndex = schedules.findIndex(
      (schedule) =>
        schedule.recurring === recurring &&
        isEqual(schedule.desired_shadow, shadow)
    );
    if (scheduleIndex > -1) {
      pullAt(recurringAndShadows, [index]);
      pullAt(schedules, [scheduleIndex]);
    }
  }

  const creates: CreateClientScheduleInProgram[] = [];
  const updates: UpdateClientScheduleInProgram[] = [];
  for (let i = 0; i < recurringAndShadows.length; i += 1) {
    const { recurring, desired_shadow: shadow } = recurringAndShadows[i];

    if (schedules.length > 0) {
      // There are schedules can be reused
      const schedule = schedules[0];
      schedules = schedules.slice(1);
      const update: UpdateClientScheduleInProgram = {
        schedule_id: schedule.schedule_id,
        starting_at: moment().format(DATE_TIME_FORMAT),
        timezone: getUserTimezoneName(),
        recurring,
        desired_shadow: shadow,
      };

      updates.push(update);
    } else {
      // There are no schedules can be used.
      // Create a new schedule.
      const create: CreateClientScheduleInProgram = {
        starting_at: moment().format(DATE_TIME_FORMAT),
        timezone: getUserTimezoneName(),
        recurring,
        desired_shadow: shadow,
      };
      creates.push(create);
    }
  }
  // Remove all remaining schedules.
  const deletes: DeleteClientSchedule[] = schedules.map((s) => ({
    schedule_id: s.schedule_id,
  }));
  return {
    creates,
    updates,
    deletes,
  };
};

const makeProgramUpdateRequest = (
  program: Program,
  name: string,
  items: Item[],
  devices: Device[]
): UpdateClientProgram => {
  const deviceIdsInProgram = program.devices.map((d) => d.device_id);
  const programDisplayRequestBodies = itemsToProgramDisplayRequestBodies(items);
  const scheduleRequests = itemsToScheduleRequests(program, items);

  return {
    program_type: ProgramType.REGULAR,
    program_name: name,
    create_program_displays: programDisplayRequestBodies.create,
    update_program_displays: programDisplayRequestBodies.update,
    delete_program_displays: programDisplayRequestBodies.delete,
    create_schedules: scheduleRequests.creates,
    update_schedules: scheduleRequests.updates,
    delete_schedules: scheduleRequests.deletes,
    add_device_ids: devices
      .map((d) => d.device_id)
      .filter((d) => !deviceIdsInProgram.includes(d)),
    delete_device_ids: deviceIdsInProgram.filter(
      (d) => !devices.map((device) => device.device_id).includes(d)
    ),
  };
};

const makeProgramCreateRequest = (
  name: string,
  items: CreatedItem[],
  devices: Device[]
): CreateClientProgram => {
  const recurringAndShadows = flatten(
    items.map((item) =>
      unpackModeCycles(item.value.daysOfWeek, item.value.modeCycles)
    )
  );
  const schedules: CreateClientScheduleInProgram[] = recurringAndShadows.map(
    ({ recurring, desired_shadow: shadow }) => ({
      type: "create",
      starting_at: moment.utc().format(DATE_TIME_FORMAT),
      timezone: getUserTimezoneName(),
      recurring,
      desired_shadow: shadow,
    })
  );

  const programDisplays: CreateClientProgramDisplayInProgram[] = items.map(
    (item) => ({
      display_name: item.value.name,
      notes: item.value.notes || "",
      days_of_week: encodeDaysOfWeek(item.value.daysOfWeek),
    })
  );

  return {
    program_name: name,
    program_type: ProgramType.REGULAR,
    schedules,
    program_displays: programDisplays,
    devices: devices.map((d) => d.device_id),
  };
};

export type { CreatedItem, UpdatedItem, Item };
export {
  programToItems,
  itemsToScheduleRequests,
  itemsToProgramDisplayRequestBodies,
  makeProgramUpdateRequest,
  makeProgramCreateRequest,
  makeDefaultItem,
  validateItems,
};
