import { AxiosError } from "axios";
import { useCallback, useEffect, useState } from "react";
import { PromiseHelpers } from "src/helper";
import { Client } from "src/interfaces/IClient";
import { getClients } from "src/services/restful/clients";
import { DeviceShadow, DeviceType } from "src/interfaces/IDevice";
import { Map as ImmutableMap, Set } from "immutable";
import { getClientFleet } from "src/services/restful/device";
import chunkArray from "lodash/chunk";
import { axiosErrorToString } from "src/services/restful/utils";
import * as Shadow from "../../interfaces/IShadow";
import Toast from "./ErrorToast";

type Data<V> = {
  data: V;
  isLoading: boolean;
  refetch: () => Promise<void>;
};

const useClients = (): Data<Array<Client>> => {
  const [clients, setClients] = useState<Array<Client>>([]);
  const [isLoading, setIsLoading] = useState(true);

  const queryClients = useCallback(() => {
    const handleError = (err: any) => {
      console.error(err);
      let errorMessage;
      if (err instanceof AxiosError) {
        const { code, response } = err;
        const message = (response && response.data.Message) || err.message;

        errorMessage = `[${code}] ${message}`;
      } else if (err instanceof Error) {
        errorMessage = err.message;
      } else {
        errorMessage = "Unknown error";
      }

      Toast.emit({
        delay: "persist",
        type: "Failed",
        title: "Get clients failed",
        content: errorMessage,
      });
    };
    return getClients()
      .then((res) => setClients(res.data))
      .catch(handleError);
  }, []);

  useEffect(() => {
    setIsLoading(true);
    queryClients().then(() => setIsLoading(false));
  }, [setIsLoading, queryClients]);

  return { data: clients, isLoading, refetch: queryClients };
};

// Device connection counts
namespace DeviceCount {
  export type Count = {
    withError: number;
    disconnected: number;
    /**
     * disconnected includes withError
     */
    total: number;
    /**
     * total includes disconnected
     */
  };
  const Count = () => ({
    withError: 0,
    disconnected: 0,
    total: 0,
  });

  export const add = (count1: Count, count2: Count): Count => ({
    withError: count1.withError + count2.withError,
    disconnected: count1.disconnected + count2.disconnected,
    total: count1.total + count2.total,
  });

  const updateMax = (f: (r: Count) => Count, counts: Counts) => ({
    ...counts,
    max: f(counts.max),
  });
  const updateHalo = (f: (r: Count) => Count, counts: Counts) => ({
    ...counts,
    halo: f(counts.halo),
  });

  export type Counts = {
    max: Count;
    halo: Count;
  };
  const Counts = () => ({
    max: Count(),
    halo: Count(),
  });

  type PropertyDict = ImmutableMap<
    number /* property id */,
    {
      property_name: string;
      counts: Counts;
    }
  >;
  const PropertyDict = () => ImmutableMap() as PropertyDict;
  const updatePropertyDict = (
    dict: PropertyDict,
    key: number,
    name: string,
    updater: (c: Counts) => Counts
  ) =>
    dict.update(key, { property_name: name, counts: Counts() }, (value) => ({
      ...value,
      counts: updater(value.counts),
    }));

  export type Dict = ImmutableMap<
    number /* client_id */,
    {
      client: Client;
      propertyDict: PropertyDict;
    }
  >;
  export const Dict = () => ImmutableMap() as Dict;
  const updateDict = (
    dict: Dict,
    client: Client,
    updater: (c: PropertyDict) => PropertyDict
  ) =>
    dict.update(
      client.client_id,
      { client, propertyDict: PropertyDict() },
      (value) => ({ ...value, propertyDict: updater(value.propertyDict) })
    );

  export const keepDisconnected = (dict: Dict) =>
    dict.map((subDict) =>
      subDict.propertyDict.filter(
        ({ counts }) =>
          counts.max.disconnected > 0 || counts.halo.disconnected > 0
      )
    );

  const determineShadowIsError = (shadow?: Shadow.Shadow): boolean => {
    if (shadow) {
      return Shadow.error(shadow) !== null;
    }
    // currently count as error if shadow exists
    return true;
  };

  const determineShadowIsConnected = (shadow?: Shadow.Shadow): boolean => {
    try {
      if (shadow) {
        return Shadow.connected(shadow);
      }
      return false;
    } catch (err) {
      // currently ignore error when parsing failed (use false as default)
      return false;
    }
  };

  export const insertDeviceShadowToDict = (
    dict: Dict,
    client: Client,
    deviceShadow: DeviceShadow
  ) => {
    const shadowIsError = determineShadowIsError(deviceShadow.shadow);
    const connected =
      !shadowIsError && determineShadowIsConnected(deviceShadow.shadow);
    const deviceType = deviceShadow.device_type;

    if (shadowIsError) {
      console.error(
        `shadow of device '${deviceShadow.device_id}' has something wrong`,
        deviceShadow.shadow
      );
    }

    const updater = (counts: Counts): Counts => {
      let countsUpdater;
      if (deviceType === DeviceType.HALO) {
        countsUpdater = updateHalo;
      } else if (deviceType === DeviceType.MAX) {
        countsUpdater = updateMax;
      }

      if (!countsUpdater) {
        return counts;
      }

      return countsUpdater(
        (count) => ({
          withError: shadowIsError ? count.withError + 1 : count.withError,
          total: count.total + 1,
          disconnected: connected ? count.disconnected : count.disconnected + 1,
        }),
        counts
      );
    };
    return updateDict(dict, client, (subDict) =>
      updatePropertyDict(
        subDict,
        deviceShadow.property_id,
        deviceShadow.property_name,
        updater
      )
    );
  };

  export type CountedByDeviceType = {
    numOfMax: number;
    numOfHalo: number;
    numOfDisconnectedMax: number;
    numOfDisconnectedHalo: number;
    numOfClientWithDisconnectedMax: number;
    numOfClientWithDisconnectedHalo: number;
  };
  export const countByDeviceType = (dict: Dict): CountedByDeviceType => {
    const temp = dict.reduce(
      (cacc, { client, propertyDict }) =>
        propertyDict.reduce(
          (pacc, { counts }) => ({
            ...pacc,
            numOfMax: pacc.numOfMax + counts.max.total,
            numOfHalo: pacc.numOfHalo + counts.halo.total,
            numOfDisconnectedMax:
              pacc.numOfDisconnectedMax + counts.max.disconnected,
            numOfDisconnectedHalo:
              pacc.numOfDisconnectedHalo + counts.halo.disconnected,
            numOfClientWithDisconnectedMax:
              counts.max.disconnected > 0
                ? pacc.numOfClientWithDisconnectedMax.add(client.client_id)
                : pacc.numOfClientWithDisconnectedMax,

            numOfClientWithDisconnectedHalo:
              counts.halo.disconnected > 0
                ? pacc.numOfClientWithDisconnectedHalo.add(client.client_id)
                : pacc.numOfClientWithDisconnectedHalo,
          }),
          cacc
        ),
      {
        numOfMax: 0,
        numOfHalo: 0,
        numOfDisconnectedMax: 0,
        numOfDisconnectedHalo: 0,
        numOfClientWithDisconnectedMax: Set<number>(),
        numOfClientWithDisconnectedHalo: Set<number>(),
      }
    );

    return {
      ...temp,
      numOfClientWithDisconnectedMax: temp.numOfClientWithDisconnectedMax.size,
      numOfClientWithDisconnectedHalo:
        temp.numOfClientWithDisconnectedHalo.size,
    };
  };

  type GetFleetOfClients = (
    onClientError: (Client: Client, error: string) => void,
    clients: Array<Client>,
    callback: (client: Client, deviceShadow: DeviceShadow) => void
  ) => Promise<void>;

  const getFleetOfClients: GetFleetOfClients = async (
    onClientError,
    clients,
    callback
  ) =>
    Promise.all(
      clients.map((client) =>
        getClientFleet({ clientHash: client.hash })
          .then((res) => res.data as Array<DeviceShadow>)
          .catch((err) => {
            console.error(err);
            const message = axiosErrorToString(err as Error);
            onClientError(client, message || "Unexpected Error");
            return [];
          })
          .then((deviceShadows) =>
            deviceShadows.forEach((deviceShadow) =>
              callback(client, deviceShadow)
            )
          )
      )
    ).then(() => undefined);

  // use chunk + mapArraySeries so we don't fire too much query at the same time
  const CHUNK_SIZE = 1;
  const getFleetOfClientsByChunks: GetFleetOfClients = async (
    onClientError,
    clients,
    callback
  ) => {
    const clientChunks: Array<Array<Client>> = chunkArray(clients, CHUNK_SIZE);
    return PromiseHelpers.mapArraySeries(clientChunks, (cs: Array<Client>) =>
      getFleetOfClients(onClientError, cs, callback)
    ).then(() => undefined);
  };

  export const useDict = (clients: Array<Client>): Data<Dict> => {
    const [dict, setDict] = useState<Dict>(Dict());
    const [isLoading, setIsLoading] = useState(false);

    const query = useCallback(() => {
      Toast.clearAll();

      const onError = (client: Client, message: string) => {
        Toast.emit({
          delay: "persist",
          type: "Failed",
          title: `Client '${client.client_name}' devices error`,
          content: message,
        });
      };

      const callback = (client: Client, deviceShadow: DeviceShadow) => {
        setDict((d) => insertDeviceShadowToDict(d, client, deviceShadow));
      };

      return getFleetOfClientsByChunks(onError, clients, callback);
    }, [clients]);

    useEffect(() => {
      setIsLoading(true);
      query().finally(() => setIsLoading(false));
    }, [query]);

    return {
      data: dict,
      isLoading,
      refetch: query,
    };
  };
}

export { useClients, DeviceCount };
