import React from "react";
import { Stack, Button as MuiButton, Typography } from "@mui/material";
import { WYND_WHITE } from "src/styles";

type ButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
};
function Button({ children, onClick }: ButtonProps) {
  return (
    <MuiButton
      variant="outlined"
      color="info"
      onClick={onClick}
      sx={{
        backgroundColor: "white",
      }}
    >
      {children}
    </MuiButton>
  );
}

type Props = {
  isOpen: boolean;
  selectedCount: number;
  onCancel: () => void;
  onReset: () => void;
  onUpdateFirmware?: () => void;
  onCreateProgram?: () => void;
  onChangeMode: () => void;
};

export default function Controls({
  isOpen,
  selectedCount,
  onCancel,
  onReset,
  onUpdateFirmware,
  onCreateProgram,
  onChangeMode,
}: Props) {
  return (
    <Stack
      direction="row"
      sx={{
        visibility: isOpen ? "visible" : "hidden",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: WYND_WHITE,
        position: "sticky",
        borderRadius: "4px",
        transform: `translateX(-12px)`,
        bottom: "32px",
        paddingLeft: 3,
        paddingTop: 1.5,
        paddingRight: 3,
        paddingBottom: 1.5,
      }}
    >
      <Stack direction="row" sx={{ alignItems: "center" }} spacing={1.5}>
        <Typography>{selectedCount} Selected</Typography>
        <MuiButton color="primary" onClick={() => onReset()}>
          <Typography variant="body2">Clear Selection</Typography>
        </MuiButton>
      </Stack>
      <Stack
        direction="row"
        sx={{ justifyContent: "flex-end", alignItems: "center" }}
        gap={2}
      >
        <Button onClick={onCancel}>Cancel</Button>
        {onUpdateFirmware && (
          <Button onClick={onUpdateFirmware}>Update Firmware</Button>
        )}
        {onCreateProgram && (
          <Button onClick={onCreateProgram}>Add New Schedule</Button>
        )}
        <Button onClick={onChangeMode}>Change Mode</Button>
      </Stack>
    </Stack>
  );
}
