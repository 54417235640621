import { gql } from "@apollo/client";
import { ClientDBName } from "src/interfaces/IClient";

const program = `
  program_id
  program_name
  program_type
  program_displays {
    days_of_week
    display_name
    notes
    program_display_id
    program_id
  }
  schedules {
    schedule_id
    schedule_name
    starting_at
    recurring
    schedule_arn
    desired_shadow
  }

  devices {
    device_id
  }
`;

// Delete can't be done in bulk
// So be careful when using this in non-batch mode
export const mutateRemoveProgramDevice = (clientDBName: ClientDBName) => gql`
  mutation DeleteProgramDevice($program_id: Int!, $device_id: String!) {
    delete_${clientDBName}_program_device_by_pk(program_id: $program_id, device_id: $device_id) {
      program_id
      device_id
    }
  }
`;

export const getProgramsByDevices = (clientDBName: ClientDBName) => gql`
  query GetProgramByDevices($device_ids: [String!]!, $program_type: program_type!) {
    ${clientDBName}_program(where: {devices: {device_id: {_in: $device_ids}}, program_type: {_eq: $program_type}}) {
      ${program}
    }
  }
`;
