import {
  Add,
  ArrowBack,
  DeleteOutline,
  Edit,
  EditOutlined,
  LocationOnOutlined,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, {
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import { Property } from "src/interfaces/IProperty";
import { Client } from "src/interfaces/IClient";
import { Member } from "src/interfaces/IMember";
import PageContainer from "src/components/Layout/PageContainer";
import { ClientContext } from "../../contexts/GqlContext";
import ConfirmDeletePropertyModal from "./ConfirmDeletePropertyModal";
import EditClientForm from "./EditClientForm";
import EditPropertyModal from "./EditPropertyModal";
import NewPropertyModal from "./NewPropertyModal";
import PointOfContact from "./PointOfContact";
import { queryGetProperties } from "../../services/graphql/Property";
import * as Nullable from "../../helper/nullable";

const usePropertyQuery = (): [Array<Property>, () => Promise<void>] => {
  const {
    client: { client_dbname: clientDBName },
  } = useContext(ClientContext);
  const [properties, setProperties] = useState([]);
  const gqlClient = useApolloClient();

  const fetchAndSaveProperties = useCallback(async () => {
    if (clientDBName) {
      try {
        const clientQuery = queryGetProperties(clientDBName);
        const { data } = await gqlClient.query({
          query: clientQuery,
        });
        if (data) {
          setProperties(data[`${clientDBName}_property`]);
        }
      } catch (e) {
        console.log(e);
      }
    }
  }, [setProperties, clientDBName, gqlClient]);

  useEffect(() => {
    fetchAndSaveProperties();
  }, [fetchAndSaveProperties]);

  return [properties, fetchAndSaveProperties];
};

export default function ClientDetailsPage() {
  const [searchParams] = useSearchParams();
  const { client, setClient } = useContext(ClientContext);
  const [properties, refetchProperty] = usePropertyQuery();
  const [isEditing, setIsEditing] = useState(false);
  const [isNewPropertyModalOpen, setIsNewPropertyModalOpen] = useState(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] =
    useState(false);
  const [isEditPropertyModalOpened, setIsEditPropertyModalOpened] =
    useState(false);
  const [focusedProperty, setFocusedProperty] =
    useState<Nullable.T<number>>(null);
  const navigate = useNavigate();

  const onPropertyAdded = () => {
    refetchProperty();
    setIsNewPropertyModalOpen(false);
  };

  const handleClientUpdated = (newClient: Client) => {
    setIsEditing(false);
    setClient((oldClient) => ({ ...oldClient, ...newClient }));
  };

  const handleDeleteClicked = (propertyId: number) => {
    setFocusedProperty(propertyId);
    setIsConfirmDeleteModalOpen(true);
  };
  const handlePropertyRemoved = () => {
    refetchProperty();
    setIsConfirmDeleteModalOpen(false);
  };

  const handlePropertyEditClicked = (propertyId: number) => {
    setFocusedProperty(propertyId);
    setIsEditPropertyModalOpened(true);
  };
  const handlePropertyEdited = () => {
    refetchProperty();
    setIsEditPropertyModalOpened(false);
  };

  const handlePOCCreated = (pocUser: Member) => {
    setClient((oldClient) => ({
      ...oldClient,
      members: [pocUser, ...client.members],
    }));
  };

  const handlePOCUnassigned = (userId: number) => {
    setClient((oldClient) => ({
      ...oldClient,
      members: [...oldClient.members.filter((m) => m.user_id !== userId)],
    }));
  };

  useEffect(() => {
    if (searchParams.get("isEditing") && client) {
      setIsEditing(true);
    }
  }, [client, searchParams]);

  return (
    <PageContainer>
      <>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 5,
          }}
        >
          <IconButton onClick={() => navigate(-1)}>
            <ArrowBack sx={{ width: "32px", height: "32px" }} />
          </IconButton>
          <Typography variant="h3" sx={{ margin: "0 1rem" }}>
            {client.client_name} Account Info
          </Typography>
          {!isEditing && (
            <Button
              onClick={() => {
                setIsEditing(true);
              }}
              variant="outlined"
              startIcon={<Edit />}
            >
              Edit Account Info
            </Button>
          )}
        </Box>
        <Box
          sx={{
            margin: "2rem 0",
          }}
        >
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: "2rem" }}
          >
            Organization Info
          </Typography>
          <EditClientForm
            client={client}
            isEditing={isEditing}
            onClose={() => setIsEditing(false)}
            handleClientUpdated={handleClientUpdated}
          />
        </Box>
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            margin: "2rem 0",
          }}
        >
          <Typography
            align="left"
            variant="h5"
            sx={{ fontWeight: "bold", marginBottom: "2rem" }}
          >
            Properties
          </Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              marginBottom: "1rem",
              flexWrap: "wrap",
            }}
          >
            {properties.map((property) => (
              <Paper
                key={property.property_id}
                variant="outlined"
                sx={{
                  width: "100%",
                  maxWidth: "30rem",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  padding: "1rem",
                  margin: "0 1rem 1rem 0",
                  position: "relative",
                }}
              >
                {isEditing && (
                  <Box
                    sx={{
                      position: "absolute",
                      top: ".5rem",
                      right: ".5rem",
                    }}
                  >
                    <IconButton
                      color="primary"
                      onClick={() => {
                        handlePropertyEditClicked(property.property_id);
                      }}
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      color="danger"
                      onClick={() => handleDeleteClicked(property.property_id)}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Box>
                )}
                <Typography
                  variant="body2"
                  sx={{ fontWeight: "bold", marginBottom: ".5rem" }}
                >
                  {property.property_name}
                </Typography>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <LocationOnOutlined
                    sx={{
                      width: "1rem",
                      height: "1rem",
                      marginRight: ".25rem",
                    }}
                  />
                  <Typography variant="body2">
                    {property.property_address || "Not specified"}
                  </Typography>
                </Box>
              </Paper>
            ))}
          </Box>
          {isEditing && (
            <Button
              onClick={() => {
                setIsNewPropertyModalOpen(true);
              }}
              variant="text"
              startIcon={<Add />}
            >
              Add Property
            </Button>
          )}
        </Box>
        <Divider />
        <PointOfContact
          client={client}
          isEditing={isEditing}
          handleUserCreated={handlePOCCreated}
          handleUserUnassigned={handlePOCUnassigned}
          properties={properties}
        />
      </>

      {isNewPropertyModalOpen && (
        <NewPropertyModal
          isOpen={isNewPropertyModalOpen}
          onClose={() => {
            setIsNewPropertyModalOpen(false);
          }}
          onPropertyAdded={onPropertyAdded}
        />
      )}
      {isConfirmDeleteModalOpen && (
        <ConfirmDeletePropertyModal
          isOpen={isConfirmDeleteModalOpen}
          onClose={() => {
            setIsConfirmDeleteModalOpen(false);
          }}
          onPropertyRemoved={handlePropertyRemoved}
          property={properties.find((x) => x.property_id === focusedProperty)}
        />
      )}
      {isEditPropertyModalOpened && (
        <EditPropertyModal
          isOpen={isEditPropertyModalOpened}
          onClose={() => {
            setIsEditPropertyModalOpened(false);
          }}
          onPropertyEdited={handlePropertyEdited}
          property={properties.find((x) => x.property_id === focusedProperty)}
        />
      )}
    </PageContainer>
  );
}
