import {
  Add,
  ArrowBack,
  DeleteOutline,
  FileCopyOutlined,
  Upload,
  VisibilityOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { createSearchParams, useNavigate } from "react-router-dom";
import { createClient } from "../../services/clientService";
import { PATHS, resolvePath } from "../../router";
import { Country } from "../../const";
import PageContainer from "../../components/Layout/PageContainer";

export default function NewClientPage() {
  const [country, setCountry] = useState("");
  const [clientName, setClientName] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [deploymentDate, setDeploymentDate] = useState(
    moment().format("yyyy-MM-DD")
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleCreateClient = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await createClient({
        client_name: clientName,
        country,
      });
      if (response.status >= 200 && response.status <= 299) {
        // eslint-disable-next-line no-unused-vars
        const clientId = response.data.client_id;
        navigate(
          {
            pathname: resolvePath(PATHS.CLIENT, { ":clientId": clientId }),
            search: `?${createSearchParams({ isEditing: true })}`,
          },
          { replace: true }
        );
      }
    } catch (err) {
      console.log(err);
      setErrorMessage(err.response.data.Message);
    }
    setIsSubmitting(false);
  };

  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack width={{ width: "32px", height: "32px" }} />
        </IconButton>
        <Typography variant="h3" sx={{ margin: "0 0 0 1rem" }}>
          Add New Client
        </Typography>
      </Box>
      <form onSubmit={handleCreateClient}>
        <Box
          sx={{
            display: "flex",
            margin: "2rem 0",
          }}
        >
          <Typography
            align="left"
            variant="body1"
            sx={{ fontWeight: "bold", width: "10rem" }}
          >
            Organization Info
          </Typography>
          <Grid sx={{ flex: "1", marginLeft: "1rem" }} container spacing={2}>
            <Grid item sm={12} md={4}>
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="select-country">Select Country</InputLabel>
                <Select
                  sx={{ textAlign: "left" }}
                  value={country}
                  labelId="select-country"
                  label="Select Country"
                  onChange={(e) => setCountry(e.target.value)}
                >
                  {Object.values(Country)
                    .sort()
                    .map((countryName) => (
                      <MenuItem value={countryName}>{countryName}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item sm={12} md={8}>
              <TextField
                required
                fullWidth
                value={clientName}
                onChange={(e) => setClientName(e.target.value)}
                placeholder="Organization Name"
                label="Organization Name"
              />
            </Grid>
          </Grid>
        </Box>
        {errorMessage !== null && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <Divider />
        {/* might use later */}
        {false && (
          <>
            <Box
              sx={{
                display: "flex",
                margin: "2rem 0",
              }}
            >
              <Typography
                align="left"
                variant="body1"
                sx={{ fontWeight: "bold", width: "10rem" }}
              >
                Property Info
              </Typography>
              <Grid
                sx={{ flex: "1", marginLeft: "1rem" }}
                container
                spacing={2}
              >
                <Grid item sm={12}>
                  <Typography
                    align="left"
                    sx={{ fontWeight: "bold" }}
                    variant="body2"
                  >
                    Property 1
                  </Typography>
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    required
                    fullWidth
                    placeholder="Property Name"
                    label="Property Name"
                  />
                </Grid>
                <Grid item sm={12} md={8}>
                  <TextField
                    required
                    fullWidth
                    placeholder="Address 1"
                    label="Address 1"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    fullWidth
                    placeholder="Address 2 (Optional)"
                    label="Address 2 (Optional)"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField fullWidth placeholder="City" label="City" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField fullWidth placeholder="State" label="State" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    fullWidth
                    placeholder="Postal Code"
                    label="Postal Code"
                  />
                </Grid>
                <Grid container item sm={12} justifyContent="flex-start">
                  <Button variant="text" startIcon={<Add />}>
                    Add Property
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                margin: "2rem 0",
              }}
            >
              <Typography
                align="left"
                variant="body1"
                sx={{ fontWeight: "bold", width: "10rem" }}
              >
                {" "}
                Point of Contact
              </Typography>
              <Grid
                container
                sx={{ flex: "1", marginLeft: "1rem" }}
                spacing={2}
              >
                <Grid item sm={12}>
                  <Typography
                    align="left"
                    sx={{ fontWeight: "bold" }}
                    variant="body2"
                  >
                    Point of Contact #1
                  </Typography>
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    required
                    fullWidth
                    placeholder="Property Name"
                    label="Property Name"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    required
                    fullWidth
                    placeholder="First Name"
                    label="First Name"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    required
                    fullWidth
                    placeholder="Last Name"
                    label="Last Name"
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField fullWidth placeholder="Email" label="Email" />
                </Grid>
                <Grid item sm={12} md={4}>
                  <TextField
                    required
                    fullWidth
                    placeholder="Phone Number"
                    label="Phone Number"
                  />
                </Grid>
                <Grid container item sm={12} justifyContent="flex-start">
                  <Button variant="text" startIcon={<Add />}>
                    Add Point of Contact
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                margin: "2rem 0",
              }}
            >
              <Typography
                align="left"
                variant="body1"
                sx={{ fontWeight: "bold", width: "10rem" }}
              >
                Billing Info
              </Typography>
              <Grid
                container
                sx={{ flex: "1", marginLeft: "1rem" }}
                spacing={2}
              >
                <Grid item sm={12} md={4}>
                  <TextField
                    type="date"
                    value={deploymentDate}
                    onChange={(e) => setDeploymentDate(e.target.value)}
                    required
                    fullWidth
                    placeholder="Deployment Date"
                    label="Deployment Date"
                  />
                </Grid>
                <Box width="100%" />
                <Grid item container alignItems="center" sm={12} md={4}>
                  <Paper
                    sx={{ padding: ".5rem", width: "100%", display: "flex" }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "center",
                        marginRight: ".5rem",
                      }}
                    >
                      <FileCopyOutlined />
                      <Typography variant="caption"> 44 KB</Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                      }}
                    >
                      <Typography
                        align="left"
                        variant="body2"
                        sx={{ margin: ".25rem" }}
                      >
                        File Name.pdf
                      </Typography>
                      <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                        <Button
                          size="small"
                          variant="outlined"
                          color="primary"
                          startIcon={<VisibilityOutlined fontSize="small" />}
                          sx={{ margin: ".25rem" }}
                        >
                          View
                        </Button>
                        <Button
                          size="small"
                          variant="outlined"
                          color="danger"
                          startIcon={<DeleteOutline fontSize="small" />}
                          sx={{ margin: ".25rem" }}
                        >
                          Delete
                        </Button>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
                <Grid container item sm={12} justifyContent="flex-start">
                  <Button variant="text" startIcon={<Upload />}>
                    Upload File
                  </Button>
                </Grid>
              </Grid>
            </Box>
            <Divider />
          </>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "3rem",
          }}
        >
          <Button variant="contained" type="submit" disabled={isSubmitting}>
            Add New Client
          </Button>
          {isSubmitting && (
            <Typography variant="h6">
              Please wait. This can take up to one minute. Do not leave this
              page.
            </Typography>
          )}
        </Box>
      </form>
    </PageContainer>
  );
}
