// This Context Provider will see if clientId exists (by parsing  url).
// If exists, all graphql request will has
//    1. "x-hasura-role" set to corresponding role (except admin)
//    2. client saved in context
import { setContext } from "@apollo/client/link/context";
import { CircularProgress, Typography } from "@mui/material";
import axios from "axios";
import React, {
  createContext,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from "react";
import {
  ApolloClient,
  ApolloProvider,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import type { ApolloClient as ApolloClientT } from "@apollo/client";
import { BatchHttpLink } from "@apollo/client/link/batch-http";

import { useParams } from "react-router-dom";
import { getClientDetails } from "../services/clientService";
import { AUTH_TOKEN, HASURA_URL, HASURA_ROLE_HEADER_KEY } from "../const";
import * as Nullable from "../helper/nullable";
import { isAdminToken, decodeHasuraRoles } from "../services/authService";
import type {
  ClientDetails as Client,
  ClientDBName,
} from "../interfaces/IClient";

export type ErrorDisplayProps = {
  code?: string;
  message?: string;
};
export function ErrorDisplay({ code, message }: ErrorDisplayProps) {
  return (
    <Typography color="error">
      {code ? `[${code}] ` : ""}Error: {message || "Unexpected Error"}
    </Typography>
  );
}

export type GqlClient = ApolloClientT<NormalizedCacheObject>;

const cacheMemory = new InMemoryCache();

function makeAuthLink(clientDBName?: ClientDBName) {
  return setContext((_, { headers }) => {
    const token = localStorage.getItem(AUTH_TOKEN);
    const ctx = {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
      },
    };
    if (clientDBName) {
      const hasuraRole = `${clientDBName}_user`;
      try {
        if (
          token &&
          // admin role should always be the x-hasura-default-role
          // so no need to use x-hasura-role header
          !isAdminToken(token) &&
          decodeHasuraRoles(token).includes(hasuraRole)
        )
          ctx.headers[HASURA_ROLE_HEADER_KEY] = hasuraRole;
      } catch (err) {
        console.error(err);
      }
    }
    return ctx;
  });
}

export function makeGqlClient(clientDBName?: ClientDBName): GqlClient {
  const httpLink = createHttpLink({
    uri: HASURA_URL,
  });

  const authLink = makeAuthLink(clientDBName);

  return new ApolloClient({
    link: authLink.concat(httpLink),
    cache: cacheMemory,
  });
}

const BATCH_MAX = 10;
const BATCH_INTERVAL = 100;

export function makeBatchGqlClient(
  clientDBName?: ClientDBName,
  batchOptions?: BatchHttpLink.Options
) {
  const batchHttpLink = new BatchHttpLink({
    uri: HASURA_URL,
    batchMax: BATCH_MAX,
    batchInterval: BATCH_INTERVAL,
    ...batchOptions,
  });
  const authLink = makeAuthLink(clientDBName);

  return new ApolloClient({
    link: authLink.concat(batchHttpLink),
    cache: cacheMemory,
  });
}

type ClientContextValue = {
  client: Client;
  setClient: (f: (c: Client) => Client) => void;
};

const ClientContext: React.Context<ClientContextValue> = createContext(
  {
    client: undefined,
    setClient: () => {
      throw new Error(
        "Context not initiated. Do you forget to give a provider?"
      );
    },
  } as unknown as ClientContextValue /* omit type check */
);

type Props = {
  children: ReactNode;
  isBatchMode?: boolean;
  noLoadingAnimation?: boolean;
};

// The client query in this page is suggested to put into SSR in the future
function GqlProvider({
  children,
  isBatchMode = false,
  noLoadingAnimation = false,
}: Props) {
  const { clientId }: { clientId?: string } = useParams();

  const [gqlClient, setGqlClient] = useState<Nullable.T<GqlClient>>(null);
  const [client, setClient] = useState<Nullable.T<Client>>(null);
  const [error, setError] = useState<ErrorDisplayProps | null>(null);

  useEffect(() => {
    const makeClient = isBatchMode ? makeBatchGqlClient : makeGqlClient;
    const makeGqlWithClient = async () => {
      try {
        const response = await getClientDetails(clientId);
        const clientData = response.data as Client;
        setClient(() => clientData);
        setGqlClient(makeClient(clientData.client_dbname));
      } catch (err) {
        if (axios.isAxiosError(err)) {
          setError({ code: err.code, message: err.message });
        } else {
          setError({
            code: undefined,
            message: undefined,
          });
        }
      }
    };
    const makeGqlWithoutClient = () => {
      setClient(null);
      setGqlClient(makeClient());
    };

    if (!clientId) {
      makeGqlWithoutClient();
    } else {
      makeGqlWithClient();
    }
  }, [clientId, isBatchMode]);

  const clientContextValue = useMemo(() => {
    if (client) {
      return {
        client,
        setClient: (f: (c: Client) => Client) => setClient(Nullable.map(f)),
      };
    }
    return null;
  }, [client, setClient]);

  if (error) {
    return <ErrorDisplay code={error?.code} message={error?.message} />;
  }
  if (gqlClient && !clientId) {
    // no clientId provided
    return <ApolloProvider client={gqlClient}>{children}</ApolloProvider>;
  }
  if (gqlClient && clientId && clientContextValue) {
    // clientId is provided and client is ready
    return (
      <ApolloProvider client={gqlClient}>
        <ClientContext.Provider value={clientContextValue}>
          {children}
        </ClientContext.Provider>
      </ApolloProvider>
    );
  }

  return noLoadingAnimation ? null : <CircularProgress size={12} />;
}

export { ClientContext };
export default GqlProvider;
