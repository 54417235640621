import { Box, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { BORDER_COLOR_BUTTON } from "src/styles";
import { DayOfWeek, daysOfWeek } from "src/helper/datetime";

// TODO: Auto generate daysOfWeek by user's locale
export const dayOfWeekToString = (wd: DayOfWeek) => {
  if (wd === 1) return "Su";
  if (wd === 2) return "M";
  if (wd === 3) return "T";
  if (wd === 4) return "W";
  if (wd === 5) return "Th";
  if (wd === 6) return "F";
  if (wd === 7) return "Sa";
  throw RangeError("dayOfWeek value should be between 0 - 6");
};

type DayOfWeekAvatarProps = {
  dayOfWeek: DayOfWeek;
  isSelected: boolean;
  isEditing: boolean;
  onClick: () => void;
};

function DayOfWeekAvatar({
  dayOfWeek,
  isEditing,
  isSelected,
  onClick,
}: DayOfWeekAvatarProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: isEditing || isSelected ? "flex" : "none",
        cursor: isEditing ? "pointer" : "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: isSelected
          ? theme.palette.primary.main
          : BORDER_COLOR_BUTTON,
        borderRadius: "100%",
        width: "40px",
        height: "40px",
      }}
      onClick={onClick}
    >
      <Typography variant="subtitle2" color="white">
        {dayOfWeekToString(dayOfWeek)}
      </Typography>
    </Box>
  );
}

interface Props {
  isEditing: boolean;
  selected: Array<DayOfWeek>;
  onChange?: (selected: Array<DayOfWeek>) => void;
}

export default function DayOfWeekPicker({
  isEditing,
  selected,
  onChange,
}: Props) {
  return (
    <Stack direction="row" gap={2}>
      {daysOfWeek.map((dayOfWeek) => (
        <DayOfWeekAvatar
          key={dayOfWeek}
          isEditing={isEditing}
          isSelected={selected.includes(dayOfWeek)}
          dayOfWeek={dayOfWeek}
          onClick={() => {
            if (isEditing && onChange) {
              if (!selected.includes(dayOfWeek)) {
                onChange([...selected, dayOfWeek]);
              } else {
                onChange(selected.filter((d) => d !== dayOfWeek));
              }
            }
          }}
        />
      ))}
    </Stack>
  );
}
