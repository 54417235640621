import React from "react";
import { FormControl, Stack } from "@mui/material";
import Select, { Item } from "src/components/UI/Select";
import InfoTooltip from "src/components/UI/InfoTooltip";

function intToInt(startRaw: number, endRaw: number): Array<number> {
  if (endRaw < startRaw) {
    throw new Error("start is larger than end");
  }

  const results = [];

  let v = Math.round(startRaw);
  const end = Math.round(endRaw);
  while (v <= end) {
    results.push(v);
    v += 1;
  }
  return results;
}

const durations = [0.5, ...intToInt(1, 24)];

type Props = {
  hours: number;
  onSelect: (hours: number) => void;
  description?: string;
};
export default function DurationSelector({
  hours,
  description,
  onSelect,
}: Props) {
  const handleChange = (value: any) => {
    const n = Number(value);
    if (value !== "" && !Number.isNaN(n)) onSelect(n);
  };
  return (
    <FormControl>
      <Select
        value={hours.toString()}
        onChange={handleChange}
        label={
          <Stack direction="row" alignItems="center" gap={0.5}>
            Duration
            <InfoTooltip title="Duration" content={description} />
          </Stack>
        }
        MenuProps={{
          sx: { maxHeight: "337px" },
        }}
      >
        {durations.map((duration) => (
          <Item value={duration} key={duration}>
            {duration}
            {duration === 1 ? " hour" : " hours"}
          </Item>
        ))}
      </Select>
    </FormControl>
  );
}
