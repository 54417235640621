import { Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Button,
  Container,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import React, { useContext, useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import { AUTH_TOKEN, COPY_SLOGAN } from "../../const";
import { AuthContext } from "../../contexts/AuthContext";
import { login } from "../../services/authService";
import { PATHS } from "../../router";

function LoginPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [formError, setFormError] = useState(null);
  const auth = useContext(AuthContext);

  const location = useLocation();
  const from = location.state?.from?.pathname || PATHS.CLIENTS;

  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    const credential = { email, password };
    try {
      const response = await login(credential);
      const { token } = response.data;
      localStorage.setItem(AUTH_TOKEN, token);
      auth.signin(token);
      navigate(from, { replace: true });
    } catch (err) {
      switch (err.name) {
        case "AxiosError":
          setFormError(err.response?.data?.Message || "Unexpected Error");
          break;
        case "TokenExpiredError":
          setFormError("Token Expired");
          break;
        default:
          throw err;
      }
    }
  };
  const { user } = useContext(AuthContext);
  if (user) {
    return <Navigate to={PATHS.CLIENTS} replace />;
  }
  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <form onSubmit={handleLogin}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Logo sx={{ marginBottom: "2rem" }} />
          <Typography align="left" variant="p">
            {COPY_SLOGAN}
          </Typography>
          <Typography align="left" variant="h1">
            Log In
          </Typography>
          <TextField
            variant="outlined"
            sx={{ marginBottom: "1rem" }}
            type="text"
            placeholder="Email/User ID"
            label="Email/User ID"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <FormControl sx={{ marginBottom: "1rem" }} variant="outlined">
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              label="Password"
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {formError !== null && (
              <FormHelperText error>{formError}</FormHelperText>
            )}
          </FormControl>
          <Button type="submit" variant="contained" size="large">
            Log In
          </Button>
        </Box>
      </form>
    </Container>
  );
}

export default LoginPage;
