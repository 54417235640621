import React from "react";
import { Box, Stack, Typography, useTheme } from "@mui/material";
import { Nullable } from "src/helper";
import { BORDER_COLOR_BUTTON } from "src/styles";
import InfoTooltip from "src/components/UI/InfoTooltip";

type OptionProps<V> = {
  label: string;
  selected: V;
  onClick: () => void;
};
function Option<V>({ label, selected, onClick }: OptionProps<V>) {
  const theme = useTheme();
  return (
    <Stack
      onClick={onClick}
      sx={[
        {
          cursor: "pointer",
          padding: "14px 38px",
          border: "1px solid",
          marginLeft: "-1px", // prevent border overlap
          marginTop: "-1px", // prevent border overlap
          borderColor: BORDER_COLOR_BUTTON,
          alignItems: "center",
          justifyContent: "center",
        },
        selected
          ? {
              color: "white",
              backgroundColor: theme.palette.primary.main,
            }
          : {},
      ]}
    >
      {label}
    </Stack>
  );
}

type LabelProps = {
  label: string;
  htmlFor?: string;
  description?: string;
};

function Label({ htmlFor, label, description }: LabelProps) {
  return (
    <Typography
      component="label"
      htmlFor={htmlFor}
      variant="subtitle2"
      sx={{
        marginBottom: 4,
        display: "flex",
        alignItems: "center",
        gap: "6px",
      }}
    >
      {label}
      <InfoTooltip title={label} content={description} />
    </Typography>
  );
}

export type Props<V> = {
  value: Nullable.T<V>;
  options: Array<{
    name: string;
    value: V;
  }>;
  onSelect: (v: V) => void;
  label?: string;
  description?: string;
};
export default function TabSelect<V>({
  description,
  label,
  value,
  options,
  onSelect,
}: Props<V>) {
  return (
    <Stack direction="column" sx={{ alignItems: "flex-start" }}>
      {label ? <Label label={label} description={description} /> : null}
      <Box
        sx={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill,minmax(139px, 1fr))",
          width: "100%",
        }}
      >
        {options.map((option) => (
          <Option
            key={option.name}
            label={option.name}
            selected={value === option.value}
            onClick={() => {
              onSelect(option.value);
            }}
          />
        ))}
      </Box>
    </Stack>
  );
}
