import React from "react";
import { Card, Grid, Typography, Divider } from "@mui/material";
import { DM_DARK_GREY, MESSAGING_SUCCESS } from "src/styles";

type AirExchangeWidgetProps = {
  air_exchanges: number;
  average_air_exchanges: number;
  sx?: object;
};

const AirExchangeWidget = ({
  air_exchanges,
  average_air_exchanges,
  sx,
}: AirExchangeWidgetProps) => (
  <Card sx={{ padding: 2, height: "100%", ...sx }}>
    <Grid container direction="row">
      <Grid
        item
        xs={5}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Typography variant="subtitle4" color={DM_DARK_GREY}>
          Additional HEPA Air Exchange (last 30 days)
        </Typography>
        <Typography variant="subtitle2">{air_exchanges}M ft3</Typography>
      </Grid>
      <Grid item xs={2} style={{ display: "flex", justifyContent: "center" }}>
        <Divider orientation="vertical" />
      </Grid>
      <Grid
        item
        xs={5}
        container
        direction="column"
        justifyContent="space-between"
      >
        <Typography variant="h7" color={MESSAGING_SUCCESS}>
          + {average_air_exchanges} ACH
        </Typography>
        <Typography variant="body3" color={DM_DARK_GREY}>
          equivalent energy saved by using HEPA filter to augment HVAC operation
        </Typography>
      </Grid>
    </Grid>
  </Card>
);

export default AirExchangeWidget;
