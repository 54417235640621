import { createTheme, ThemeOptions } from "@mui/material/styles";
import CheckCircle from "@mui/icons-material/CheckCircle";
import Warning from "@mui/icons-material/Warning";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import Typography from "@mui/material/Typography";
import React from "react";

// Merge customized typography variant into mui's Typography
// Look styles.js for detail implementation
declare module "@mui/material/Typography" {
  interface TypographyPropsVariantOverrides {
    h7: true;
    subtitle3: true;
    subtitle4: true;
    body3: true;
    body4: true;
  }
}

declare module "@mui/material/styles" {
  interface TypographyVariants {
    h7: React.CSSProperties;
    subtitle3: React.CSSProperties;
    subtitle4: React.CSSProperties;
    body3: React.CSSProperties;
    body4: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    h7?: React.CSSProperties;
    subtitle3?: React.CSSProperties;
    subtitle4?: React.CSSProperties;
    body3?: React.CSSProperties;
    body4?: React.CSSProperties;
  }
}

declare module "@mui/material/styles/createPalette" {
  interface Palette {
    danger: Palette["primary"];
  }
  interface PaletteOptions {
    danger: {
      main: Palette["primary"]["main"];
      contrastText: Palette["primary"]["contrastText"];
    };
  }
}

declare module "@mui/material" {
  interface ButtonPropsColorOverrides {
    danger: true;
  }
}
declare module "@mui/material" {
  interface LinearProgressPropsColorOverrides {
    danger: true;
  }
}
declare module "@mui/material" {
  interface IconButtonPropsColorOverrides {
    danger: true;
  }
}

export const WYND_WHITE = "#EDF1F4";
export const DM_COLOR_BG = "#1E2024";
export const DM_COLOR_ACCENT = "#2DCCD3";
export const DM_COLOR_TEXT = "#CCCCDB";
export const BORDER_COLOR_BUTTON = "#CFCFCF";
export const BORDER_COLOR_CARD = "#F2F2F2";
export const ACTIVE_GREEN = "#00A04A";
export const INACTIVE_RED = "#E34850";
export const DM_DARK_GREY = "#455558";

export const MESSAGING_SUCCESS = "#00A04A";
export const MESSAGING_WARNING = "#E68619";
export const MESSAGING_ERROR = "#E34850";

const typography: ThemeOptions["typography"] = {
  h1: {
    fontSize: "40px",
    fontWeight: "800",
    lineHeight: "48px",
    letterSpacing: "0em",
    marginTop: 32,
    marginBottom: 40,
  },
  h2: {
    fontSize: "28px",
    fontWeight: "900",
    lineHeight: "25px",
    letterSpacing: "0.02em",
    marginBottom: 24,
  },
  h3: {
    fontSize: "40px",
    fontWeight: "900",
    lineHeight: "48px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  h6: {
    fontSize: "28px",
    fontWeight: "800",
    lineHeight: "33.6px",
  },
  h7: {
    fontWeight: "900",
    fontSize: "24px",
    lineHeight: "28.8px",
  },
  subtitle1: {
    fontSize: "24px",
    fontWeight: "700",
    lineHeight: "28.8px",
    letterSpacing: "5%",
  },
  subtitle2: {
    fontSize: "16px",
    fontWeight: "700",
    lineHeight: "19.2px",
  },
  subtitle3: {
    fontSize: "14px",
    fontWeight: "700",
    lineHeight: "16.8px",
  },
  subtitle4: {
    fontSize: "12px",
    fontWeight: "700",
    lineHeight: "14.4px",
  },
  body2: {
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "16.8px",
    letterSpacing: "4%",
  },
  body3: {
    fontSize: "12px",
    fontWeight: "400",
    lineHeight: "14.4px",
  },
  // for the rest of typography style, some browser restrict minimal fontsize (chrome: 12px).
  // So just use the body3 and transform them if you need
  body4: {
    fontSize: "10px",
    fontWeight: "400",
    lineHeight: "12px",
  },
  // body5: {
  //   fontSize: "8px",
  //   fontWeight: "400",
  //   lineHeight: "9.6px",
  // },
  button: {
    textTransform: "none",
  },
};

export const lightTheme = createTheme({
  typography,
  palette: {
    mode: "light",
    primary: {
      //      main: '#FFB266', // orange
      main: "#009AAF", // wynd teal
      light: "#2DCCD3",
      contrastText: "#FFFFFF",
    },
    danger: {
      main: "#D02D2D",
      contrastText: "#FFFFFF",
    },
    action: {
      active: "#009AAF", // wynd teal
      //      active: '#D02D8F', // purple
      selected: "#00FFFF", // teal
    },
    background: {
      default: "#FBFCFD", // light grey
      //      default: '#382DD0', // blue
      paper: "#FFFFFF",
      //      paper: '#CAD02D', // yellow
    },
    info: {
      main: DM_COLOR_TEXT,
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        outlinedInfo: {
          border: "1px solid",
          borderColor: BORDER_COLOR_BUTTON,
          color: DM_DARK_GREY,
        },
        containedInfo: {
          backgroundColor: "white",
          border: `1px solid ${BORDER_COLOR_BUTTON}`,
          color: DM_DARK_GREY,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          position: "relative",
          padding: "32px",
          rowGap: "24px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          paddingTop: "0px",
          padding: "0px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          paddingTop: "0px",
          padding: "0px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0px",
        },
      },
    },
    MuiAlert: {
      defaultProps: {
        iconMapping: {
          error: React.createElement(Warning, {
            sx: { color: INACTIVE_RED },
          }),
          success: React.createElement(CheckCircle, {
            sx: { color: ACTIVE_GREEN },
          }),
        },
      },
      styleOverrides: {
        root: {
          alignItems: "center",
          boxShadow: "0px 3px 1px 0px #0000000A, 0px 3px 8px 0px #0000001F",
        },
        standardSuccess: {
          background: "#FFFFFF",
          borderLeft: "solid 4px",
          borderColor: ACTIVE_GREEN,
        },
        standardError: {
          background: "#FFFFFF",
          borderLeft: "solid 4px",
          borderColor: INACTIVE_RED,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        outlined: {
          fontSize: "14px",
          height: "17px",
          lineHeight: "16.8px",
          padding: "16px 12px 15px 12px",
          minHeight: "unset",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          backgroundColor: "white",
          fontSize: "14px",
          height: "17px",
          lineHeight: "16.8px",
          padding: "16px 12px 15px 12px",
          minHeight: "unset",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: "14px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          ...typography.subtitle3,
        },
        root: {
          padding: "15px, 24px, 16px, 24px",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          ...typography.body2,
          backgroundColor: WYND_WHITE,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        inputRoot: {
          paddingTop: "3px",
          paddingBottom: "3px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "none",
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: "#F2F2F2",
          borderRadius: "12px",
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          height: "4px",
          borderRadius: "3px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          minWidth: "48px",
          padding: "12px 4px",
          minHeight: "unset",
          fontWeight: 500,
        },
      },
    },
  },
});

export const darkTheme = createTheme({
  typography,
  palette: {
    mode: "dark",
    primary: {
      //      main: '#FFB266', // orange
      main: "#009AAF", // wynd teal
      light: "#2DCCD3",
      contrastText: "#FFFFFF",
    },
    danger: {
      main: "#D02D2D",
      contrastText: "#FFFFFF",
    },
    action: {
      active: "#009AAF", // wynd teal
      //      active: '#D02D8F', // purple
      selected: "#00FFFF", // teal
    },
    background: {
      default: "#000000", // wynd black
      //      default: '#382DD0', // blue
      paper: "#26282E", // yellow
      //      paper: '#CAD02D', // yellow
    },
  },
});
