/* eslint-disable @typescript-eslint/no-unused-vars */
import Box from "@mui/material/Box";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CircularProgress } from "@mui/material";
import { useAtomicVar } from "src/helper";

// functions

function makeArray<V>(size: number, maker: (i: number) => V): Array<V> {
  const result = [];
  for (let index = 0; index < size; index += 1) {
    result.push(maker(index));
  }
  return result;
}

function useAtomicList<V>(): {
  pop: () => Promise<undefined | V>;
  setList: (newList: Array<V>) => void;
} {
  const updateList = useAtomicVar<Array<V>>([]);

  const pop = useCallback(
    () =>
      new Promise((resolve: (v: undefined | V) => void) => {
        const updater = (l: V[]) => {
          const [hd, tl] = [l[0], l.slice(1)];
          resolve(hd);
          return tl;
        };
        updateList(updater);
      }),
    [updateList]
  );

  const setList = useCallback(
    (newList: Array<V>) => {
      const updater = () => newList;
      updateList(updater);
    },
    [updateList]
  );

  return {
    pop,
    setList,
  };
}

function updateArrayAt<El>(
  arr: Array<El>,
  at: number,
  updater: (v: El) => El
): Array<El> {
  if (arr.length === 0 || at < 0) {
    console.warn("Index for update array is out of range");
    return arr;
  }
  const head = arr[0];
  if (at > 0) {
    return [head].concat(updateArrayAt(arr.slice(1), at - 1, updater));
  }
  return [updater(head)].concat(arr.slice(1));
}

// Components

type ObserversElProps = {
  index: number;
  onObserved: (index: number) => Promise<boolean>; // if true: observation should stop
};

function ObserversEl({ index, onObserved }: ObserversElProps) {
  const elRef = useRef(null);
  const longRef = useRef(null);
  const [stopObserve, setStopObserve] = useState(false);
  useEffect(() => {
    const el = elRef.current;
    const long = longRef.current;
    if (el && long && !stopObserve) {
      const callback = (v: IntersectionObserverEntry[]) => {
        const isIntersecting = v && v[0] && v[0].isIntersecting;
        if (isIntersecting) {
          // this will trigger observer remount and try to observe again
          // It prevent freezing when newly appended item is not high enough to push observer out of window
          setStopObserve(true);
          onObserved(index).then(setStopObserve);
        }
      };
      const options = {
        threshold: [1, 0.5, 0],
        rootMargin: "0px 0px 200px 0px",
      };

      const observer = new IntersectionObserver(callback, options);

      observer.observe(el);
      observer.observe(long);
      return () => {
        observer.unobserve(el);
        observer.unobserve(long);
      };
    }
    return undefined;
  }, [index, onObserved, stopObserve]);

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flex: 1,
        flexDirection: "column",
        alignSelf: "flex-end",
      }}
    >
      <Box
        ref={elRef}
        sx={{
          height: "50px",
          width: "50px",
          flexGrow: 0,
        }}
      >
        {stopObserve || <CircularProgress size="12px" />}
      </Box>
      <Box
        ref={longRef}
        sx={{
          width: "50px",
          flexGrow: 1,
        }}
      />
    </Box>
  );
}

type Props = {
  children: Array<React.ReactElement>;
  colNum: number;
};

export default function UIMasonry({
  children,
  colNum: colContainerNum,
}: Props) {
  const colNum = Math.min(React.Children.count(children), colContainerNum);
  const [columns, setColumns] = useState<Array<Array<React.ReactElement>>>(
    makeArray(colNum, () => [])
  );
  const [observers, setObservers] = useState<React.ReactNode[]>(
    makeArray(colNum, () => null)
  );
  const { pop, setList: setElements } = useAtomicList<React.ReactElement>();

  const observeCallback = useCallback(
    async (index: number) => {
      const reactEl = await pop();
      if (reactEl) {
        setColumns((rEls) =>
          updateArrayAt(rEls, index, (column) => [reactEl].concat(column))
        );
        return false;
      }
      return true;
    },
    [pop, setColumns]
  );

  useEffect(() => {
    setColumns(makeArray(colNum, () => []));
    setElements(children);
    setObservers(
      makeArray(colNum, (index) => (
        <ObserversEl
          key={Math.random()} // work around when children changes, force observer remount to trigger observe event
          index={index}
          onObserved={observeCallback}
        />
      ))
    );
  }, [setElements, children, colNum, observeCallback]);

  return (
    <Box
      sx={{
        display: "flex",
        gap: 4.5,
        minHeight: "100vh",
        gridTemplateColumns: `repeat(${colContainerNum}, 1fr)`,
      }}
    >
      {makeArray(colNum, (index) => (
        <Box
          key={index}
          sx={{
            display: "flex",
            // use column-reverse so page won't stick to bottom when new item is inserted
            // https://stackoverflow.com/a/44051405
            flexDirection: "column-reverse",
            gap: 4.5,
            position: "relative",
            flex: 1,
          }}
        >
          {observers[index]}
          {columns[index]}
        </Box>
      ))}

      {
        // Dummy blocks: help to keep size of each column
        makeArray(colContainerNum - colNum, () => (
          <Box sx={{ flex: 1 }} />
        ))
      }
    </Box>
  );
}
