import React from "react";
import { Card, CircularProgress, Box, Typography } from "@mui/material";
import {
  DM_DARK_GREY,
  MESSAGING_ERROR,
  MESSAGING_SUCCESS,
  MESSAGING_WARNING,
  WYND_WHITE,
} from "src/styles";
import { WyndScoreRatingThreshold } from "src/const";

type CleanAirZonesWidgetProps = {
  value: number;
  sx?: object;
};

const CleanAirZonesWidget = ({ value, sx }: CleanAirZonesWidgetProps) => {
  const levelColor =
    value > WyndScoreRatingThreshold.FANTASTIC
      ? MESSAGING_SUCCESS
      : value > WyndScoreRatingThreshold.FAIR
      ? MESSAGING_WARNING
      : MESSAGING_ERROR;
  return (
    <Card sx={{ padding: 2, height: "100%", ...sx }}>
      <Typography variant="subtitle4" color={DM_DARK_GREY}>
        Clean Air Zones
      </Typography>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
      >
        <Typography variant="subtitle1">{value}%</Typography>
        <Box position="relative">
          <CircularProgress
            variant="determinate"
            value={100}
            size={70}
            thickness={22}
            sx={{ color: WYND_WHITE }}
          />
          <CircularProgress
            variant="determinate"
            value={value}
            size={70}
            thickness={22}
            sx={{ color: levelColor, position: "absolute", left: 0 }}
          />
        </Box>
      </Box>
    </Card>
  );
};

export default CleanAirZonesWidget;
