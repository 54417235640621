import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import React from "react";

export default function PageContainer({
  children,
  isLoading = false,
}: {
  children: React.ReactNode;
  isLoading?: boolean;
}) {
  return (
    <Box
      sx={{
        padding: {
          sm: "1rem 2rem",
          md: "4.5rem",
        },
      }}
    >
      {isLoading ? <LinearProgress /> : children}
    </Box>
  );
}
