import React, { useContext, useState } from "react";
import { Map } from "immutable";
import {
  Stack,
  Tabs,
  Tab,
  SvgIconProps,
  Button,
  Divider,
  Typography,
  Collapse,
  Menu,
  MenuItem,
  useTheme,
} from "@mui/material";
import {
  DeviceType,
  Device,
  deviceTypeToPluralName,
} from "src/interfaces/IDevice";
import Max from "src/assets/icons/Max";
import Halo from "src/assets/icons/Halo";
import Filters from "src/assets/icons/Filters";
import { Zone } from "src/interfaces/IZone";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DM_DARK_GREY } from "src/styles";
import DeviceCard from "src/components/UI/DeviceCard";
import RelocateDeviceModal from "src/components/UI/RelocateDeviceModal";
import RemoveDeviceModal from "src/components/UI/RemoveDeviceModal";
import { Nullable } from "src/helper";
import { ClientContext } from "src/contexts/GqlContext";
import EditFloorModal from "src/components/UI/EditFloorModal";
import EditZoneModal from "src/components/UI/EditZoneModal";
import type { Shadow } from "../../interfaces/IShadow";
import * as IShadow from "../../interfaces/IShadow";
import { Context as SelectionCtx } from "./DeviceSelection";
import { Device as DeviceWithShadow } from "./types";
import Toast from "./ErrorToast";

type TabObject = {
  label: string;
  deviceType: DeviceType;
  iconComponent: React.FC<SvgIconProps>;
};

type DeviceId = string;

type DeviceShadowMap = Map<DeviceId, Shadow>;

// hooks

// Tab
const tabObjects: Array<TabObject> = [
  {
    deviceType: DeviceType.MAX,
    label: "Max",
    iconComponent: Max,
  },
  { deviceType: DeviceType.HALO, label: "Halo", iconComponent: Halo },
];

type DeviceTypeTabsProps = {
  tab: DeviceType;
  setTab: React.Dispatch<React.SetStateAction<DeviceType>>;
};
function DeviceTypeTabs({ tab, setTab }: DeviceTypeTabsProps) {
  const handleChange = (event: React.SyntheticEvent, newValue: DeviceType) => {
    setTab(newValue);
  };
  return (
    <Tabs value={tab} onChange={handleChange}>
      {tabObjects.map(({ deviceType, label, iconComponent }) => (
        <Tab
          key={label}
          label={label}
          value={deviceType}
          icon={React.createElement(iconComponent, {
            color: tab === deviceType ? "primary" : "disabled",
          })}
          iconPosition="start"
        />
      ))}
    </Tabs>
  );
}

// Floor
type FloorProps = {
  floor: string;
  zoneCount: number;
  onMoreAction: (anchorEl: HTMLElement) => void;
  children: React.ReactNode;
};
function Floor({ floor, zoneCount, onMoreAction, children }: FloorProps) {
  const [expanded, setExpanded] = useState(true);
  const handleClickMoreAction = (e: React.MouseEvent<HTMLElement>) => {
    onMoreAction(e.currentTarget);
  };

  return (
    <Stack direction="column">
      <Stack
        sx={{
          display: "inline-flex",
          flexDirection: "row",
          alignItems: "flex-end",
          marginBottom: 3,
        }}
      >
        <Typography variant="h6" sx={{ marginRight: 0.5 }}>
          {floor}
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{ marginRight: 1.5, color: DM_DARK_GREY }}
        >
          ({zoneCount})
        </Typography>
        <Stack direction="row" sx={{ height: "32px" }}>
          <Button
            variant="outlined"
            color="info"
            sx={{ marginRight: 2 }}
            onClick={handleClickMoreAction}
          >
            More Actions
          </Button>
          <Button
            variant="outlined"
            color="info"
            onClick={() => setExpanded((v) => !v)}
            sx={{
              padding: 1,
              minWidth: "unset",
              width: "32px",
              height: "32px",
            }}
          >
            {expanded ? <ExpandMoreIcon /> : <ExpandLessIcon />}
          </Button>
        </Stack>
      </Stack>
      <Collapse in={expanded}>{children}</Collapse>
    </Stack>
  );
}

// Zone
type ZoneProps = {
  zone: Zone;
  deviceCount: number;
  onSelectAllDevices: () => void;
  onMoreAction: (anchorEl: HTMLElement) => void;
  children: React.ReactNode;
};
function ZoneComponent({
  zone,
  deviceCount,
  onSelectAllDevices,
  onMoreAction,
  children,
}: ZoneProps) {
  const handleClickMoreAction = (e: React.MouseEvent<HTMLElement>) => {
    onMoreAction(e.currentTarget);
  };

  return (
    <Stack direction="row">
      <Stack
        direction="column"
        sx={{ gap: 2, flex: "0 0 288px", alignItems: "flex-start" }}
      >
        <Stack direction="row" sx={{ alignItems: "flex-end" }}>
          <Typography variant="subtitle1" sx={{ marginRight: 0.5 }}>
            {zone.zone_name}
          </Typography>
          <Typography variant="subtitle3" color={DM_DARK_GREY}>
            ({deviceCount})
          </Typography>
        </Stack>
        <Button variant="outlined" color="info" onClick={handleClickMoreAction}>
          More Actions
        </Button>
        <Button color="primary" onClick={() => onSelectAllDevices()}>
          Select All in This Zone
        </Button>
      </Stack>
      {children}
    </Stack>
  );
}

// DeviceList
function zonesOfFloor(floor: string, zones: Array<Zone>) {
  return zones.filter((z) => z.floor === floor);
}

function devicesOfZone(zone: Zone, devices: Array<Device>) {
  return devices.filter((d) => d.zone_id === zone.zone_id);
}

/**
 * @param type
 * @param onEdit: on user click edit floor/zone
 * @returns [menu element, open menu function]
 */
const useMoreActionsMenu = <Value extends string | Zone>(
  type: "Floor" | "Zone",
  onEdit: (v: Value) => void
): [JSX.Element, (anchorEl: HTMLElement, value: Value) => void] => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = useState<Nullable.T<HTMLElement>>(null);
  const [value, setValue] = useState<Nullable.T<Value>>(null);

  const menuElement = (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={() => setAnchorEl(null)}
      sx={{ "& > .MuiPaper-root": { minWidth: theme.spacing(21) } }}
    >
      <MenuItem
        onClick={() => {
          if (value) onEdit(value);
          setAnchorEl(null);
        }}
      >
        {`Edit ${type}`}
      </MenuItem>
      {/* TODO: <MenuItem onClick={() => {}}>Floor Plan</MenuItem> */}
      {/* TODO: <MenuItem onClick={() => {}}>View Data</MenuItem> */}
    </Menu>
  );

  return [
    menuElement,
    (el: HTMLElement, v: Value) => {
      setAnchorEl(el);
      setValue(v);
    },
  ];
};

type Props = {
  floors: Array<string>;
  zones: Array<Zone>;
  devices: Array<Device>;
  deviceShadowMap: DeviceShadowMap;
  onRemoveDevice: () => Promise<void>;
  onRelocateDevice: () => Promise<void>;
  onZoneChange: () => Promise<void>;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function DeviceList({
  floors,
  zones,
  devices: devicesExt,
  deviceShadowMap,
  onRemoveDevice,
  onRelocateDevice,
  onZoneChange,
}: Props) {
  const [tab, setTab] = useState(DeviceType.MAX);
  const { client } = useContext(ClientContext);
  const { select, deselect, isSelected } = useContext(SelectionCtx);

  const [deviceToRemove, setDeviceToRemove] =
    useState<Nullable.T<Device>>(null);
  const [deviceToRelocate, setDeviceToRelocate] =
    useState<Nullable.T<Device>>(null);
  const [floorOfDeviceToRelocate, setFloorOfDeviceToRelocate] = useState<
    "" | string
  >("");

  const devices = devicesExt.filter((d) => d.device_type === tab);

  const handleSelectDevices = (ds: Array<Device>) => {
    select(
      ds.reduce((acc: Array<DeviceWithShadow>, d: Device) => {
        const shadow = deviceShadowMap.get(d.device_id);
        if (shadow && IShadow.connected(shadow)) {
          acc.push({
            shadow,
            ...d,
          });
        }
        return acc;
      }, [])
    );
  };
  const handleCheck = (device: Device) => {
    select([
      {
        shadow: deviceShadowMap.get(device.device_id),
        ...device,
      },
    ]);
  };
  const handleUncheck = (device: Device) => {
    deselect(device);
  };

  // Floor
  const [floorToEdit, setFloorToEdit] = useState<Nullable.T<string>>(null);
  const [floorMenuElement, handleFloorMoreAction] = useMoreActionsMenu(
    "Floor",
    (floor: string) => {
      setFloorToEdit(floor);
    }
  );

  // Zone
  const [zoneToEdit, setZoneToEdit] = useState<Nullable.T<Zone>>(null);
  const [zoneMenuElement, handleZoneMoreAction] = useMoreActionsMenu(
    "Zone",
    (zone: Zone) => {
      setZoneToEdit(zone);
    }
  );

  // Notifications
  const emitDeleteSuccessNotification = (
    titleLabel: string,
    valueLabel: string
  ) =>
    Toast.emit({
      delay: 8000,
      type: "Success",
      title: `${titleLabel} Deleted`,
      content: `"${valueLabel}" has been successfully deleted. All the existing devices on this floor would be moved to unassigned.`,
    });

  const emitDeleteFailedNotification = (
    titleLabel: string,
    valueLabel: string
  ) =>
    Toast.emit({
      delay: "persist",
      type: "Failed",
      title: `Failed to Delete the ${titleLabel}`,
      content: `We are unable to delete "${valueLabel}". Please try again.`,
    });

  return (
    <Stack>
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <DeviceTypeTabs tab={tab} setTab={setTab} />
        <Button color="primary" startIcon={<Filters />}>
          <Typography variant="subtitle4">Filter Devices</Typography>
        </Button>
      </Stack>
      <Divider />
      <Stack
        direction="row"
        spacing={0.5}
        sx={{
          marginTop: 3,
          marginBottom: 4,
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Typography>
          Select device(s) to change fan mode, add new schedules, and update
          firmware.
        </Typography>
        <Button color="primary" onClick={() => handleSelectDevices(devices)}>
          Select All {deviceTypeToPluralName(tab)}
        </Button>
      </Stack>
      <Stack direction="column" sx={{ gap: "30px" }}>
        {[...floors].sort().map((floor) => {
          const zonesOfThisFloor = zonesOfFloor(floor, zones);
          return (
            <Floor
              key={floor}
              floor={floor}
              zoneCount={zonesOfThisFloor.length}
              onMoreAction={(domEl) => handleFloorMoreAction(domEl, floor)}
            >
              <Stack direction="column" gap={3.5}>
                {[...zonesOfThisFloor]
                  .sort((z1, z2) => z1.zone_name.localeCompare(z2.zone_name))
                  .map((zone) => {
                    const devicesOfThisZone = devicesOfZone(zone, devices);
                    return (
                      <ZoneComponent
                        key={zone.zone_id}
                        zone={zone}
                        deviceCount={devicesOfThisZone.length}
                        onMoreAction={(domEl) =>
                          handleZoneMoreAction(domEl, zone)
                        }
                        onSelectAllDevices={() =>
                          handleSelectDevices(devicesOfThisZone)
                        }
                      >
                        <Stack
                          direction="row"
                          gap={3}
                          sx={{ flexWrap: "wrap" }}
                        >
                          {devicesOfThisZone.map((device) => {
                            const shadow = deviceShadowMap.get(
                              device.device_id
                            );
                            const connectedParser = IShadow.ignoreError(
                              IShadow.connected,
                              {
                                fallback: false,
                              }
                            );
                            const connected = shadow && connectedParser(shadow);

                            return (
                              <DeviceCard
                                key={device.device_id}
                                device={device}
                                shadow={shadow}
                                zone={zone}
                                checkable={{
                                  checked: isSelected(device),
                                  disabled: !connected,
                                  onCheck: (bool: boolean) =>
                                    (bool ? handleCheck : handleUncheck)(
                                      device
                                    ),
                                }}
                                editable={{
                                  onRelocate: () => {
                                    setDeviceToRelocate(device);
                                    setFloorOfDeviceToRelocate(floor);
                                  },
                                  onRemove: () => setDeviceToRemove(device),
                                }}
                              />
                            );
                          })}
                        </Stack>
                      </ZoneComponent>
                    );
                  })}
              </Stack>
            </Floor>
          );
        })}
      </Stack>
      <RemoveDeviceModal
        isOpen={deviceToRemove !== null}
        onClose={() => setDeviceToRemove(null)}
        device={deviceToRemove}
        clientDBName={client.client_dbname}
        onRemove={() => onRemoveDevice()}
      />
      <RelocateDeviceModal
        isOpen={deviceToRelocate !== null && floorOfDeviceToRelocate !== null}
        onClose={() => {
          setDeviceToRelocate(null);
          setFloorOfDeviceToRelocate("");
        }}
        device={deviceToRelocate}
        client={client}
        floor={floorOfDeviceToRelocate}
        onDeviceChange={() => onRelocateDevice()}
      />
      <EditFloorModal
        onClose={() => setFloorToEdit(null)}
        floorToEdit={floorToEdit}
        floors={floors}
        onDeleteSuccessCallback={() => {
          emitDeleteSuccessNotification("Floor", floorToEdit ?? "");
          return onZoneChange;
        }}
        onDeleteFailedCallback={() =>
          emitDeleteFailedNotification("Floor", floorToEdit ?? "")
        }
        onEditSuccessCallback={onZoneChange}
      />
      <EditZoneModal
        onClose={() => setZoneToEdit(null)}
        zoneToEdit={zoneToEdit}
        zones={zones}
        onDeleteSuccessCallback={() => {
          emitDeleteSuccessNotification("Zone", zoneToEdit?.zone_name ?? "");
          return onZoneChange();
        }}
        onDeleteFailedCallback={() =>
          emitDeleteFailedNotification("Zone", zoneToEdit?.zone_name ?? "")
        }
        onEditSuccessCallback={onZoneChange}
      />
      {floorMenuElement}
      {zoneMenuElement}
    </Stack>
  );
}

export type { DeviceShadowMap };
export default DeviceList;
