/**
 * Generate simple Cron string for AWS EventBridge scheduler
 * Following this https://docs.aws.amazon.com/AmazonCloudWatch/latest/events/ScheduledEvents.html
 */
import { DayOfWeek, daysOfWeek as allDaysOfWeek } from "../datetime";
import { Range, generateIntegers } from "../number";

class ParseCronStringError extends Error {}

const checkRange = (v: number, range: [number, number]) => {
  if (v > range[1] || v < range[0]) {
    throw new ParseCronStringError(
      `value '${v}' is not in range ${range[0]} - ${range[1]}`
    );
  }
};

type Ranges<Num extends number> = [Num, Num];
const rangeToString = <Num extends number>([start, end]: Ranges<Num>) =>
  `${start}-${end}`;
const rangesFromString = <Num extends number>(
  s: string,
  range?: [number, number]
): undefined | Ranges<Num> => {
  const rangesMatched = s.match(/([0-9]?)-([0-9]?)/);
  if (rangesMatched) {
    const start = Number(rangesMatched[1]);
    const end = Number(rangesMatched[2]);
    if (range) {
      checkRange(start, range);
      checkRange(end, range);
    }

    return [start as Num, end as Num] as Ranges<Num>;
  }

  return undefined;
};

type All = "*";
const all: All = "*";

type OneOf = "?";
const oneOf: OneOf = "?";

type Value<Num extends number> = Num | Ranges<Num>;
const valueToString = <Num extends number>(v: Value<Num>) => {
  if (Array.isArray(v) && v.length === 2) {
    return rangeToString(v);
  }
  return v;
};
const valuesToString = <Num extends number>(vs: Value<Num>[] | All | OneOf) => {
  if (vs === all) return "*";
  if (vs === oneOf) return "?";
  return vs.map(valueToString).join(",");
};

const numFromString = <Num extends number>(
  s: string,
  range?: [number, number]
): undefined | Num => {
  const num = Number(s);
  if (Number.isNaN(num)) {
    return undefined;
  }
  if (range) {
    checkRange(num, range);
  }

  return num as Num;
};

const valueFromString = <Num extends number>(
  s: string,
  range?: [number, number]
): Value<Num> => {
  const n = numFromString<Num>(s, range);
  if (n !== undefined) {
    return n;
  }

  const ranges = rangesFromString<Num>(s, range);
  if (ranges) {
    return ranges;
  }

  throw new ParseCronStringError(`cannot parse '${s}'`);
};

export type Minute = Range<0, 60>;
export type Hour = Range<0, 24>;
export type Dom = Range<1, 32>;
export type Month = Range<1, 13>;

type CronMinute = All | (Minute | Ranges<Minute>)[];
type CronHour = All | (Hour | Ranges<Hour>)[];
type CronDay = All | OneOf | (Dom | Ranges<Dom>)[];
type CronMonth = All | (Month | Ranges<Month>)[];
type CronDayOfWeek = All | OneOf | DayOfWeek[];
type CronYear = All | (number | Ranges<number>)[];

type Cron = {
  minute: CronMinute;
  hour: CronHour;
  day: CronDay;
  month: CronMonth;
  dayOfWeek: CronDayOfWeek;
  year: CronYear;
};

const cronToString = (c: Cron) =>
  [
    valuesToString(c.minute),
    valuesToString(c.hour),
    valuesToString(c.day),
    valuesToString(c.month),
    valuesToString(c.dayOfWeek),
    valuesToString(c.year),
  ].join(" ");

const minuteFromString = (s: string): CronMinute => {
  if (s === "*") {
    return all as All;
  }

  return s.split(",").map((v) => valueFromString<Minute>(v, [0, 59]));
};

const hourFromString = (s: string): CronHour => {
  if (s === "*") {
    return all as All;
  }
  return s.split(",").map((v) => valueFromString<Hour>(v, [0, 23]));
};

const dayFromString = (s: string): CronDay => {
  if (s === "*") {
    return all as All;
  }
  if (s === "?") {
    return oneOf as OneOf;
  }
  return s.split(",").map((v) => valueFromString<Dom>(v, [1, 31]));
};

const monthFromString = (s: string): CronMonth => {
  if (s === "*") {
    return all as All;
  }
  return s.split(",").map((v) => valueFromString<Month>(v, [1, 12]));
};

const dayOfWeekFromString = (s: string): CronDayOfWeek => {
  if (s === all) {
    return all;
  }
  if (s === oneOf) {
    return oneOf;
  }
  return s.split(",").map((v) => Number(v));
};

const yearFromString = (s: string): CronYear => {
  if (s === all) {
    return all;
  }
  return s.split(",").map((v) => valueFromString<number>(v));
};

const cronFromString = (s: String): Cron => {
  const values = s.split(" ");
  if (values.length !== 6) {
    throw new ParseCronStringError("cron string has wrong length");
  }

  return {
    minute: minuteFromString(values[0]),
    hour: hourFromString(values[1]),
    day: dayFromString(values[2]),
    month: monthFromString(values[3]),
    dayOfWeek: dayOfWeekFromString(values[4]),
    year: yearFromString(values[5]),
  };
};

export const weeklyCronStringByHHMM = (
  daysOfWeek: Array<DayOfWeek>,
  hour: Range<0, 24>,
  minute: Range<0, 60>
) => {
  const cron: Cron = {
    minute: [minute],
    hour: [hour],
    day: oneOf,
    month: all,
    dayOfWeek: daysOfWeek,
    year: all,
  };
  return cronToString(cron);
};

export const minutelyCronString = cronToString({
  minute: all,
  hour: all,
  day: all,
  month: all,
  dayOfWeek: all,
  year: all,
});

export const getDaysOfWeekFromCronString = (s: string): Array<DayOfWeek> => {
  const cron = cronFromString(s);
  if (cron.dayOfWeek === all || cron.dayOfWeek === oneOf) return allDaysOfWeek;

  return cron.dayOfWeek.sort() as Array<DayOfWeek>;
};

export const getMinutesFromCronString = (s: string): Array<Minute> => {
  const cron = cronFromString(s);
  if (cron.minute === all) {
    return generateIntegers(0, 59) as Array<Minute>;
  }
  return cron.minute.sort() as Array<Minute>;
};

export const getHoursFromCronString = (s: string): Array<Hour> => {
  const cron = cronFromString(s);
  if (cron.hour === all) {
    return generateIntegers(0, 23) as Array<Hour>;
  }
  return cron.hour.sort() as Array<Hour>;
};
