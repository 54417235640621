import jwtDecode from "jwt-decode";
import moment from "moment";
import { httpPublicRequest } from "./httpService";
import { HASURA_ADMIN_ROLE } from "../const";

const TokenExpiredError = () => { };
TokenExpiredError.prototype = new Error();

const login = (credential) =>
  httpPublicRequest.post("/v1/auth/login", credential);

const isTokenDataExpired = (
  tokenData /* value decoded from token using jwtDecode */
) => {
  const now = moment().valueOf() / 1000;
  return now > tokenData.exp;
};

// use closure so you may check expire for many time with decoding only runned once
const isTokenExpired = (token) => {
  const tokenData = jwtDecode(token);
  return () => isTokenDataExpired(tokenData);
};

const decodeAndCheckJWTValidity = (token) => {
  const tokenData = jwtDecode(token);
  if (isTokenDataExpired(tokenData)) {
    throw TokenExpiredError;
  }
  return tokenData;
};

const isAdminToken = (token) => {
  const tokenData = jwtDecode(token);
  return (
    tokenData["https://hasura.io/jwt/claims"]["x-hasura-default-role"] ===
    HASURA_ADMIN_ROLE
  );
};

const decodeHasuraRoles = (token) => {
  const tokenData = jwtDecode(token);
  const obj = tokenData && tokenData["https://hasura.io/jwt/claims"];
  const allowedRoles = obj && obj["x-hasura-allowed-roles"];
  return allowedRoles || [];
};

const isClientSpecific = (tokenData) =>
  tokenData["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"].length ===
  1 &&
  tokenData["https://hasura.io/jwt/claims"]["x-hasura-default-role"] !==
  HASURA_ADMIN_ROLE;

export {
  login,
  decodeAndCheckJWTValidity,
  isClientSpecific,
  decodeHasuraRoles,
  isAdminToken,
  isTokenExpired,
};
