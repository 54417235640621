/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Box,
  Button,
  Card,
  Divider,
  Fade,
  List,
  ListItem as MuiListItem,
  Stack,
  styled,
  Typography,
  useTheme,
} from "@mui/material";
import React from "react";
import { Client } from "src/interfaces/IClient";
import { Property } from "src/interfaces/IProperty";
import { Zone } from "src/interfaces/IZone";
import { DM_DARK_GREY, WYND_WHITE } from "src/styles";
import AddIcon from "@mui/icons-material/Add";
import { Nullable } from "src/helper";

type PropertyItemProps = {
  zones: Array<Zone>;
  property: Property;
};
const ListItem = styled(MuiListItem)(({ theme }) => ({
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  paddingLeft: 0,
}));
function PropertyItem({ zones, property }: PropertyItemProps) {
  return (
    <List sx={{ padding: 0 }}>
      <Typography variant="subtitle3" component={ListItem}>
        {property.property_name}
      </Typography>
      {zones.map((zone) => (
        <Typography
          key={zone.zone_id}
          variant="body3"
          component={ListItem}
          sx={{ color: DM_DARK_GREY }}
        >
          {zone.zone_name}
        </Typography>
      ))}
    </List>
  );
}

type Props = {
  client: Client;
  properties: Array<Property>;
  zones: Array<Zone>;
  error?: Nullable.T<string>;
  onClickedAccountInfo: () => void;
  onClickedManageDevices: () => void;
};
export default function ClientCard({
  client,
  properties,
  zones,
  error,
  onClickedAccountInfo,
  onClickedManageDevices,
}: Props) {
  const theme = useTheme();
  return (
    <Fade in>
      <Card sx={{ padding: 4.5, flex: 1 }}>
        <Stack direction="column" divider={<Divider />} spacing={3}>
          <Stack direction="column" sx={{ alignItems: "flex-start" }}>
            <Typography variant="subtitle3" sx={{ color: "#8A9597" }}>
              ID: {client.client_id}
            </Typography>
            <Typography
              variant="h7"
              sx={{ fontWeight: "700", maringTop: 0.5, marginBottom: 1 }}
            >
              {client.client_name}
            </Typography>
            <Typography
              variant="subtitle4"
              sx={{
                backgroundColor: WYND_WHITE,
                padding: 1,
                paddingTop: 0.5,
                paddingBottom: 0.5,
                borderRadius: "12px",
              }}
            >
              {`${properties.length} active properties`}
            </Typography>
          </Stack>
          <Stack direction="column">
            <Box
              sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}
            >
              {error && (
                <Typography
                  sx={{ width: "100%", color: theme.palette.error.main }}
                >
                  {error}
                </Typography>
              )}
              {properties.map((p) => (
                <PropertyItem
                  key={p.property_id}
                  property={p}
                  zones={zones.filter((z) => z.property_id === p.property_id)}
                />
              ))}
            </Box>
            <Box sx={{ width: "100%", textAlign: "left" }}>
              <Button sx={{ left: "-8px" }} startIcon={<AddIcon />}>
                Add Property
              </Button>
            </Box>
          </Stack>
          <Stack
            direction="row"
            sx={{ justifyContent: "flex-end" }}
            spacing={2}
          >
            <Button
              variant="outlined"
              color="info"
              onClick={onClickedAccountInfo}
            >
              Account Info
            </Button>{" "}
            <Button
              variant="outlined"
              color="info"
              onClick={onClickedManageDevices}
            >
              Manage Devices
            </Button>
          </Stack>
        </Stack>
      </Card>
    </Fade>
  );
}
