import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import PauseBadge from "src/assets/icons/PauseBadge";
import moment, { Moment } from "moment";
import { DM_DARK_GREY } from "src/styles";

const countDown = (time: Moment) => {
  const now = moment();
  const duration = moment.duration(time.diff(now));
  const hours = duration.hours();
  const minutes = duration.minutes();
  const seconds = duration.seconds();
  const days = Math.floor(duration.asDays());

  return `${days > 0 ? `${days}d ` : ""}${hours}:${minutes}:${seconds}`;
};

export default function PauseCountDown({ startingAt }: { startingAt: Moment }) {
  const [countDownString, setCountDownString] = useState<string>(
    countDown(startingAt)
  );
  useEffect(() => {
    const iid = setInterval(() => {
      setCountDownString(countDown(startingAt));
    }, 1000);

    return () => clearInterval(iid);
  }, [startingAt]);
  return (
    <Typography
      component="span"
      variant="body3"
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 1,
        color: DM_DARK_GREY,
      }}
    >
      <PauseBadge />
      {`Schedule Paused: ${countDownString} left`}
    </Typography>
  );
}
