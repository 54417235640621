import React, { createContext, useContext, useMemo, useState } from "react";
import { DeviceType } from "src/interfaces/IDevice";
import { Nullable } from "src/helper";
import { ClientContext } from "src/contexts/GqlContext";
import { ProgramType, Program } from "src/interfaces/IProgram";
import { Set as DeviceSet } from "src/interfaces/set/device";
import { Map as DeviceMap } from "src/interfaces/map/device";
import { ApolloClient, useApolloClient } from "@apollo/client";
import { getProgramsByDevices } from "src/services/graphql/Program";
import { ClientDBName } from "src/interfaces/IClient";
import { handleErrors as handleGqlErrors } from "src/services/graphql/utils";
import { Device } from "./types";
import Controls from "./Controls";
import { Context as PageContext } from "./PageController";
import { makeChangeModePage } from "./pages/ChangeModePage";
import { makeUpdateFirmwarePage } from "./pages/UpdateFirmwarePage";
import {
  makeCreateProgramPage,
  DeviceProgramRelation,
} from "./pages/CreateProgramPage";
import DeviceSelectModal from "./ui/DeviceSelectModal";

type ContextValue = {
  isSelected: (d: Device) => boolean;
  select: (devices: Array<Device>) => void;
  deselect: (d: Device) => void;
  reset: () => void;
  close: () => void;
};

const throwNonInitiatedError = () => {
  throw ReferenceError("DeviceSelection context is not initiated");
};

const Context = createContext<ContextValue>({
  isSelected: throwNonInitiatedError,
  select: throwNonInitiatedError,
  deselect: throwNonInitiatedError,
  reset: throwNonInitiatedError,
  close: throwNonInitiatedError,
});

// Controls component and related functions

const programsToRelations = (
  devices: DeviceSet,
  programs: Program<ProgramType.REGULAR>[]
) => {
  let deviceMap = DeviceMap<number>();
  programs.forEach((program) =>
    program.devices.forEach(({ device_id }) => {
      const device = devices.find((d) => d.device_id === device_id);
      if (device) {
        deviceMap = deviceMap.set(device, program.program_id);
      }
    })
  );
  return deviceMap.toArray();
};

const findProgramsOfDevices = (
  gqlClient: ApolloClient<object>,
  clientDBName: ClientDBName,
  devices: DeviceSet
): Promise<[Device, number /* program_id */][]> =>
  gqlClient
    .query({
      query: getProgramsByDevices(clientDBName),
      variables: {
        device_ids: devices.toArray().map((d) => d.device_id),
        program_type: ProgramType.REGULAR,
      },
      // Always fetch from network to get the latest data
      fetchPolicy: "network-only",
    })
    .then(handleGqlErrors)
    .then(
      (data) =>
        (data[`${clientDBName}_program`] ||
          []) as Program<ProgramType.REGULAR>[]
    )
    .then((programs) => programsToRelations(devices, programs));

function ControlsComponent({
  isOpen,
  propertyId,
  selectedDevices,
  selectedDeviceType,
}: {
  isOpen: boolean;
  propertyId: undefined | number;
  selectedDevices: DeviceSet;
  selectedDeviceType: Nullable.T<DeviceType>;
}) {
  const gqlClient = useApolloClient();
  const value = useContext(Context);
  const disabled =
    selectedDevices.size === 0 ||
    selectedDeviceType === null ||
    propertyId === undefined;

  const { client } = useContext(ClientContext);
  const { pushToPage } = useContext(PageContext);

  const pushToCreateProgramPage = (
    deviceProgramRelations: DeviceProgramRelation[]
  ) => {
    if (!disabled) {
      value.close();
      pushToPage(
        makeCreateProgramPage({
          devices: selectedDevices.toArray(),
          deviceProgramRelations,
          propertyId,
        })
      );
    }
  };

  const onCreateProgram = async () => {
    if (disabled) return;
    const devicePrograms: [Device, number /* program_id */][] =
      await findProgramsOfDevices(
        gqlClient,
        client.client_dbname,
        selectedDevices
      );
    if (devicePrograms.length === 0) {
      pushToCreateProgramPage([]);
    } else {
      const callback = (devices: Device[]) => {
        const set = DeviceSet(devices);
        const relations = devicePrograms.reduce(
          (acc: DeviceProgramRelation[], [device, program_id]) => {
            if (set.has(device)) {
              acc.push([program_id, device.device_id]);
            }
            return acc;
          },
          []
        );
        pushToCreateProgramPage(relations);
      };

      DeviceSelectModal.open(
        devicePrograms.map((v) => v[0]),
        callback
      );
    }
  };

  const onChangeMode = () => {
    if (disabled) return;
    value.close();
    pushToPage(
      makeChangeModePage({
        devices: selectedDevices.toArray(),
        deviceType: selectedDeviceType,
      })
    );
  };

  return (
    <>
      <DeviceSelectModal />
      <Controls
        isOpen={isOpen}
        onCancel={value.close}
        onReset={value.reset}
        selectedCount={selectedDevices.size}
        onUpdateFirmware={() => {
          if (!disabled) pushToPage(makeUpdateFirmwarePage());
        }}
        onCreateProgram={
          selectedDeviceType === DeviceType.MAX ? onCreateProgram : undefined
        }
        onChangeMode={onChangeMode}
      />
    </>
  );
}

// Component

function Component({
  propertyId,
  children,
}: {
  propertyId: undefined | number;
  children: React.ReactNode;
}) {
  const [selectedDevices, setSelectedDevices] = useState<DeviceSet>(
    DeviceSet()
  );
  const [selectedDeviceType, setSelectedDeviceType] =
    useState<Nullable.T<DeviceType>>(null);

  const [openControls, setOpenControls] = useState(false);

  const value = useMemo(
    () => ({
      isSelected: (device: Device) => selectedDevices.has(device),
      select: (devices: Array<Device>) => {
        if (devices.length > 0) {
          setOpenControls(true);
          const deviceType = devices[0].device_type;
          setSelectedDeviceType(deviceType);

          setSelectedDevices((set) =>
            devices.reduce((acc, device: Device) => {
              // Use this to force all selected device has the same deviceType
              if (device.device_type === deviceType) {
                return acc.add(device);
              }
              return acc;
            }, set)
          );
        }
      },
      deselect: (device: Device) =>
        setSelectedDevices((set) => set.delete(device)),
      reset: () => {
        setSelectedDevices(DeviceSet());
        setSelectedDeviceType(null);
      },
      close: () => {
        setSelectedDevices(DeviceSet());
        setSelectedDeviceType(null);
        setOpenControls(false);
      },
    }),
    [selectedDevices, setSelectedDevices]
  );
  return (
    <Context.Provider value={value}>
      {children}
      {propertyId !== undefined && (
        <ControlsComponent
          isOpen={openControls}
          propertyId={propertyId}
          selectedDeviceType={selectedDeviceType}
          selectedDevices={selectedDevices}
        />
      )}
    </Context.Provider>
  );
}

export type { Device };
export { Context };
export default Component;
