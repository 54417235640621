import { Grid, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import PageContainer from "src/components/Layout/PageContainer";
import UISelect, { Item } from "src/components/UI/Select";
import { AuthContext } from "src/contexts/AuthContext";
import { Client } from "src/interfaces/IClient";
import { getClients } from "src/services/restful/clients";
import { getIAQMetrics } from "src/services/restful/iaq";
import AirExchangeWidget from "./AirExchangeWidget";
import CleanAirZonesWidget from "./CleanAirZonesWidget";
import CleanestZoneWidget from "./CleanestZoneWidget";
import EnergySavingsWidget from "./EnergySavingsWidget";
import InfectionRiskWidget from "./InfectionRiskWidget";
import WyndScoreWidget from "./WyndScoreWidget";

export default function IAQControlCenter() {
  const { user, isAuthLoading } = useContext(AuthContext);
  const [clients, setClients] = useState(Array<Client>);
  const [curClientDBName, setCurClientDBName] = useState("");
  const [properties, setProperties] = useState(Array<string>);
  const [curProperty, setCurProperty] = useState("");
  const [isClientsLoading, setIsClientsLoading] = useState(true);
  const [isPropertiesLoading, setIsPropertiesLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [iaqData, setIaqData] = useState(Object);

  useEffect(() => {
    const getUserClients = async () => {
      try {
        const response = await getClients();
        setClients(response.data);
        if (user?.isClientSpecific) {
          setCurClientDBName(response.data[0].client_dbname);
        }
      } catch (e) {
        setIsError(true);
        console.log(e);
      } finally {
        setIsClientsLoading(false);
      }
    };
    getUserClients();
  }, [user]);

  useEffect(() => {
    const getIAQ = async () => {
      if (curClientDBName) {
        try {
          setIsPropertiesLoading(true);
          const response = await getIAQMetrics(curClientDBName);
          const iaqDict = response.data;
          setIaqData(iaqDict);
          setProperties(Object.keys(iaqDict));
        } catch (e) {
          setIsError(true);
          console.log(e);
        } finally {
          setIsPropertiesLoading(false);
        }
      }
    };
    setCurProperty("");
    getIAQ();
  }, [curClientDBName]);

  return (
    <PageContainer isLoading={isClientsLoading || isAuthLoading}>
      {isError ? (
        <Typography>error loading iaq data</Typography>
      ) : (
        <>
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            gap={3}
            marginBottom="58px"
          >
            {user?.isClientSpecific ? (
              <Typography variant="body1" marginRight="150px">
                {clients[0]?.client_name}
              </Typography>
            ) : (
              <UISelect
                label="Client"
                value={curClientDBName}
                onChange={setCurClientDBName}
                sx={{ width: "15%", marginRight: "70px" }}
              >
                {clients.map((client: Client) => (
                  <Item key={client.client_id} value={client.client_dbname}>
                    {client.client_name}
                  </Item>
                ))}
              </UISelect>
            )}
            {!isPropertiesLoading && (
              <UISelect
                label="Property"
                value={curProperty}
                onChange={setCurProperty}
                sx={{ width: "50%" }}
              >
                {properties.map((property: string) => (
                  <Item key={property} value={property}>
                    {property}
                  </Item>
                ))}
              </UISelect>
            )}
          </Stack>
          {iaqData[curProperty] ? (
            <>
              <Stack
                direction="row"
                alignItems="center"
                gap={3}
                marginBottom={6}
              >
                <Typography variant="h3">IAQ Control Center</Typography>
              </Stack>
              <Stack direction="column" gap={3} flexWrap="wrap">
                <Grid container spacing={2} height="246px">
                  <Grid item xs={12} md={3}>
                    <WyndScoreWidget value={iaqData[curProperty].wynd_score} />
                  </Grid>
                  <Grid item xs={12} md={9} container spacing={2}>
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12} md={4}>
                        <InfectionRiskWidget
                          risk={iaqData[curProperty].infection_risk}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CleanAirZonesWidget
                          value={iaqData[curProperty]?.clean_air_zones}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CleanestZoneWidget
                          zone={iaqData[curProperty]?.cleanest_zone?.zone_name}
                          property={curProperty}
                          floor={iaqData[curProperty]?.cleanest_zone?.floor}
                          value={
                            iaqData[curProperty]?.cleanest_zone?.wynd_score
                          }
                        />
                      </Grid>
                    </Grid>
                    <Grid item xs={12} container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <AirExchangeWidget
                          air_exchanges={
                            iaqData[curProperty]?.air_exchange.air_exchanges
                          }
                          average_air_exchanges={
                            iaqData[curProperty]?.air_exchange
                              .average_air_exchanges
                          }
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <EnergySavingsWidget
                          energy_savings={
                            iaqData[curProperty]?.air_exchange.energy_savings
                          }
                          carbon_emissions={
                            iaqData[curProperty]?.air_exchange.carbon_emissions
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Stack>
            </>
          ) : (
            <Typography>no devices</Typography>
          )}
        </>
      )}
    </PageContainer>
  );
}
