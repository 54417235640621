import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

export default function Clock(props: SvgIconProps) {
  return (
    <SvgIcon
      width="12"
      height="12"
      viewBox="0 0 12 12"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ ...props, sx: { fill: "none" } }}
    >
      <path
        d="M6 10.5C8.48528 10.5 10.5 8.48528 10.5 6C10.5 3.51472 8.48528 1.5 6 1.5C3.51472 1.5 1.5 3.51472 1.5 6C1.5 8.48528 3.51472 10.5 6 10.5Z"
        stroke="black"
        strokeMiterlimit="10"
      />
      <path
        d="M6 3.375V6H8.625"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
