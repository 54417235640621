import { ArrowBack } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PageContainer from "src/components/Layout/PageContainer";
import { ClientContext } from "src/contexts/GqlContext";
import { Device, DeviceType } from "src/interfaces/IDevice";
import { RegularProgram as Program } from "src/interfaces/IProgram";
import { useQuery } from "@apollo/client";
import { queryGetDevices } from "src/services/graphql/Device";
import _get from "lodash/get";
import { useIsEqual } from "src/helper";
import difference from "lodash/difference";
import { Page, Context as PageContext } from "../../PageController";
import FormEdit from "../../ui/FormEdit";
import FormDisplay from "../../ui/FormDisplay";
import { getProgramStartingAt } from "../../utils";
import PauseCountDown from "../../ui/PauseCountDown";
import { deleteProgram, pauseProgram, resumeProgram } from "../../api";
import { emitFailed } from "../../ErrorToast";
import PauseProgramModal from "./PauseProgramModal";
import ConfirmModal from "../../ui/ConfirmModal";

// Page settings
export type Props = {
  program: Program;
};

export type EditProgramPage = Page<Props>;

export const pageName = "edit-schedule";

export const makeEditProgramPage = (props: Props): EditProgramPage => ({
  name: pageName,
  props,
});

// State
enum State {
  Editing = "editing",
  Pausing = "pausing",
  Displaying = "displaying",
}
namespace State {
  export const fromSearch = (searchParams: URLSearchParams): State => {
    const state = searchParams.get("state");
    if (state === State.Editing) {
      return State.Editing;
    }
    if (state === State.Pausing) {
      return State.Pausing;
    }
    return State.Displaying;
  };
}

type StateActionsProps = {
  state: State;
  setState: (state: State) => void;
  onDelete: () => void;
  onPause: (d: Date) => Promise<void>;
};

// Components
function StateActions({
  state,
  setState,
  onDelete,
  onPause,
}: StateActionsProps) {
  return state === State.Displaying || state === State.Pausing ? (
    <Stack direction="row" gap={2}>
      <Button
        onClick={() => {
          setState(State.Editing);
        }}
        variant="outlined"
        color="info"
      >
        Edit Schedule
      </Button>
      <Button
        onClick={() => {
          setState(State.Pausing);
        }}
        variant="outlined"
        color="info"
      >
        Pause Schedule
      </Button>
      <PauseProgramModal
        onPause={onPause}
        isOpen={state === State.Pausing}
        onClose={() => setState(State.Displaying)}
      />
    </Stack>
  ) : (
    <Button onClick={onDelete} variant="outlined" color="danger">
      Delete Schedule
    </Button>
  );
}

function ResumeProgram({
  program,
  onResume,
}: {
  program: Program;
  onResume: () => Promise<void>;
}) {
  const startingAt = getProgramStartingAt(program);
  const [resuming, setResuming] = useState(false);

  if (!startingAt) {
    return null;
  }
  const onClick = () => {
    setResuming(true);
    onResume().then(() => setResuming(false));
  };

  return (
    <Stack direction="row" gap={0.5}>
      <PauseCountDown startingAt={startingAt} />
      <Button
        color="primary"
        onClick={onClick}
        disabled={resuming}
        endIcon={
          resuming ? <CircularProgress color="inherit" size={12} /> : null
        }
      >
        Resume Schedule
      </Button>
    </Stack>
  );
}

const useProgramDevices = (program: Program): Device[] => {
  const { client } = useContext(ClientContext);
  const { data } = useQuery(queryGetDevices(client.client_dbname), {
    variables: {
      device_id: { _in: program.devices.map((device) => device.device_id) },
    },
  });

  return useIsEqual<Device[]>(
    (ds1: Device[], ds2: Device[]) =>
      difference(
        ds1.map((d) => d.device_id),
        ds2.map((d) => d.device_id)
      ).length === 0,
    _get(data, `${client.client_dbname}_device`, [])
  );
};

export function Component({ program: programControlled }: Props) {
  const [program, setProgram] = useState<Program>(programControlled);
  useEffect(() => {
    setProgram(programControlled);
  }, [programControlled]);

  const { client } = useContext(ClientContext);

  const { backToLastPage } = useContext(PageContext);

  const devices = useProgramDevices(program);

  const [searchParams] = useSearchParams();

  const [deletionConfirmModalIsOpen, setDeletionConfirmModalIsOpen] =
    useState(false);

  const [state, setState] = React.useState<State>(
    State.fromSearch(searchParams)
  );
  const isEditing = state === State.Editing;

  useEffect(() => {
    setState(State.fromSearch(searchParams));
  }, [searchParams]);

  const onDone = (p: Program) => {
    setState(State.Displaying);
    setProgram(p);
  };

  const onDelete = async () => {
    try {
      await deleteProgram(client, program.program_id);
      backToLastPage();
      setDeletionConfirmModalIsOpen(false);
    } catch (err) {
      emitFailed(
        "Failed to delete program",
        (err as Error)?.message || "Unknown error"
      );
    }
  };

  const onPause = async (d: Date) => {
    try {
      const output = await pauseProgram(client, program.program_id, d);
      setProgram(output);
      setState(State.Displaying);
    } catch (error) {
      emitFailed(
        "Failed to resume program",
        (error as Error)?.message || "Unknown error"
      );
    }
  };

  const onResume = async () => {
    try {
      const output = await resumeProgram(client, program.program_id);
      setProgram(output);
    } catch (error) {
      emitFailed(
        "Failed to resume program",
        (error as Error)?.message || "Unknown error"
      );
    }
  };

  const onCancel = () => setState(State.Displaying);

  return (
    <PageContainer>
      <Stack direction="column" gap={7}>
        <Stack direction="row" alignItems="center">
          <IconButton
            onClick={() => {
              if (isEditing) {
                setState(State.Displaying);
              } else {
                backToLastPage();
              }
            }}
          >
            <ArrowBack sx={{ width: "32px", height: "32px" }} />
          </IconButton>
          <Typography variant="h3" sx={{ margin: "0 1rem" }}>
            {client.client_name} {program.program_name}
          </Typography>
          <StateActions
            state={state}
            setState={setState}
            onPause={onPause}
            onDelete={() => setDeletionConfirmModalIsOpen(true)}
          />
        </Stack>
        <ResumeProgram program={program} onResume={onResume} />
        {isEditing ? (
          <FormEdit
            program={program}
            devicesOfProgram={devices}
            onCancel={onCancel}
            deviceType={DeviceType.MAX}
            onDone={onDone}
          />
        ) : (
          <FormDisplay
            program={program}
            devices={devices}
            deviceType={DeviceType.MAX}
          />
        )}
      </Stack>
      <ConfirmModal
        title="Do you want to delete the schedule?"
        content="Your schedule will be deleted permanently once you confirm."
        isOpen={deletionConfirmModalIsOpen}
        onClose={() => setDeletionConfirmModalIsOpen(false)}
        onConfirmed={onDelete}
      />
    </PageContainer>
  );
}
