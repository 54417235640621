import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { Nullable } from "src/helper";
import Button from "@mui/material/Button";
import DeviceCard from "src/components/UI/DeviceCard";
import { Shadow } from "src/interfaces/IShadow";
import EditZoneModal from "src/components/UI/EditZoneModal";
import type { Zone as ZoneType } from "../../interfaces/IZone";
import type { Device } from "../../interfaces/IDevice";
import { Edit as EditIcon } from "./Icons";
import ToastMaker from "../../components/UI/NotificationToast";

const Toast = ToastMaker();

type Props = {
  devices: Array<{ device: Device; shadow: undefined | Shadow }>;
  zone: ZoneType;
  onRelocate: (device: Device) => void;
  onRemove: (device: Device) => void;
  onEdit: (zone: ZoneType) => void;
};

export function Zone({ devices, zone, onRelocate, onRemove, onEdit }: Props) {
  return (
    <Stack direction="column" sx={{ gap: 4, marginBottom: 3 }}>
      <Stack direction="row" sx={{ alignItems: "center", gap: 2 }}>
        <Typography variant="subtitle2">{zone.zone_name}</Typography>
        <Button
          color="primary"
          startIcon={<EditIcon />}
          onClick={() => onEdit(zone)}
        >
          Edit Zone
        </Button>
      </Stack>
      <Stack direction="row" sx={{ gap: 3, flexWrap: "wrap" }}>
        {devices.map(({ device, shadow }) => (
          <DeviceCard
            key={device.device_id}
            device={device}
            shadow={shadow}
            zone={zone}
            editable={{
              onRemove: () => onRemove(device),
              onRelocate: () => onRelocate(device),
            }}
          />
        ))}
      </Stack>
    </Stack>
  );
}

const notiEmitSuccess = (zoneName: string) =>
  Toast.emit({
    delay: "persist",
    type: "Success",
    title: "Zone Deleted",
    content: `"${zoneName}" has been successfully deleted. All the existing devices in the zone would be moved to unassigned.`,
  });

const notiEmitFailed = (zoneName: string) =>
  Toast.emit({
    delay: "persist",
    type: "Failed",
    title: "Failed to Delete the Zone",
    content: `We are unable to delete "${zoneName}". Please try again.`,
  });

type ZonesProps = {
  devices: Array<{ device: Device; shadow: undefined | Shadow }>;
  zones: Array<ZoneType>;
  onRelocate: (device: Device) => void;
  onRemove: (device: Device) => void;
  onZoneChange: () => Promise<void>;
};

export function Zones({
  devices,
  zones,
  onRelocate,
  onRemove,
  onZoneChange,
}: ZonesProps) {
  const [editZone, setEditZone] = useState<Nullable.T<ZoneType>>(null);

  return (
    <Box>
      {zones.map((zone) => (
        <Zone
          key={zone.zone_id}
          zone={zone}
          devices={devices.filter((d) => d.device.zone_id === zone.zone_id)}
          onRelocate={onRelocate}
          onRemove={onRemove}
          onEdit={setEditZone}
        />
      ))}
      <Toast.Component />
      <EditZoneModal
        zones={zones}
        zoneToEdit={editZone}
        onClose={() => setEditZone(null)}
        onEditSuccessCallback={onZoneChange}
        onDeleteSuccessCallback={() => {
          if (editZone) notiEmitSuccess(editZone.zone_name);
        }}
        onDeleteFailedCallback={() => {
          if (editZone) notiEmitFailed(editZone.zone_name);
        }}
      />
    </Box>
  );
}
