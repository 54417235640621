// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { ApolloCache, DocumentNode, gql } from "@apollo/client";
import { Modifiers } from "@apollo/client/cache/core/types/common";
import curry from "lodash/curry";
import { ClientDBName } from "src/interfaces/IClient";
import { Device } from "src/interfaces/IDevice";

type Returning<V> = {
  data: {
    [key: string]: {
      returning: V;
    };
  };
};

const queryGetDevices = (clientDBName: ClientDBName): DocumentNode => gql`
    query GetDevices($zone_id: Int_comparison_exp = {}, $property_id: Int_comparison_exp = {}, $device_id: String_comparison_exp = {}) {
      ${clientDBName}_device(where: {
        is_active: {_eq: true},
        zone_id: $zone_id,
        property_id: $property_id
        device_id: $device_id
      }) {
        device_id
        property_id
        property {
          name: property_name
        }
        zone_id
        zone {
          name: zone_name
          floor
        }
        extra_data
        device_type
      }
    }
  `;

const mutateRelocateDevice = (clientDBName: ClientDBName): DocumentNode => gql`
  mutation RelocateDevice($device_id: String!, $zone_id: Int!, $property_id: Int!) {
    update_${clientDBName}_device(
      where: { device_id: { _eq: $device_id } }
      _set: { zone_id: $zone_id, property_id: $property_id }
    ) {
      returning {
        device_id
        property_id
        property {
          name: property_name
        }
        zone_id
        zone {
          name: zone_name
          floor
        }
        extra_data
        device_type
      }
    }
  }
`;

// consider user may remove then insert device back, always use upsert for add device
const mutateUpsertDevice = (clientDBName: ClientDBName): DocumentNode => gql`
  mutation UpsertDevice($device_id: String!, $zone_id: Int!, $device_type: device_type!, $property_id: Int!) {
    insert_${clientDBName}_device(
      objects: {
        device_id: $device_id,
        device_type: $device_type,
        property_id: $property_id,
        zone_id: $zone_id,
        is_active: true
      },
      on_conflict: {
        constraint: device_pkey,
        update_columns: [
          zone_id,
          property_id,
          is_active
        ]
      }) {
      returning {
        device_id
        property_id
        property {
          name: property_name
        }
        zone_id
        zone {
          name: zone_name
          floor
        }
        extra_data
        device_type
      }
    }
  }
`;

const mutateRemoveDevice = (clientDBName: ClientDBName): DocumentNode => gql`
  mutation RemoveDevice($device_id: String! ) {
    update_${clientDBName}_device(_set: {is_active: false}, where: {device_id: {_eq: $device_id}}) {
      returning {
        device_id
      }
    }
  }
`;

const mutateUndoRemoveDevice = (
  clientDBName: ClientDBName
): DocumentNode => gql`
mutation RemoveDevice($device_id: String! ) {
  update_${clientDBName}_device(_set: {is_active: true}, where: {device_id: {_eq: $device_id}}) {
    returning {
      device_id
      property_id
      property {
          name: property_name
        }
      zone_id
      zone {
          name: zone_name
          floor
        }
      extra_data
      device_type
    }
  }
}
`;

type UpdateDevice = (device: Device, devices: Array<Device>) => Array<Device>;
type UpdateDevices = (
  device: Array<Device>,
  devices: Array<Device>
) => Array<Device>;

const editDevice: UpdateDevice = (device, devices) =>
  devices.map((d) => (d.device_id === device.device_id ? device : d));

const editDevices: UpdateDevices = (newDevices, devices) =>
  newDevices.reduce((acc, value) => editDevice(value, acc), devices);

type Action = "update_";

const editDeviceCache = curry(
  (
    clientName: ClientDBName,
    action: Action,
    cache: ApolloCache<any>,
    { data }: Returning<Array<Device>>
  ) => {
    if (clientName) {
      const target = `${action}${clientName}_device`;
      const newDevices = data[target].returning;

      const fields: Modifiers = {
        [target]: (devices: Array<Device>) => editDevices(newDevices, devices),
      };

      cache.modify({
        fields,
      });
    }
  }
);

export {
  queryGetDevices,
  mutateRelocateDevice,
  mutateRemoveDevice,
  mutateUndoRemoveDevice,
  mutateUpsertDevice,
  editDeviceCache,
};
