import { ContentCopy, Menu } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import SearchBar from "../../components/UI/SearchBar";
import PageContainer from "../../components/Layout/PageContainer";
import ClientCardView from "./ClientCardView";
import ClientListView from "./ClientListView";
import { getClients } from "../../services/clientService";
import { PATHS, resolvePath } from "../../router";

const VIEW_TYPE_LIST = "list";
const VIEW_TYPE_CARD = "card";

const useClients = (setError) => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUserClients = async () => {
    try {
      const response = await getClients();
      setClients(response.data);
    } catch (e) {
      setError("Unable to retreive client data");
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUserClients();
  }, []);

  return [clients, isLoading];
};

export default function ClientsPage() {
  const [viewType, setViewType] = useState(VIEW_TYPE_LIST);
  const [error, setError] = useState(null);
  const [clients, isLoading] = useClients(setError);
  const [filterInput, setFilterInput] = useState("");

  const navigate = useNavigate();
  const onNavigateToAccountInfo = (clientId) => {
    navigate(resolvePath(PATHS.CLIENT, { ":clientId": clientId }));
  };
  const onNavigateToManageDevices = (clientId) => {
    navigate(resolvePath(PATHS.ZONES, { ":clientId": clientId }));
  };

  const visibleClients = clients.filter((client) =>
    client.client_name.includes(filterInput)
  );
  return (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 5,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography variant="h3" sx={{ marginRight: "1rem" }}>
            Clients
          </Typography>
          <Link to={PATHS.CLIENTS_NEW} style={{ textDecoration: "none" }}>
            <Button variant="contained">Add New Client</Button>
          </Link>
        </Box>
        <SearchBar
          id="combo-box-demo"
          options={clients.map((client) => client.client_name)}
          onChange={(newValue) => {
            if (newValue === null) {
              setFilterInput("");
            } else {
              setFilterInput(newValue);
            }
          }}
        />
      </Box>
      <Box sx={{ display: "flex", marginBottom: "1rem" }}>
        <Button
          onClick={() => setViewType(VIEW_TYPE_CARD)}
          sx={{ borderRadius: "4px 0 0 4px", width: "6rem" }}
          variant={viewType === VIEW_TYPE_CARD ? "contained" : "text"}
          startIcon={<ContentCopy />}
        >
          Card
        </Button>
        <Button
          onClick={() => setViewType(VIEW_TYPE_LIST)}
          sx={{ borderRadius: "0 4px 4px 0", width: "6rem" }}
          variant={viewType === VIEW_TYPE_LIST ? "contained" : "text"}
          startIcon={<Menu />}
        >
          List
        </Button>
      </Box>
      {viewType === VIEW_TYPE_CARD ? (
        <ClientCardView
          clients={visibleClients}
          isLoading={isLoading}
          error={error}
          handleAccountInfoClicked={onNavigateToAccountInfo}
          handleManageDevicesClicked={onNavigateToManageDevices}
        />
      ) : (
        <ClientListView
          clients={visibleClients}
          isLoading={isLoading}
          error={error}
          handleAccountInfoClicked={onNavigateToAccountInfo}
          handleManageDevicesClicked={onNavigateToManageDevices}
        />
      )}
    </PageContainer>
  );
}
