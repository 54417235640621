import { ArrowBack } from "@mui/icons-material";
import {
  Alert,
  Avatar,
  Card,
  CardContent,
  CircularProgress,
  Container,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { AUTH_TOKEN } from "../../const";
import { AuthContext } from "../../contexts/AuthContext";
import { getClients, selectClient } from "../../services/clientService";
import { PATHS } from "../../router";

function SelectClientPage() {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const {
    signout,
    user,
    isAuthLoading,
    signin,
    shouldSelectClient,
  } = useContext(AuthContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const getUserClients = async () => {
    try {
      const response = await getClients();
      setClients(response.data);
    } catch (e) {
      setError("Unable to retreive client data");
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  const selectUserClient = async (clientId) => {
    try {
      setIsLoading(true);
      const response = await selectClient(clientId);
      const { token } = response.data;
      localStorage.setItem(AUTH_TOKEN, token);
      signin(token);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!isAuthLoading) {
      if (!shouldSelectClient) {
        navigate(PATHS.CLIENTS, { replace: true });
      }
      if (!user) {
        navigate(PATHS.LOGIN, { replace: true });
      }
    }
  }, [user, isAuthLoading, shouldSelectClient]);

  useEffect(() => {
    getUserClients();
  }, []);

  return (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Card sx={{ width: "100%" }}>
        <CardContent sx={{ display: "flex", flexDirection: "column" }}>
          <IconButton onClick={signout} sx={{ alignSelf: "flex-start" }}>
            <ArrowBack />
          </IconButton>
          <Typography variant="h5">Select your clients</Typography>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <List>
              {clients.map((client) => (
                <ListItem disablePadding key={client.client_id}>
                  <ListItemButton
                    onClick={() => {
                      selectUserClient(client.client_id);
                    }}
                  >
                    <ListItemAvatar>
                      <Avatar>{client.client_name.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText>{client.client_name}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          )}
          {error && <Alert severity="error">{error}</Alert>}
        </CardContent>
      </Card>
    </Container>
  );
}

export default SelectClientPage;
