/* eslint-disable no-underscore-dangle */
import { useRef, useState, useEffect, useCallback } from "react";

// This hooks give you an atomic variable and its updater that you can get+set operation atomically.
// One alternative to do the same thing is use functional setState.
// But unlike setState, the updater of this hook accept non-pure function.
// See here for more detail https://github.com/teamwynd/wynd-enterprise/pull/298#discussion_r1013553387
function useAtomicVar<V>(init: V) {
  const val = useRef<V>(init);
  const [updaters, setUpdaters] = useState<Array<(l: V) => V>>([]);

  useEffect(() => {
    if (updaters.length > 0) {
      const l = updaters.length;
      // use slice is important,
      // because while useEffect is running, updaters may be already pushed by external user
      setUpdaters((ups) => ups.slice(l, ups.length));
      val.current = updaters.reduce(
        (acc, updater) => updater(acc),
        val.current
      );
    }
  }, [updaters]);

  return useCallback(
    (updater: (v: V) => V) => {
      setUpdaters((ups) => ups.concat([updater]));
    },
    [setUpdaters]
  );
}

function useIsEqual<T>(isEqual: (oldT: T, newT: T) => boolean, arg: T): T {
  const [result, setResult] = useState<T>(arg);

  useEffect(() => {
    setResult((oldResult) => (isEqual(arg, oldResult) ? oldResult : arg));
  }, [arg, isEqual]);

  return result;
}

function useIsMounted() {
  const isMounted = useRef(true);
  useEffect(() => {
    // Cannot remove this line. Otherwise linter will break the code....
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return () => isMounted.current;
}

// eslint-disable-next-line import/prefer-default-export
export { useAtomicVar, useIsEqual, useIsMounted };
