// eslint-disable-next-line import/prefer-default-export
export enum DayOfWeek {
  Sun = 1,
  Mon = 2,
  Tue = 3,
  Wed = 4,
  Thu = 5,
  Fri = 6,
  Sat = 7,
}

export const daysOfWeek: Array<DayOfWeek> = Object.values(DayOfWeek)
  .filter((wd) => typeof wd !== "string")
  .map((wd) => Number(wd));

export const decodeDaysOfWeek = (n: number): DayOfWeek[] => {
  // Ex: 100 = 1100100
  const binaryRepresentation = [...Array(7)].map((_, index) => {
    const i = 6 - index;
    // eslint-disable-next-line no-bitwise
    const rep = n >> i;
    if (rep === 0) {
      return 0;
    }
    // eslint-disable-next-line no-bitwise
    return rep & 1;
  });

  return binaryRepresentation.reduce((acc: DayOfWeek[], curr, index) => {
    if (curr === 1) {
      acc.push(index + 1);
    }
    return acc;
  }, []);
};

export const encodeDaysOfWeek = (ds: Array<DayOfWeek>): number => {
  const bStr = daysOfWeek.map((d) => (ds.includes(d) ? 1 : 0)).join("");

  return parseInt(bStr, 2);
};
