import ToastMaker from "src/components/UI/NotificationToast";

const ErrorToast = ToastMaker();

export default ErrorToast;

const emitFailed = (title: string, message: string) =>
  ErrorToast.emit({
    delay: "persist",
    type: "Failed",
    title,
    content: message,
  });

const emitSuccess = (title: string, message: string) =>
  ErrorToast.emit({
    delay: 5000,
    type: "Success",
    title,
    content: message,
  });

export { emitFailed, emitSuccess };
