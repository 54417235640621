import React from "react";
import { FormControlLabel, TextField, Theme, SxProps } from "@mui/material";

type Props<V> = {
  sx?: SxProps<Theme>;
  label: string;
  value: V;
  required?: boolean;
  fullWidth?: boolean;
  onChange: (v: V) => void;
  placeholder?: string;
  type?: React.InputHTMLAttributes<unknown>["type"];
  error?: boolean;
  helperText?: string;
  inputProps?: React.InputHTMLAttributes<unknown>;
};

export default function UIInput<V>({
  sx = {},
  value,
  required,
  fullWidth,
  onChange,
  label,
  placeholder,
  type,
  error,
  helperText,
  inputProps,
}: Props<V>) {
  return (
    <FormControlLabel
      componentsProps={{
        typography: {
          variant: "subtitle3",
          sx: { marginBottom: 0.5 },
        },
      }}
      sx={{
        marginLeft: 0,
        marginRight: 0,
        alignItems: "flex-start",
        width: fullWidth ? "100%" : "auto",
        ".MuiFormControlLabel-label": {
          ":after": required
            ? {
                paddingLeft: 0.5,
                content: "'*'",
                color: "orange",
              }
            : {},
        },
        ...sx,
      }}
      defaultValue=""
      label={label}
      labelPlacement="top"
      control={
        <TextField
          required={required}
          fullWidth
          placeholder={placeholder}
          value={value}
          type={type}
          onChange={(e) => {
            const v = e.target.value;
            onChange(v as V);
          }}
          error={error}
          helperText={helperText}
          inputProps={inputProps}
        />
      }
    />
  );
}
