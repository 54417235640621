import React from "react";
import { Stack, Button, Typography, Grid } from "@mui/material";
import { MODE } from "src/interfaces/IShadow";
import AddIcon from "@mui/icons-material/Add";
import DayOfWeekPicker from "src/components/UI/DayOfWeekPicker";
import Input from "src/components/UI/Input";
import ModeCycleComponent from "./ModeCycle";
import type { ModeCycle, Value, ErrorObject } from "./types";
import {
  generateNthEndTimeOptions,
  generateNthStartTimeOptions,
} from "./hours";

const makeModeCycle = (): ModeCycle => ({
  mode: MODE.AUTO,
  startTime: null,
  endTime: null,
});

const modeCycleToKey = (modeCycle: ModeCycle) =>
  `${modeCycle.startTime}-${modeCycle.endTime}`;

const deleteItemAt = <T extends unknown>(i: number, arr: T[]) => {
  const newArr = [...arr];
  newArr.splice(i, 1);
  return newArr;
};

const replaceItemAt = <T extends unknown>(i: number, item: T, arr: T[]) => {
  const newArr = [...arr];
  newArr[i] = item;
  return newArr;
};

type Props = {
  value: Value;
  onChange: (v: Value) => void;
  error: ErrorObject;
  showError: boolean;
};

export default function Edit({ value, onChange, error, showError }: Props) {
  return (
    <Stack direction="column" gap={6}>
      <Grid direction="row" gap={3} container>
        <Grid item xs={12} md={4}>
          <Input
            fullWidth
            required
            value={value.name}
            onChange={(v) => {
              onChange({ ...value, name: v });
            }}
            label="Setting Name"
            placeholder="Input"
            error={showError && error.name}
          />
        </Grid>
        <Grid item xs={12} md>
          <Input
            fullWidth
            value={value.notes}
            onChange={(v) => {
              onChange({ ...value, notes: v });
            }}
            label="Notes"
            placeholder="Input"
            error={showError && error.notes}
          />
        </Grid>
      </Grid>
      <Stack direction="column" gap={4} alignItems="flex-start">
        <Typography variant="subtitle2">Schedule & Modes</Typography>
        <DayOfWeekPicker
          isEditing
          selected={value.daysOfWeek}
          onChange={(v) => {
            onChange({ ...value, daysOfWeek: v });
          }}
        />
        {value.modeCycles.map((modeCycle, i) => {
          const modeCycleError = error.modeCycles?.[i];
          return (
            <ModeCycleComponent
              key={modeCycleToKey(modeCycle)}
              modeCycle={modeCycle}
              onDelete={() =>
                onChange({
                  ...value,
                  modeCycles: deleteItemAt(i, value.modeCycles),
                })
              }
              onChange={(newModeCycle) => {
                onChange({
                  ...value,
                  modeCycles: replaceItemAt(i, newModeCycle, value.modeCycles),
                });
              }}
              startTimeOptions={generateNthStartTimeOptions(
                value.modeCycles,
                i
              )}
              endTimeOptions={generateNthEndTimeOptions(value.modeCycles, i)}
              error={showError ? modeCycleError : undefined}
              hideDeleteButton={i === 0 && value.modeCycles.length === 1}
            />
          );
        })}
        <Button
          fullWidth={false}
          onClick={() => {
            onChange({
              ...value,
              modeCycles: [...value.modeCycles, makeModeCycle()],
            });
          }}
          startIcon={<AddIcon />}
        >
          Add New Mode
        </Button>
      </Stack>
    </Stack>
  );
}
