import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

function overwriteSx(props: { sx?: any }) {
  const { sx } = props;
  return {
    ...props,
    sx: sx ? { ...sx, fill: "none" } : { fill: "none" },
  };
}

export default function TurboFanLogo(props: SvgIconProps) {
  return (
    <SvgIcon
      width="72"
      height="72"
      viewBox="0 0 72 72"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...overwriteSx(props)}
    >
      <mask id="center-circle-mask">
        <circle cx="36" cy="36" r="36" fill="white" />
        <circle cx="36" cy="36" r="6" fill="black" />
      </mask>
      <path
        d="M32.9779 36.431L20.25 23.7031C21.9196 22.0335 24.0138 20.8732 26.2361 20.3865C28.4583 19.8998 30.6958 20.1114 32.6317 20.9914C34.5676 21.8714 36.1038 23.375 37.0225 25.2894C37.9413 27.2039 38.1961 29.4319 37.7509 31.6581"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentColor"
        mask="url(#center-circle-mask)"
      />
      <path
        d="M38.1381 37.7594L33.4794 55.146C31.1987 54.5349 29.1467 53.3014 27.6141 51.6203C26.0815 49.9391 25.146 47.8955 24.9401 45.779C24.7342 43.6625 25.2684 41.5803 26.4669 39.8274C27.6655 38.0745 29.4676 36.7398 31.6182 36.0123"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentColor"
        mask="url(#center-circle-mask)"
      />
      <path
        d="M36 36L51.5885 27C52.769 29.0448 53.3478 31.368 53.2428 33.6405C53.1377 35.913 52.3542 38.0195 51.0032 39.6617C49.6521 41.3039 47.8021 42.3985 45.7151 42.7905C43.6282 43.1824 41.4101 42.8519 39.375 41.8457"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="currentColor"
        mask="url(#center-circle-mask)"
      />
      <path
        d="M36 65.25C52.1543 65.25 65.25 52.1543 65.25 36C65.25 19.8457 52.1543 6.75 36 6.75C19.8457 6.75 6.75 19.8457 6.75 36C6.75 52.1543 19.8457 65.25 36 65.25Z"
        stroke="currentColor"
        strokeWidth="5"
        strokeLinecap="round"
        strokeLinejoin="round"
        mask="url(#center-circle-mask)"
      />
    </SvgIcon>
  );
}
