import React from "react";
import { Card, CircularProgress, Box, Typography } from "@mui/material";
import {
  DM_DARK_GREY,
  lightTheme,
  MESSAGING_ERROR,
  MESSAGING_SUCCESS,
  MESSAGING_WARNING,
  WYND_WHITE,
} from "src/styles";
import { WyndScoreRatingThreshold } from "src/const";
import Fantastic from "./Fantastic";
import Fair from "./Fair";
import Poor from "./Poor";

type WyndScoreWidgetProps = {
  value: number;
  sx?: object;
};

const WyndScoreWidget = ({ value, sx }: WyndScoreWidgetProps) => {
  const theme = lightTheme;
  const caption =
    value > WyndScoreRatingThreshold.FANTASTIC
      ? "Fantastic"
      : value > WyndScoreRatingThreshold.FAIR
      ? "Fair"
      : "Poor";
  const levelColor =
    value > WyndScoreRatingThreshold.FANTASTIC
      ? MESSAGING_SUCCESS
      : value > WyndScoreRatingThreshold.FAIR
      ? MESSAGING_WARNING
      : MESSAGING_ERROR;
  const icon =
    value > WyndScoreRatingThreshold.FANTASTIC ? (
      <Fantastic />
    ) : value > WyndScoreRatingThreshold.FAIR ? (
      <Fair />
    ) : (
      <Poor />
    );
  return (
    <Box sx={{ position: "relative", height: "100%", ...sx }}>
      <Card sx={{ padding: 2, height: "100%" }}>
        <Typography variant="subtitle4" color={DM_DARK_GREY}>
          Wynd Score
        </Typography>
        <Box display="flex" justifyContent="center" marginTop="20px">
          <Box sx={{ position: "relative", marginRight: "16px" }}>
            <CircularProgress
              variant="determinate"
              value={100}
              size="120px"
              thickness={5}
              sx={{ color: WYND_WHITE }}
            />
            <CircularProgress
              variant="determinate"
              value={value}
              size="120px"
              thickness={5}
              sx={{ color: levelColor, position: "absolute", left: 0 }}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="h3">{value}</Typography>
            </Box>
          </Box>
        </Box>
      </Card>
      <Box
        padding={1}
        textAlign="center"
        position="absolute"
        bottom={0}
        left={0}
        right={0}
        borderRadius="0px 0px 10px 10px"
        display="flex"
        justifyContent="center"
        sx={{
          bgcolor: levelColor,
        }}
      >
        {icon}
        <Typography variant="h7" color={theme.palette.primary.contrastText}>
          {caption}
        </Typography>
      </Box>
    </Box>
  );
};

export default WyndScoreWidget;
