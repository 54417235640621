import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import type { SvgIconProps } from "@mui/material/SvgIcon";

export function Filled(props: SvgIconProps) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...{ ...props, sx: { fill: "none" } }}
    >
      <rect width="20" height="20" />
      <path
        d="M18.5515 12.767V14.642C18.5515 14.9735 18.4198 15.2914 18.1854 15.5259C17.951 15.7603 17.633 15.892 17.3015 15.892H8.82495C8.76692 15.892 8.71003 15.8758 8.66066 15.8453C8.61129 15.8148 8.5714 15.7711 8.54544 15.7192C8.51949 15.6673 8.5085 15.6092 8.51371 15.5514C8.51893 15.4936 8.54013 15.4384 8.57495 15.392L13.7078 8.70447C13.8101 8.57466 13.8583 8.41031 13.8423 8.24578C13.8263 8.08126 13.7473 7.92929 13.6218 7.82166C13.5576 7.76766 13.4831 7.72732 13.4028 7.7031C13.3224 7.67888 13.238 7.6713 13.1547 7.6808C13.0713 7.69031 12.9908 7.71671 12.918 7.75839C12.8451 7.80007 12.7816 7.85615 12.7312 7.92322L6.70776 15.767C6.68031 15.8056 6.644 15.8372 6.60186 15.8589C6.55973 15.8806 6.51299 15.892 6.46558 15.892H2.30151C1.96999 15.892 1.65205 15.7603 1.41763 15.5259C1.18321 15.2914 1.05151 14.9735 1.05151 14.642V12.8529C1.053 12.2706 1.11056 11.6898 1.22339 11.1185L2.99683 11.5951L3.16089 11.6107C3.30227 11.6108 3.43951 11.563 3.55019 11.475C3.66087 11.387 3.73845 11.2641 3.77026 11.1263C3.80555 10.9649 3.77626 10.796 3.68866 10.6559C3.60107 10.5157 3.46211 10.4154 3.30151 10.3763L1.55151 9.90759C2.69214 6.67322 5.65308 4.29041 9.17651 4.04041V5.86853C9.17395 6.03071 9.23299 6.18783 9.34174 6.30818C9.45049 6.42853 9.60084 6.50314 9.76245 6.51697C9.84775 6.52231 9.93324 6.5101 10.0136 6.48109C10.094 6.45208 10.1676 6.40689 10.2299 6.34831C10.2921 6.28974 10.3417 6.21902 10.3755 6.14052C10.4093 6.06203 10.4267 5.97744 10.4265 5.89197V4.04041C12.5228 4.18655 14.4956 5.08554 15.9812 6.57166C16.9211 7.50902 17.6368 8.64676 18.075 9.89978L16.3015 10.3763C16.1409 10.4154 16.002 10.5157 15.9144 10.6559C15.8268 10.796 15.7975 10.9649 15.8328 11.1263C15.8646 11.2641 15.9422 11.387 16.0528 11.475C16.1635 11.563 16.3008 11.6108 16.4421 11.6107L16.6062 11.5951L18.3953 11.1107C18.4987 11.6567 18.551 12.2112 18.5515 12.767Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
}

export function Outlined(props: SvgIconProps) {
  return (
    <SvgIcon
      width="20"
      height="20"
      viewBox="0 0 20 20"
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...props}
    >
      <path
        d="M1.875 14.3751V12.586C1.875 8.09381 5.48437 4.39069 9.96875 4.37506C11.0384 4.37095 12.0983 4.57807 13.0877 4.98455C14.077 5.39102 14.9764 5.98886 15.7342 6.74374C16.492 7.49863 17.0933 8.39571 17.5035 9.38352C17.9138 10.3713 18.125 11.4304 18.125 12.5001V14.3751C18.125 14.5408 18.0592 14.6998 17.9419 14.817C17.8247 14.9342 17.6658 15.0001 17.5 15.0001H2.5C2.33424 15.0001 2.17527 14.9342 2.05806 14.817C1.94085 14.6998 1.875 14.5408 1.875 14.3751Z"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 4.375V6.875"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.14844 10.3984L4.57031 11.0469"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.8516 10.3984L15.4297 11.0469"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.07812 15L13.4219 8.03906"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}
