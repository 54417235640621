import React from "react";

export default function High() {
  return (
    <svg
      width="60"
      height="60"
      viewBox="0 0 60 60"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        y="58"
        width="19"
        height="16"
        rx="2"
        transform="rotate(-90 0 58)"
        fill="#E34850"
      />
      <rect
        x="22"
        y="58"
        width="38"
        height="16"
        rx="2"
        transform="rotate(-90 22 58)"
        fill="#E34850"
      />
      <rect
        x="44"
        y="58"
        width="56"
        height="16"
        rx="2"
        transform="rotate(-90 44 58)"
        fill="#E34850"
      />
    </svg>
  );
}
