import { ArrowBack } from "@mui/icons-material";
import { IconButton, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import PageContainer from "src/components/Layout/PageContainer";
import { ClientContext } from "src/contexts/GqlContext";
import { Device, DeviceType } from "src/interfaces/IDevice";
import { RegularProgram as Program } from "src/interfaces/IProgram";
import { useApolloClient } from "@apollo/client";
import { Page, Context as PageContext } from "../../PageController";
import Form from "../../ui/FormEdit";
import { removeProgramDevice } from "../../api";
import ErrorToast from "../../ErrorToast";
import { makeViewProgramPage } from "../ViewProgram";

export type DeviceProgramRelation = [
  Program["program_id"],
  Device["device_id"]
];

type Props = {
  devices: Device[];
  propertyId: number;
  deviceProgramRelations: DeviceProgramRelation[];
};

export type CreateProgramPage = Page<Props>;

export const pageName = "create-program";

export const makeCreateProgramPage = (props: Props): CreateProgramPage => ({
  name: pageName,
  props,
});

export function Component({
  devices,
  deviceProgramRelations,
  propertyId,
}: Props) {
  const { backToLastPage } = useContext(PageContext);
  const { client } = useContext(ClientContext);
  const gqlClient = useApolloClient();
  const { pushToPage } = useContext(PageContext);
  const removeRelationship = () =>
    removeProgramDevice(
      gqlClient,
      client.client_dbname,
      deviceProgramRelations
    ).then(() => {});
  const onDone = () => {
    ErrorToast.emit({
      delay: 5000,
      type: "Success",
      title: "New Schedule Created",
      content: "You’ve successfully created a new schedule.",
    });
    pushToPage(
      makeViewProgramPage({
        propertyId,
      }),
      { replace: true }
    );
  };
  return (
    <PageContainer>
      <Stack direction="column" gap={7}>
        <Stack direction="row" alignItems="center">
          <IconButton onClick={backToLastPage}>
            <ArrowBack sx={{ width: "32px", height: "32px" }} />
          </IconButton>
          <Typography variant="h3" sx={{ margin: "0 1rem" }}>
            Add New Schedule
          </Typography>
        </Stack>
        <Form
          devicesOfProgram={devices}
          deviceType={DeviceType.MAX}
          onCancel={backToLastPage}
          onDone={onDone}
          runBeforeSave={removeRelationship}
        />
      </Stack>
    </PageContainer>
  );
}
