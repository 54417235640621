import React from "react";
import { Typography } from "@mui/material";
import { DM_DARK_GREY } from "src/styles";

export default function DeviceCount({ count }: { count: number }) {
  return (
    <Typography
      variant="subtitle2"
      component="span"
      sx={{
        marginLeft: 0.5,
        marginRight: 1.5,
        color: DM_DARK_GREY,
      }}
    >
      ({count})
    </Typography>
  );
}
