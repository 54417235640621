import React, { ReactNode } from "react";
import { Card, IconButton, Stack, Typography, useTheme } from "@mui/material";
import { Device } from "src/interfaces/IDevice";
import CloseIcon from "@mui/icons-material/Close";
import { DM_DARK_GREY } from "src/styles";
import mapValues from "lodash/mapValues";
import RowBox from "./RowBox";

type ContainerProps = {
  label: string;
  deviceCount: number;
  children: ReactNode;
};
function Container({ label, deviceCount, children }: ContainerProps) {
  return (
    <RowBox
      label={
        <>
          {label}
          <Typography
            component="span"
            variant="subtitle2"
            sx={{ marginLeft: 0.5, color: DM_DARK_GREY }}
          >
            ({deviceCount})
          </Typography>
        </>
      }
    >
      <Stack direction="column" gap={3}>
        {children}
      </Stack>
    </RowBox>
  );
}

type CardsContainerProps = {
  label: string;
  deviceCount?: number;
  children: ReactNode;
};
function CardsContainer({ label, deviceCount, children }: CardsContainerProps) {
  return (
    <Stack direction="column" gap={3}>
      <Stack direction="row">
        <Typography variant="subtitle2">
          {`${label} `}
          {deviceCount && `(${deviceCount})`}
        </Typography>
      </Stack>
      <Stack direction="row" gap={3}>
        {children}
      </Stack>
    </Stack>
  );
}

type DeviceCardProps = {
  zoneName: string;
  index?: number;
  device: Device;
  onRemove?: (d: Device) => void;
};
function DeviceCard({ index, zoneName, device, onRemove }: DeviceCardProps) {
  const theme = useTheme();
  return (
    <Card
      sx={{
        padding: 2,
        borderRadius: 0.5,
        width: "264px",
        "&:hover": onRemove
          ? {
              borderColor: theme.palette.primary.light,
              svg: {
                color: theme.palette.error.light,
              },
            }
          : {},
      }}
    >
      <Stack direction="row" sx={{ justifyContent: "space-between" }}>
        <Stack direction="column" gap={0.5}>
          <Typography variant="subtitle3">
            {`${zoneName} ${index !== undefined ? index : ""}`}
          </Typography>
          <Typography variant="body4">{device.device_id}</Typography>
        </Stack>
        {onRemove && (
          <IconButton
            sx={{ padding: 0 }}
            onClick={() => onRemove(device)}
            color="info"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Stack>
    </Card>
  );
}

type FloorZonesMap = {
  [floor: string]: Array<{
    zone_name: string;
    zone_id: number;
  }>;
};

function floorZonesMapFromDevices(
  devices: Array<Device>,
  includePropertyName: boolean = false
) {
  const floorZonesMap: FloorZonesMap = {};
  devices.forEach((device) => {
    const { floor } = device.zone;
    const propertyName = device.property.name;
    const key = includePropertyName ? `${propertyName}, ${floor}` : floor;
    const value = {
      zone_name: device.zone.name,
      zone_id: device.zone_id,
    };

    if (floorZonesMap[key] === undefined) {
      floorZonesMap[key] = [];
    }
    if (!floorZonesMap[key].find((z) => z.zone_id === value.zone_id)) {
      floorZonesMap[key].push(value);
    }
  });

  return mapValues(floorZonesMap, (arr) =>
    arr.sort((z1, z2) => z1.zone_name.localeCompare(z2.zone_name))
  );
}

type Props = {
  devices: Array<Device>;
  onRemoveDevice?: (device: Device) => void;
};
export default function DeviceList({ devices, onRemoveDevice }: Props) {
  const floorZonesMap = floorZonesMapFromDevices(devices);

  return (
    <Stack direction="column" gap={4}>
      {Object.entries(floorZonesMap).map(([floor, zones]) => {
        const devicesOfTheFloor = devices.filter(
          (d) => zones.findIndex((z) => z.zone_id === d.zone_id) >= 0
        );
        return (
          <Container
            key={floor}
            label={floor}
            deviceCount={devicesOfTheFloor.length}
          >
            {zones.map((zone) => {
              const devicesOfZone = devicesOfTheFloor.filter(
                (d) => zone.zone_id === d.zone_id
              );
              return (
                <CardsContainer
                  key={zone.zone_id}
                  label={zone.zone_name}
                  deviceCount={devicesOfZone.length}
                >
                  {devicesOfZone.map((device: Device, index: number) => (
                    <DeviceCard
                      key={device.device_id}
                      device={device}
                      index={devicesOfZone.length > 1 ? index + 1 : undefined}
                      zoneName={zone.zone_name}
                      onRemove={onRemoveDevice}
                    />
                  ))}
                </CardsContainer>
              );
            })}
          </Container>
        );
      })}
    </Stack>
  );
}

export function CompactDeviceList({ devices, onRemoveDevice }: Props) {
  const floorZonesMap = floorZonesMapFromDevices(devices, true);

  return (
    <Stack direction="column" gap={4}>
      {Object.entries(floorZonesMap).map(([floor, zones]) => {
        // this keep the order of the zones
        const devicesOfTheFloor = zones.reduce((acc: Device[][], zone) => {
          const devicesOfZone: Device[] = devices.filter(
            (d) => zone.zone_id === d.zone_id
          );
          return acc.concat([devicesOfZone]);
        }, []);
        return (
          <CardsContainer key={floor} label={floor}>
            {devicesOfTheFloor.reduce(
              (acc: React.ReactElement[], devicesOfTheZone: Device[]) =>
                acc.concat(
                  devicesOfTheZone.map((device: Device, index: number) => (
                    <DeviceCard
                      key={device.device_id}
                      device={device}
                      index={
                        devicesOfTheZone.length > 1 ? index + 1 : undefined
                      }
                      zoneName={device.zone.name}
                      onRemove={onRemoveDevice}
                    />
                  ))
                ),
              []
            )}
          </CardsContainer>
        );
      })}
    </Stack>
  );
}
