import { httpAuthorizedRequest } from "./httpService";

const getClients = () => httpAuthorizedRequest.get("/clients");
const getClientDetails = (clientId) =>
  httpAuthorizedRequest.get(`/clients/${clientId}`);

const getClientHash = (clientDBName) =>
  httpAuthorizedRequest.post("/clients/hash", {
    client_dbname: clientDBName,
  });

const selectClient = (clientId) =>
  httpAuthorizedRequest.post(`/v1/auth/client/${clientId}`);

const createClient = (client) => httpAuthorizedRequest.post(`/clients`, client);
const updateClient = ({ clientId, ...updatedClient }) =>
  httpAuthorizedRequest.put(`/clients/${clientId}`, updatedClient);

const unassignUserFromClient = ({ clientId, userId }) =>
  httpAuthorizedRequest.delete(`/v1/users/${userId}/clients/${clientId}`);

const updateClientDevices = (clientId) =>
  httpAuthorizedRequest.put(`/clients/${clientId}/devices/metadata`);

export {
  getClientHash,
  getClients,
  getClientDetails,
  selectClient,
  createClient,
  updateClient,
  unassignUserFromClient,
  updateClientDevices 
};
