import { ApolloClient, useApolloClient } from "@apollo/client";
import React, { useContext } from "react";
import { Nullable, Result } from "src/helper";
import { Client } from "src/interfaces/IClient";
import { editZoneCache, mutateUpdateZone } from "src/services/graphql/Zone";
import * as GraphQLUtils from "src/services/graphql/utils";
import EditModal from "src/components/UI/EditModal";
import { ClientContext } from "src/contexts/GqlContext";
import { DEFAULT_FLOOR_NAME } from "src/interfaces/IZone";

const updateFloor = async (
  gqlClient: ApolloClient<object>,
  client: Client,
  newFloor: string,
  oldFloor: string
) => {
  const result = await gqlClient.mutate({
    mutation: mutateUpdateZone(client.client_dbname),
    variables: {
      where: {
        floor: { _eq: oldFloor },
      },
      _set: {
        floor: newFloor,
      },
    },
    update: editZoneCache(client.client_dbname),
  });

  const fetchResult = GraphQLUtils.parseFetchResult(result);
  if (fetchResult.type === "Error") {
    return Promise.reject(
      new Error(GraphQLUtils.errorMessages(fetchResult.value))
    );
  }
  return undefined;
};

export default function EditFloorModal({
  floors,
  floorToEdit,
  onClose,
  onEditSuccessCallback,
  onDeleteSuccessCallback,
  onDeleteFailedCallback,
}: {
  floors: Array<string>;
  floorToEdit: Nullable.T<string>;
  onClose: () => void;
  onEditSuccessCallback?: () => void;
  onDeleteSuccessCallback?: () => void;
  onDeleteFailedCallback?: () => void;
}) {
  const handleInputValidate = (newFloor: string) => {
    if (floors.find((floor) => floor === newFloor) !== undefined) {
      return Result.error("The name of the floor is taken.");
    }
    return Result.ok(undefined);
  };

  const gqlClient = useApolloClient();
  const { client } = useContext(ClientContext);

  const handleSubmit = async (oldFloor: string, newFloor: string) => {
    if (floors.find((floor) => floor === newFloor) !== undefined) {
      return Promise.reject(new Error("The name of the floor is taken."));
    }

    await updateFloor(gqlClient, client, newFloor, oldFloor);
    return onEditSuccessCallback && onEditSuccessCallback();
  };

  const handleDelete = async (floor: string) => {
    try {
      await updateFloor(gqlClient, client, DEFAULT_FLOOR_NAME, floor);
      if (onDeleteSuccessCallback) onDeleteSuccessCallback();
    } catch (err) {
      console.error(err);
      if (onDeleteFailedCallback) onDeleteFailedCallback();
    }
  };

  return (
    <EditModal
      editTypeName="Floor"
      value={floorToEdit}
      onClose={onClose}
      validateInput={handleInputValidate}
      onSubmit={
        floorToEdit === null
          ? null
          : (newFloor: string) => handleSubmit(floorToEdit, newFloor)
      }
      onDelete={
        floorToEdit === null ? null : (floor: string) => handleDelete(floor)
      }
      confirmDeleteMsg="Deleting the floor will make all the existing devices on the default floor. Do you still want to delete the floor?"
    />
  );
}
