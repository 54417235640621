import { Box, Button, Dialog, DialogActions, DialogTitle, LinearProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { updateClientDevices } from '../../services/clientService';

type Props = {
  isOpen: boolean;
  onClose: (_?:object, reason?: string) => void;
  deviceSize: number;
  clientId: number;
}

export const DEVICE_SIZE_LIMIT = 30;

export default function UpdateDatabaseModal({
  isOpen,
  onClose,
  deviceSize,
  clientId
}: Props) {
  const [progressValue, setProgressValue] = useState<number>(0);
  let timer: NodeJS.Timer;

  // call update database api
  // set ~1 min timer to change progressValue
  useEffect(() => {
    if (isOpen && deviceSize < DEVICE_SIZE_LIMIT) { // start timer
      updateClientDevices(clientId);
      timer = setInterval(() => {
        setProgressValue((v) => v + Math.floor(Math.random() * 10)); 
      }, 2000);
    } else if (progressValue !== 0) {
      setProgressValue(0);
    }

    return () => clearInterval(timer);
  }, [isOpen]);

  useEffect(() => {
    if(progressValue >= 97) {
        onClose();
    }
  }, [progressValue]);

  if(deviceSize >= DEVICE_SIZE_LIMIT) {
    return (
      <Dialog 
        fullWidth 
        maxWidth="sm"
        disableEscapeKeyDown 
        open={isOpen} 
        onClose={onClose}
      >
        <DialogTitle>
          <Typography variant="h7">Please contact engineering.</Typography>
        </DialogTitle>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
  
  return (
    <Dialog 
      fullWidth 
      maxWidth="sm"
      disableEscapeKeyDown 
      open={isOpen} 
      onClose={onClose}
    >
      <DialogTitle>
        <Typography variant="h7">Update Device Shadow in Progress</Typography>
      </DialogTitle>
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={3}>
          <LinearProgress 
            variant="determinate" 
            value={progressValue}
          />
        </Box>
        <Box minWidth={35}>
          <Typography variant="body2" color="textSecondary">
            {`${Math.round(progressValue)}%`}
          </Typography>
        </Box>
      </Box>
    </Dialog>
  )
}