import { ArrowBack } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  TextField,
  Typography,
  Checkbox,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useApolloClient } from "@apollo/client";
import PageContainer from "../../components/Layout/PageContainer";
import { createUser, randomString } from "../../services/userService";
import { UserStatus, MembershipRole } from "../../const";
import { axiosErrorToString } from "../../services/restful/utils";
import { getClients } from "../../services/clientService";
import { queryGetProperties } from "../../services/graphql/Property";

const useClients = () => {
  const [clients, setClients] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getUserClients = async () => {
    try {
      const response = await getClients();
      setClients(response.data);
    } catch (e) {
      console.log(e);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getUserClients();
  }, []);

  return [clients, isLoading];
};

export default function NewUserPage() {
  const [email, setEmail] = useState("");
  const [fullName, setFullName] = useState("");
  const [status] = useState(UserStatus.ACTIVE);
  const [isSuperuser, setIsSuperuser] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [isUserGenerated, setIsUserGenerated] = useState(false);
  const [clients, isLoading] = useClients();
  const [selectedClient, setSelectedClient] = useState(null);
  const [assignedProperties, setAssignedProperties] = useState([]);
  const [clientProperties, setClientProperties] = useState([]);

  const [password] = useState(() => randomString());

  const gqlClient = useApolloClient();

  useEffect(() => {
    if (selectedClient) {
      setAssignedProperties([]);
      try {
        const propertyQuery = queryGetProperties(selectedClient.client_dbname);
        gqlClient
          .query({ query: propertyQuery })
          .then((res) =>
            setClientProperties(
              res.data[`${selectedClient.client_dbname}_property`]
            )
          );
      } catch (e) {
        console.log(e);
      }
    }
  }, [selectedClient]);

  useEffect(() => {
    if (isSuperuser) {
      setClientProperties([]);
      setSelectedClient(null);
      setAssignedProperties([]);
    }
  }, [isSuperuser]);

  const handleCreateUser = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const assignedPropertiesIds = assignedProperties.map(
        (property) => property.property_id
      );
      const newUser = isSuperuser
        ? {
            email,
            password,
            full_name: fullName,
            status,
            is_superuser: isSuperuser,
          }
        : {
            email,
            password,
            full_name: fullName,
            status,
            is_superuser: isSuperuser,
            extra_data: { properties: assignedPropertiesIds },
            membership: {
              client_id: selectedClient.client_id,
              role: MembershipRole.MEMBER,
            },
          };
      const response = await createUser(newUser);
      if (response.status >= 200 && response.status <= 299) {
        setIsUserGenerated(true);
      }
    } catch (err) {
      const message = axiosErrorToString(err);
      setErrorMessage(message || "Unexpected Error");
    }
    setIsSubmitting(false);
  };

  return isUserGenerated ? (
    <PageContainer>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack width={{ width: "32px", height: "32px" }} />
        </IconButton>
        <Typography variant="h3" sx={{ margin: "0 0 0 1rem" }}>
          New User Generated
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          margin: "2rem 0",
          justifyContent: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            border: 1,
            height: "137px",
            width: "1060px",
            borderRadius: "8px",
            borderColor: "#CFCFCF",
            backgroundColor: "#F6F8FA",
            flexDirection: "column",
          }}
        >
          <Typography variant="subtitle2" marginBottom="16px">
            Full Name: {fullName}
          </Typography>
          <Typography variant="subtitle2" marginBottom="16px">
            Email: {email}
          </Typography>
          <Typography variant="subtitle2" marginBottom="16px">
            Password: {password}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignContent: "center",
          justifyContent: "center",
        }}
      >
        <Button
          variant="outlined"
          onClick={() => navigator.clipboard.writeText(password)}
        >
          Copy Info
        </Button>
      </Box>
    </PageContainer>
  ) : (
    <PageContainer isLoading={isLoading}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBack width={{ width: "32px", height: "32px" }} />
        </IconButton>
        <Typography variant="h3" sx={{ margin: "0 0 0 1rem" }}>
          Add New User
        </Typography>
      </Box>
      <form onSubmit={handleCreateUser}>
        <Box
          sx={{
            display: "flex",
            margin: "2rem 0",
          }}
        >
          <Typography
            align="left"
            variant="body1"
            sx={{ fontWeight: "bold", width: "10rem" }}
          >
            User Info
          </Typography>
          <Grid sx={{ flex: "1", marginLeft: "1rem" }} container spacing={2}>
            <Grid item sm={12} md={8}>
              <TextField
                required
                fullWidth
                value={fullName}
                onChange={(e) => setFullName(e.target.value)}
                placeholder="Full Name"
                label="Full Name"
              />
            </Grid>
            <Grid item sm={12} md={4}>
              <TextField
                required
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Email"
                label="Email"
              />
            </Grid>
          </Grid>
        </Box>
        {errorMessage !== null && (
          <Alert severity="error">{errorMessage}</Alert>
        )}
        <Box
          sx={{
            display: "flex",
            margin: "2rem 0",
            marginLeft: "12rem",
            alignItems: "center",
          }}
        >
          <Checkbox onChange={() => setIsSuperuser(!isSuperuser)} />
          <Typography
            align="left"
            variant="body1"
            sx={{ fontWeight: 700, fontSize: "14px" }}
          >
            This user is a super user.
          </Typography>
        </Box>
        {!isSuperuser && (
          <Box
            sx={{
              display: "flex",
              margin: "2rem 0",
              marginLeft: "12rem",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <FormControl
              fullWidth
              required
              variant="outlined"
              sx={{ marginRight: "1rem" }}
            >
              <InputLabel id="select-client">Select Client</InputLabel>
              <Select
                sx={{ textAlign: "left" }}
                value={selectedClient?.client_name}
                labelId="select-client"
                label="Select Client"
                onChange={(e) => setSelectedClient(e.target.value)}
              >
                {clients.map((client) => (
                  <MenuItem value={client}>{client.client_name}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {clientProperties && (
              <FormControl fullWidth required variant="outlined">
                <InputLabel id="select-properties">Properties</InputLabel>
                <Select
                  labelId="properties"
                  label="Properties"
                  multiple
                  value={assignedProperties}
                  renderValue={(selected) =>
                    selected
                      .map((property) => property.property_name)
                      .join(", ")
                  }
                  onChange={(e) => setAssignedProperties(e.target.value)}
                >
                  {clientProperties.map((property) => (
                    <MenuItem value={property}>
                      <Checkbox
                        checked={assignedProperties.includes(property)}
                      />
                      {property.property_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>
        )}
        <Divider />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            margin: "3rem",
          }}
        >
          <Button
            variant="contained"
            type="submit"
            disabled={
              isSubmitting ||
              !fullName ||
              !email ||
              (!isSuperuser && !selectedClient)
            }
          >
            Add New User
          </Button>
          {isSubmitting && (
            <Typography variant="h6">
              Please wait. This can take up to one minute. Do not leave this
              page.
            </Typography>
          )}
        </Box>
      </form>
    </PageContainer>
  );
}
