export const PATHS = {
  ROOT: "/",
  LOGIN: "/web/auth/login",
  AUTH_CLIENTS: "/web/auth/clients",
  CODE: "/web/code",
  CODES: "/web/codes",
  CLIENTS: "/web/clients",
  CLIENTS_NEW: "/web/clients/new",
  CLIENT: "/web/clients/:clientId",
  ZONES: "/web/clients/:clientId/zones",
  ZONES_NEW: "/web/clients/:clientId/zones/new",
  DASHBOARD: "/web/admin/dashboard",
  FLEET_MANAGEMENT: "/web/fleet",
  USERS: "/web/users",
  USERS_NEW: "/web/users/new",
  SIGNUP: "/web/signup",
  IAQ_CONTROL_CENTER: "/web/IAQ",
};

export const resolvePath = (
  path: string,
  replaceMap: { [key: string]: string | number }
) => {
  let resolvedPath = path.slice();
  Object.entries(replaceMap).forEach(([key, value]) => {
    const replacedBy = typeof value === "number" ? value.toString() : value;
    resolvedPath = resolvedPath.replace(key, replacedBy);
  });
  return resolvedPath;
};

export const format = (
  path: string,
  params: { [key: string]: string }
): string =>
  Object.entries(params).reduce(
    (result, [key, value]) => result.replace(new RegExp(`:${key}`, "g"), value),
    path
  );
