import {
  Box,
  Button,
  Container,
  FormControl,
  InputLabel,
  TextField,
  Typography,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import { getClients } from "../../services/clientService";
import { AUTH_TOKEN, COPY_SLOGAN } from "../../const";
import { PATHS } from "../../router";

function GenerateCodesPage() {
  const navigate = useNavigate();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [client, setClient] = useState("");
  const [DBName, setDBName] = useState("");
  const [code, setCode] = useState("");
  const [clientList, setClientList] = useState([]);

  useEffect(() => {
    getClients().then((value) =>
      setClientList(
        value.data.map((entry) => [entry.client_name, entry.client_dbname])
      )
    );
  }, []);

  const generateCode = async () => {
    const token = localStorage.getItem(AUTH_TOKEN);
    const requestURL = `${process.env.REACT_APP_API_DOMAIN}/v1/auth/generate_code`;
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: token,
      },
      body: JSON.stringify({
        client,
        DBName,
        firstName,
        lastName,
      }),
    };
    const response = await fetch(requestURL, requestOptions);
    const responseBody = await response.json();
    setCode(responseBody.code);
  };

  const handleClientSelect = (event) => {
    setClient(event.target.value[0]);
    setDBName(event.target.value[1]);
  };

  return code ? (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <Box>
        <Typography>New Code Generated</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          border: 1,
          height: "72px",
          width: "1368px",
        }}
      >
        <Typography>{code}</Typography>
      </Box>
      <Button
        variant="outlined"
        onClick={() => navigator.clipboard.writeText(code)}
      >
        Copy Code
      </Button>
    </Container>
  ) : (
    <Container
      maxWidth="xs"
      sx={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Logo sx={{ marginBottom: "2rem" }} />
        <Typography align="left" variant="p">
          {COPY_SLOGAN}
        </Typography>
        <Typography align="left" variant="h1">
          Generate Code
        </Typography>
        <TextField
          variant="outlined"
          sx={{ marginBottom: "1rem" }}
          type="text"
          placeholder="First Name"
          label="First Name"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          variant="outlined"
          sx={{ marginBottom: "1rem" }}
          type="text"
          placeholder="Last Name"
          label="Last Name"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
        <FormControl fullWidth variant="outlined">
          <InputLabel>Client</InputLabel>
          <Select onChange={handleClientSelect}>
            {clientList.map((element) => (
              <MenuItem value={element}>{element[0]}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button onClick={() => navigate(PATHS.CODES)}>
          See Past Generated Codes
        </Button>
        <Button onClick={generateCode} variant="contained" size="large">
          Generate Code
        </Button>
      </Box>
    </Container>
  );
}

export default GenerateCodesPage;
