import Box from "@mui/material/Box";
import React, {
  createContext,
  useState,
  useMemo,
  useContext,
  useEffect,
} from "react";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import { Nullable } from "src/helper";

export type Page<Props> = {
  name: string;
  props: Props;
};

export const defaultPageName = "default";
const makeDefaultPage = (): Page<{}> => ({
  name: defaultPageName,
  props: {},
});

export type ContextValue = {
  page: Page<any>;
  pushToPage: (page: Page<any>, opts?: { replace?: boolean }) => void;
  backToLastPage: () => void;
};

export const Context = createContext<ContextValue>({
  page: {} as never,
  pushToPage: () => {
    throw Error("Context is not initiated");
  },
  backToLastPage: () => {
    throw Error("Context is not initiated");
  },
});

type PageRendererProps<PagePropsType> = {
  pageName: string;
  component: React.FunctionComponent<PagePropsType>;
  // if true, mount component only when it is picked
  mountOnEntered?: boolean;
  // if true, dismount component when exit to other pages
  dismountOnExited?: boolean;
  // Component will be unmount quietly when defaultProps is null
  // If you don't want the component be unmount, you need provide defaultProps
  defaultProps: Nullable.T<PagePropsType>;
};
export function PageRenderer<PagePropsType extends {}>({
  component,
  pageName,
  defaultProps,
  mountOnEntered = false,
  dismountOnExited = false,
}: PageRendererProps<PagePropsType>) {
  const { page: currentPage } = useContext(Context);
  const isCurrentPage = currentPage.name === pageName;
  const [render, setRender] = useState(!mountOnEntered);
  useEffect(() => {
    if (isCurrentPage) {
      setRender(true);
    } else if (dismountOnExited) {
      setRender(false);
    }
  }, [isCurrentPage, dismountOnExited]);

  const [propsCache, setPropsCache] = useState(defaultProps);
  useEffect(() => {
    if (isCurrentPage) {
      setPropsCache(currentPage.props);
    }
  }, [isCurrentPage, currentPage.props]);
  const props = isCurrentPage ? currentPage.props : propsCache || defaultProps;

  return (
    <Box sx={{ display: isCurrentPage ? "block" : "none" }}>
      {render && props
        ? React.createElement<PagePropsType>(component, props)
        : null}
    </Box>
  );
}

type PageState = {
  page: Page<any>;
  locationKey: string;
};

type Props = {
  children: React.ReactNode;
};
export default function PageController({ children }: Props) {
  const [[currentPage, lastPages], setPageState] = useState<
    [Page<any>, Array<PageState>]
  >([makeDefaultPage(), []]);

  const navigate = useNavigate();
  const location = useLocation();
  const { clientId } = useParams();

  useEffect(() => {
    // work around to handle page when user click browser location back button
    const currentLocationKey = location.key;
    const index = lastPages.findIndex(
      ({ locationKey }) => locationKey === currentLocationKey
    );
    if (index >= 0) {
      setPageState([lastPages[index].page, lastPages.slice(index + 1)]);
    }
  }, [location, lastPages, setPageState]);

  const value = useMemo(
    () => ({
      page: currentPage,
      pushToPage: (
        p: Page<any>,
        opts: { replace?: boolean } = { replace: false }
      ) => {
        setPageState(([current, last]) => [
          p,
          [{ locationKey: location.key, page: current }, ...last],
        ]);
        // keep the current clientId path if exists
        navigate(`${clientId ? `${clientId}/` : ""}${p.name}`, {
          replace: opts.replace || false,
        });
      },
      backToLastPage: () => {
        navigate(-1);
        setPageState(([, last]) => {
          if (last.length <= 0) return [makeDefaultPage(), []];
          return [last[0].page, last.slice(1)];
        });
      },
    }),
    [currentPage, navigate, clientId, location]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
}
