import React from "react";
import {
  FormControlLabel,
  TextField,
  Theme,
  SxProps,
  MenuItem,
  Stack,
  Checkbox,
} from "@mui/material";
import type { MenuProps } from "@mui/material/Menu";
import type { MenuItemProps } from "@mui/material/MenuItem";

type Props<V> = {
  sx?: SxProps<Theme>;
  label: React.ReactNode;
  value: V;
  required?: boolean;
  fullWidth?: boolean;
  onChange: (v: V) => void;
  children: React.ReactNode;
  multiple?: boolean;
  error?: boolean;
  helperText?: React.ReactNode;
  MenuProps?: Partial<MenuProps>;
};

export const Item = (props: MenuItemProps & { showCheckbox?: boolean }) => {
  const { showCheckbox, children, ...rest } = props;
  const { selected } = props;
  return React.createElement(
    MenuItem,
    rest,
    showCheckbox ? (
      <Stack direction="row" alignItems="center">
        <Checkbox size="small" checked={!!selected} /> {children}
      </Stack>
    ) : (
      children
    )
  );
};

export default function UISelect<V>({
  sx = {},
  value,
  required,
  fullWidth,
  onChange,
  children,
  label,
  error,
  helperText,
  multiple = false,
  MenuProps,
}: Props<V>) {
  return (
    <FormControlLabel
      componentsProps={{
        typography: {
          variant: "subtitle3",
          sx: { marginBottom: 0.5 },
        },
      }}
      sx={{
        marginLeft: 0,
        marginRight: 0,
        alignItems: "flex-start",
        width: fullWidth ? "100%" : "auto",
        ".MuiFormControlLabel-label": {
          ":after": required
            ? {
                paddingLeft: 0.5,
                content: "'*'",
                color: "orange",
              }
            : {},
        },
        ...sx,
      }}
      defaultValue=""
      label={label}
      labelPlacement="top"
      control={
        <TextField
          variant="outlined"
          select
          sx={{
            // workaround to show placeholder
            // https://github.com/mui/material-ui/issues/11069#issuecomment-1238685560
            "& .MuiSelect-select .notranslate::after": {
              content: "'Select'",
              opacity: 0.42,
            },
          }}
          required={required}
          fullWidth
          inputProps={{ placeholder: "Select" }}
          SelectProps={{ multiple, MenuProps }}
          value={value}
          onChange={(e) => {
            const v = e.target.value;
            onChange(v as V);
          }}
          error={error}
          helperText={helperText}
        >
          {children}
        </TextField>
      }
    />
  );
}
