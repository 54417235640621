import React from "react";
import { Stack, Typography } from "@mui/material";
import { sideLabelWidth } from "../constants";

type Props = { children: React.ReactNode; label: React.ReactNode };

export default function RowBox({ children, label }: Props) {
  return (
    <Stack direction="row" flexWrap="wrap">
      <Stack sx={{ flex: `0 1 ${sideLabelWidth}` }}>
        <Typography variant="h7">{label}</Typography>
      </Stack>
      {children}
    </Stack>
  );
}
