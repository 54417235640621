import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Zone } from "src/interfaces/IZone";
import { Property } from "src/interfaces/IProperty";
import { Device } from "src/interfaces/IDevice";
import { ClientDetails } from "src/interfaces/IClient";
import { queryGetDevices } from "src/services/graphql/Device";
import _get from "lodash/get";
import { propertyQuery, zoneQuery } from "src/services/graphqlService";
import type { FormType } from "./FormFactory";
import * as FormFactory from "./FormFactory";

type GqlTableQueryResult<ResultDataType> = {
  data: Array<ResultDataType>;
  isLoading: boolean;
  refetch: () => Promise<unknown>;
};

function useDeviceQuery(
  client: ClientDetails,
  zones: Array<Zone>
): GqlTableQueryResult<Device> {
  const [devices, setDevices] = useState<Array<Device>>([]);

  const {
    loading: isLoading,
    refetch,
    data,
  } = useQuery(queryGetDevices(client.client_dbname), {
    variables: {
      zone_id: {
        _in: zones.map((z) => z.zone_id),
      },
    },
    skip: zones.length <= 0,
  });

  useEffect(() => {
    setDevices(_get(data, `${client.client_dbname}_device`, []));
  }, [data, setDevices, client]);

  return { data: devices, isLoading, refetch };
}

export function usePropertyQuery(
  client: ClientDetails
): GqlTableQueryResult<Property> {
  const [properties, setProperties] = useState([]);
  const {
    loading: isLoading,
    refetch,
    data,
  } = useQuery(propertyQuery(client.client_dbname), {
    skip: client.client_dbname === "",
  });

  useEffect(() => {
    setProperties(_get(data, `${client.client_dbname}_property`, []));
  }, [data, setProperties, client]);

  return { data: properties, isLoading, refetch };
}

export function useZoneQuery(client: ClientDetails): GqlTableQueryResult<Zone> {
  const [zones, setZones] = useState([]);
  const {
    loading: isLoading,
    refetch,
    data,
  } = useQuery(zoneQuery(client.client_dbname), {
    skip: client.client_dbname === "",
  });

  useEffect(() => {
    setZones(_get(data, `${client.client_dbname}_zone`, []));
  }, [data, setZones, client]);

  return { data: zones, isLoading, refetch };
}

interface Options extends FormFactory.ComponentOptions {
  controlledValue: FormFactory.SelectedValue;
}

const useControlledValue = (
  setForm: React.Dispatch<React.SetStateAction<FormType>>,
  controlledValue: undefined | FormFactory.SelectedValue
) => {
  useEffect(() => {
    const pid = controlledValue?.propertyId;
    if (pid) {
      setForm((st: FormType) => FormFactory.withModifiedPropertyId(pid, st));
    }
  }, [controlledValue?.propertyId, setForm]);

  useEffect(() => {
    const zid = controlledValue?.zoneId;
    if (zid) {
      setForm((st: FormType) => FormFactory.withModifiedZoneId(zid, st));
    }
  }, [controlledValue?.zoneId, setForm]);

  useEffect(() => {
    const zids = controlledValue?.zoneIdsOfFloors;
    if (zids) {
      setForm((st: FormType) =>
        FormFactory.withModifiedZoneIdOfFloors(zids, st)
      );
    }
  }, [controlledValue?.zoneIdsOfFloors, setForm]);
};

export default function useDeviceSelector(
  client: ClientDetails,
  options?: Options
) {
  const [form, setForm] = useState<FormType>(
    FormFactory.make(options?.controlledValue)
  );

  useControlledValue(setForm, options?.controlledValue);

  const propertyQueryResult = usePropertyQuery(client);
  const properties = propertyQueryResult.data;
  useEffect(() => {
    // This is a constraint to prevent the form from being reset when the data is not ready
    // TODO: remove this constraint by changing pending data in usePropertyQuery
    if (properties.length > 0) {
      setForm((st: FormType) =>
        FormFactory.withModifiedProperties(properties, st)
      );
    }
  }, [properties, setForm]);
  // use refetch zone when zone is delete is important
  // because delete zone cause both device data and zone data change
  const zoneQueryResult = useZoneQuery(client);
  const zones = zoneQueryResult.data;
  useEffect(() => {
    // This is a constraint to prevent the form from being reset when the data is not ready
    // TODO: remove this constraint by changing pending data in useZoneQuery
    if (zones.length > 0) {
      setForm((st) => FormFactory.withModifiedZones(zones, st));
    }
  }, [zones, setForm]);

  const deviceQueryResult = useDeviceQuery(
    client,
    FormFactory.getIncludedZones(form)
  );

  const formElement = (
    <FormFactory.Component form={form} setForm={setForm} options={options} />
  );

  return {
    devices: deviceQueryResult.data,
    properties,
    property: FormFactory.getSelectedProperty(form),
    zone: FormFactory.getSelectedZone(form),
    zones: FormFactory.toZoneOptions(form),
    floors: FormFactory.getSelectedFloors(form),
    isLoading: {
      property: propertyQueryResult.isLoading,
      zone: zoneQueryResult.isLoading,
      device: deviceQueryResult.isLoading,
    },
    formElement,
    refetchZone: zoneQueryResult.refetch,
    refetchDevice: deviceQueryResult.refetch,
  };
}
