export const DEFAULT_ZONE_NAME = "DEFAULT";
export const DEFAULT_FLOOR_NAME = "DEFAULT";

export interface Zone {
  zone_id: number;
  zone_name: string;

  property_id: number;
  floor: string;
  extra_data?: null | JSON;
}
