import { ArrowBack } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import _isEqual from "lodash/isEqual";
import { ClientDetails, ClientHash } from "src/interfaces/IClient";
import { Device as DeviceType, DeviceShadow } from "src/interfaces/IDevice";
import PageContainer from "src/components/Layout/PageContainer";
import useDeviceSelector from "src/components/Widget/DeviceSelector";
import { Shadow } from "src/interfaces/IShadow";
import RemoveModal from "src/components/UI/RemoveDeviceModal";
import RelocationModal from "src/components/UI/RelocateDeviceModal";
import { ClientContext } from "../../contexts/GqlContext";
import { getClientFleet } from "../../services/restful/device";
import { PATHS, resolvePath } from "../../router";
import { Floors } from "./Floor";

import * as Nullable from "../../helper/nullable";
import SuperUserOnly from "../../components/Auth/SuperUserOnly";
import UpdateDatabaseModal, { DEVICE_SIZE_LIMIT } from "./UpdateDatabaseModal";
import ToastMaker from "../../components/UI/NotificationToast";

const Toast = ToastMaker();

type Shadows = { [deviceId: string]: undefined | Shadow };

const notiEmitSuccess = (clientName: string) =>
  Toast.emit({
    delay: 3000,
    type: "Success",
    title: `${clientName} Database Successfully Updated`,
    content: `You've successfully updated firmware for ${clientName}`,
  });

async function queryShadows(
  clientHash: ClientHash,
  deviceIds: Array<string>
): Promise<Shadows> {
  if (!deviceIds || deviceIds.length === 0) {
    return {};
  }
  const res = await getClientFleet({
    clientHash,
    deviceIds,
  });

  if (!res.data) {
    return {};
  }
  return res.data.reduce(
    (acc: Shadows, { device_id, shadow }: DeviceShadow) => {
      acc[device_id] = shadow;
      return acc;
    },
    {}
  );
}

function mergeDevicesAndShadows(
  devices: Array<DeviceType>,
  shadows: Shadows
): Array<{ device: DeviceType; shadow: undefined | Shadow }> {
  return devices.map((device) => {
    const shadow = shadows[device.device_id];
    console.warn(
      `Device shadow lost from query. device_id: ${device.device_id}`
    );
    return { device, shadow };
  });
}

function useShadowQuery(clientHash: ClientHash, devices: Array<DeviceType>) {
  const [deviceIds, setDeviceIds] = useState<Array<string>>([]);
  useEffect(() => {
    setDeviceIds((oldDeviceIds) => {
      const newDeviceIds = devices.map((d) => d.device_id).sort();
      // This state triggers query shadow.
      // So perform a comparison first so no redundant state update.
      return _isEqual(oldDeviceIds, newDeviceIds) ? oldDeviceIds : newDeviceIds;
    });
  }, [devices]);
  const [shadows, setShadows] = useState({});
  useEffect(() => {
    queryShadows(clientHash, deviceIds)
      .catch((e) => {
        // This is a temporary workaround so that it won't block all device display
        console.error(e);
        return {};
      })
      .then(setShadows);
  }, [clientHash, deviceIds]);

  return shadows;
}

export default function ZonePage() {
  const { client } = useContext(ClientContext);
  const navigate = useNavigate();

  const [deviceToRelocate, setDeviceToRelocate] =
    useState<Nullable.T<{ device: DeviceType; floor: string }>>(null);
  const [deviceToRemove, setDeviceToRemove] =
    useState<Nullable.T<DeviceType>>(null);

  const {
    floors,
    zones,
    refetchZone,
    devices: rawDevices,
    refetchDevice,
    isLoading,
    formElement,
  } = useDeviceSelector(client);

  const shadows = useShadowQuery(client.hash, rawDevices);

  const [devices, setDevices] = useState<
    Array<{ device: DeviceType; shadow: undefined | Shadow }>
  >([]);
  useEffect(() => {
    setDevices(mergeDevicesAndShadows(rawDevices, shadows));
  }, [rawDevices, shadows]);

  // update database modal controller
  const [isUpdateDatabaseModalOpen, setIsUpdateDatabaseModalOpen] =
    useState<boolean>(false);

  const handleRelocation = (device: DeviceType, floor: string) => {
    setDeviceToRelocate({ device, floor });
  };

  const handleRemove = (device: DeviceType) => {
    setDeviceToRemove(device);
  };

  return (
    <>
      <PageContainer isLoading={isLoading.property || isLoading.zone}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginBottom: 7,
          }}
        >
          <IconButton onClick={() => navigate(PATHS.CLIENTS)} edge="start">
            <ArrowBack />
          </IconButton>
          <Typography variant="h2" sx={{ margin: "0 1rem" }} fontSize="40px">
            {client.client_name}
          </Typography>
          <Button
            variant="contained"
            onClick={() =>
              navigate(
                resolvePath(PATHS.ZONES_NEW, {
                  ":clientId": client.client_id,
                })
              )
            }
          >
            Add New Zone
          </Button>
          <SuperUserOnly>
            <Button
              variant="outlined"
              sx={{ margin: "0 1rem" }}
              onClick={() => setIsUpdateDatabaseModalOpen(true)}
            >
              Update Device Shadow
            </Button>
          </SuperUserOnly>
        </Box>
        <Box>
          {formElement}
          {zones.length === 0 ? (
            "No zone currently in this property."
          ) : (
            <Floors
              devices={devices}
              zones={zones}
              floors={floors}
              onZoneChange={() => refetchZone().then()}
              onRelocate={handleRelocation}
              onRemove={handleRemove}
            />
          )}
        </Box>
      </PageContainer>
      <UpdateDatabaseModal
        isOpen={isUpdateDatabaseModalOpen}
        onClose={(_, reason) => {
          if (reason !== "backdropClick") {
            setIsUpdateDatabaseModalOpen(false);
            if (devices.length < DEVICE_SIZE_LIMIT)
              notiEmitSuccess(client.client_name);
          }
        }}
        deviceSize={devices.length}
        clientId={client.client_id}
      />
      <RemoveModal
        isOpen={deviceToRemove !== null}
        onClose={() => setDeviceToRemove(null)}
        device={deviceToRemove}
        clientDBName={client.client_dbname}
        onRemove={() => refetchDevice().then()}
      />
      <RelocationModal
        isOpen={deviceToRelocate !== null}
        onClose={() => setDeviceToRelocate(null)}
        device={deviceToRelocate?.device || null}
        floor={deviceToRelocate?.floor || ""}
        client={client}
        onDeviceChange={() => refetchDevice().then()}
      />
      <Toast.Component />
    </>
  );
}
