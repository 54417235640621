import React from "react";
import GqlProvider from "src/contexts/GqlContext";
import * as DefaultPage from "./pages/DefaultPage";
import PageController, { PageRenderer } from "./PageController";
import * as ChangeMode from "./pages/ChangeModePage";
import * as CreateProgram from "./pages/CreateProgramPage";
import * as UpdateFirmware from "./pages/UpdateFirmwarePage";
import * as ViewProgram from "./pages/ViewProgram";
import * as EditProgram from "./pages/EditProgramPage";
import ErrorToast from "./ErrorToast";

export default function FleetManagement() {
  return (
    <PageController>
      <PageRenderer
        pageName={DefaultPage.pageName}
        component={DefaultPage.Component}
        defaultProps={{}}
      />
      <GqlProvider isBatchMode noLoadingAnimation>
        <PageRenderer
          pageName={ChangeMode.pageName}
          component={ChangeMode.Component}
          defaultProps={null}
          mountOnEntered
          dismountOnExited
        />

        <PageRenderer
          pageName={CreateProgram.pageName}
          component={CreateProgram.Component}
          defaultProps={null}
          mountOnEntered
          dismountOnExited
        />
        <PageRenderer
          pageName={EditProgram.pageName}
          component={EditProgram.Component}
          defaultProps={null}
          mountOnEntered
          dismountOnExited
        />
        <PageRenderer
          pageName={UpdateFirmware.pageName}
          component={UpdateFirmware.Component}
          defaultProps={{}}
          mountOnEntered
          dismountOnExited
        />
        <PageRenderer
          pageName={ViewProgram.pageName}
          component={ViewProgram.Component}
          defaultProps={null}
          mountOnEntered
        />
      </GqlProvider>
      <ErrorToast.Component />
    </PageController>
  );
}
