import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  LinearProgress,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Paper, { PaperProps } from "@mui/material/Paper";
import React, { useState } from "react";
import { Client } from "src/interfaces/IClient";
import { Member } from "src/interfaces/IMember";
import { axiosErrorToString } from "src/services/restful/utils";
import { Property } from "src/interfaces/IProperty";
import { MembershipRole, UserStatus } from "../../const";
import { createUser, randomString } from "../../services/userService";
import * as Nullable from "../../helper/nullable";

const FormPaperComponent = (props: PaperProps) =>
  React.createElement(Paper, { ...props, component: "form" } as PaperProps);

type Props = {
  isOpen: boolean;
  onClose: () => void;
  client: Client;
  onUserCreated: (member: Member) => void;
  properties: Property[];
};

function CreateUserModal({
  isOpen,
  onClose,
  client,
  onUserCreated,
  properties,
}: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [fullName, setFullName] = useState("");
  const [status] = useState(UserStatus.INVITED);
  const [assignedProperties, setAssignedProperties] = useState(Array<Property>);
  const [errorText, setErrorText] = useState<Nullable.T<string>>(null);

  const password = randomString();

  const handleFormSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const assignedPropertiesIds = assignedProperties.map(
        (property: Property) => property.property_id
      );
      const user = {
        email,
        full_name: fullName,
        status,
        password,
        is_superuser: false,
        phone_number: `+1${phoneNumber}`,
        extra_data: { properties: assignedPropertiesIds },
        membership: {
          client_id: client.client_id,
          role: MembershipRole.MEMBER,
        },
      };
      const response = await createUser(user);
      onUserCreated(response.data);
      onClose();
    } catch (err) {
      const message = axiosErrorToString(err as Error);
      setErrorText(message || "Request failed");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={isOpen}
      onClose={onClose}
      PaperComponent={FormPaperComponent}
      PaperProps={{ onSubmit: handleFormSubmit }}
    >
      {isLoading && (
        <LinearProgress
          sx={{
            position: "absolute",
            top: "0px",
            left: "0px",
            width: "100%",
          }}
        />
      )}
      <DialogTitle>Create New Point of Contact</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          value={fullName}
          onChange={(e) => setFullName(e.target.value)}
          label="Full Name"
          required
          placeholder="Full Name"
          sx={{ margin: ".5rem 0 " }}
        />
        <TextField
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          type="email"
          label="Email"
          placeholder="Email"
          sx={{ margin: ".5rem 0 " }}
        />
        <TextField
          fullWidth
          value={phoneNumber}
          onChange={(e) => setPhoneNumber(e.target.value)}
          required
          type="number"
          label="phoneNumber"
          placeholder="phoneNumber"
          sx={{ margin: ".5rem 0 " }}
        />
        <FormControl fullWidth variant="outlined" sx={{ margin: ".5rem 0 " }}>
          <InputLabel id="role-label">Property</InputLabel>
          <Select
            labelId="properties"
            label="Properties"
            multiple
            value={assignedProperties}
            // @ts-ignore - necessary to load object into value
            renderValue={(selected) =>
              selected
                .map((property: Property) => property.property_name)
                .join(", ")
            }
            // @ts-ignore - necessary to load object into value
            onChange={(e) => setAssignedProperties(e.target.value)}
          >
            {properties.map((property: Property) => (
              // @ts-ignore - necessary to load object into value
              <MenuItem value={property}>
                <Checkbox checked={assignedProperties.includes(property)} />
                {property.property_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {errorText && <Alert severity="error">{errorText}</Alert>}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={isLoading}
          onClick={onClose}
          color="info"
          variant="outlined"
        >
          Cancel
        </Button>
        <Button disabled={isLoading} type="submit" variant="contained">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CreateUserModal;
