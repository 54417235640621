import { ApolloCache, gql } from "@apollo/client";
import { Zone } from "src/interfaces/IZone";
import { Modifiers } from "@apollo/client/cache/core/types/common";
import curry from "lodash/curry";
import { ClientDBName } from "src/interfaces/IClient";

const queryGetZones = (clientDBName: ClientDBName) => gql`
query GetZones($property_id: Int_comparison_exp = {}) {
  ${clientDBName}_zone(where: {property_id: $property_id}) {
    zone_id
    zone_name
    property_id
    floor
    extra_data
  }
}
`;

const mutateAddZone = (clientDBName: ClientDBName) => gql`
  mutation AddZone($property_id: Int!, $zone_name: String!, $floor: String!) {
    insert_${clientDBName}_zone(objects: {
      {
        zone_name: $zone_name,
        property_id: $property_id,
        floor: $floor
      }

    }) {
      returning {
        zone_id
        zone_name
        property_id
        floor
        extra_data
      }
    }
  }
`;

const mutateAddZones = (clientDBName: ClientDBName) => gql`
  mutation AddZone($objects: [${clientDBName}_zone_insert_input!]!) {
    insert_${clientDBName}_zone(objects: $objects) {
      returning {
        zone_id
        zone_name
        property_id
        floor
        extra_data
      }
    }
  }
`;

const mutateUpdateZone = (clientDBName: ClientDBName) => gql`
  mutation UpdateZone($where: ${clientDBName}_zone_bool_exp!, $_set: ${clientDBName}_zone_set_input!) {
    update_${clientDBName}_zone(where: $where, _set: $_set) {
      returning {
        zone_id
        zone_name
        property_id
        floor
        extra_data
      }
    }
  }
`;

type Returning<V> = {
  data: {
    [key: string]: {
      returning: V;
    };
  };
};

type UpdateZone = (property: Zone, zones: Zone[]) => Zone[];
type UpdateZones = (property: Zone[], zones: Zone[]) => Zone[];

const updateZoneCache = curry(
  (
    method: UpdateZones,
    clientDBName: ClientDBName,
    action: "insert" | "update" | "delete",
    cache: ApolloCache<any>,
    { data }: Returning<Zone[]>
  ) => {
    if (clientDBName) {
      const newZones = data[`${action}_${clientDBName}_zone`].returning;

      const fields: Modifiers = {
        [`${clientDBName}_zone`]: (zones: Zone[]) => method(newZones, zones),
      };

      cache.modify({
        fields,
      });
    }
  }
);

const addZone: UpdateZone = (newZone, zones) => [newZone, ...zones];

const addZones: UpdateZones = (newZones, zones) =>
  newZones.reduce((acc, value) => addZone(value, acc), zones);

const addZoneCache = (clientDBName: ClientDBName) =>
  updateZoneCache(addZones, clientDBName, "insert");

const editZone: UpdateZone = (zone, zones) =>
  zones.map((p) => (p.zone_id === zone.zone_id ? zone : p));

const editZones: UpdateZones = (newZones, zones) =>
  newZones.reduce((acc, value) => editZone(value, acc), zones);

const editZoneCache = (clientDBName: ClientDBName) =>
  updateZoneCache(editZones, clientDBName, "update");

export {
  queryGetZones,
  mutateAddZone,
  mutateAddZones,
  mutateUpdateZone,
  addZoneCache,
  editZoneCache,
};
