import { Nullable, Parser } from "../helper";

const ShadowSymbol = Symbol("Opaque type for device shadow");

// eslint-disable-next-line @typescript-eslint/naming-convention
export enum LED_COLOR {
  BLUE = "blue",
  GREEN = "green",
  RED = "red",
  TEAL = "teal",
  WHITE = "white",
}

export enum MODE {
  AUTO = 2,
  OFF = 1,
  LOW = 3,
  MEDIUM = 4,
  HIGH = 5,
}

export const modes = [MODE.AUTO, MODE.OFF, MODE.LOW, MODE.MEDIUM, MODE.HIGH];

export const modeToName = (m: MODE) => {
  switch (m) {
    case MODE.OFF:
      return "Off";
    case MODE.AUTO:
      return "Auto";
    case MODE.LOW:
      return "Low";
    case MODE.MEDIUM:
      return "Medium";
    case MODE.HIGH:
      return "High";
    default:
      return "Unknown";
  }
};

export const modeToMotorSpeed = {
  [MODE.OFF]: 0,
  [MODE.AUTO]: 10,
  [MODE.LOW]: 10,
  [MODE.MEDIUM]: 30,
  [MODE.HIGH]: 100,
};

export type Shadow = { _opaque: typeof ShadowSymbol };

export function ignoreError<V>(
  parser: Parser.Parser<V>,
  { fallback }: { fallback: V }
): Parser.Parser<V> {
  return (value: Parser.Value) => {
    try {
      return parser(value);
    } catch {
      return fallback;
    }
  };
}

/*
 * These are getter function. In this way, we can apply some decoder to it
 * Strongly suggest apply error first. Otherwise you should always wrap them with try/catch
 */

/**
 * Extract error message if shadow has error. Otherwise return null
 */
export const error = (shadow: Shadow): Nullable.T<string> =>
  Parser.objectOptionalField("error", Parser.string, shadow as {});

export const lastConnectedTime = (shadow: Shadow) =>
  Parser.objectField("lastConnectedTime", Parser.dateFromNumber, shadow as {});
export const connected = (shadow: Shadow) =>
  Parser.objectField<boolean>("connected", Parser.boolean, shadow as {});
export const ledColor = (shadow: Shadow) =>
  Parser.objectOptionalField(
    "led_color",
    Parser.string /* currently ignore unknown color  */,
    shadow as {}
  );

const parseModeFromNumber = (n: number): MODE => {
  if (n < 1 || n > 5) {
    throw new Parser.ParseError(`Unrecognized mode value: ${n}`);
  }
  return n as MODE;
};
export const deviceMode = (shadow: Shadow) =>
  Parser.objectField(
    "device_mode",
    Parser.composeMany(
      Parser.oneOf([Parser.number, Parser.stringNumber]),
      parseModeFromNumber
    ),
    shadow
  ) as MODE;
export const nightMode = (shadow: Shadow): boolean =>
  Parser.objectField("night_mode", Parser.boolean, shadow);
export const lockMode = (shadow: Shadow): Nullable.T<boolean> =>
  Parser.objectOptionalField("lock_mode", Parser.boolean, shadow);

interface DesiredEmptyShadow {
  motor_speed?: undefined;
  device_mode?: undefined;
  led_color?: undefined;
  night_mode?: undefined;
  lock_mode?: undefined;
}

interface DesiredModeShadow {
  motor_speed: number;
  device_mode: MODE;
  led_color?: undefined;
  night_mode?: undefined;
  lock_mode?: undefined;
}

interface DesiredLedColorShadow {
  motor_speed?: undefined;
  device_mode?: undefined;
  led_color: LED_COLOR;
  night_mode?: undefined;
  lock_mode?: undefined;
}

interface DesiredNightModeShadow {
  motor_speed?: undefined;
  device_mode?: undefined;
  led_color?: undefined;
  night_mode: boolean;
  lock_mode?: undefined;
}

interface DesiredLockModeShadow {
  motor_speed?: undefined;
  device_mode?: undefined;
  led_color?: undefined;
  night_mode?: undefined;
  lock_mode: boolean;
}

// DesiredShadow are the schema that used for updating device shadow
export type DesiredShadow =
  | DesiredModeShadow
  | DesiredLedColorShadow
  | DesiredNightModeShadow
  | DesiredLockModeShadow
  | DesiredEmptyShadow;

export const makeDesiredModeShadow = (device_mode: MODE): DesiredShadow => ({
  device_mode,
  motor_speed: modeToMotorSpeed[device_mode],
});

export const makeDesiredLedColorShadow = (
  led_color: LED_COLOR
): DesiredShadow => ({ led_color });

export const makeDesiredNightModeShadow = (
  night_mode: boolean
): DesiredShadow => ({ night_mode });

export const makeDesiredLockModeShadow = (
  lock_mode: boolean
): DesiredShadow => ({ lock_mode });

export const makeDesiredEmptyShadow = (): DesiredShadow => ({});
