/* eslint-disable no-console */
import * as Nullable from "src/helper/nullable";
import {
  getHoursFromCronString,
  getMinutesFromCronString,
  Hour,
  Minute,
  weeklyCronStringByHHMM,
} from "src/helper/cron";
import { RecurringSchedule as Schedule } from "src/interfaces/ISchedule";
import {
  DesiredShadow,
  makeDesiredModeShadow,
  MODE,
} from "src/interfaces/IShadow";
import { DayOfWeek } from "src/helper/datetime";
import { ModeCycle, StartHour, EndHour } from "../ScheduleAndModes";

/**
 * End time cron string should always have minute 59 and hour: 0 - 23
 */
const hourMinuteToEndHour = (hour: Hour, minute: Minute): EndHour => {
  if (minute !== 59) {
    console.warn("Minute for end time should always be 59");
  }
  let h = hour;
  if (hour >= 24) {
    console.warn("Hour should not be greater than 24");
    h = 0;
  }

  return (h + 1) as EndHour;
};

/**
 * Start time cron string should always have minute 0 and hour: 0 - 23
 */
const hourMinuteToStartHour = (hour: Hour, minute: Minute): StartHour => {
  if (minute !== 0) {
    console.warn("Minute for start time should always be 0");
  }
  let h = hour;
  if (hour >= 24) {
    console.warn("Hour should not be greater than 24");
    h = 0;
  }

  return h;
};

const getHourFromSchedule = (schedule: Schedule): Hour => {
  const hours = getHoursFromCronString(schedule.recurring);
  if (hours.length === 0) {
    throw new RangeError(
      `Schedule must have at least one hour. ${schedule.schedule_id} is invalid`
    );
  }

  if (hours.length > 1) {
    console.warn(`Schedule ${schedule.schedule_id} has more than one hour.`);
  }
  return hours[0];
};

const getMinuteFromSchedule = (schedule: Schedule): Minute => {
  const minutes = getMinutesFromCronString(schedule.recurring);
  if (minutes.length === 0) {
    throw new RangeError(
      `Schedule must have one minute. ${schedule.schedule_id} is invalid`
    );
  }

  if (minutes.length > 1) {
    console.warn(
      `Schedule ${schedule.schedule_id} shouldn't have more than one minute.`
    );
  }
  return minutes[0];
};

/** It's assumed that the all desired shadow of a schedule are the same.
 */
const getModeFromSchedule = (schedule: Schedule): undefined | MODE =>
  schedule?.desired_shadow?.device_mode;

/*
 * Sort schedules by hour in descending order
 */
export const sorter = (s1: Schedule, s2: Schedule) => {
  const hourS1 = getHourFromSchedule(s1);
  const minuteS1 = getMinuteFromSchedule(s1);
  const hourS2 = getHourFromSchedule(s2);
  const minuteS2 = getMinuteFromSchedule(s2);
  if (hourS1 === hourS2) {
    if (minuteS1 === minuteS2) return 0;
    return minuteS1 < minuteS2 ? 1 : -1;
  }

  return hourS1 < hourS2 ? 1 : -1;
};

/**
 * @param schedules: Should be sorted in descending order: [23, 22, 21, ...]
 * Ideally, the schedules are sorted like: [end, start, end, start, ...]
 * End time should always be MODE.AUTO. If not, we assume that the end time is missing.
 * But it's not guaranteed. So we need to take care of the following cases:
 * [...end, end...]
 * [...start, start...]
 */
const make = (
  endHour: Nullable.T<EndHour>,
  schedules: Schedule[],
  acc: ModeCycle[]
): ModeCycle[] => {
  if (schedules.length === 0) return acc.reverse();

  const [first, ...rest] = schedules;
  const mode = getModeFromSchedule(first);
  const hour = getHourFromSchedule(first);
  const minute = getMinuteFromSchedule(first);

  // mode is missing. Ignore this schedule
  if (mode === undefined) {
    console.warn(`Schedule ${first.schedule_id} has no mode. Ignore it.`);
    return make(endHour, rest, acc);
  }

  if (endHour === null) {
    if (mode !== MODE.AUTO) {
      console.warn(
        `Consecutive start hours. Take as end hour missing.`,
        schedules
      );

      return make(null, rest, [
        {
          mode,
          startTime: hourMinuteToStartHour(hour, minute),
          endTime: null,
        },
        ...acc,
      ]);
    }

    return make(hourMinuteToEndHour(hour, minute), rest, acc);
  }

  return make(null, rest, [
    {
      mode,
      startTime: hourMinuteToStartHour(hour, minute),
      endTime: endHour,
    },
    ...acc,
  ]);
};

export const packSchedulesToModeCycles = (schedules: Schedule[]): ModeCycle[] =>
  make(null, [...schedules].sort(sorter), []).reverse();

export const unpackModeCycles = (
  daysOfWeek: DayOfWeek[],
  modeCycles: ModeCycle[]
): {
  recurring: string;
  desired_shadow: DesiredShadow;
}[] => {
  if (modeCycles.length === 0) return [];

  const [first, ...rest] = modeCycles;

  const start = first.startTime && {
    recurring: weeklyCronStringByHHMM(daysOfWeek, first.startTime, 0),
    desired_shadow: makeDesiredModeShadow(first.mode),
  };

  const end = first.endTime && {
    recurring: weeklyCronStringByHHMM(
      daysOfWeek,
      (first.endTime - 1) as Hour,
      59
    ),
    desired_shadow: makeDesiredModeShadow(MODE.AUTO),
  };

  if (start && end) {
    return [start, end, ...unpackModeCycles(daysOfWeek, rest)];
  }
  // if start or end is missing, it means the modeCycle is incomplete and should be ignored
  return unpackModeCycles(daysOfWeek, rest);
};
