import Stack from "@mui/material/Stack";
import React, { useContext, useEffect, useState } from "react";
import TurboFanLogoIcon from "src/assets/icons/TurboFanLogo";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import ScheduleIcon from "@mui/icons-material/Schedule";

import {
  CircularProgress,
  IconButton,
  Typography,
  useTheme,
} from "@mui/material";
import moment from "moment";
import { Device } from "src/interfaces/IDevice";
import { ClientContext } from "src/contexts/GqlContext";
import { axiosErrorToString } from "src/services/restful/utils";
import { Nullable } from "src/helper";
import { useApolloClient, useQuery } from "@apollo/client";
import { queryGetDevices } from "src/services/graphql/Device";
import _get from "lodash/get";
import { Property } from "src/interfaces/IProperty";
import { startTurboMode, stopTurboMode, useTurboModeSchedule } from "./api";
import { INACTIVE_RED, WYND_WHITE } from "../../styles";
import ErrorToast from "./ErrorToast";

function TurboFanLogo({ modeIsEnabled }: { modeIsEnabled: boolean }) {
  return (
    <TurboFanLogoIcon
      height="100%"
      sx={{
        height: "100%",
        width: "72px",
        margin: "10px 32px",
        color: modeIsEnabled ? "white" : "#879497",
      }}
    />
  );
}

function timeLeft(date: Date, now: Date): string {
  const endTime = moment(date);
  const diff = endTime.diff(now);

  if (diff < 0) return "00:00:00";

  return moment.utc(diff).format("HH:mm:ss");
}

function StatusBadge({
  modeIsEnabled,
  endAt,
}: {
  modeIsEnabled: boolean;
  endAt: undefined | Date;
}) {
  const [now, setNow] = useState(new Date());
  const theme = useTheme();

  useEffect(() => {
    const iid = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(iid);
  }, []);

  return (
    <Typography
      component="span"
      sx={{
        display: "inline-flex",
        alignItems: "center",
        marginLeft: 1.5,
        borderRadius: "16px",
        backgroundColor: modeIsEnabled ? "white" : "#CFCFCF",
        padding: "6px 12px",
        fontSize: "14px",
        lineHeight: "20px",
        height: "28px",
        color: modeIsEnabled
          ? theme.palette.primary.main
          : theme.palette.text.primary,
        gap: 1,
      }}
    >
      {modeIsEnabled && endAt ? (
        <>
          <Typography variant="subtitle3">ON</Typography>
          <ScheduleIcon sx={{ fontSize: "14px" }} />
          <Typography variant="subtitle3">{timeLeft(endAt, now)}</Typography>
        </>
      ) : (
        "OFF"
      )}
    </Typography>
  );
}

type StopButtonProps = {
  isLoading: boolean;
  modeIsEnabled: boolean;
  disabled: boolean;
  onClick: () => Promise<void>;
};

function StopButton({
  modeIsEnabled,
  isLoading,
  disabled,
  onClick,
}: StopButtonProps) {
  const theme = useTheme();

  return (
    <IconButton
      onClick={onClick}
      disabled={disabled}
      sx={[
        { width: "56px", height: "56px", margin: "18px 45px" },
        modeIsEnabled
          ? {
              backgroundColor: "white",
              color: INACTIVE_RED,
              boxShadow: "0px 0px 12px rgba(255, 255, 255, 0.8)",
            }
          : {
              backgroundColor: theme.palette.primary.main,
              color: "white",
              boxShadow: "0px 0px 8px 0px #2DCCD3CC",
            },
      ]}
    >
      {isLoading ? (
        <CircularProgress size={24} color="inherit" />
      ) : (
        <PowerSettingsNewIcon />
      )}
    </IconButton>
  );
}

const useClientDevices = () => {
  const { client } = useContext(ClientContext);
  const [devices, setDevices] = useState<Nullable.T<Array<Device>>>(null);
  const { loading } = useQuery(queryGetDevices(client.client_dbname), {
    variables: {},
    onCompleted: (data) =>
      setDevices(_get(data, `${client.client_dbname}_device`, [])),
    onError: (error) => {
      ErrorToast.emit({
        delay: "persist",
        type: "Failed",
        title: "Initialization failed when trying to get client devices",
        content: error.message,
      });
      console.error(error);
    },
  });

  return {
    isLoading: devices === null || loading,
    devices: devices || [],
  };
};

const useIsLoading = (
  otherIsLoading: boolean
): [boolean, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [isLoading, setIsLoading] = useState(false);

  return [isLoading || otherIsLoading, setIsLoading];
};

const datetimeIsLaterThanNow = (datetimeString: string) => {
  const d = new Date(datetimeString);
  const now = new Date();
  if (Number.isNaN(d)) {
    console.warn(`Invalida date string ${datetimeString}`);
    return true;
  }

  return now <= d;
};

type Props = {
  property: Nullable.T<Property>;
};

export default function TurboMode({ property }: Props) {
  const { client } = useContext(ClientContext);
  const gqlClient = useApolloClient();
  const {
    schedule,
    isLoading: scheduleIsLoading,
    refetch: refetchSchedule,
  } = useTurboModeSchedule(property);

  const modeIsEnabled =
    !!schedule && datetimeIsLaterThanNow(schedule.starting_at);

  const endAt = schedule && new Date(schedule.starting_at);
  // Turbo mode control all devices so not using the devices from parent's device selector
  const { devices, isLoading: deviceIsLoading } = useClientDevices();
  const [modeIsLoading, setModeIsLoading] = useIsLoading(deviceIsLoading);

  const handleClick = async () => {
    if (!property) return;

    setModeIsLoading(true);
    let action;
    try {
      if (schedule) {
        action = "stop";
        await stopTurboMode(client, devices, schedule.schedule_id);
      } else {
        action = "start";
        await startTurboMode(gqlClient, client, property);
      }
    } catch (err) {
      console.error(err);
      const message = axiosErrorToString(err as Error);
      ErrorToast.emit({
        delay: "persist",
        type: "Failed",
        title: `Failed to ${action} the schedule`,
        content: message || "Unexpected error",
      });
    } finally {
      refetchSchedule();
      setModeIsLoading(false);
    }
  };

  const isLoading = modeIsLoading || scheduleIsLoading;

  return (
    <Stack
      direction="row"
      sx={{
        height: "92px",
        borderRadius: "4px",
        background: modeIsEnabled
          ? `linear-gradient(90deg, #53ADC0 0%, #186196 100%), ${WYND_WHITE}`
          : WYND_WHITE,
        color: modeIsEnabled ? "white" : "initial",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack direction="row" sx={{ alignItems: "center" }}>
        <TurboFanLogo modeIsEnabled={modeIsEnabled} />
        <Stack direction="column" sx={{ margin: "15.5px 0px", gap: 1 }}>
          <Typography
            variant="h7"
            sx={{ display: "flex", alignItems: "center" }}
          >
            Turbo Mode{" "}
            <StatusBadge endAt={endAt} modeIsEnabled={modeIsEnabled} />
          </Typography>
          <Typography>
            Turn on all purifiers to maximum strength for 15 minutes
          </Typography>
        </Stack>
      </Stack>
      <StopButton
        modeIsEnabled={modeIsEnabled}
        isLoading={isLoading}
        onClick={handleClick}
        disabled={isLoading || !property}
      />
    </Stack>
  );
}

export { timeLeft };
