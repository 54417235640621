import type {
  ModeCycleError,
  ModeCycle,
  Value,
  ErrorObject,
  StartHour,
  EndHour,
} from "./types";

class ModeCycleErrorRecord implements ModeCycleError {
  startTimeIsEmpty: boolean;

  endTimeIsEmpty: boolean;

  constructor(modeCycle: ModeCycle) {
    this.startTimeIsEmpty = false;
    this.endTimeIsEmpty = false;

    if (modeCycle.startTime === null) {
      this.startTimeIsEmpty = true;
    }
    if (modeCycle.endTime === null) {
      this.endTimeIsEmpty = true;
    }
    const outOfRange =
      modeCycle.startTime !== null &&
      modeCycle.endTime !== null &&
      modeCycle.startTime >= modeCycle.endTime;
    if (outOfRange) {
      this.startTimeIsEmpty = true;
      this.endTimeIsEmpty = true;
    }
  }

  noError() {
    return !this.startTimeIsEmpty && !this.endTimeIsEmpty;
  }
}

class ErrorRecord implements ErrorObject {
  nameIsEmpty: boolean;

  daysOfWeekIsEmpty: boolean;

  modeCycleErrors: ModeCycleErrorRecord[];

  constructor(value: Value) {
    this.nameIsEmpty = value.name === "";
    this.daysOfWeekIsEmpty = value.daysOfWeek.length === 0;
    this.modeCycleErrors = value.modeCycles.map(
      (modeCycle) => new ModeCycleErrorRecord(modeCycle)
    );
  }

  noError() {
    return (
      !this.nameIsEmpty &&
      !this.daysOfWeekIsEmpty &&
      this.modeCycleErrors.every((modeCycleValidator) =>
        modeCycleValidator.noError()
      )
    );
  }
}

export const validate = (value: Value): ErrorRecord => new ErrorRecord(value);

export type {
  ErrorObject,
  Value,
  ModeCycle,
  ModeCycleError,
  StartHour,
  EndHour,
};
export { default as Display } from "./Display";
export { default as Edit } from "./Edit";
