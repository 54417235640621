import curry from "lodash/curry";

export type T<V> = V | null;

export const map = curry(<A, B>(f: (a: A) => B, v: T<A>): T<B> => {
  if (v === null) {
    return null;
  }
  return f(v);
});

export const flatMap = curry(<A, B>(f: (a: A) => T<B>, v: T<A>): T<B> => {
  if (v === null) {
    return null;
  }
  return f(v);
});

export const unwrapOr = curry(
  <A>(default_: A, v: T<A>): A => (v === null ? default_ : v)
);
