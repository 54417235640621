// This is a generic map that allows you to create a map with key of predefined object type (KeyType).
// It utilize immutable-js Map and Record to achieve this
// The keyFactory mask the fields of the KeyType so only those fields are used for key comparison
// See src/__tests__/interfaces/map/typedMap.ts for how to use it
// Immutable's Record: https://immutable-js.com/docs/v4.2.4/Record/
import { Map, Record } from "immutable";

export interface TypedMap<
  KeyType extends MaskedKeyType,
  MaskedKeyType extends {},
  Value
> {
  /**
   * Convert the map to an array of [KeyType, Value] pairs
   * @returns {Array<[KeyType, Value]>}
   */
  toArray(): [KeyType, Value][];
  /**
   * Get the value of the given key
   * @param {KeyType} key
   * @returns {Value | undefined}
   */
  get(key: KeyType): undefined | Value;
  /**
   * Set the value of the given key
   * @param {KeyType} key
   * @param {Value} value
   * @returns {TypedMap<KeyType, MaskedKeyType, Value>}
   */
  set(key: KeyType, value: Value): TypedMap<KeyType, MaskedKeyType, Value>;
  /**
   * Check if the map has the given key
   * @param {KeyType} key
   * @returns {boolean}
   */
  has(key: KeyType): boolean;
  /**
   * Delete the given key from the map
   * @param {KeyType} key
   * @returns {TypedMap<KeyType, MaskedKeyType, Value>}
   */
  delete(key: KeyType): TypedMap<KeyType, MaskedKeyType, Value>;
  /**
   * Get the size of the map
   * @returns {number}
   */
  size: number;
  /**
   * Find the first key that matches the predicate
   * @param {(value: Value, key: KeyType) => boolean} predicate
   * @returns {Value | undefined}
   */
  find(predicate: (value: Value, key: KeyType) => boolean): undefined | Value;
  /**
   * Find the first key that matches the predicate
   * @param {(value: Value, key: KeyType) => boolean} predicate
   * @returns {KeyType | undefined}
   */
  findKey(
    predicate: (key: KeyType, value: Value) => boolean
  ): undefined | KeyType;
  /**
   * Get the first key and value in the map
   * @returns {[KeyType, Value] | undefined}
   */
  first(): undefined | [KeyType, Value];
}

class TypedMapBase<
  KeyType extends MaskedKeyType,
  MaskedKeyType extends {},
  Value
> implements TypedMap<KeyType, MaskedKeyType, Value>
{
  protected immutableMap: Map<
    Record<MaskedKeyType>,
    {
      key: KeyType;
      value: Value;
    }
  >;

  protected keyFactory: Record.Factory<MaskedKeyType>;

  constructor(
    keyFactory: Record.Factory<MaskedKeyType>,
    immutableMap: Map<
      Record<MaskedKeyType>,
      {
        key: KeyType;
        value: Value;
      }
    >
  ) {
    this.keyFactory = keyFactory;
    this.immutableMap = immutableMap;
  }

  toArray(): [KeyType, Value][] {
    return this.immutableMap
      .toArray()
      .map(([, { key, value }]) => [key, value]);
  }

  get(key: KeyType): undefined | Value {
    return this.immutableMap.get(this.keyFactory(key))?.value;
  }

  set(key: KeyType, value: Value): TypedMap<KeyType, MaskedKeyType, Value> {
    const newMap = this.immutableMap.set(this.keyFactory(key), {
      key,
      value,
    });
    return new TypedMapBase(this.keyFactory, newMap);
  }

  has(key: KeyType): boolean {
    return this.immutableMap.has(this.keyFactory(key));
  }

  delete(key: KeyType): TypedMap<KeyType, MaskedKeyType, Value> {
    const newMap = this.immutableMap.delete(this.keyFactory(key));
    return new TypedMapBase(this.keyFactory, newMap);
  }

  find(predicate: (value: Value, key: KeyType) => boolean): Value | undefined {
    return this.immutableMap.find(({ key, value }) => predicate(value, key))
      ?.value;
  }

  findKey(
    predicate: (key: KeyType, value: Value) => boolean
  ): KeyType | undefined {
    return this.immutableMap.find(({ key, value }) => predicate(key, value))
      ?.key;
  }

  first(): [KeyType, Value] | undefined {
    const first = this.immutableMap.first();
    if (first) {
      return [first.key, first.value];
    }
    return undefined;
  }

  get size(): number {
    return this.immutableMap.size;
  }
}

export const makeTypedMap =
  <KeyType extends MaskedKeyType, MaskedKeyType extends {}>(
    keyFactory: Record.Factory<MaskedKeyType>
  ) =>
  <Value>(
    init?: [KeyType, Value][]
  ): TypedMap<KeyType, MaskedKeyType, Value> => {
    let map: Map<
      Record<MaskedKeyType>,
      {
        key: KeyType;
        value: Value;
      }
    >;
    if (init && Array.isArray(init)) {
      map = Map(
        init.map(([key, value]) => [
          keyFactory(key),
          {
            key,
            value,
          },
        ])
      );
    } else {
      map = Map();
    }

    return new TypedMapBase(keyFactory, map);
  };
